import CustomTable from '@/common/components/custom/CustomTable';
import React, { useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';
import { useMutation } from 'react-query';
import Loading from '@/common/components/Loading';
import { getUserUsageStats } from '@/api/query/workflow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Color } from '@/common/colors/colors';
import moment from 'moment';
import DataTable from '@/common/components/custom/DataTable';

const TopContent = styled.div`
  display: flex;
  justify-content: right;
`;
const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ActionWrapper = styled.div`
  margin-bottom: 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

const HEADERS = [
  {
    header: 'User',
    accessorKey: 'email',
    size: 20,
  },
  {
    header: 'Amount',
    accessorKey: 'amount',
    size: 20,
  },
  {
    header: 'Days',
    accessorKey: 'days',
    size: 20,
  },
  {
    header: 'Last activity Date',
    accessorKey: 'last_activity',
    size: 20,
  },
  {
    header: 'Payment Date',
    accessorKey: 'payment_date',
    size: 20,
  },
  {
    header: 'Expired Date',
    accessorKey: 'expired_date',
    size: 20,
  },
  {
    header: 'Actions',
    accessorKey: '_id',
    size: 20,
    cell: (info) => (
      <div>
        <FontAwesomeIcon
          icon={faEye}
          color={Color.Dark}
          onClick={() =>
            window.open(`/admin/customer/stats/${info.getValue()}`, '_blank')
          }
        />
      </div>
    ),
  },
];

const HEADERS_TWO = [
  {
    header: 'Days',
    accessorKey: 'days',
    size: 20,
  },
  {
    header: 'Amount',
    accessorKey: 'amount',
    size: 20,
  },
  {
    header: 'Users',
    accessorKey: 'emails',
    size: 20,
  },
];

const AdminUsage = () => {
  const [tableData, setTableData] = useState([]);
  const [tableDataTwo, setTableDataTwo] = useState([]);

  const findStatsMutation = useMutation({
    mutationFn: () => getUserUsageStats(),
    onSuccess: ({ data }) => {
      setTableData(() => data.daysReportDetails);
      setTableDataTwo(() => data.daysReport);
    },
  });

  useEffect(() => {
    findStatsMutation.mutate();
  }, []);

  return (
    <>
      {findStatsMutation.isLoading && <Loading />}
      <Wrapper>
        <TableWrapper>
          <DataTable data={tableDataTwo} columns={HEADERS_TWO} />
        </TableWrapper>
        <TableWrapper>
          <DataTable data={tableData} columns={HEADERS} />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default AdminUsage;
