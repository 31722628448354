import CustomAddButton from '@/common/components/custom/buttons/CustomAddButton';
import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { useMutation } from 'react-query';
import CustomEditButton from '@/common/components/custom/buttons/CustomEditButton';
import Loading from '@/common/components/Loading';
import DataTable from '@/common/components/custom/DataTable';
import AdminPopulareEdit from './components/AdminPopulareEdit';
import { findAllPopulare } from '@/api/query/populare';

const TopContent = styled.div`
  display: flex;
  justify-content: right;
`;
const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
`;

const ActionWrapper = styled.div`
  margin-bottom: 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

const AdminPopulare = () => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [edit, setEdit] = useState();

  const HEADERS = [
    {
      header: 'no.',
      accessorKey: 'no',
      size: 20,
    },
    {
      header: 'Word',
      accessorKey: 'word',
      size: 20,
    },
    {
      header: 'Flashcard',
      accessorKey: 'flashcard',
      size: 20,
    },
    {
      header: 'Scene',
      accessorKey: 'scene',
      size: 20,
    },
    {
      header: 'Actions',
      accessorKey: 'actions',
      size: 20,
      cell: (info) => (
        <div style={{ display: 'flex', gap: '10px' }}>
          <CustomEditButton onClick={(e) => setEdit(info.getValue())} />
        </div>
      ),
    },
  ];

  const findLessonsMutation = useMutation({
    mutationFn: () => findAllPopulare(),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data.map((d, i) => ({
          no: i + 1,
          word: d.wordLessonType.length,
          flashcard: d.flashcard.length,
          scene: d.lesson.length,
          updatedAt: d.updatedAt,
          actions: d,
        })),
      );
      setData(data);
    },
  });

  useEffect(() => {
    if (!edit) {
      findLessonsMutation.mutate();
    }
  }, [edit]);

  return (
    <>
      {findLessonsMutation.isLoading && <Loading />}
      <Wrapper>
        {edit && <AdminPopulareEdit edit={edit} setEdit={setEdit} />}
        {tableData?.length === 0 && (
          <TopContent>
            <ActionWrapper>
              <CustomAddButton onClick={() => setEdit({})} />
            </ActionWrapper>
          </TopContent>
        )}
        <TableWrapper>
          <DataTable columns={HEADERS} data={tableData} rows={[5, 10, 15]} />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default AdminPopulare;
