import React from "react";
import { styled } from "styled-components";
import Icon from "@/common/images/Logo.svg";
import { Link, useNavigate } from "react-router-dom";

export const CustomLink = styled(Link)`
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
  gap: 2px;
  justify-content: center;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const CustomLinkMobile = styled(CustomLink)`
  display: none;

  @media (max-width: 600px) {
    display: flex;
  }
`;

const Wrapper = styled.header`
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 18px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 600px) {
    /* height: 60px; */
  }
`;

const Logo = styled.img`
  width: 136px;
  cursor: pointer;
`;

const BaseHeader = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Logo
        src={Icon}
        onClick={() => {
          navigate("/");
        }}
      />
    </Wrapper>
  );
};

export default BaseHeader;
