import CustomLine from '@/common/charts/Line';
import moment from 'moment';
import React, { useMemo } from 'react';

const MonthtargetChart = ({ payments }) => {
  const chartData = useMemo(() => {
    const labels = [];
    const targetData = [];
    const countryDataMap = new Map();
    const targetSum = 50_000;

    for (let i = 30; i >= 0; i--) {
      labels.push(moment().subtract(i, 'days').format('DD/MM'));
    }

    if (payments) {
      payments.forEach((payment) => {
        const country = payment.date.split('_')[0];
        if (!countryDataMap.has(country)) {
          countryDataMap.set(country, []);
        }
      });

      labels.forEach((label, i) => {
        countryDataMap.forEach((data, country) => {
          const currentPrice =
            payments.find((p) => {
              const county = p.date.split('_')[0];
              return county === country && p.date.split('_')[1] === label;
            })?.sum || 0;
          const previousPrice = data[i - 1] || 0;
          data.push(Math.round((currentPrice + previousPrice) * 100) / 100);
        });

        targetData.push(
          Math.round(
            (targetSum / 30 + (targetData[Math.max(i - 1, 0)] || 0)) * 100,
          ) / 100,
        );
      });
    }

    const sortedCountries = Array.from(countryDataMap.entries()).sort(
      ([a], [b]) => a.localeCompare(b),
    );

    const datasets = sortedCountries.map(([country, data], index) => ({
      label: country,
      data: data,
      borderColor: `hsl(${index * 50}, 70%, 50%)`,
      backgroundColor: `hsl(${index * 50}, 70%, 50%)`,
      yAxisID: 'y',
    }));

    datasets.push({
      label: 'target',
      data: targetData,
      borderColor: '#B2B2B2',
      backgroundColor: '#B2B2B2',
      yAxisID: 'y',
    });

    return {
      labels,
      datasets,
    };
  }, [payments]);

  return (
    <div
      style={{
        minWidth: '300px',
      }}
    >
      <CustomLine
        data={chartData}
        options={
          {
            // plugins: {
            //   legend: {
            //     display: false,
            //   },
            // },
          }
        }
      />
    </div>
  );
};

export default MonthtargetChart;
