import { getFlashcards } from '@/api/query/flashcard';
import { findFlashcardCategories } from '@/api/query/flashcard-category';
import { findLessons } from '@/api/query/lesson';
import { createPopulare, updatePopulare } from '@/api/query/populare';
import {
  findWordLesson,
  getWordLessonTypeForCustomer,
} from '@/api/query/word-lesson-type';
import Loading from '@/common/components/Loading';
import PopUp from '@/common/components/PopUp';
import Input from '@/common/components/custom/Input';
import SelectInput from '@/common/components/custom/SelectInput';
import CustomSaveButton from '@/common/components/custom/buttons/CustomSaveButton';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { styled } from 'styled-components';

const Title = styled.h2`
  text-align: center;
`;

const SaveWrapper = styled.div`
  margin: 20px 0 10px 0;
  display: flex;
  justify-content: right;
`;

const Form = styled.form`
  margin: 0px 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 400px;
`;

export const ImageWrapper = styled.div`
  width: 100px;
  height: 100px;
  overflow: hidden;
`;

export const ImageInput = styled.input`
  display: none;
`;

const AdminPopulareEdit = ({ setEdit, edit }) => {
  const [selectedWords, setSelectedWords] = useState([]);
  const [wordsOptions, setWordOptions] = useState([]);
  const [selectedFlashcards, setSelectedFlashcards] = useState([]);
  const [flashcardOptions, setFlashcardOptions] = useState([]);
  const [lessonOptions, setLessonOptions] = useState([]);
  const [selectedLessons, setSelectedLessons] = useState([]);

  const createPopulareMutation = useMutation({
    mutationFn: (value) => createPopulare(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const updatePopulareMutation = useMutation({
    mutationFn: (value) => updatePopulare(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const handleSave = async (e) => {
    e && e.preventDefault();

    const payload = edit;
    payload.flashcard = selectedFlashcards.map((sf) => sf.value);
    payload.lesson = selectedLessons.map((sl) => sl.value);
    payload.wordLessonType = selectedWords.map((sw) => sw.value);

    if (edit._id) {
      return updatePopulareMutation.mutate(payload);
    }

    createPopulareMutation.mutate(payload);
  };

  const loadLessonsMutation = useMutation({
    mutationFn: () => findLessons(),
    onSuccess: ({ data }) => {
      const options = data.map((d) => ({ label: d.name, value: d._id }));
      setLessonOptions(options);
      setSelectedLessons(
        options.filter((o) => edit?.lesson?.includes(o.value)) || {},
      );
    },
  });

  const loadWordsMutation = useMutation({
    mutationFn: () => getWordLessonTypeForCustomer(),
    onSuccess: ({ data }) => {
      const options = data.map((d) => ({ label: d.name, value: d._id }));
      setWordOptions(options);
      setSelectedWords(
        options.filter((o) => edit?.wordLessonType?.includes(o.value)) || {},
      );
    },
  });

  const loadFlashcardsMutation = useMutation({
    mutationFn: () => findFlashcardCategories(),
    onSuccess: ({ data }) => {
      const options = data.map((d) => ({ label: d.name, value: d._id }));
      setFlashcardOptions(options);
      setSelectedFlashcards(
        options.filter((o) => edit?.flashcard?.includes(o.value)) || {},
      );
    },
  });

  useEffect(() => {
    loadLessonsMutation.mutate();
    loadWordsMutation.mutate();
    loadFlashcardsMutation.mutate();
  }, []);

  return (
    <>
      {(createPopulareMutation.isLoading ||
        loadLessonsMutation.isLoading ||
        loadWordsMutation.isLoading ||
        loadFlashcardsMutation.isLoading ||
        updatePopulareMutation.isLoading) && <Loading />}
      <PopUp setClose={setEdit}>
        <Title>Populare {edit._id ? 'Edit' : 'Create'}</Title>
        <Form onSubmit={(e) => handleSave(e)} novalidate>
          <div style={{ display: 'flex' }}>
            <SelectInput
              labelUp={true}
              width={100}
              name={'Flashcard'}
              options={flashcardOptions}
              setSelected={setSelectedFlashcards}
              selected={selectedFlashcards}
              multiple={true}
            />
            <SelectInput
              labelUp={true}
              width={100}
              name={'Words'}
              options={wordsOptions}
              setSelected={setSelectedWords}
              selected={selectedWords}
              multiple={true}
            />
            <SelectInput
              labelUp={true}
              width={100}
              name={'Scenes'}
              options={lessonOptions}
              setSelected={setSelectedLessons}
              selected={selectedLessons}
              multiple={true}
            />
          </div>
          <SaveWrapper>
            <CustomSaveButton />
          </SaveWrapper>
        </Form>
      </PopUp>
    </>
  );
};

export default AdminPopulareEdit;
