import { useMutation } from 'react-query';
import { sendAnyMailFromCustomerDashboard } from '@/api/query/mailConfig';

export const useSendAnyMail = (addMessage) => {
  const mutation = useMutation(sendAnyMailFromCustomerDashboard, {
    onSuccess: () => {
      addMessage('Mail Sent', 'success');
    },
    onError: () => {
      addMessage('Something went wrong while sending mail', 'error');
    },
  });

  return mutation;
};
