import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import SynthiAiLogo from '@/common/images/SynthiAi.png';
import { getCurrentPriceConfig } from '@/api/query/price-config';
import { useMutation } from 'react-query';
import Visa from '../icons/Visa blue.svg';
import Master from '../icons/mastercard.svg';
import Blik from '../icons/blik.svg';
import Transfer from '../icons/przelewy.svg';
import TransferCZ from '../icons/bank_cs.svg';
import Payu from '../svg/payou.logo.svg';
import Lock from '../icons/secured-lock.svg';

import { useLanguageData } from '@/common/hooks/useLanguageData';
import { LanguageContext } from '@/common/contexts/LanguageContext';
import useQueryMemo from '@/common/hooks/urlMemo';
import { IpStatsContext } from '@/common/contexts/IpStatsContext';
import { IP_STATS_ENUM } from '@/common/enums/ipStatsEnum';
import { registerOnePageView } from '@/api/query/order';

const Label = styled.label`
  color: #868686;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
`;

const Input = styled.input`
  margin-top: 4px;
  display: flex;
  padding: 8px 10px 8px 10px;
  width: 260px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #29185e;
  background: #fff;

  &:hover {
    outline: none;
  }
  &:focus {
    outline: none;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100vh;

  @media (min-width: 600px) {
    flex-direction: row;
    overflow: hidden;
  }
`;

const LetMailButton = styled.button`
  display: inline-flex;
  padding: 9px 82px 10px 82px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  background: linear-gradient(91deg, #4f17aa 1.48%, #ac02f1 99.24%);
  margin: 5px 0;

  @media (min-width: 600px) {
    margin-top: 60px;
  }
`;

const LeftInfo = styled.div`
  @media (min-width: 600px) {
    width: 50vw;
    margin-top: 35px;
  }
`;

const Title = styled.h3`
  color: var(--Text-color, #29185e);
  font-family: 'Work Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;

  @media (min-width: 600px) {
    text-align: left;
    width: 320px;
    margin: 20px auto;
  }
`;

const TitleDesktop = styled(Title)`
  display: none;

  @media (min-width: 600px) {
    display: flex;
  }
`;

const PriceWrapper = styled.div`
  /* Hide the default radio button */
  input[type='radio'] {
    display: none;
  }

  /* Style the custom radio button */
  .custom-radio {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .custom-radio-button {
    width: 10px; /* Adjust size as needed */
    height: 10px;
    border: 1px solid #c1c1c1;
    border-radius: 50%;
    background: white; /* Background color */
    display: inline-block;
    position: relative;
    transition: background 0.3s;
  }

  input[type='radio']:checked + .custom-radio-button::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px; /* Inner dot size */
    height: 10px;
    border-radius: 50%;
    background: #730bdb; /* Color of the inner dot */
    transform: translate(-50%, -50%);
  }

  input[type='radio']:focus + .custom-radio-button,
  input[type='radio']:hover + .custom-radio-button {
    box-shadow: 0 0 0 2px rgba(115, 11, 219, 0.3);
  }
`;

const SynthiAiLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px 0 0 0;

  @media (min-width: 600px) {
    justify-content: left;
    width: 320px;
    margin: 16px auto;
  }
`;

const PlanName = styled.div`
  color: var(--Highlight-text, #730bdb);
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 22.4px */
`;

const SaveWrapper = styled.div`
  color: ${(props) => (props.promo ? '#149069' : 'var(--pink, #e94f96)')};
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
`;

const PriceInfoWrapper = styled.div`
  border-radius: 8px;
  background: #f4f6f8;
  width: 280px;
  margin: 6px 0px 16px 0;
  padding: 10px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Border = styled.div`
  width: 272px;
  height: 1px;
  background: #e0e0e0;
`;

const Text = styled.div`
  color: var(--Text-color, #29185e);
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
`;

const RightInfo = styled.div`
  margin: 10px 0;
`;

const EmailBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #f4f6f8;
  border-radius: 12px;
  width: 320px;
  height: 80px;
  margin: -30px auto 0 auto;

  @media (max-width: 600px) {
    margin-top: 20px;
  }
`;

const PaymentIcon = styled.img`
  width: 60px;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  border-radius: 5px;
  border: 1px solid rgba(79, 167, 180, 0.2);
  background: #fff;
  padding: 5px;
`;

const ActionWrapper = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 0px 0;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }

  @media (min-width: 600px) {
    padding-top: 5vh;
    flex-wrap: wrap;
    width: 50vw;
    background: #f4f6f8;
    justify-content: unset;
  }
`;

const LinkWrapper = styled.div`
  color: #05275d;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (min-width: 600px) {
    margin-top: 8px;
  }

  /* Hide the default checkbox */
  input[type='checkbox'] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 15px;
    height: 15px;
    border: 2px solid #29185e;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }

  /* Style the checkbox when checked */
  input[type='checkbox']:checked {
    background-color: #29185e;
  }

  /* Create the checkmark using a pseudo-element */
  input[type='checkbox']:checked::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  input[type='checkbox']:focus {
    box-shadow: 0 0 3px #29185e;
  }
`;

const PayuLink = styled.a`
  color: #05275d;
`;

const PaymentTitle = styled.p`
  color: #05275d;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const StyledInput = styled(Input)`
  @media (min-width: 600px) {
    border: 1px solid #29185e;
    color: #29185e;
    width: 300px;
  }
`;

const IMAGES = [
  {
    image: Blik,
    width: '30px',
    langs: ['pl'],
  },
  {
    image: Visa,
    width: '30px',
    langs: ['pl', 'cs', 'ro'],
  },
  {
    image: Master,
    width: '20px',
    langs: ['pl', 'cs', 'ro'],
  },
  {
    image: Transfer,
    width: '45px',
    langs: ['pl'],
  },
  {
    image: TransferCZ,
    width: '50px',
    langs: ['cs'],
  },
];

const OnePagePayment = () => {
  const translations = useLanguageData('checkout');
  const [email, setEmail] = useState();
  const { lang } = useContext(LanguageContext);
  const queryMemo = useQueryMemo();
  const common = useLanguageData('common');
  const [promo, setPromo] = useState();

  const emailRef = useRef();
  const [pricesData, setPricesData] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const { handleSendIPstats, handleFbStats } = useContext(IpStatsContext);

  const handleLoadPricesMutation = useMutation({
    mutationFn: (value) => getCurrentPriceConfig(value),
    onSuccess: ({ data }) => {
      const prices = data.prices.sort((a, b) => a.full_price - b.full_price);
      setPricesData(() => prices);
      let plan = localStorage.getItem('plan');
      let selected = prices?.find((p) => p?.type?.value === plan) || prices[0];
      setSelectedOption(() => selected);
    },
  });

  const handleChangeSelect = (pd) => {
    setSelectedOption(() => pd);
  };

  const handleRegisterOnePageViewMutation = useMutation({
    mutationFn: (payload) => registerOnePageView(payload),
    onSuccess: ({ data }) => {
      localStorage.setItem('user_register', JSON.stringify(data.user));
      window.location.replace(data.link);
    },
  });

  const handleCreateOrder = (e) => {
    e?.preventDefault();

    const utm_campaign =
      queryMemo.get('utm_campaign') || localStorage.getItem('utm_campaign');
    const utm_medium =
      queryMemo.get('utm_medium') || localStorage.getItem('utm_medium');
    const utm_source =
      queryMemo.get('utm_source') || localStorage.getItem('utm_source');
    const utm_content =
      queryMemo.get('utm_content') || localStorage.getItem('utm_content');
    const utm_fbclid =
      queryMemo.get('utm_fbclid') || localStorage.getItem('utm_fbclid');
    const utm_term =
      queryMemo.get('utm_term') || localStorage.getItem('utm_term');

    const utm = {
      utm_campaign: utm_campaign,
      utm_medium: utm_medium,
      utm_source: utm_source,
      utm_content: utm_content,
      utm_fbclid: utm_fbclid,
      utm_term: utm_term,
      payment_option: localStorage.getItem('payment_option'),
    };

    const payload = {};
    payload.email = emailRef?.current?.value?.toLowerCase();
    payload.utm = utm;
    payload.lang = lang;
    payload.plan = selectedOption._id;
    payload.promo = promo;

    handleFbStats('InitiateCheckout', {});
    handleFbStats('AddPaymentInfo', {});

    window?.gtag('initiate_checkout', {});
    window?.gtag('add_payment_info', {});

    window?.fbq('track', 'InitiateCheckout');
    window?.fbq('track', 'AddPaymentInfo');

    window?.uetq?.push('event', 'subscribe');

    handleSendIPstats(IP_STATS_ENUM.PLAN);
    handleSendIPstats(IP_STATS_ENUM.PAY);

    if (promo) {
      if (selectedOption.type.value === 'PREMIUM') {
        handleSendIPstats('AD_LP2_CHECKOUT_6MO_PROMO');
      }
      if (selectedOption.type.value === 'PRO') {
        handleSendIPstats('AD_LP2_CHECKOUT_3MO_PROMO');
      }
      if (selectedOption.type.value === 'BASIC') {
        handleSendIPstats('AD_LP2_CHECKOUT_1MO_PROMO');
      }
    } else {
      if (selectedOption.type.value === 'PREMIUM') {
        handleSendIPstats('AD_LP2_CHECKOUT_6MO');
      }
      if (selectedOption.type.value === 'PRO') {
        handleSendIPstats('AD_LP2_CHECKOUT_3MO');
      }
      if (selectedOption.type.value === 'BASIC') {
        handleSendIPstats('AD_LP2_CHECKOUT_1MO');
      }
    }

    handleRegisterOnePageViewMutation.mutate(payload);
  };

  useEffect(() => {
    const payload = {
      country: lang === 'cs' ? 'cz' : lang,
    };

    handleLoadPricesMutation.mutate(payload);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setEmail(() => localStorage.getItem('quiz_email'));
      const promo = localStorage.getItem('promo');

      if (promo) {
        setPromo(() => true);
      }
    }, 100);
  }, []);

  console.log(selectedOption);

  return (
    <Wrapper>
      <LeftInfo>
        <SynthiAiLogoWrapper>
          <img src={SynthiAiLogo} />
        </SynthiAiLogoWrapper>
        <Title>{translations.leftTitle}</Title>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div style={{ minWidth: '320px' }}>
            {[...pricesData]
              .sort((a, b) => b.full_price - a.full_price)
              .map((pd) => (
                <PriceWrapper style={{ marginBottom: '12px' }}>
                  <div
                    style={{
                      display: 'flex',
                      gap: '22px',
                      width: 'fit-content',
                    }}
                    onClick={() => handleChangeSelect(pd)}
                  >
                    <input
                      type="radio"
                      id={pd._id}
                      value={pd._id}
                      name="plan"
                      checked={pd._id === selectedOption?._id}
                    />
                    <span
                      class="custom-radio-button"
                      style={{ marginTop: '5px' }}
                    ></span>
                    <label
                      htmlFor={pd._id}
                      style={{
                        display: 'flex',
                        gap: '22px',
                      }}
                    >
                      <PlanName
                        style={{
                          color:
                            pd._id === selectedOption?._id
                              ? '#730BDB'
                              : '#29185E',
                        }}
                      >
                        {pd.inAppPrice.label}
                      </PlanName>{' '}
                      <SaveWrapper promo={promo}>
                        {promo
                          ? pd.inAppPrice.promo_saving
                          : pd.inAppPrice.saving}
                      </SaveWrapper>{' '}
                    </label>
                  </div>
                  {pd._id === selectedOption?._id && (
                    <PriceInfoWrapper>
                      <TextWrapper>
                        <Text>{pd.inAppPrice.full_label}</Text>
                        <Text>{pd.inAppPrice.orginal_full_price}</Text>
                      </TextWrapper>
                      <TextWrapper>
                        <Text>
                          {promo
                            ? pd.inAppPrice.promo_discount_label
                            : pd.inAppPrice.discount_label}
                        </Text>
                        <Text
                          style={{
                            color: `${
                              promo ? '#149069' : 'var(--pink, #E94F96)'
                            }`,
                          }}
                        >
                          {promo
                            ? pd.inAppPrice.promo_discount_value
                            : pd.inAppPrice.discount_value}
                        </Text>
                      </TextWrapper>
                      <Border />
                      <TextWrapper>
                        <Text>{translations.total}</Text>
                        <Text>
                          <b>
                            {' '}
                            {promo ? pd.promo_full_price : pd.full_price}{' '}
                            {translations.currency}
                          </b>
                        </Text>
                      </TextWrapper>
                    </PriceInfoWrapper>
                  )}
                </PriceWrapper>
              ))}
          </div>
        </div>
      </LeftInfo>
      <ActionWrapper onSubmit={(e) => handleCreateOrder(e)}>
        <TitleDesktop>{translations.rightTitle}</TitleDesktop>
        <EmailBox>
          <InputWrapper>
            <Label>{translations.email}</Label>
            <StyledInput
              ref={emailRef}
              id="email-SynthiAi"
              type="email"
              required
              defaultValue={email}
              placeholder={translations.placeHolderEmail}
              style={{ border: 'none', padding: '8px 0px 8px 14px' }}
            />
          </InputWrapper>
        </EmailBox>
        <LinkWrapper style={{ display: 'flex', alignItems: 'center' }}>
          <input type="checkbox" id="rules_2" required defaultChecked />
          <label
            htmlFor="rules_2"
            style={{
              cursor: 'pointer',
              display: 'flex',
              gap: '3px',
              padding: '0 0 0 5px ',
            }}
          >
            {translations.accept}{' '}
            <div
              onClick={() => window.open(common.termsLink)}
              style={{ textDecoration: 'underline' }}
            >
              {' '}
              {translations.rules}
            </div>
            SynthiAi
          </label>
        </LinkWrapper>
        <LinkWrapper
          htmlFor="rules_1"
          style={{
            marginTop: '0px',
            minWidth: '180px',
            fontSize: '12px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {translations.payingAccept}{' '}
          <PayuLink
            href="https://static.payu.com/sites/terms/files/payu_terms_of_service_single_transaction_pl_pl.pdf"
            target="_blank"
            style={{ paddingLeft: '5px' }}
          >
            {' '}
            {translations.paymentRules}
          </PayuLink>
          .
        </LinkWrapper>
        <LetMailButton type="submit" style={{ fontSize: '18px' }}>
          {translations.pay}
        </LetMailButton>
        <PaymentTitle>
          <img src={Lock} /> {translations.savePayments}
          <PaymentIcon src={Payu} style={{ width: '45px' }} />
        </PaymentTitle>
        <ImageWrapper style={{ marginTop: '-15px' }}>
          {IMAGES.filter((i) => i.langs.includes(lang)).map((i) => (
            <PaymentIcon src={i.image} style={{ width: i.width }} />
          ))}
        </ImageWrapper>
      </ActionWrapper>
    </Wrapper>
  );
};

export default OnePagePayment;
