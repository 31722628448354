import Api from '../api';
const { axios: api } = Api;
const queryString = require('query-string');

const PREFIX = 'web-push';

export const getWebPushClients = async () => {
  const data = await api.get(`/${PREFIX}`);
  return { data: data.data };
};

export const getWebPushSent = async (payload) => {
  const data = await api.get(`/${PREFIX}/sent`, payload);
  return { data: data.data };
};

export const sendWebPush = async (payload) => {
  const data = await api.post(`/${PREFIX}/send/`, payload);
  return { data: data.data };
};

export const sendWebPushTest = async (payload) => {
  const data = await api.post(`/${PREFIX}/send/test`, payload);
  return { data: data.data };
};
