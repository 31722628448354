import React, { useState } from 'react';
import styled from 'styled-components';
import { Color } from '../colors/colors';

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.gap};
`;

const ToggleSwitch = ({
  checked,
  toggleRef,
  text,
  width = 310,
  fontSize = '18px',
  style = {},
  onChange = () => {},
  gap = '30px',
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  return (
    <Wrapper gap={gap}>
      <label
        style={{
          width: `${width}px`,
          textAlign: 'right',
          fontSize: fontSize,
          fontWeight: 'bold',
          color: Color.darkBlue,
          ...style,
        }}
        htmlFor="toggle"
      >
        {text}
      </label>
      <Switch>
        <input
          ref={toggleRef}
          type="checkbox"
          checked={isChecked}
          onChange={() => {
            setIsChecked((prevState) => !prevState);
            onChange();
          }}
        />
        <span class="slider round"></span>
      </Switch>
    </Wrapper>
  );
};

export default ToggleSwitch;
