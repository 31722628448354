import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import SynthiAiLogo from '@/common/images/SynthiAi.png';
import { getCurrentPriceConfig } from '@/api/query/price-config';
import { useMutation } from 'react-query';
import { LanguageContext } from '@/common/contexts/LanguageContext';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import OneTimeStripeConf from './components/OneTimeStripeConf';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100vh;

  @media (min-width: 600px) {
    flex-direction: row;
    overflow: hidden;
  }
`;

const LeftInfo = styled.div`
  @media (min-width: 600px) {
    width: 50vw;
    margin-top: 35px;
  }
`;

const Title = styled.h3`
  color: var(--Text-color, #29185e);
  font-family: 'Work Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;

  @media (min-width: 600px) {
    text-align: left;
    width: 320px;
    margin: 20px auto;
  }
`;

const TitleDesktop = styled(Title)`
  display: none;

  @media (min-width: 600px) {
    display: flex;
  }
`;

const PriceWrapper = styled.div`
  /* Hide the default radio button */
  input[type='radio'] {
    display: none;
  }

  /* Style the custom radio button */
  .custom-radio {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .custom-radio-button {
    width: 10px; /* Adjust size as needed */
    height: 10px;
    border: 1px solid #c1c1c1;
    border-radius: 50%;
    background: white; /* Background color */
    display: inline-block;
    position: relative;
    transition: background 0.3s;
  }

  input[type='radio']:checked + .custom-radio-button::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px; /* Inner dot size */
    height: 10px;
    border-radius: 50%;
    background: #730bdb; /* Color of the inner dot */
    transform: translate(-50%, -50%);
  }

  input[type='radio']:focus + .custom-radio-button,
  input[type='radio']:hover + .custom-radio-button {
    box-shadow: 0 0 0 2px rgba(115, 11, 219, 0.3);
  }
`;

const SynthiAiLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px 0 0 0;

  @media (min-width: 600px) {
    justify-content: left;
    width: 320px;
    margin: 16px auto;
  }
`;

const PlanName = styled.div`
  color: var(--Highlight-text, #730bdb);
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 22.4px */
`;

const SaveWrapper = styled.div`
  color: ${(props) => (props.promo ? '#149069' : 'var(--pink, #e94f96)')};
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
`;

const PriceInfoWrapper = styled.div`
  border-radius: 8px;
  background: #f4f6f8;
  width: 280px;
  margin: 6px 0px 16px 0;
  padding: 10px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Border = styled.div`
  width: 272px;
  height: 1px;
  background: #e0e0e0;
`;

const Text = styled.div`
  color: var(--Text-color, #29185e);
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
`;

const Text2 = styled(Text)`
  font-size: 10px;
  text-align: right;
  width: 100%;
  margin-top: -8px;
`;

const RightInfo = styled.div`
  margin: 10px 0;
`;

const EmailBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #f4f6f8;
  border-radius: 12px;
  width: 320px;
  height: 80px;
  margin: -30px auto 0 auto;

  @media (max-width: 600px) {
    margin-top: 20px;
  }
`;

const PaymentIcon = styled.img`
  width: 60px;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  border-radius: 5px;
  border: 1px solid rgba(79, 167, 180, 0.2);
  background: #fff;
  padding: 5px;
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 0px 0;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }

  @media (min-width: 600px) {
    padding-top: 5vh;
    flex-wrap: wrap;
    width: 50vw;
    background: #f4f6f8;
    justify-content: unset;
  }
`;

const LinkWrapper = styled.div`
  color: #05275d;

  a {
    color: #05275d;
  }

  text-align: center;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (min-width: 600px) {
    margin-top: 8px;
  }

  /* Hide the default checkbox */
  input[type='checkbox'] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 15px;
    height: 15px;
    border: 2px solid #29185e;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }

  /* Style the checkbox when checked */
  input[type='checkbox']:checked {
    background-color: #29185e;
  }

  /* Create the checkmark using a pseudo-element */
  input[type='checkbox']:checked::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  input[type='checkbox']:focus {
    box-shadow: 0 0 3px #29185e;
  }
`;

const PayuLink = styled.a`
  color: #05275d;
`;

const PaymentTitle = styled.p`
  color: #05275d;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const OnePageStripeOneTimePayment = () => {
  const [email, setEmail] = useState();
  const { lang } = useContext(LanguageContext);
  const data = useLanguageData('checkout');
  const [promo, setPromo] = useState();

  const emailRef = useRef();
  const [pricesData, setPricesData] = useState([]);
  const [selectedOption, setSelectedOption] = useState();

  const handleLoadPricesMutation = useMutation({
    mutationFn: (value) => getCurrentPriceConfig(value),
    onSuccess: ({ data }) => {
      const prices = data.prices.sort((a, b) => a.full_price - b.full_price);

      setPricesData(() => prices);

      let plan = localStorage.getItem('plan');
      let selected = prices?.find((p) => p?.type?.value === plan) || prices[0];

      setSelectedOption(() => selected);
    },
  });

  const handleChangeSelect = (pd) => {
    setSelectedOption(() => pd);
  };

  useEffect(() => {
    const payload = {
      country: lang,
    };

    handleLoadPricesMutation.mutate(payload);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setEmail(() => localStorage.getItem('quiz_email'));
      const promo = localStorage.getItem('promo');
      if (promo) {
        setPromo(() => true);
      }
    }, 100);
  }, []);

  return (
    <Wrapper>
      <LeftInfo>
        <SynthiAiLogoWrapper>
          <img src={SynthiAiLogo} />
        </SynthiAiLogoWrapper>
        <Title>{data?.leftTitle}</Title>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div style={{ minWidth: '320px' }}>
            {[...pricesData]
              .sort((a, b) => b.full_price - a.full_price)
              .map((pd) => (
                <PriceWrapper style={{ marginBottom: '12px' }}>
                  <div
                    style={{
                      display: 'flex',
                      gap: '22px',
                      width: 'fit-content',
                    }}
                    onClick={() => handleChangeSelect(pd)}
                  >
                    <input
                      type="radio"
                      id={pd._id}
                      value={pd._id}
                      name="plan"
                      checked={pd._id === selectedOption?._id}
                    />
                    <span
                      class="custom-radio-button"
                      style={{ marginTop: '5px' }}
                    ></span>
                    <label
                      htmlFor={pd._id}
                      style={{
                        display: 'flex',
                        gap: '22px',
                      }}
                    >
                      <PlanName
                        style={{
                          color:
                            pd._id === selectedOption?._id
                              ? '#730BDB'
                              : '#29185E',
                        }}
                      >
                        {pd.inAppPrice.label}
                      </PlanName>{' '}
                      <SaveWrapper promo={promo}>
                        {promo
                          ? pd.inAppPrice.promo_saving
                          : pd.inAppPrice.saving}
                      </SaveWrapper>{' '}
                    </label>
                  </div>
                  {pd._id === selectedOption?._id && (
                    <PriceInfoWrapper>
                      <TextWrapper>
                        <Text>{pd.inAppPrice.full_label}</Text>
                        <Text>{pd.inAppPrice.orginal_full_price}</Text>
                      </TextWrapper>
                      <TextWrapper>
                        <Text>
                          {promo
                            ? pd.inAppPrice.promo_discount_label
                            : pd.inAppPrice.discount_label}
                        </Text>
                        <Text
                          style={{
                            color: `${
                              promo ? '#149069' : 'var(--pink, #E94F96)'
                            }`,
                          }}
                        >
                          {promo
                            ? pd.inAppPrice.promo_discount_value
                            : pd.inAppPrice.discount_value}
                        </Text>
                      </TextWrapper>
                      <Border />
                      <TextWrapper>
                        <Text>{data?.total}</Text>
                        <Text>
                          <b>
                            {' '}
                            {promo ? pd.promo_full_price : pd.full_price}{' '}
                            {data?.currencySign}
                          </b>
                        </Text>
                      </TextWrapper>
                      <TextWrapper>
                        <Text2>{pd.inAppPrice.nowYouWillPay}</Text2>
                      </TextWrapper>
                    </PriceInfoWrapper>
                  )}
                </PriceWrapper>
              ))}
          </div>
        </div>
      </LeftInfo>
      <ActionWrapper>
        <TitleDesktop>{data?.rightTitle}</TitleDesktop>
        <OneTimeStripeConf
          selectedProduct={selectedOption}
          promo={promo}
          email={email}
          subscribe={data?.subscribe}
        />
      </ActionWrapper>
    </Wrapper>
  );
};

export default OnePageStripeOneTimePayment;
