import CustomTable from "@/common/components/custom/CustomTable";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useMutation } from "react-query";
import SearchBar from "./components/SearchBar";
import Loading from "@/common/components/Loading";
import { findRefundationReport } from "@/api/query/report";
import moment from "moment";

const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

const HEADERS = [
  "No.",
  "Customer",
  "Payment date",
  "Redundation date",
  "Price",
  "Reason Type",
  "Other Reason",
  "Comment",
  "Provider",
  "Utm Source",
  "Utm Medium",
];

const HEADERS_SUM = ["Paid", "Refunded", "Refunded %"];

const RefundationReport = () => {
  const [tableData, setTableData] = useState([]);
  const [tableSumData, setTableSumData] = useState([]);

  const findStatsMutation = useMutation({
    mutationFn: (values) => findRefundationReport(values),
    onSuccess: ({ data }) => {
      const all = data.length;
      const refunded = data.filter((f) => f.status_refund_date).length;

      setTableSumData(() => [
        [all, refunded, Math.round((refunded / all) * 10000) / 100],
      ]);

      console.log(data[0]);

      setTableData(() =>
        data
          .filter((f) => f.status_refund_date)
          .map((d, i) => [
            i + 1,
            d.order?.user?.email,
            moment(d.status_complated_date).format("YYYY-MM-DD"),
            moment(d.status_refund_date).format("YYYY-MM-DD"),
            d.price / 100,
            d.refundation?.reason || "----",
            d.refundation?.otherReasonDescription || "----",
            d.refundation?.comment || "----",
            d.type,
            d.order?.user?.utms?.utm_source ||
              d.order?.user?.guess_utm?.utm_source,
            d.order?.user?.utms?.utm_medium ||
              d.order?.user?.guess_utm?.utm_medium,
          ])
      );
    },
  });

  const handleSearch = (payload) => {
    findStatsMutation.mutate(payload);
  };

  return (
    <>
      {findStatsMutation.isLoading && <Loading />}
      <Wrapper>
        <TableWrapper>
          <SearchBar handleSearch={handleSearch} />
        </TableWrapper>
        <TableWrapper>
          <CustomTable
            headers={HEADERS_SUM}
            data={tableSumData}
            csvData={tableSumData}
          />
        </TableWrapper>
        <TableWrapper>
          <CustomTable headers={HEADERS} data={tableData} csvData={tableData} />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default RefundationReport;
