import Api from '../api';
const { axios: api } = Api;
const queryString = require('query-string');

const PREFIX = 'cookie';

export const createCookie = async (payload) => {
  const data = await api.post(`/${PREFIX}`, payload);
  return { data: data.data };
};

export const loadCookie = async (payload) => {
  const data = await api.get(`/${PREFIX}/${payload.consent_uid}`);
  return { data: data.data };
};
