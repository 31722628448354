// src/components/CustomerStats/popups/CancelSubscriptionPopUp.js
import React from 'react';
import PopUp from '@/common/components/PopUp';

const CancelSubscriptionPopUp = ({ handleDecision, setShow }) => (
  <PopUp
    title="Confirm Subscription Cancellation"
    onClose={() => setShow(false)}
  >
    <p>Are you sure you want to cancel the subscription?</p>
    <button onClick={handleDecision}>Yes</button>
    <button onClick={() => setShow(false)}>No</button>
  </PopUp>
);

export default CancelSubscriptionPopUp;
