import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { SOURCES_TYPES } from "@/common/components/customer/Hands";
import TopTitleHeader from "@/common/components/TopTitleHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faCheck,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import LevelSetings from "./components/LevelSetings";
import { useSpring, animated } from "react-spring";
import Exchange from "./images/exchange.svg";
import {
  generateWordsFlashCardTopic,
  saveWordsFashcard,
} from "@/api/query/flashcard-category";
import Loading from "@/common/components/Loading";
import { shuffleArray } from "@/common/functions/shuffleArray";
import { getVoice } from "@/api/query/workflow";
import { Howl } from "howler";
import VolumeGranat from "./images/Volume_granat.svg";
import SwitchWhite from "./images/switch_bialy.svg";
import { useLanguageData } from "@/common/hooks/useLanguageData";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100 - 60px);
  overflow-y: auto;
  text-align: center;
  margin: 20px 20px 0 20px;
  gap: 10px;

  @media (min-width: 600px) {
    flex-grow: 1;
    margin: 0;
    width: 100%;
    height: fit-content;
    position: relative;
  }
`;

const TitleLessonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 95%;
  border-bottom: 1px solid #d9d9d9;

  @media (max-width: 600px) {
    flex-direction: column;
    border: none;
  }
`;

const Text = styled.h3`
  color: #650675;

  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 123.5%;
  letter-spacing: 0.48px;
  margin: 0;
`;

const BackWrapper = styled(Link)`
  display: flex;
  align-items: center;
  min-width: 260px;
  gap: 5px;
  color: #868686;

  text-decoration: none;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%;
  letter-spacing: 0.4px;

  @media (max-width: 600px) {
    display: none;
  }
`;

const ProgressWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;

  @media (max-width: 600px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const BackFlashcardButton = styled.button`
  width: 170px;
  height: 24px;
  border-radius: 19px;
  border: 1px solid #aba9a9;
  background: white;
  padding: 0;

  color: #868686;
  text-align: center;
  font-family: "Work Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */

  @media (max-width: 600px) {
    display: none;
  }
`;

const BackFlashcardButtonMobile = styled.button`
  color: #29185e;
  font-family: "Work Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 14.82px */
  letter-spacing: 0.24px;

  display: inline-flex;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 41px;
  background: #ece5f2;
  text-transform: none;

  @media (min-width: 600px) {
    display: none;
  }
`;
const ProgressBar = styled.div``;

const ColorBar = styled.div`
  display: flex;
`;

const ColorBarElement = styled.div`
  width: 58.3px;
  height: 14px;

  @media (max-width: 600px) {
    width: 30px;
  }
`;

const ChangeLevel = styled.div`
  width: 120px;
  margin-right: 50px;
  background-color: white;
  cursor: pointer;

  color: #05275d;
  text-align: center;
  font-family: "Work Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 15.4px */
  text-decoration-line: underline;

  @media (max-width: 600px) {
    background-color: unset;
    width: 100%;
    text-align: right;
    margin-bottom: 15px;
    margin-top: -10px;
    margin-right: 15px;
    max-width: 320px;
  }
`;

const BttomBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  color: #05275d;
  text-align: center;
  font-family: "Work Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 15.4px */

  div {
    width: 60px;
  }

  @media (max-width: 600px) {
    div {
      margin-top: 5px;
      width: 45px;
    }
  }
`;

const CardTitle = styled.h3`
  align-items: center;
  display: flex;
  gap: 10px;
  color: #05275d;
  font-family: "Work Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 50.4px */

  @media (max-width: 600px) {
    margin: 0;
  }
`;

const CardSubtitle = styled.p`
  @media (max-width: 600px) {
    margin: 0;
  }
`;

const FlippableCard = styled.div`
  position: relative;
  width: 350px;
  height: 200px;
  perspective: 1000px;
  cursor: pointer;

  @media (max-width: 600px) {
    display: flex;
    justify-content: center;

    margin-top: -10px;
    width: 320px;
    height: 160px;
  }
`;

const CardSide = styled(animated.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CardFront = styled(CardSide)`
  background-color: #650675;
  color: white;
`;

const CardBack = styled(CardSide)`
  background-color: #ece5f2;
  color: #05275d;
`;

const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  gap: 80px;

  @media (max-width: 600px) {
    display: none;
  }
`;

const ActionWrapperMobile = styled.div`
  display: flex;
  justify-content: center;
  gap: 80px;

  @media (min-width: 600px) {
    display: none;
  }
`;

const MoveTitle = styled.div`
  color: #29185e;
  text-align: center;
  font-family: "Work Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
`;

const Circle = styled.div`
  margin-top: 10px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 36px;
  cursor: pointer;
`;

const Title = styled.div`
  color: #05275d;
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
  margin: 20px 0 20px 0;

  @media (max-width: 600px) {
    min-width: 80vw;
  }
`;

const Types = {
  TASK: "task",
  FLASHCARD: "flashcard",
};

const ContainerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;

  @media (max-width: 600px) {
    gap: 30px;
  }
`;
const LeftContainer = styled.div`
  display: flex;
  width: 115px;
  height: 35px;
  padding: 10px 30px 10px 30px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  cursor: pointer;

  color: #05275d;
  text-align: center;
  font-family: "Work Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  font-size: 14px;

  @media (max-width: 600px) {
    width: 80px;
    height: 20px;
    font-size: 11px;
  }
`;
const RightContainer = styled.div`
  display: flex;
  width: 115px;
  height: 36px;
  padding: 10px 33px 11px 34px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #d9d9d9;

  color: #05275d;
  text-align: center;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 15.4px */
  font-size: 14px;

  @media (max-width: 600px) {
    width: 80px;
    height: 20px;
    font-size: 11px;
  }
`;

const EmptyContainer = styled.div`
  display: flex;
  width: 115px;
  height: 36px;
  padding: 10px 33px 11px 34px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;

  @media (max-width: 600px) {
    width: 80px;
    height: 20px;
  }
`;

const CardMobile = styled.div`
  border-radius: 16px;
  width: 90%;
  overflow-x: hidden;
  background: #fff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  text-align: center;
  margin-top: -10px;
  gap: 10px;
  padding-bottom: 20px;
  overflow-x: hidden;

  @media (min-width: 600px) {
    background: unset;
    box-shadow: unset;
    padding-bottom: 10px;
  }
`;

const MobileWrapper = styled.div`
  display: flex;
  height: 18vh;
  flex-direction: column;
  justify-content: space-between;

  @media (min-height: 700px) {
    height: 30vh;
  }

  @media (min-width: 600px) {
    height: unset;
  }
`;

const Subtitle = styled.h3`
  color: #05275d;
  text-align: center;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  margin-top: -25px;
`;

const Question = styled.h4`
  color: #05275d;
  text-align: center;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  margin-top: 10px;
  margin-bottom: 5px;
`;

const CustomButton = styled.button`
  width: 240px;
  border-radius: 19px;
  border-radius: 100px;
  background: linear-gradient(91deg, #4f17aa 1.48%, #ac02f1 99.24%);
  padding: 0;

  display: inline-flex;
  padding: 10px 20px 10px 20px;
  justify-content: center;
  align-items: center;

  color: white;
  text-align: center;
  font-family: "Work Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */

  cursor: pointer;
`;

const LevelConfig = [
  {
    type: Types.FLASHCARD,
    level: 1,
  },
  {
    type: Types.FLASHCARD,
    level: 2,
  },
  {
    type: Types.FLASHCARD,
    level: 3,
  },
  {
    type: Types.FLASHCARD,
    level: 4,
  },
  {
    type: Types.FLASHCARD,
    level: 5,
  },
  {
    type: Types.FLASHCARD,
    level: 6,
  },
  {
    type: Types.FLASHCARD,
    level: 7,
  },
  {
    type: Types.FLASHCARD,
    level: 8,
  },
  {
    type: Types.FLASHCARD,
    level: 9,
  },
  {
    type: Types.TASK,
    level: 10,
  },
];

const FLASHCARD_STATUS = {
  KNOW: "know",
  DONT_KNOW: "dont_know",
};

const PROCESS_STATUS = {
  SUCCESS: "success",
  WRONG: "wrong",
};

const NewFlashcard = ({ setPageNumber }) => {
  const { id } = useParams();
  const [results, setResults] = useState([]);
  const translation = useLanguageData("features.flashcards");

  const navigate = useNavigate();

  const [data, setData] = useState({ words: [] });
  const [currentLevel, setCurrentLevel] = useState(1);
  const [settings, setSettigns] = useState();
  const [dontDisplay, setDontDisplay] = useState([]);
  const [generatePairs, setGeneratePair] = useState([]);

  const [isFlipped, setIsFlipped] = useState(false);

  const [curentSelect, setCurentSelect] = useState({});

  const handleLoadNewMutation = useMutation({
    mutationFn: (value) => saveWordsFashcard(value),
    onSuccess: ({ data }) => {
      const payload = {};
      payload._id = id;
      handleLoadHobbyTopicMutation.mutate(payload);
    },
  });

  const handleSaveMutation = useMutation({
    mutationFn: (value) => saveWordsFashcard(value),
    onSuccess: () => {
      navigate(`/learn/flashcards/${data?.topic?.wordLessonTopType}`);
    },
  });

  const handleSave = () => {
    const payload = {};
    payload.words = results;
    handleSaveMutation.mutate(payload);
  };

  const handleNext = () => {
    const payload = {};
    payload.words = results;
    handleLoadNewMutation.mutate(payload);

    setResults([]);
    setData({ words: [] });
    setCurrentLevel(1);
    setDontDisplay([]);
    setCurentSelect({});
    setGeneratePair([]);
  };

  const nextStep = (status) => {
    setIsFlipped(false);

    setResults((prev) => [
      ...prev.filter((p) => p.english !== data.words[currentLevel - 1].english),
      {
        ...data.words[currentLevel - 1],
        status,
      },
    ]);

    if (currentLevel === 9) {
      handleGeneratePairs();
    }

    setCurrentLevel((prev) => prev + 1);
  };

  const handleLoadHobbyTopicMutation = useMutation({
    mutationFn: (value) => generateWordsFlashCardTopic(value),
    onSuccess: ({ data }) => {
      setData(data);
    },
  });

  const getVoiceMutation = useMutation({
    mutationFn: (value) => getVoice(value),
    onSuccess: async ({ data }) => {
      const blob = new Blob([new Uint8Array(data.data)], {
        type: "audio/mp3",
      });
      const url = await (function blobToBase64(blob) {
        return new Promise((resolve, _) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });
      })(blob);

      const audio = new Howl({
        src: [url],
        onend: () => {},
        onunload: () => {},
      });

      audio.play();
    },
  });

  useEffect(() => {
    const payload = {};
    payload._id = id;
    handleLoadHobbyTopicMutation.mutate(payload);
  }, [id]);

  useEffect(() => {
    window.document.title = `Synthi Ai - ${translation.title}`;
  }, []);

  const { transform, opacity } = useSpring({
    opacity: isFlipped ? 0 : 1,
    transform: `rotateX(${isFlipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });

  const handleCardClick = (e) => {
    setIsFlipped(!isFlipped);
  };

  const handleGeneratePairs = () => {
    const dontKnow = results.filter(
      (r) => r.status === FLASHCARD_STATUS.DONT_KNOW
    );
    const know = results.filter((r) => r.status === FLASHCARD_STATUS.KNOW);

    const toAdd = dontKnow.slice(3);

    const howMuchAdd = 5 - toAdd.length;

    for (let i = 0; i < howMuchAdd; i++) {
      toAdd.push(know[i]);
    }

    const leftArr = shuffleArray(toAdd);
    const rightArr = shuffleArray(toAdd);

    setGeneratePair(() =>
      leftArr.map((l, i) => ({ left: l, right: rightArr[i] }))
    );
  };

  const handleSetSelected = (site, word) => {
    if (curentSelect?.left && curentSelect?.right) {
      return;
    }

    let toSet = { ...curentSelect, [site]: word };

    if (toSet.left && toSet.right) {
      if (toSet.left.english === toSet.right.english) {
        setCurentSelect(() => ({
          ...toSet,
          status: PROCESS_STATUS.SUCCESS,
        }));

        setTimeout(() => {
          setCurentSelect((prev) => {
            setDontDisplay((prevDont) => [...prevDont, prev.left]);
            return {};
          });
        }, 500);
      } else {
        setCurentSelect(() => ({
          ...toSet,
          status: PROCESS_STATUS.WRONG,
        }));

        setTimeout(() => {
          setCurentSelect((prev) => {
            return {};
          });
        }, 1000);
      }

      return;
    }

    return setCurentSelect(() => ({
      ...toSet,
    }));
  };

  const getBorderColor = (site, word) => {
    if (site === "left" && word?.english === curentSelect?.left?.english) {
      if (curentSelect?.status === PROCESS_STATUS.SUCCESS) {
        return "#0FA831";
      }
      if (curentSelect?.status === PROCESS_STATUS.WRONG) {
        return "#E52F08";
      }
      return "#650675";
    }

    if (site === "right" && word?.english === curentSelect?.right?.english) {
      if (curentSelect?.status === PROCESS_STATUS.SUCCESS) {
        return "#0FA831";
      }
      if (curentSelect?.status === PROCESS_STATUS.WRONG) {
        return "#E52F08";
      }
      return "#650675";
    }

    return "#D9D9D9";
  };

  const handleGetVoice = (text) => {
    const payload = {};
    payload.text = text;

    getVoiceMutation.mutate(payload);
  };

  return (
    <>
      {(handleLoadHobbyTopicMutation.isLoading ||
        handleLoadNewMutation.isLoading ||
        handleSaveMutation.isLoading) && <Loading />}
      <PageWrapper>
        <TopTitleHeader
          title={translation.title}
          navigate={`/learn/flashcards/${data?.topic?.wordLessonTopType}`}
          source={SOURCES_TYPES.FLASH_CARD_NEW}
          showHands={true}
        />
        <CardMobile>
          {currentLevel < 10 ? (
            <>
              <ProgressWrapper>
                <BackFlashcardButton
                  onClick={() =>
                    setCurrentLevel((prev) => Math.max(1, prev - 1))
                  }
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    icon={faAngleLeft}
                    style={{ marginRight: "5px" }}
                  />
                  {translation.previous}
                </BackFlashcardButton>
                <ProgressBar>
                  <ColorBar>
                    {LevelConfig.map((lc, i) => (
                      <ColorBarElement
                        style={{
                          background:
                            lc.level <= currentLevel && i < 10
                              ? "#0FA831"
                              : i < 9
                              ? "#EBECED"
                              : "#FDD49D",
                          borderRight: i < 9 ? "1px solid #D9D9D9" : "",
                          borderRadius:
                            i === 0
                              ? "16px 0 0 16px"
                              : i === 9
                              ? "0 16px 16px 0"
                              : "",
                        }}
                      />
                    ))}
                  </ColorBar>
                  <BttomBarWrapper>
                    <div></div>
                    <div>{Math.min(currentLevel, 9)} / 9</div>
                    <div>{translation.task}</div>
                  </BttomBarWrapper>
                </ProgressBar>
                <ChangeLevel onClick={setSettigns}>
                  {translation.changeLevel}
                </ChangeLevel>
              </ProgressWrapper>
              <CardWrapper style={{ marginTop: "30px" }}>
                <FlippableCard onClick={handleCardClick} id="flip">
                  <CardBack style={{ opacity, transform }}>
                    <CardTitle>
                      {data?.words[currentLevel - 1]?.english}{" "}
                      <img
                        src={VolumeGranat}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleGetVoice(
                            data?.words[currentLevel - 1]?.english
                          );
                        }}
                      />
                    </CardTitle>
                  </CardBack>
                  <CardFront
                    style={{
                      opacity: opacity.to((o) => 1 - o),
                      transform: transform.to((t) => `${t} rotateX(180deg)`),
                    }}
                  >
                    <CardTitle
                      style={{
                        color: "white",
                      }}
                    >
                      {data?.words[currentLevel - 1]?.translation}
                    </CardTitle>
                  </CardFront>
                  <img
                    src={!isFlipped ? Exchange : SwitchWhite}
                    style={{
                      position: "absolute",
                      bottom: "15px",
                      right: "15px",
                    }}
                  />
                </FlippableCard>
              </CardWrapper>
              <ActionWrapper>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <MoveTitle>{translation.dontKnow}</MoveTitle>
                  <div>
                    <Circle
                      style={{ background: "#FABB05" }}
                      onClick={() => nextStep(FLASHCARD_STATUS.DONT_KNOW)}
                    >
                      <FontAwesomeIcon icon={faClose} />
                    </Circle>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <MoveTitle>{translation.know}</MoveTitle>
                  <div>
                    <Circle
                      style={{ background: "#01D894" }}
                      onClick={() => nextStep(FLASHCARD_STATUS.KNOW)}
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </Circle>
                  </div>
                </div>
              </ActionWrapper>
            </>
          ) : dontDisplay.length < 5 ? (
            <>
              <ProgressWrapper>
                <BackFlashcardButton
                  onClick={() =>
                    setCurrentLevel((prev) => Math.max(1, prev - 1))
                  }
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    icon={faAngleLeft}
                    style={{ marginRight: "5px" }}
                  />
                  {translation.previous}
                </BackFlashcardButton>
                <ProgressBar>
                  <ColorBar>
                    {LevelConfig.map((lc, i) => (
                      <ColorBarElement
                        style={{
                          background:
                            lc.level <= currentLevel && i < 10
                              ? "#0FA831"
                              : i < 9
                              ? "#EBECED"
                              : "#FDD49D",
                          borderRight: i < 9 ? "1px solid #D9D9D9" : "",
                          borderRadius:
                            i === 0
                              ? "16px 0 0 16px"
                              : i === 9
                              ? "0 16px 16px 0"
                              : "",
                        }}
                      />
                    ))}
                  </ColorBar>
                  <BttomBarWrapper>
                    <div></div>
                    <div>{Math.min(currentLevel, 9)} / 9</div>
                    <div> {translation.task}</div>
                  </BttomBarWrapper>
                </ProgressBar>
                <ChangeLevel onClick={setSettigns}>
                  {" "}
                  {translation.changeLevel}
                </ChangeLevel>
              </ProgressWrapper>
              <Title>{translation.pickPairs}</Title>
              {generatePairs.map((gp) => (
                <ContainerWrapper>
                  {dontDisplay.find((dd) => dd.english === gp.left.english) ? (
                    <EmptyContainer />
                  ) : (
                    <LeftContainer
                      onClick={() => handleSetSelected("left", gp.left)}
                      style={{
                        border: `1px solid ${getBorderColor("left", gp.left)}`,
                        background: ["#D9D9D9", "#E52F08"].includes(
                          getBorderColor("left", gp.left)
                        )
                          ? "white"
                          : getBorderColor("left", gp.left),
                        color: ["#650675"].includes(
                          getBorderColor("left", gp.left)
                        )
                          ? "white"
                          : "#05275D",
                      }}
                    >
                      {gp.left?.english}
                    </LeftContainer>
                  )}
                  {dontDisplay.find((dd) => dd.english === gp.right.english) ? (
                    <EmptyContainer />
                  ) : (
                    <RightContainer
                      onClick={() => handleSetSelected("right", gp.right)}
                      style={{
                        border: `1px solid ${getBorderColor(
                          "right",
                          gp.right
                        )}`,
                        background: ["#D9D9D9", "#E52F08"].includes(
                          getBorderColor("right", gp.right)
                        )
                          ? "white"
                          : getBorderColor("right", gp.right),
                        color: ["#650675"].includes(
                          getBorderColor("right", gp.right)
                        )
                          ? "white"
                          : "#05275D",
                      }}
                    >
                      {gp.right?.translation}
                    </RightContainer>
                  )}
                </ContainerWrapper>
              ))}
            </>
          ) : (
            <>
              <Title style={{ marginTop: "80px" }}>{translation.goodJob}</Title>
              <Subtitle>{translation.titleEnd}</Subtitle>
              <Question>{translation.subtitleEnd}</Question>
              <CustomButton onClick={handleNext}>
                {translation.repeat}
              </CustomButton>
              <CustomButton onClick={handleSave}>
                {translation.other}
              </CustomButton>
            </>
          )}
        </CardMobile>
        {currentLevel < 10 && (
          <MobileWrapper>
            {currentLevel > 1 && (
              <BackFlashcardButtonMobile
                onClick={() => setCurrentLevel((prev) => Math.max(1, prev - 1))}
                style={{ cursor: "pointer" }}
              >
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  style={{ marginRight: "5px" }}
                />
                {translation.previous}
              </BackFlashcardButtonMobile>
            )}
            <ActionWrapperMobile>
              <div>
                <MoveTitle> {translation.dontKnow}</MoveTitle>
                <div>
                  <Circle
                    style={{ background: "#FF9900" }}
                    onClick={() => nextStep(FLASHCARD_STATUS.DONT_KNOW)}
                  >
                    <FontAwesomeIcon icon={faClose} />
                  </Circle>
                </div>
              </div>
              <div>
                <MoveTitle>{translation.know}</MoveTitle>
                <div>
                  <Circle
                    style={{ background: "#0FA831" }}
                    onClick={() => nextStep(FLASHCARD_STATUS.KNOW)}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </Circle>
                </div>
              </div>
            </ActionWrapperMobile>
          </MobileWrapper>
        )}
      </PageWrapper>
      {settings && <LevelSetings setClose={setSettigns} settings={settings} />}
    </>
  );
};

export default NewFlashcard;
