import { loadMailConfigs } from '@/api/query/mailConfig';
import CustomDeleteButton from '@/common/components/custom/buttons/CustomDeleteButton';
import CustomEditButton from '@/common/components/custom/buttons/CustomEditButton';
import SelectInput from '@/common/components/custom/SelectInput';
import PopUp from '@/common/components/PopUp';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 180px;
`;

const AnyMailPopUp = ({ handleSend, setClose }) => {
  const [mails, setMails] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const { id } = useParams();

  const handleLoadMailsMutation = useMutation({
    mutationFn: () => loadMailConfigs(),
    onSuccess: ({ data }) => {
      const options = [];
      data.forEach((d) => {
        d.templateConfig.forEach((tc) => {
          options.push({ label: tc.name, value: tc, configId: d._id });
        });
      });

      setMails(() => options);
    },
  });

  const handleSendPayload = () => {
    const payload = {
      id: selectedOption.value.id,
      templateConfig: selectedOption.configId,
      customer: id,
    };

    handleSend(payload);
  };

  useEffect(() => {
    handleLoadMailsMutation.mutate();
  }, []);

  return (
    <PopUp setClose={setClose}>
      <h3>Send any mail you want :)</h3>
      <SelectInput
        options={mails}
        selected={selectedOption}
        setSelected={setSelectedOption}
        showLabel={false}
        selectWidth={500}
      />
      <ButtonWrapper>
        <CustomDeleteButton text={'Send'} onClick={() => handleSendPayload()} />
      </ButtonWrapper>
    </PopUp>
  );
};

export default AnyMailPopUp;
