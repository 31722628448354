import { getWebPushClients, sendWebPushTest } from '@/api/query/web-push';
import PopUp from '@/common/components/PopUp';
import SelectInput from '@/common/components/custom/SelectInput';
import CustomAddButton from '@/common/components/custom/buttons/CustomAddButton';
import { getFormatedFullDate } from '@/common/functions/getFormatedFullDate';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const AdminNotyficationTest = ({ data, setClose }) => {
  const [options, setOptions] = useState();
  const [selected, setSelected] = useState();

  const handleGetAllOptionsToSelectMutation = useMutation({
    mutationFn: () => getWebPushClients(),
    onSuccess: ({ data }) => {
      setOptions(() =>
        data
          .filter((f) => f.canSend)
          .map((d) => ({
            label: `${getFormatedFullDate(d.created_at)}_${d.user?.email}`,
            value: d._id,
          })),
      );
    },
  });

  const sendMutation = useMutation({
    mutationFn: (payload) => sendWebPushTest(payload),
    onSuccess: ({ data }) => {
      setClose(() => null);
    },
  });

  const handleSend = () => {
    const paylaod = {
      webPushClients: selected.map((s) => s.value),
      notyfication: data._id,
    };

    sendMutation.mutate(paylaod);
  };

  useEffect(() => {
    handleGetAllOptionsToSelectMutation.mutate();
  }, []);

  return (
    <PopUp setClose={setClose}>
      <h3>WebPush Tester</h3>
      <Wrapper>
        <SelectInput
          options={options}
          selected={selected}
          setSelected={setSelected}
          multiple={true}
          showLabel={false}
          selectWidth={800}
        />
        <CustomAddButton onClick={() => handleSend()} text={'SEND'} />
      </Wrapper>
    </PopUp>
  );
};

export default AdminNotyficationTest;
