import React, { useRef, useState } from 'react';
import { styled } from 'styled-components';
import { Color } from '@/common/colors/colors';
import moment from 'moment';
import Input from '@/common/components/custom/Input';
import SelectInput from '@/common/components/custom/SelectInput';

const SelectBarContainer = styled.form`
  border-radius: 10px;
  background-color: ${Color.White};
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border: 1px dashed grey;
  max-width: 1000px;
`;

const SearchButton = styled.button`
  padding: 8px 16px;
  background-color: ${Color.DarkBlue};
  color: white;
  border: none;
  font-size: 16px;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  margin-left: 20px;

  &:hover {
    background-color: ${Color.Grey};
  }
`;

const SearchBar = ({ handleSearch, options }) => {
  const today = moment().format('YYYY-MM-DD');
  const [selectedMailConfig, setSelectedMailConfig] = useState();

  const fromRef = useRef();
  const toRef = useRef();

  const handleSearchCheck = (e) => {
    e?.preventDefault();

    const payload = {};

    payload.mailConfig = selectedMailConfig.map((d) => d.value);
    payload.from = moment(fromRef.current.value).startOf('day');
    payload.to = moment(toRef.current.value).endOf('day');

    handleSearch(payload);
  };

  return (
    <>
      <SelectBarContainer onSubmit={handleSearchCheck}>
        <div>
          <Input
            type="date"
            inputRef={fromRef}
            name="From date"
            inputWidth={120}
            width={100}
            required={true}
            value={today}
          />
          <Input
            type="date"
            inputRef={toRef}
            name="To date"
            inputWidth={120}
            width={100}
            required={true}
            value={today}
          />
        </div>
        <div>
          <SelectInput
            name={'Mail Config'}
            width={120}
            selectWidth={300}
            options={options}
            selected={selectedMailConfig}
            setSelected={setSelectedMailConfig}
            multiple={true}
          />
        </div>
        <div>
          <SearchButton type="submit">Search </SearchButton>
        </div>
      </SelectBarContainer>
    </>
  );
};

export default SearchBar;
