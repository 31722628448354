import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { styled } from 'styled-components';

const AddButton = styled.button`
  background-color: #4caf50; /* Kolor tła przycisku dodawania */
  color: white; /* Kolor tekstu */
  padding: 8px 16px; /* Wielkość przycisku */
  border: none;
  border-radius: 5px; /* Zaokrąglenie krawędzi */
  cursor: pointer;
  margin-right: 10px;
  font-size: 14px;
`;

const CustomAddButton = ({ text = 'add', onClick = () => {} }) => {
  return (
    <AddButton type="button" onClick={onClick}>
      <FontAwesomeIcon icon={faPlus} /> &nbsp;{text}
    </AddButton>
  );
};

export default CustomAddButton;
