// src/hooks/useCustomerData.js
import { useMutation } from 'react-query';
import { findCustomerWithStats } from '@/api/query/customer';

export const useCustomerData = (id, setData) => {
  const mutation = useMutation(() => findCustomerWithStats({ _id: id }), {
    onSuccess: ({ data }) => {
      setData(data);
    },
    onError: () => {
      // Handle error if needed
    },
  });

  return mutation;
};
