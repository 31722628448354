import Api from '../api';

const { axios: api } = Api;
const queryString = require('query-string');

const PREFIX = 'workflow/daily';

export const transcriptWithOutUser = async (payload) => {
  const data = await api.post(`/${PREFIX}/transcript-without`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return { data: data.data };
};

export const transcript = async (payload) => {
  const data = await api.post(`/${PREFIX}/transcript`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return { data: data.data };
};

export const getVoiceDaily = async (payload) => {
  const data = await api.post(`/${PREFIX}/voice-from-text`, payload);
  return { data: data.data };
};

export const generateHint = async (payload) => {
  const data = await api.post(`/${PREFIX}/generate-hint`, payload);
  return { data: data.data };
};

export const rateCustomerMessageChat = async (payload) => {
  const data = await api.post(`/${PREFIX}/rate-message-with-gpt/`, payload);

  return { data: data.data };
};

export const askChatGptProductDaily = async (payload) => {
  const data = await api.post(
    `/${PREFIX}/ask-chat-gpt/${payload._id}`,
    payload,
  );

  return { data: data.data };
};

export const getTranslationDaily = async (payload) => {
  const data = await api.post(`/${PREFIX}/get-translation`, payload);
  return { data: data.data, name: payload.name };
};

const cleanObject = (object) => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, v]) => v !== null && v !== ''),
  );
};
