import PopUp from '@/common/components/PopUp';
import Input from '@/common/components/custom/Input';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { styled } from 'styled-components';
import Loading from '@/common/components/Loading';
import CustomSaveButton from '@/common/components/custom/buttons/CustomSaveButton';
import { MessageContext } from '@/common/contexts/MessageContext';
import { findNativeLanguages } from '@/api/query/nativeLanguages';
import { getCurrencies } from '@/api/query/currency';
import SelectInput from '@/common/components/custom/SelectInput';
import { createCountry, updateCountry } from '@/api/query/country';

export const ImageInput = styled.input`
  display: none;
`;
export const ImageWrapper = styled.div`
  width: 300px;
  height: 300px;
  overflow: hidden;
`;

const Title = styled.h2`
  text-align: center;
`;

const Form = styled.form`
  margin: 0px 30px;
  display: flex;
  flex-direction: column;
  height: 400px;
`;

const AdminCountryEdit = ({ setEdit, edit }) => {
  const [languageOptions, setLanguageOptions] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState();

  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState();

  const nameRef = useRef();
  const shortRef = useRef();

  const { addMessage } = useContext(MessageContext);

  const getLanguagesMutation = useMutation({
    mutationFn: () => findNativeLanguages(),
    onSuccess: ({ data }) => {
      const languages = data.map((d) => ({ label: d.name, value: d._id }));
      setLanguageOptions(() => languages);
      if (edit._id) {
        setSelectedLanguage(() =>
          languages.find((l) => l.value === edit.language._id),
        );
      }
    },
    onError: () => {
      addMessage('Sth went wrong', 'error');
    },
  });

  const getCurrenciesMutation = useMutation({
    mutationFn: () => getCurrencies(),
    onSuccess: ({ data }) => {
      const currencies = data.map((d) => ({ label: d.label, value: d._id }));
      setCurrencyOptions(() => currencies);
      if (edit._id) {
        setSelectedCurrency(() =>
          currencies.find((l) => l.value === edit.currency._id),
        );
      }
    },
    onError: () => {
      addMessage('Sth went wrong', 'error');
    },
  });

  const createCategoryMutation = useMutation({
    mutationFn: (value) => createCountry(value),
    onSuccess: ({ data }) => {
      addMessage('CREATED', 'success');
      setEdit(null);
    },
    onError: () => {
      addMessage('Sth went wrong', 'error');
    },
  });

  const updateCategoryMutation = useMutation({
    mutationFn: (value) => updateCountry(value),
    onSuccess: ({ data }) => {
      addMessage('UPDATED', 'success');
      setEdit(null);
    },
    onError: () => {
      addMessage('Sth went wrong', 'error');
    },
  });

  const handleSave = (e) => {
    e && e.preventDefault();

    const payload = edit;

    payload.name = nameRef.current.value;
    payload.short = shortRef.current.value;
    payload.currency = selectedCurrency.value;
    payload.language = selectedLanguage.value;

    if (edit._id) {
      return updateCategoryMutation.mutate(payload);
    }

    createCategoryMutation.mutate(payload);
  };

  useEffect(() => {
    getLanguagesMutation.mutate();
    getCurrenciesMutation.mutate();
  }, []);

  return (
    <>
      {(updateCategoryMutation.isLoading ||
        createCategoryMutation.isLoading) && <Loading />}
      <PopUp setClose={setEdit}>
        <Title>Category {edit._id ? 'Edit' : 'Create'}</Title>
        <Form onSubmit={(e) => handleSave(e)}>
          <Input
            inputRef={nameRef}
            name="Name"
            inputWidth={400}
            width={100}
            required
            requiredSign
            value={edit.name}
          />
          <Input
            inputRef={shortRef}
            name="Short"
            inputWidth={400}
            width={100}
            value={edit.short}
            required
            requiredSign
          />
          <SelectInput
            name={'Currency'}
            width={100}
            selectWidth={400}
            options={currencyOptions}
            setSelected={setSelectedCurrency}
            selected={selectedCurrency}
            required
          />
          <SelectInput
            name={'Language'}
            width={100}
            selectWidth={400}
            options={languageOptions}
            setSelected={setSelectedLanguage}
            selected={selectedLanguage}
            required
          />
          <div style={{ flex: 1 }} />
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <CustomSaveButton text="Save" />
          </div>
        </Form>
      </PopUp>
    </>
  );
};

export default AdminCountryEdit;
