import { getAllPosibleFiledsToFilter } from '@/api/query/report';
import { Color } from '@/common/colors/colors';
import Input from '@/common/components/custom/Input';
import SelectInput from '@/common/components/custom/SelectInput';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';

const BorderLine = styled.div`
  width: 100%;
  background-color: ${Color.Blue};
  height: 1px;
`;

const ColorTitle = styled.h4`
  color: ${Color.Blue};
  margin: 0;
`;

const OptionWrapper = styled.div`
  margin-top: 20px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const SubTitle = styled.h3`
  text-decoration: underline;
  margin-bottom: 25px;
`;

const ARITHMETIC_OPTIONS = [
  {
    label: 'equal',
    value: '$eq',
  },
  {
    label: 'lesser',
    value: '$lt',
  },
  {
    label: 'lesser or equal',
    value: '$lte',
  },
  {
    label: 'grater',
    value: '$gt',
  },
  {
    label: 'grater or equal',
    value: '$gte',
  },
];

const ARITHMETIC_OPTIONS_STRING = [
  {
    label: 'equal',
    value: '$eq',
  },
  {
    label: 'not equal',
    value: '$ne',
  },
  {
    label: 'regex',
    value: '$reg',
  },
];

const TIME_UNIT_OPTIONS = [
  {
    label: 'Hours',
    value: 'hours',
  },
  {
    label: 'Days',
    value: 'days',
  },
];

const FilterOptionFinderString = ({ fieldData, filters, dispatchFilters }) => {
  const handleOnChange = (value) => {
    if (value.length > 0) {
      return dispatchFilters({
        type: 'add',
        key: fieldData.label,
        value: value,
        type_field: 'String',
        arithmetic: filters[fieldData.label]?.arithmetic,
      });
    }
    return dispatchFilters({
      type: 'remove',
      key: fieldData.label,
      value: value,
    });
  };

  const handleOnChangeEnum = (value, site) => {
    return dispatchFilters({
      type: 'add',
      key: fieldData.label,
      value: {
        ...(filters[fieldData.label]?.value || {}),
        [site]: value.map((v) => v.value),
      },
      type_field: 'String',
      enumValues: fieldData.value.enumValues,
    });
  };

  if (fieldData.value.enumValues) {
    const options = fieldData.value.enumValues.map((option) => {
      return { label: option, value: option };
    });
    const current = filters[fieldData.label]?.value;

    return (
      <OptionWrapper>
        <ColorTitle>{fieldData.label}:</ColorTitle>
        <BorderLine />
        <div>IN ARR</div>
        <SelectInput
          showLabel={false}
          options={options}
          selected={options.filter((option) =>
            current?.in?.includes(option.value),
          )}
          setSelected={(e) => handleOnChangeEnum(e, 'in')}
          multiple={true}
          selectWidth={500}
        />
        <div>NOT IN ARR</div>
        <SelectInput
          showLabel={false}
          options={options}
          selected={options.filter((option) =>
            current?.nin?.includes(option.value),
          )}
          setSelected={(e) => handleOnChangeEnum(e, 'nin')}
          multiple={true}
          selectWidth={500}
        />
      </OptionWrapper>
    );
  }

  const handleOnChangeArithmetic = (value) => {
    return dispatchFilters({
      type: 'add',
      key: fieldData.label,
      arithmetic: value.value,
      value: filters[fieldData.label]?.value,
      type_field: 'String',
    });
  };

  return (
    <OptionWrapper>
      <ColorTitle>{fieldData.label}:</ColorTitle>
      <BorderLine />
      <SelectInput
        options={ARITHMETIC_OPTIONS_STRING}
        showLabel={false}
        selectWidth={150}
        selected={ARITHMETIC_OPTIONS_STRING.find(
          (e) => e.value === filters[fieldData.label]?.arithmetic,
        )}
        setSelected={(e) => handleOnChangeArithmetic(e)}
      />
      <Input
        showLabel={false}
        value={filters[fieldData.label]?.value}
        onChange={(e) => handleOnChange(e.target.value)}
      />
    </OptionWrapper>
  );
};

const FilterOptionFinderNumber = ({ fieldData, filters, dispatchFilters }) => {
  const handleOnChange = (value) => {
    return dispatchFilters({
      type: 'add',
      key: fieldData.label,
      value: value,
      type_field: 'Number',
      arithmetic: filters[fieldData.label]?.arithmetic,
    });
  };

  const handleOnChangeArithmetic = (value) => {
    return dispatchFilters({
      type: 'add',
      key: fieldData.label,
      arithmetic: value,
      value: filters[fieldData.label]?.value,
      type_field: 'Number',
    });
  };

  return (
    <OptionWrapper>
      <ColorTitle>{fieldData.label}:</ColorTitle>
      <BorderLine />
      <SelectInput
        options={ARITHMETIC_OPTIONS}
        showLabel={false}
        selected={ARITHMETIC_OPTIONS.find(
          (e) => e.value === filters[fieldData.label]?.arithmetic,
        )}
        setSelected={(e) => handleOnChangeArithmetic(e)}
      />
      <Input
        showLabel={false}
        type="number"
        value={filters[fieldData.label]?.value}
        onChange={(e) => handleOnChange(e.target.value)}
      />
    </OptionWrapper>
  );
};
const FilterOptionFinderDate = ({ fieldData, filters, dispatchFilters }) => {
  const [selectedFromUnit, setSelectedFromUnit] = useState(() => {
    if (filters[fieldData?.label]?.from_hours) {
      return TIME_UNIT_OPTIONS[0];
    }
    if (filters[fieldData?.label]?.from_days) {
      return TIME_UNIT_OPTIONS[1];
    }
  });
  const [selectedToUnit, setSelectedToUnit] = useState(() => {
    if (filters[fieldData?.label]?.to_hours) {
      return TIME_UNIT_OPTIONS[0];
    }
    if (filters[fieldData?.label]?.to_days) {
      return TIME_UNIT_OPTIONS[1];
    }
  });

  const amountFromRef = useRef();
  const amountToRef = useRef();

  const handleOnChange = (value, from) => {
    return dispatchFilters({
      type: 'add',
      key: fieldData.label,
      type_field: 'Date',
      from: filters[fieldData.label]?.from,
      to: filters[fieldData.label]?.to,
      [`from_${selectedFromUnit?.value}`]: amountFromRef.current?.value,
      [`to_${selectedToUnit?.value}`]: amountToRef.current?.value,
      [from]: value,
      arithmetic_from: filters[fieldData.label]?.arithmetic_from,
      arithmetic_to: filters[fieldData.label]?.arithmetic_to,
    });
  };

  const handleOnChangeArithmetic = (value, from) => {
    return dispatchFilters({
      type: 'add',
      key: fieldData.label,
      type_field: 'Date',
      from: filters[fieldData.label]?.from,
      to: filters[fieldData.label]?.to,
      arithmetic_from: filters[fieldData.label]?.arithmetic_from,
      arithmetic_to: filters[fieldData.label]?.arithmetic_to,
      [`from_${selectedFromUnit?.value}`]: amountFromRef.current?.value,
      [`to_${selectedToUnit?.value}`]: amountToRef.current?.value,
      [from]: value.value,
    });
  };

  const handleOnChangeUnit = (unit, setSelected, ref) => {
    setSelected(() =>
      TIME_UNIT_OPTIONS.find((option) => option.value === unit.value),
    );

    return dispatchFilters({
      type: 'add',
      key: fieldData.label,
      type_field: 'Date',
      from: filters[fieldData.label]?.from,
      to: filters[fieldData.label]?.to,
      arithmetic_from: filters[fieldData.label]?.arithmetic_from,
      [`from_${selectedFromUnit?.value}`]: amountFromRef.current?.value,
      [`to_${selectedToUnit?.value}`]: amountToRef.current?.value,
      [unit]: ref.current?.value,
      arithmetic_to: filters[fieldData.label]?.arithmetic_to,
    });
  };

  return (
    <OptionWrapper>
      <ColorTitle>{fieldData.label}:</ColorTitle>
      <BorderLine />
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h4
            style={{
              width: '30px',
              color: Color.Blue,
              margin: '0px 10px',
            }}
          >
            From:
          </h4>
          <SelectInput
            options={ARITHMETIC_OPTIONS}
            showLabel={false}
            selected={ARITHMETIC_OPTIONS.find(
              (e) => e.value === filters[fieldData.label]?.arithmetic_from,
            )}
            setSelected={(e) => handleOnChangeArithmetic(e, 'arithmetic_from')}
            selectWidth={120}
          />
          <SelectInput
            options={TIME_UNIT_OPTIONS}
            name="Unit"
            selected={selectedFromUnit}
            selectWidth={130}
            width={40}
            setSelected={(e) =>
              handleOnChangeUnit(e, setSelectedFromUnit, amountFromRef)
            }
            color={Color.Blue}
          />
          <Input
            name="Amount"
            type="number"
            inputRef={amountFromRef}
            disabled={!selectedFromUnit}
            value={
              filters[fieldData.label]?.[`from_${selectedFromUnit?.value}`]
            }
            onChange={(e) =>
              handleOnChange(e.target.value, `from_${selectedFromUnit?.value}`)
            }
            width={60}
            inputWidth={80}
            color={Color.Blue}
          />
          <h4
            style={{
              width: '30px',
              color: Color.Blue,
              margin: '0px 10px',
            }}
          >
            Or:
          </h4>
          <Input
            showLabel={false}
            type="date"
            value={filters[fieldData.label]?.from}
            onChange={(e) => handleOnChange(e.target.value, 'from')}
            inputWidth={120}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h4
            style={{
              width: '30px',
              color: Color.Blue,
              margin: '0px 10px',
            }}
          >
            To:
          </h4>
          <SelectInput
            options={ARITHMETIC_OPTIONS}
            showLabel={false}
            selected={ARITHMETIC_OPTIONS.find(
              (e) => e.value === filters[fieldData.label]?.arithmetic_to,
            )}
            setSelected={(e) => handleOnChangeArithmetic(e, 'arithmetic_to')}
            selectWidth={120}
          />
          <SelectInput
            options={TIME_UNIT_OPTIONS}
            name="Unit"
            selected={selectedToUnit}
            selectWidth={130}
            width={40}
            setSelected={(e) =>
              handleOnChangeUnit(e, setSelectedToUnit, amountToRef)
            }
            color={Color.Blue}
          />
          <Input
            name="Amount"
            type="number"
            inputRef={amountToRef}
            disabled={!selectedToUnit}
            value={filters[fieldData.label]?.[`to_${selectedToUnit?.value}`]}
            onChange={(e) =>
              handleOnChange(e.target.value, `to_${selectedToUnit?.value}`)
            }
            width={60}
            inputWidth={80}
            color={Color.Blue}
          />
          <h4
            style={{
              width: '30px',
              color: Color.Blue,
              margin: '0px 10px',
            }}
          >
            Or:
          </h4>
          <Input
            showLabel={false}
            type="date"
            value={filters[fieldData.label]?.to}
            onChange={(e) => handleOnChange(e.target.value, 'to')}
            inputWidth={120}
          />
        </div>
      </div>
    </OptionWrapper>
  );
};

const FilterOptionExists = ({ fieldData, filters, dispatchFilters }) => {
  const handleOnChange = (value) => {
    return dispatchFilters({
      type: 'add',
      key: fieldData.label,
      type_field: 'Exists',
      isTrue: value,
    });
  };

  return (
    <OptionWrapper>
      <ColorTitle>{fieldData.label}:</ColorTitle>
      <BorderLine />
      <Input
        showLabel={false}
        type="checkbox"
        checked={filters[fieldData.label]?.isTrue}
        onChange={(e) => handleOnChange(e.target.checked)}
      />
    </OptionWrapper>
  );
};

const FilterOptionFinderBool = ({ fieldData, filters, dispatchFilters }) => {
  const handleOnChange = (value) => {
    return dispatchFilters({
      type: 'add',
      key: fieldData.label,
      type_field: 'Bool',
      isTrue: value,
    });
  };

  return (
    <OptionWrapper>
      <ColorTitle>{fieldData.label}:</ColorTitle>
      <BorderLine />
      <Input
        showLabel={false}
        type="checkbox"
        checked={filters[fieldData.label]?.isTrue}
        onChange={(e) => handleOnChange(e.target.checked)}
      />
    </OptionWrapper>
  );
};

const FilterOptionFinderObjectId = ({
  fieldData,
  filters,
  dispatchFilters,
  nativeLanguages,
  hobbies,
  speakers,
  chatConfigs,
}) => {
  const handleOnChangeEnum = (value) => {
    return dispatchFilters({
      type: 'add',
      key: fieldData.label,
      value: value.map((v) => v.value),
      type_field: 'Ref',
    });
  };

  let options = [];
  const current = filters[fieldData.label]?.value;

  if (fieldData.value.ref === 'NativeLanguage') {
    options = nativeLanguages;
  }

  if (fieldData.value.ref === 'AiPerson') {
    options = speakers;
  }

  if (fieldData.value.ref === 'PromptGroup') {
    options = chatConfigs;
  }

  return (
    <OptionWrapper>
      <ColorTitle>{fieldData.label}:</ColorTitle>
      <BorderLine />
      <SelectInput
        options={options}
        showLabel={false}
        selected={options.filter((option) => current?.includes(option.value))}
        setSelected={(e) => handleOnChangeEnum(e)}
        multiple={true}
        selectWidth={500}
      />
    </OptionWrapper>
  );
};

const FilterOptionFinder = ({
  data,
  filters,
  dispatchFilters,
  nativeLanguages,
  hobbies,
  speakers,
  chatConfigs,
}) => {
  const getObjectIdFilterOption = (fieldData) => {
    if (fieldData.value.type === 'Ref') {
      return (
        <FilterOptionFinderObjectId
          fieldData={fieldData}
          filters={filters}
          dispatchFilters={dispatchFilters}
          nativeLanguages={nativeLanguages}
          hobbies={hobbies}
          speakers={speakers}
          chatConfigs={chatConfigs}
        />
      );
    }

    if (fieldData.value.type === 'Exists') {
      return (
        <FilterOptionExists
          fieldData={fieldData}
          filters={filters}
          dispatchFilters={dispatchFilters}
        />
      );
    }
  };

  return data.map((fieldData) => {
    if (fieldData.value.type === 'String' || fieldData.value.type === 'Enum')
      return (
        <FilterOptionFinderString
          fieldData={fieldData}
          filters={filters}
          dispatchFilters={dispatchFilters}
        />
      );
    if (fieldData.value.type === 'Date')
      return (
        <FilterOptionFinderDate
          fieldData={fieldData}
          filters={filters}
          dispatchFilters={dispatchFilters}
        />
      );
    if (fieldData.value.type === 'Number')
      return (
        <FilterOptionFinderNumber
          fieldData={fieldData}
          filters={filters}
          dispatchFilters={dispatchFilters}
        />
      );
    if (['Boolean', 'Bool'].includes(fieldData.value.type))
      return (
        <FilterOptionFinderBool
          fieldData={fieldData}
          filters={filters}
          dispatchFilters={dispatchFilters}
        />
      );
    if (
      fieldData.value.type === 'ObjectID' ||
      fieldData.value.type === 'Ref' ||
      fieldData.value.type === 'Exists'
    ) {
      return getObjectIdFilterOption(fieldData);
    }
  });
};

const SearchFilter = ({
  filters,
  dispatchFilters,
  selectedData,
  setSelectedData,
  data,
  nativeLanguages,
  hobbies,
  speakers,
  chatConfigs,
}) => {
  const handleSelected = (e) => {
    dispatchFilters({ type: 'validate', values: e });
    setSelectedData(e);
  };

  return (
    <div>
      {data && (
        <div>
          <SubTitle>Filters:</SubTitle>
          <SelectInput
            name="Field"
            color={Color.Blue}
            width={40}
            selected={selectedData}
            setSelected={(e) => handleSelected(e)}
            options={data}
            multiple={true}
            selectWidth={920}
          />
          <FilterOptionFinder
            data={selectedData}
            dispatchFilters={dispatchFilters}
            filters={filters}
            nativeLanguages={nativeLanguages}
            hobbies={hobbies}
            speakers={speakers}
            chatConfigs={chatConfigs}
          />
        </div>
      )}
    </div>
  );
};

export default SearchFilter;
