import routes from '@/common/routes/routes';
import React, { useContext, useEffect, useLayoutEffect } from 'react';
import { Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import { Howler } from 'howler';
import { debounce } from './common/functions/debounce';
import { IpStatsContext } from './common/contexts/IpStatsContext';
import useQueryMemo from './common/hooks/urlMemo';
import userManager from './api/userManager';
import { useMutation } from 'react-query';
import { addFBStats, addIpStats } from './api/query/ipStats';
import { isMobile } from 'react-device-detect';
import { EventService } from './common/services/eventService';
import { EventNamesEnum } from './common/enums/eventNamesEnum';
import MessageQueue, { useMessageQueue } from './common/message';
import { MessageContext } from './common/contexts/MessageContext';
import { pingUserServer } from './api/query/workflow';
import moment from 'moment';
import { getPermission } from './common/utils';
import { LanguageContext } from './common/contexts/LanguageContext';
import Seo from './common/components/Seo';

const INTERVAL = 30 * 1000;

function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryMemo = useQueryMemo();

  const { lang } = useContext(LanguageContext);
  const clearFunction = (event) => {
    event.preventDefault();
  };
  const location = useLocation();

  const unloadHowler = () => {
    Howler.unload();
  };

  const triggerResize = () => {
    window.dispatchEvent(new Event('resize'));
  };

  const handleSendIPstatsMutation = useMutation({
    mutationFn: (value) => addIpStats(value),
    onSuccess: () => {},
  });

  const setupUtms = () => {
    if (queryMemo.get('quiz_email')) {
      localStorage.setItem('quiz_email', queryMemo.get('quiz_email'));
    }
    if (queryMemo.get('promo')) {
      localStorage.setItem('promo', queryMemo.get('promo'));
    }
    if (queryMemo.get('utm_campaign')) {
      localStorage.setItem('utm_campaign', queryMemo.get('utm_campaign'));
    }
    if (queryMemo.get('utm_medium')) {
      localStorage.setItem('utm_medium', queryMemo.get('utm_medium'));
    }
    if (queryMemo.get('utm_source')) {
      localStorage.setItem('utm_source', queryMemo.get('utm_source'));
    }
    if (queryMemo.get('utm_content')) {
      localStorage.setItem('utm_content', queryMemo.get('utm_content'));
    }
    if (queryMemo.get('plan')) {
      localStorage.setItem('plan', queryMemo.get('plan'));
    }

    if (queryMemo.get('fbclid')) {
      localStorage.setItem('fbclid', queryMemo.get('fbclid'));
    }

    if (queryMemo.get('pageName')) {
      localStorage.setItem('pageName', queryMemo.get('pageName'));
    }
    if (queryMemo.get('msclkid')) {
      localStorage.setItem('msclkid', queryMemo.get('msclkid'));
    }
  };

  const handleSendIPstats = (type, userId = null) => {
    const utm_campaign =
      queryMemo.get('utm_campaign') || localStorage.getItem('utm_campaign');
    const utm_medium =
      queryMemo.get('utm_medium') || localStorage.getItem('utm_medium');
    const utm_source =
      queryMemo.get('utm_source') || localStorage.getItem('utm_source');
    const utm_content =
      queryMemo.get('utm_content') || localStorage.getItem('utm_content');
    const utm_fbclid =
      queryMemo.get('fbclid') || localStorage.getItem('fbclid');
    const utm_term =
      queryMemo.get('utm_term') || localStorage.getItem('utm_term');
    const pageName =
      queryMemo.get('pageName') || localStorage.getItem('pageName');
    const msclkid = queryMemo.get('msclkid') || localStorage.getItem('msclkid');

    const utm = {
      utm_campaign: utm_campaign,
      utm_medium: utm_medium,
      utm_source: utm_source,
      utm_content: utm_content,
      utm_fbclid: utm_fbclid,
      utm_term: utm_term,
      pageName: pageName,
      payment_option: localStorage.getItem('payment_option'),
      uid: localStorage.getItem('uid'),
      msclkid: msclkid,
    };

    if (queryMemo.get('quiz_email')) {
      localStorage.setItem('quiz_email', queryMemo.get('quiz_email'));
    }
    if (queryMemo.get('promo')) {
      localStorage.setItem('promo', queryMemo.get('promo'));
    }
    if (queryMemo.get('utm_campaign')) {
      localStorage.setItem('utm_campaign', queryMemo.get('utm_campaign'));
    }
    if (queryMemo.get('utm_medium')) {
      localStorage.setItem('utm_medium', queryMemo.get('utm_medium'));
    }
    if (queryMemo.get('utm_source')) {
      localStorage.setItem('utm_source', queryMemo.get('utm_source'));
    }
    if (queryMemo.get('utm_content')) {
      localStorage.setItem('utm_content', queryMemo.get('utm_content'));
    }

    if (queryMemo.get('fbclid')) {
      localStorage.setItem('fbclid', queryMemo.get('fbclid'));
    }

    if (queryMemo.get('pageName')) {
      localStorage.setItem('pageName', queryMemo.get('pageName'));
    }
    if (queryMemo.get('msclkid')) {
      localStorage.setItem('msclkid', queryMemo.get('msclkid'));
    }

    window.gtag('set', { campaignName: utm_campaign });
    window.gtag('set', { campaignSource: utm_medium });
    window.gtag('set', { campaignMedium: utm_source });
    window.gtag('set', { campaignContent: utm_content });
    window.gtag('set', { campaignFbclid: utm_fbclid });
    window.gtag('set', { campaignMsclid: msclkid });

    const payload = {};
    payload.user = userId || userManager?.getUser()?.id;
    payload.lead_history = localStorage?.getItem('lead-history-id');
    payload.IpStatsType = type;
    payload.utm = utm;
    payload.device_type = isMobile ? 'mobile' : 'desktop';
    payload.device_description = window.navigator.userAgent;
    payload.lang = lang;

    handleSendIPstatsMutation.mutate(payload);
  };

  useLayoutEffect(() => {
    const srouce = queryMemo.get('utm_source');
    if (srouce === 'mail' || srouce === 'wp') {
      const event = queryMemo.get('event');

      if (event) {
        handleSendIPstats(event?.toLowerCase());
      }
    }
  }, []);

  useEffect(() => {
    document.addEventListener('contextmenu', clearFunction);
    return () => document.removeEventListener('contextmenu', clearFunction);
  }, []);

  useEffect(() => {
    unloadHowler();
  }, [location.pathname]);

  useEffect(() => {
    window.addEventListener('blur', unloadHowler);

    return () => window.removeEventListener('blur', unloadHowler);
  }, []);

  useEffect(() => {
    const setVh = debounce(() => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, 50);

    window.addEventListener('resize', setVh);

    return () => {
      window.removeEventListener('resize', setVh);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('orientationchange', triggerResize);

    return () => {
      window.removeEventListener('orientationchange', triggerResize);
    };
  }, []);

  useEffect(() => {
    triggerResize();
  }, []);

  useEffect(() => {
    setupUtms();

    const trackingId = localStorage.getItem('uid') || queryMemo.get('uid');

    const SynthiAiUserIdTrack =
      (trackingId != 'undefinded' && trackingId) ||
      new Date().getTime().toString(16) + Math.floor(Math.random() * 100);
    localStorage.setItem('uid', SynthiAiUserIdTrack);

    EventService.publish(EventNamesEnum.TRACING_ID_PROCESSED);

    if (
      ['login', 'register', 'checkout'].includes(
        location.pathname.replaceAll('/', ''),
      ) &&
      !queryMemo.get('uid')
    ) {
      setSearchParams({
        uid: SynthiAiUserIdTrack,
      });
    }
  }, [location.pathname]);

  const { addMessage, removeMessage, messages } = useMessageQueue();

  const pingActiveUser = useMutation({
    mutationFn: (data) => pingUserServer(data),
    onSuccess: () => {},
  });

  const handleFbStatsMutation = useMutation({
    mutationFn: (value) => addFBStats(value),
    onSuccess: () => {},
  });

  const handleFbStats = (event, eventData) => {
    const utm_fbclid =
      queryMemo.get('fbclid') || localStorage.getItem('fbclid');

    let fbp = document.cookie
      .split(';')
      .filter((c) => c.includes('_fbp='))
      .map((c) => c.split('_fbp=')[1]);
    let fbc = document.cookie
      .split(';')
      .filter((c) => c.includes('_fbc='))
      .map((c) => c.split('_fbc=')[1]);

    fbp = (fbp.length && fbp[0]) || null;
    fbc = (fbc.length && fbc[0]) || null;
    if (!fbc && utm_fbclid) {
      fbc = 'fb.1.' + +new Date() + '.' + utm_fbclid;
    }

    const payload = {
      event: event,
      event_data: eventData,
      fbp: fbp,
      fbclid: fbc,
      user_agent: navigator.userAgent,
      url: window.location.origin + window.location.pathname,
    };

    handleFbStatsMutation.mutate(payload);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const user = userManager.getUser();
      if (user && user.role !== 'role') {
        const payload = {
          userDate: moment().utcOffset(0, true).toISOString(),
          interval: INTERVAL / 1000,
        };

        pingActiveUser.mutate(payload);
      }
    }, INTERVAL);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (navigator?.serviceWorker?.ready) {
      getPermission(handleSendIPstats);
    }
  }, [navigator?.serviceWorker?.ready]);

  return (
    <>
      <IpStatsContext.Provider
        value={{
          handleSendIPstats,
          handleFbStats,
        }}
      >
        <MessageQueue
          messages={messages}
          removeMessage={removeMessage}
          addMessage={addMessage}
        />
        <MessageContext.Provider
          value={{
            addMessage,
            removeMessage,
            messages,
          }}
        >
          <Routes>
            {routes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <>
                    <Seo />
                    {route.element}
                  </>
                }
              />
            ))}
          </Routes>
        </MessageContext.Provider>
      </IpStatsContext.Provider>
    </>
  );
}

export default App;
