import React, { useMemo } from 'react';
import { TableWrapper } from '../styles';
import { formatDate } from '@/pages/admin/mail/AdminMail';
import DataTable from '@/common/components/custom/DataTable';

const MAIL_HEADERS = [
  {
    header: 'No.',
    accessorKey: 'no',
    size: 50,
  },
  {
    header: 'Send At',
    accessorKey: 'send_at',
    size: 150,
  },
  {
    header: 'Type',
    accessorKey: 'type',
    size: 100,
  },
  {
    header: 'Delivered',
    accessorKey: 'delivered',
    size: 150,
  },
  {
    header: 'Open',
    accessorKey: 'open',
    size: 150,
  },
  {
    header: 'Clicked',
    accessorKey: 'clicked',
    size: 150,
  },
  {
    header: 'Bounce',
    accessorKey: 'bounce',
    size: 150,
  },
  {
    header: 'Spam',
    accessorKey: 'spam',
    size: 150,
  },
  {
    header: 'Deferred',
    accessorKey: 'deferred',
    size: 150,
  },
  {
    header: 'Dropped',
    accessorKey: 'dropped',
    size: 150,
  },
];

const MailsTable = ({ mails }) => {
  // Sortowanie maili według daty wysłania (od najnowszych)
  const sortedMails = useMemo(() => {
    return [...mails].sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    );
  }, [mails]);

  const tableData = useMemo(() => {
    return sortedMails.map((mail, index) => ({
      no: index + 1,
      send_at: formatDate(mail.created_at),
      type: mail.mailType,
      delivered: mail.delivered_at ? formatDate(mail.delivered_at) : '------',
      open: mail.first_open_at ? formatDate(mail.first_open_at) : '------',
      clicked: mail.first_click_at ? formatDate(mail.first_click_at) : '------',
      bounce: mail.bounced_at ? formatDate(mail.bounced_at) : '------',
      spam: mail.first_spam_report_at
        ? formatDate(mail.first_spam_report_at)
        : '------',
      deferred: mail.deferred_at ? formatDate(mail.deferred_at) : '------',
      dropped: mail.dropped_at ? formatDate(mail.dropped_at) : '------',
    }));
  }, [sortedMails]);

  return (
    <TableWrapper>
      <h3>Mails:</h3>
      <DataTable columns={MAIL_HEADERS} data={tableData} />
    </TableWrapper>
  );
};

export default MailsTable;
