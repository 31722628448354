import PopUp from '@/common/components/PopUp';
import Input from '@/common/components/custom/Input';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { styled } from 'styled-components';
import Loading from '@/common/components/Loading';
import CustomSaveButton from '@/common/components/custom/buttons/CustomSaveButton';
import { createPrices, updatePrices } from '@/api/query/notification';
import { MessageContext } from '@/common/contexts/MessageContext';
import { createPrice, updatePrice } from '@/api/query/price';
import { getPaymentTypes } from '@/api/query/paymentType';
import SelectInput from '@/common/components/custom/SelectInput';

export const ImageInput = styled.input`
  display: none;
`;
export const ImageWrapper = styled.div`
  width: 300px;
  height: 300px;
  overflow: hidden;
`;

const Title = styled.h2`
  text-align: center;
`;

const Form = styled.form`
  margin: 0px 30px;
  display: flex;
  flex-direction: column;
  height: 700px;
`;

const AdminPriceEdit = ({ setEdit, edit }) => {
  const fullPriceRef = useRef();
  const perMonthRef = useRef();
  const nameRef = useRef();
  const lpLabelRef = useRef();
  const lpSavingRef = useRef();
  const lpDescriptionRef = useRef();
  const lpOriginalPriceInfoRef = useRef();
  const lpPromotionMonthlyPriceInfoRef = useRef();
  const lpPromotionFullPriceInfoRef = useRef();
  const inAppSavingRef = useRef();
  const inAppLabelRef = useRef();
  const inAppOriginalPriceInfoRef = useRef();
  const inAppPromotionMonthlyPriceInfoRef = useRef();
  const inAppChoseRef = useRef();
  const inAppNowYouWillPayRef = useRef();
  const inAppPromotionFullPriceInfoRef = useRef();
  const inAppFullLabelRef = useRef();
  const inAppDiscountLabelRef = useRef();
  const inAppDiscountValueRef = useRef();
  const inAppOriginalFullPriceRef = useRef();
  const inAppPromoSavingRef = useRef(); // New input ref
  const inAppPromoDiscountLabelRef = useRef(); // New input ref
  const inAppPromoDiscountValueRef = useRef(); // New input ref
  const productPageOfferNameRef = useRef();
  const productPageAccessForRef = useRef();
  const productPageLabelRef = useRef();
  const productPagePromotionMonthlyPriceInfoRef = useRef();
  const productPageStartRef = useRef();
  const productPageSavingRef = useRef();
  const stripeProductIdRef = useRef();
  const stripeProductIdPromoRef = useRef();

  const promoFullPriceRef = useRef(); // New input ref

  const [typeOptions, setTypeOptions] = useState([]);
  const [selectedType, setSelectedType] = useState();

  const getPaymentTypeMutation = useMutation({
    mutationFn: (value) => getPaymentTypes(value),
    onSuccess: ({ data }) => {
      const paymentTypes = data.map((d) => ({ label: d.label, value: d._id }));
      setTypeOptions(() => paymentTypes);

      if (edit._id) {
        setSelectedType(() =>
          paymentTypes.find((c) => c.value === edit.type._id),
        );
      }
    },
    onError: () => {
      addMessage('Sth went wrong', 'error');
    },
  });

  const { addMessage } = useContext(MessageContext);

  const createCategoryMutation = useMutation({
    mutationFn: (value) => createPrice(value),
    onSuccess: ({ data }) => {
      addMessage('CREATED', 'success');
      setEdit(null);
    },
    onError: () => {
      addMessage('Sth went wrong', 'error');
    },
  });

  const updateCategoryMutation = useMutation({
    mutationFn: (value) => updatePrice(value),
    onSuccess: ({ data }) => {
      addMessage('UPDATED', 'success');
      setEdit(null);
    },
    onError: () => {
      addMessage('Sth went wrong', 'error');
    },
  });

  const handleSave = (e) => {
    e && e.preventDefault();

    const payload = {
      ...edit,
      stripe_product_id: stripeProductIdRef.current.value,
      stripe_promo_product_id: stripeProductIdPromoRef.current.value,
      full_price: fullPriceRef.current.value,
      per_month: perMonthRef.current.value,
      promo_full_price: promoFullPriceRef.current.value, // New field
      name: nameRef.current.value,
      type: selectedType.value,
      lpPrice: {
        label: lpLabelRef.current.value,
        saving: lpSavingRef.current.value,
        description: lpDescriptionRef.current.value,
        original_price_info: lpOriginalPriceInfoRef.current.value,
        promotion_monthly_price_info:
          lpPromotionMonthlyPriceInfoRef.current.value,
        promotion_full_price_info: lpPromotionFullPriceInfoRef.current.value,
      },
      inAppPrice: {
        saving: inAppSavingRef.current.value,
        label: inAppLabelRef.current.value,
        original_price_info: inAppOriginalPriceInfoRef.current.value,
        promotion_monthly_price_info:
          inAppPromotionMonthlyPriceInfoRef.current.value,
        chose: inAppChoseRef.current.value,
        nowYouWillPay: inAppNowYouWillPayRef.current.value,
        promotion_full_price_info: inAppPromotionFullPriceInfoRef.current.value,
        full_label: inAppFullLabelRef.current.value,
        discount_label: inAppDiscountLabelRef.current.value,
        discount_value: inAppDiscountValueRef.current.value,
        orginal_full_price: inAppOriginalFullPriceRef.current.value,
        promo_saving: inAppPromoSavingRef.current.value, // New field
        promo_discount_label: inAppPromoDiscountLabelRef.current.value, // New field
        promo_discount_value: inAppPromoDiscountValueRef.current.value, // New field
      },
      productPagePrice: {
        offerName: productPageOfferNameRef.current.value,
        accessFor: productPageAccessForRef.current.value,
        label: productPageLabelRef.current.value,
        promotion_monthly_price_info:
          productPagePromotionMonthlyPriceInfoRef.current.value,
        start: productPageStartRef.current.value,
        saving: productPageSavingRef.current.value,
      },
    };

    if (edit._id) {
      return updateCategoryMutation.mutate(payload);
    }

    createCategoryMutation.mutate(payload);
  };

  useEffect(() => {
    getPaymentTypeMutation.mutate();
  }, []);

  return (
    <>
      {(updateCategoryMutation.isLoading ||
        createCategoryMutation.isLoading) && <Loading />}
      <PopUp setClose={setEdit}>
        <Title>Price {edit._id ? 'Edit' : 'Create'}</Title>
        <Form onSubmit={(e) => handleSave(e)}>
          <Input
            inputRef={stripeProductIdRef}
            name="Stripe Product ID"
            inputWidth={400}
            width={300}
            value={edit.stripe_product_id}
          />
          <Input
            inputRef={stripeProductIdPromoRef}
            name="Stripe Product promo ID"
            inputWidth={400}
            width={300}
            value={edit.stripe_promo_product_id}
          />
          <Input
            inputRef={nameRef}
            name="Name"
            inputWidth={400}
            width={300}
            value={edit.name}
          />
          <SelectInput
            name={'Type'}
            width={300}
            selectWidth={400}
            options={typeOptions}
            setSelected={setSelectedType}
            selected={selectedType}
          />
          <Input
            inputRef={fullPriceRef}
            name="Full price"
            inputWidth={400}
            width={300}
            value={edit.full_price}
          />
          <Input
            inputRef={perMonthRef}
            name="Per month"
            inputWidth={400}
            width={300}
            value={edit.per_month}
          />
          <Input
            inputRef={promoFullPriceRef} // New input
            name="Promo Full Price"
            inputWidth={400}
            width={300}
            value={edit.promo_full_price}
          />
          <Title>LP Price</Title>
          <Input
            inputRef={lpLabelRef}
            name="LP Label"
            inputWidth={400}
            width={300}
            value={edit.lpPrice?.label}
          />
          <Input
            inputRef={lpSavingRef}
            name="LP Saving"
            inputWidth={400}
            width={300}
            value={edit.lpPrice?.saving}
          />
          <Input
            inputRef={lpDescriptionRef}
            name="LP Description"
            inputWidth={400}
            width={300}
            value={edit.lpPrice?.description}
          />
          <Input
            inputRef={lpOriginalPriceInfoRef}
            name="LP Original Price Info"
            inputWidth={400}
            width={300}
            value={edit.lpPrice?.original_price_info}
          />
          <Input
            inputRef={lpPromotionMonthlyPriceInfoRef}
            name="LP Promotion Monthly Price Info"
            inputWidth={400}
            width={300}
            value={edit.lpPrice?.promotion_monthly_price_info}
          />
          <Input
            inputRef={lpPromotionFullPriceInfoRef}
            name="LP Promotion Full Price Info"
            inputWidth={400}
            width={300}
            value={edit.lpPrice?.promotion_full_price_info}
          />
          <Title>In-App Price</Title>
          <Input
            inputRef={inAppSavingRef}
            name="In-App Saving"
            inputWidth={400}
            width={300}
            value={edit.inAppPrice?.saving}
          />
          <Input
            inputRef={inAppLabelRef}
            name="In-App Label"
            inputWidth={400}
            width={300}
            value={edit.inAppPrice?.label}
          />
          <Input
            inputRef={inAppOriginalPriceInfoRef}
            name="In-App Original Price Info"
            inputWidth={400}
            width={300}
            value={edit.inAppPrice?.original_price_info}
          />
          <Input
            inputRef={inAppPromotionMonthlyPriceInfoRef}
            name="In-App Promotion Monthly Price Info"
            inputWidth={400}
            width={300}
            value={edit.inAppPrice?.promotion_monthly_price_info}
          />
          <Input
            inputRef={inAppChoseRef}
            name="In-App Chose"
            inputWidth={400}
            width={300}
            value={edit.inAppPrice?.chose}
          />
          <Input
            inputRef={inAppNowYouWillPayRef}
            name="In-App Now You Will Pay"
            inputWidth={400}
            width={300}
            value={edit.inAppPrice?.nowYouWillPay}
          />
          <Input
            inputRef={inAppPromotionFullPriceInfoRef}
            name="In-App Promotion Full Price Info"
            inputWidth={400}
            width={300}
            value={edit.inAppPrice?.promotion_full_price_info}
          />
          <Input
            inputRef={inAppFullLabelRef}
            name="In-App Full Label"
            inputWidth={400}
            width={300}
            value={edit.inAppPrice?.full_label}
          />
          <Input
            inputRef={inAppDiscountLabelRef}
            name="In-App Discount Label"
            inputWidth={400}
            width={300}
            value={edit.inAppPrice?.discount_label}
          />
          <Input
            inputRef={inAppDiscountValueRef}
            name="In-App Discount Value"
            inputWidth={400}
            width={300}
            value={edit.inAppPrice?.discount_value}
          />
          <Input
            inputRef={inAppOriginalFullPriceRef}
            name="In-App Original Full Price"
            inputWidth={400}
            width={300}
            value={edit.inAppPrice?.orginal_full_price}
          />
          <Input
            inputRef={inAppPromoSavingRef} // New input
            name="In-App Promo Saving"
            inputWidth={400}
            width={300}
            value={edit.inAppPrice?.promo_saving}
          />
          <Input
            inputRef={inAppPromoDiscountLabelRef} // New input
            name="In-App Promo Discount Label"
            inputWidth={400}
            width={300}
            value={edit.inAppPrice?.promo_discount_label}
          />
          <Input
            inputRef={inAppPromoDiscountValueRef} // New input
            name="In-App Promo Discount Value"
            inputWidth={400}
            width={300}
            value={edit.inAppPrice?.promo_discount_value}
          />

          <Title>Product Page Price</Title>
          <Input
            inputRef={productPageOfferNameRef}
            name="Product Page Offer Name"
            inputWidth={400}
            width={300}
            value={edit.productPagePrice?.offerName}
          />
          <Input
            inputRef={productPageAccessForRef}
            name="Product Page Access For"
            inputWidth={400}
            width={300}
            value={edit.productPagePrice?.accessFor}
          />
          <Input
            inputRef={productPageLabelRef}
            name="Product Page Label"
            inputWidth={400}
            width={300}
            value={edit.productPagePrice?.label}
          />
          <Input
            inputRef={productPagePromotionMonthlyPriceInfoRef}
            name="Product Page Promotion Monthly Price Info"
            inputWidth={400}
            width={300}
            value={edit.productPagePrice?.promotion_monthly_price_info}
          />
          <Input
            inputRef={productPageStartRef}
            name="Product Page Start"
            inputWidth={400}
            width={300}
            value={edit.productPagePrice?.start}
          />
          <Input
            inputRef={productPageSavingRef}
            name="Product Page Saving"
            inputWidth={400}
            width={300}
            value={edit.productPagePrice?.saving}
          />
          <div style={{ flex: '1' }} />
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <CustomSaveButton text="Save" />
          </div>
        </Form>
      </PopUp>
    </>
  );
};

export default AdminPriceEdit;
