import { loadFeatureStats } from '@/api/query/report';
import CustomBar from '@/common/charts/Bar';
import { Color } from '@/common/colors/colors';
import Loading from '@/common/components/Loading';
import Input from '@/common/components/custom/Input';
import CustomSaveButton from '@/common/components/custom/buttons/CustomSaveButton';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';

const Form = styled.form`
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
`;

const FeatureUsage = () => {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const searchRef = useRef();

  const loadFeatureStatsMutation = useMutation({
    mutationFn: (data) => loadFeatureStats(data),
    onSuccess: ({ data }) => {
      setChartData(() => ({
        labels: ['Feature Stats'],
        datasets: [
          {
            label: 'Image Mode',
            data: [data.image_mode],
            backgroundColor: Color.Dark,
          },
          {
            label: 'Scene',
            data: [data.scene],
            backgroundColor: Color.Blue,
          },
          {
            label: 'Daily',
            data: [data.daily],
            backgroundColor: Color.Accent,
          },
          {
            label: 'Flashcard',
            data: [data.flashcard],
            backgroundColor: Color.SecondaryBrand,
          },
          {
            label: 'Word',
            data: [data.word],
            backgroundColor: Color.Success,
          },
          {
            label: 'Call Mode',
            data: [data.call_mode],
            backgroundColor: Color.SubtleText,
          },
        ],
      }));
    },
  });

  const handleSearch = (e) => {
    e?.preventDefault();

    const payload = {};
    payload.days = searchRef.current.value;

    loadFeatureStatsMutation.mutate(payload);
  };

  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <>
      {loadFeatureStatsMutation.isLoading && <Loading />}
      <div>
        <Form onSubmit={handleSearch}>
          <div>Feature Usage</div>
          <Input
            inputRef={searchRef}
            name="days"
            type="number"
            value={30}
            width={80}
            inputWidth={40}
            required
            requiredSign
          />{' '}
          <CustomSaveButton text="Search" />
        </Form>
        <CustomBar data={chartData} />
      </div>
    </>
  );
};

export default FeatureUsage;
