import { styled } from 'styled-components';

export const Wrapper = styled.section`
  margin-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 1200px;
  gap: 40px;
`;

export const Title = styled.h2`
  color: #05275d;
  text-align: center;
  /* H2 */
  font-family: Work Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.8px;
  margin: 0;

  @media (max-width: 600px) {
    margin-top: -50px;
    font-size: 28px;
  }
`;

export const PlanWrapper = styled.div`
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    max-width: 300px;
    margin-top: -20px;
    gap: 10px;
  }
`;

export const MarginPlan = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 280px;
  flex-shrink: 0;
  border-radius: 16px;
  background: #fff;
  padding: 20px;

  @media (max-width: 600px) {
    width: 240px;
    padding: 10px;
  }
`;

export const MainPlan = styled.div`
  display: flex;
  padding: 30px 35px;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  border-radius: 16px;
  background: linear-gradient(
    46deg,
    rgba(6, 191, 214, 0.6) 0%,
    rgba(2, 214, 156, 0.6) 100%
  );

  @media (max-width: 600px) {
    padding: 10px 30px;
    gap: 10px;
  }
`;

export const TopInfo = styled.h3`
  color: #05275d;
  text-align: center;
  /* H3 */
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
  margin: 20px 0 0 0;

  @media (max-width: 600px) {
    margin-top: 10px;
    font-size: 22px;
  }
`;

export const Price = styled.h2`
  color: #05275d;
  text-align: center;
  /* H2 */
  font-family: Work Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.8px;
  margin: 40px 0 0 0;

  @media (max-width: 600px) {
    margin-top: 20px;
    font-size: 34px;
  }
`;

export const ButtonBuy = styled.button`
  color: #fff;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  border-radius: 100px;
  background: #650675;
  border: none;
  padding: 15px 80px;
  margin-top: 40px;

  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 600px) {
    margin-top: 20px;
    padding: 12px 50px;
    font-size: 18px;
  }
`;

export const SubTop = styled.div`
  color: #05275d;
  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
