import React, { useMemo } from 'react';
import CustomAddButton from '@/common/components/custom/buttons/CustomAddButton';
import CustomEditButton from '@/common/components/custom/buttons/CustomEditButton';
import { TableWrapper } from '../styles';
import { formatDate } from '@/pages/admin/mail/AdminMail';
import DataTable from '@/common/components/custom/DataTable';

const PAYMENT_HEADERS = [
  {
    header: 'No.',
    accessorKey: 'no',
    size: 50,
  },
  {
    header: 'Price',
    accessorKey: 'price',
    size: 100,
  },
  {
    header: 'Status',
    accessorKey: 'status',
    size: 100,
  },
  {
    header: 'Pending at',
    accessorKey: 'pending_at',
    size: 150,
  },
  {
    header: 'Canceled at',
    accessorKey: 'canceled_at',
    size: 150,
  },
  {
    header: 'Completed at',
    accessorKey: 'completed_at',
    size: 150,
  },
  {
    header: 'Actions',
    accessorKey: 'actions',
    size: 100,
    cell: ({ row }) => (
      <CustomEditButton onClick={() => row.original && row.original.onEdit()} />
    ),
  },
];

const PaymentDetails = ({ payments, setEditPayment }) => {
  const sortedPayments = useMemo(() => {
    return [...payments].sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    );
  }, [payments]);

  const tableData = useMemo(() => {
    return sortedPayments.map((payment, index) => ({
      no: index + 1,
      price: (payment.price / 100).toFixed(2),
      status: payment.status,
      pending_at: payment.status_pending_date
        ? formatDate(payment.status_pending_date)
        : formatDate(payment.created_at),
      canceled_at: payment.status_canceled_date
        ? formatDate(payment.status_canceled_date)
        : '------',
      completed_at: payment.status_complated_date
        ? formatDate(payment.status_complated_date)
        : '------',
      actions: payment,
      onEdit: () => setEditPayment(payment),
    }));
  }, [sortedPayments, setEditPayment]);

  return (
    <TableWrapper>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <h3>Payment:</h3>
        <CustomAddButton onClick={() => setEditPayment({})} />
      </div>
      <DataTable columns={PAYMENT_HEADERS} data={tableData} />
    </TableWrapper>
  );
};

export default PaymentDetails;
