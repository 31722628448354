import PopUp from '@/common/components/PopUp';
import CustomTable from '@/common/components/custom/CustomTable';
import Input from '@/common/components/custom/Input';
import CustomAddButton from '@/common/components/custom/buttons/CustomAddButton';
import CustomDeleteButton from '@/common/components/custom/buttons/CustomDeleteButton';
import CustomEditButton from '@/common/components/custom/buttons/CustomEditButton';
import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import EditPrompt from './EditPrompt';
import SelectInput from '@/common/components/custom/SelectInput';
import { LEVEL_OPTIONS } from '@/pages/landing/components/DemoScript/components/LevelMessage/levelMessage.constants';
import TextArea from '@/common/components/TextArea';

const HEADERS = ['wieght', 'prompt', 'actions'];

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
  min-width: 1000px;
`;

const EditPromptGroup = ({ edit, setClose, handleAddPrompt }) => {
  const [showPrompt, setShowPrompt] = useState();
  const [prompts, setPrompts] = useState(edit?.prompts || []);
  const nameRef = useRef();
  const weightRef = useRef();
  const hintPromptRef = useRef();
  const feedbackPromptRef = useRef();
  const trnaslationPromptRef = useRef();

  const deletePrompt = (promptElement) => {
    const filtred = prompts
      .filter((p) => p.id !== promptElement.id)
      .map((p, i) => ({ ...p, id: i + 1 }));

    setPrompts(() => filtred);
  };

  const tableData = useMemo(() => {
    if (!prompts) {
      return [];
    } else {
      return prompts
        .sort((a, b) => a.weight - b.weight)
        .map((g) => [
          g.weight,
          g.prompt,
          <div style={{ display: 'flex', gap: '10px' }}>
            <CustomEditButton onClick={() => setShowPrompt(g)} />
            <CustomDeleteButton onClick={() => deletePrompt(g)} />
          </div>,
        ]);
    }
  }, [prompts]);

  const handleSave = (e) => {
    const paylaod = edit;

    paylaod.name = nameRef.current.value;
    paylaod.weight = weightRef.current.value;
    paylaod.prompts = prompts;
    paylaod.hintPrompt = hintPromptRef.current.value;
    paylaod.feedbackPrompt = feedbackPromptRef?.current?.value;
    paylaod.trnaslationPrompt = trnaslationPromptRef?.current?.value;

    if (edit.id) {
      handleAddPrompt((prev) => [
        ...prev.filter((p) => p.id !== paylaod.id),
        paylaod,
      ]);
      setClose(null);
      return;
    }

    handleAddPrompt((prev) => [...prev, { ...paylaod, id: prev.length + 1 }]);
    setClose(null);
  };

  return (
    <>
      <PopUp setClose={setClose}>
        <div
          style={{
            width: '1000px',
          }}
        >
          <h2>Prompt Group {edit.id ? 'Edit' : 'Create'}</h2>
          <Input
            inputRef={nameRef}
            name="Name"
            inputWidth={200}
            width={100}
            required
            requiredSign
            value={edit.name}
          />
          <Input
            inputRef={weightRef}
            name="Weight"
            inputWidth={200}
            type="number"
            width={100}
            required
            requiredSign
            value={edit.weight}
          />

          <TextArea
            textAreaRef={trnaslationPromptRef}
            label="Translation prompt"
            defaultValue={edit.trnaslationPrompt}
          />
          <TextArea
            textAreaRef={feedbackPromptRef}
            label="Feedback prompt"
            defaultValue={edit.feedbackPrompt}
          />
          <TextArea
            textAreaRef={hintPromptRef}
            label="Hint prompt"
            required
            requiredSign
            defaultValue={edit.hintPrompt}
          />
          <TableWrapper>
            <CustomTable headers={HEADERS} data={tableData} />
          </TableWrapper>
          <ButtonWrapper>
            <CustomAddButton onClick={() => setShowPrompt({})} />
            <CustomAddButton onClick={() => handleSave()} text="Save" />
          </ButtonWrapper>
        </div>
      </PopUp>
      {showPrompt && (
        <EditPrompt
          setClose={setShowPrompt}
          edit={showPrompt}
          handleAddPrompt={setPrompts}
        />
      )}
    </>
  );
};

export default EditPromptGroup;
