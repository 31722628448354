import {
  faBookOpen,
  faChalkboard,
  faFileWord,
  faHistory,
  faIcons,
  faImage,
  faLanguage,
  faMagic,
  faNewspaper,
  faPeoplePulling,
  faPerson,
  faPhoneFlip,
  faSchool,
  faSection,
  faServer,
  faSignLanguage,
  faTachometer,
  faUser,
  faVialCircleCheck,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px 20px 0 20px;
  gap: 20px;

  @media (max-width: 600px) {
    padding: 10px 10px 0 10px;
  }
`;

const CustomLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-weight: bold;
  color: #05275d;
  font-family: Work Sans;
  gap: 8px;
  padding: 32px;
  border-radius: 16px;
  background: rgba(79, 167, 180, 0.2);
  transition: all 0.3s;

  &:hover {
    transform: scale(0.8);
  }

  @media (max-width: 600px) {
    padding: 10px;
    font-size: 16px;
    border-radius: 10px;
  }
`;

const IconWrapper = styled.div`
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const AdminElements = [
  {
    name: 'Lekcje scenki',
    link: '/admin/lesson',
    icon: faSchool,
  },
  {
    name: 'Lekcje Słowa',
    link: '/admin/word-lesson-type',
    icon: faFileWord,
  },
  {
    name: 'Config scenek Słowa',
    link: '/admin/word-lesson-type-config',
    icon: faFileWord,
  },

  {
    name: 'Image Mode',
    link: '/admin/image-mode',
    icon: faImage,
  },
  {
    name: 'Flashcard categories',
    link: '/admin/flashcard-categories',
    icon: faChalkboard,
  },
  {
    name: 'Daily config',
    link: '/admin/daily-config',
    icon: faChalkboard,
  },
  {
    name: 'Call mode config',
    link: '/admin/call-mode-config',
    icon: faPhoneFlip,
  },
  {
    link: '/admin/news',
    name: 'News',
    icon: faNewspaper,
  },
  {
    link: '/admin/populare',
    name: 'Populare config',
    icon: faPeoplePulling,
  },
  {
    link: '/admin/web-push-client',
    name: 'Web push clients',
    icon: faPeoplePulling,
  },
  {
    link: '/admin/web-push-sent',
    name: 'Web push sent',
    icon: faPeoplePulling,
  },
  {
    link: '/admin/web-push-notification',
    name: 'Web push notification',
    icon: faPeoplePulling,
  },
  {
    link: '/admin/chat-config',
    name: 'Chat Configs',
    icon: faVialCircleCheck,
  },
];

const Workflow = () => {
  return (
    <Wrapper>
      {AdminElements.map((element) => (
        <CustomLink to={element.link}>
          <IconWrapper>
            <FontAwesomeIcon icon={element.icon} />
          </IconWrapper>
          {element.name}
        </CustomLink>
      ))}
    </Wrapper>
  );
};

export default Workflow;
