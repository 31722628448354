import { loadMailTemplates } from '@/api/query/mailTemplates';
import Loading from '@/common/components/Loading';
import CustomEditButton from '@/common/components/custom/buttons/CustomEditButton';
import { faCheck, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import MailConfigEdit from './components/MailConfigEdit';
import CustomAddButton from '@/common/components/custom/buttons/CustomAddButton';
import DataTable from '@/common/components/custom/DataTable';
import { loadMailConfigs } from '@/api/query/mailConfig';
import moment from 'moment';
const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

const TopContent = styled.div`
  display: flex;
  justify-content: right;
`;

const MailConfig = () => {
  const [edit, setEdit] = useState();
  const [mailConfigs, setMailConfigs] = useState([]);

  const HEADERS = [
    {
      header: 'no.',
      accessorKey: 'no',
      size: 20,
    },
    {
      header: 'Created At',
      accessorKey: 'created_at',
      size: 20,
    },
    {
      header: 'Name',
      accessorKey: 'name',
      size: 20,
    },
    {
      header: 'Cron',
      accessorKey: 'cron',
      size: 20,
    },
    {
      header: 'Active',
      accessorKey: 'active',
      size: 20,
      cell: (info) => (
        <div style={{ display: 'flex', gap: '10px' }}>
          <FontAwesomeIcon
            icon={info.getValue() ? faCheck : faX}
            style={{ color: `${info.getValue() ? 'green' : 'red'}` }}
          />
        </div>
      ),
    },
    {
      header: 'Actions',
      accessorKey: 'actions',
      size: 20,
      cell: (info) => (
        <div style={{ display: 'flex', gap: '10px' }}>
          <CustomEditButton onClick={(e) => setEdit(info.getValue())} />
        </div>
      ),
    },
  ];

  const handleLoadMailConfigMutation = useMutation({
    mutationFn: () => loadMailConfigs(),
    onSuccess: ({ data }) => {
      setMailConfigs(() =>
        data.map((d, i) => ({
          no: i + 1,
          created_at: moment(d.created_at).format('DD/MM/YYYY HH:mm'),
          name: d.name,
          cron: d.cron,
          active: d.active,
          actions: d,
        })),
      );
    },
  });

  useEffect(() => {
    if (!edit) {
      handleLoadMailConfigMutation.mutate();
    }
  }, [edit]);

  return (
    <Wrapper>
      {handleLoadMailConfigMutation.isLoading && <Loading />}
      {edit && <MailConfigEdit edit={edit} setEdit={setEdit} />}
      <TopContent>
        <CustomAddButton onClick={() => setEdit({})} />
      </TopContent>
      <TableWrapper>
        <DataTable columns={HEADERS} data={mailConfigs} rows={[10, 15, 20]} />
      </TableWrapper>
    </Wrapper>
  );
};

export default MailConfig;
