import Api from '../api';
const { axios: api } = Api;

const queryString = require('query-string');

const PREFIX = 'filter';

export const createFilter = async (payload) => {
  const data = await api.post(`/${PREFIX}`, payload);
  return { data: data.data };
};

export const updateFilter = async (payload) => {
  const data = await api.patch(`/${PREFIX}/${payload._id}`, payload);
  return { data: data.data };
};

export const getFilters = async () => {
  const data = await api.get(`/${PREFIX}/`);
  return { data: data.data };
};

export const searchFilterInformation = async (payload) => {
  const data = await api.post(`/${PREFIX}/search`, payload);
  return { data: data.data };
};
