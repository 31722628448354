import Api from '../api';
const { axios: api } = Api;
const queryString = require('query-string');

const PREFIX = 'chat-config';

export const getChatConfigs = async (payload) => {
  const data = await api.get(`/${PREFIX}`, payload);
  return { data: data.data };
};

export const createChatConfig = async (payload) => {
  const data = await api.post(`/${PREFIX}/`, payload);
  return { data: data.data };
};

export const updateChatConfig = async (payload) => {
  const data = await api.patch(`/${PREFIX}/${payload._id}`, payload);
  return { data: data.data };
};

export const handleRestartCustomersPicks = async (payload) => {
  const data = await api.patch(`/${PREFIX}/customer-picks/${payload.type}`);
  return { data: data.data };
};
