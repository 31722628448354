import {
  crateMailTemplate,
  updateMailTemplate,
} from '@/api/query/mailTemplates';
import PopUp from '@/common/components/PopUp';
import Input from '@/common/components/custom/Input';
import CustomSaveButton from '@/common/components/custom/buttons/CustomSaveButton';
import React, { useRef } from 'react';
import { useMutation } from 'react-query';

const MailTemplatesEdit = ({ edit, setEdit }) => {
  const nameRef = useRef();
  const sendgridIdRef = useRef();

  const handleUpdateMailTemplateMutation = useMutation({
    mutationFn: (payload) => updateMailTemplate(payload),
    onSuccess: () => {
      setEdit(() => null);
    },
  });

  const handleCreateMailTemplateMutation = useMutation({
    mutationFn: (payload) => crateMailTemplate(payload),
    onSuccess: () => {
      setEdit(() => null);
    },
  });

  const handleSave = (e) => {
    e?.preventDefault();

    const payload = {
      ...edit,
      name: nameRef.current.value,
      sendgridId: sendgridIdRef.current.value,
    };

    if (payload._id) {
      return handleUpdateMailTemplateMutation.mutate(payload);
    }

    handleCreateMailTemplateMutation.mutate(payload);
  };

  return (
    <PopUp setClose={setEdit}>
      <form onSubmit={handleSave}>
        <h3>MailTemplates {edit._id ? 'Edit' : 'Create'}</h3>
        <Input name="Name" inputRef={nameRef} value={edit.name} width={120} />
        <Input
          name="Sendgrid ID"
          inputRef={sendgridIdRef}
          value={edit.sendgridId}
          width={120}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'right',
          }}
        >
          <CustomSaveButton />
        </div>
      </form>
    </PopUp>
  );
};

export default MailTemplatesEdit;
