import Api from "../api";

const { axios: api } = Api;
const queryString = require("query-string");

const PREFIX = "lesson";

export const createLesson = async (payload) => {
  const data = await api.post(`/${PREFIX}`, payload);

  return { data: data.data };
};

export const updateLesson = async (payload) => {
  const data = await api.patch(`/${PREFIX}/${payload._id}`, payload);

  return { data: data.data };
};

export const deleteLesson = async (id) => {
  const data = await api.delete(`/${PREFIX}/${id}`);

  return { data: data.data };
};

export const findLessons = async () => {
  const data = await api.get(`/${PREFIX}`);

  return { data: data.data };
};

export const findLessonsForCustomer = async () => {
  const data = await api.get(`/${PREFIX}/for-customer`);

  return { data: data.data };
};

export const findLessonById = async (_id) => {
  const data = await api.get(`/${PREFIX}/${_id}`);

  return { data: data.data };
};

export const findCustomerLessonById = async (_id) => {
  const data = await api.get(`/${PREFIX}/customer-one/${_id}`);

  return { data: data.data };
};
