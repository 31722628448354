import CustomAddButton from '@/common/components/custom/buttons/CustomAddButton';
import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { useMutation } from 'react-query';
import CustomEditButton from '@/common/components/custom/buttons/CustomEditButton';
import AdminWordLessonTypeEdit from './components/AdminAiPerson';
import Loading from '@/common/components/Loading';
import { getAiPersons } from '@/api/query/ai-person';
import DataTable from '@/common/components/custom/DataTable';
import { findNativeLanguages } from '@/api/query/nativeLanguages';

const TopContent = styled.div`
  display: flex;
  justify-content: right;
`;
const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
`;

const ActionWrapper = styled.div`
  margin-bottom: 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

const AdminAiPerson = () => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [edit, setEdit] = useState();
  const [languages, setLangauges] = useState([]);

  const HEADERS = [
    {
      header: 'no.',
      accessorKey: 'no',
      size: 20,
    },
    {
      header: 'Name',
      accessorKey: 'name',
      size: 20,
    },
    {
      header: 'Tags',
      accessorKey: 'tags',
      size: 20,
    },
    {
      header: 'Icon',
      accessorKey: 'icon',
      size: 20,
      cell: (info) => (
        <div
          style={{
            width: '50px',
            height: '50px',
            overflow: 'hidden',
            borderRadius: '50%',
          }}
        >
          <img
            alt=""
            src={info.getValue()}
            style={{ width: '100%', height: 'auto' }}
          />
        </div>
      ),
    },
    {
      header: 'Image',
      accessorKey: 'image',
      size: 20,
      cell: (info) => (
        <div
          style={{
            width: '50px',
            height: '50px',
            overflow: 'hidden',
            borderRadius: '50%',
          }}
        >
          <img
            alt=""
            src={info.getValue()}
            style={{ width: '100%', height: 'auto' }}
          />
        </div>
      ),
    },
    {
      header: 'Prompt',
      accessorKey: 'prompt',
      size: 20,
    },
    {
      header: 'Language',
      accessorKey: 'language',
      size: 20,
    },
    {
      header: 'Actions',
      accessorKey: 'actions',
      size: 20,
      cell: (info) => (
        <div style={{ display: 'flex', gap: '10px' }}>
          <CustomEditButton onClick={(e) => setEdit(info.getValue())} />
        </div>
      ),
    },
  ];

  const findWordLessonsConfigMutation = useMutation({
    mutationFn: () => getAiPersons(),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data.map((d, i) => ({
          no: i + 1,
          name: d.name,
          tags: d?.tags.map((t) => t.tag).join(', '),
          icon: d.icon?.base64,
          image: d.image?.base64,
          prompt: d.prompt,
          language: d.language?.name,
          actions: d,
        })),
      );
      setData(data);
    },
  });

  const languagesMutation = useMutation({
    mutationFn: () => findNativeLanguages(),
    onSuccess: ({ data }) => {
      setLangauges(() =>
        data.map((d, i) => ({
          label: d.name,
          value: d._id,
        })),
      );
    },
  });

  useEffect(() => {
    if (!edit) {
      findWordLessonsConfigMutation.mutate();
      languagesMutation.mutate();
    }
  }, [edit]);

  return (
    <>
      {findWordLessonsConfigMutation.isLoading && <Loading />}
      <Wrapper>
        {edit && (
          <AdminWordLessonTypeEdit
            edit={edit}
            setEdit={setEdit}
            languages={languages}
          />
        )}
        <TopContent>
          <ActionWrapper>
            <CustomAddButton onClick={() => setEdit({})} />
          </ActionWrapper>
        </TopContent>
        <TableWrapper>
          <DataTable columns={HEADERS} data={tableData} rows={[10, 15, 20]} />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default AdminAiPerson;
