import CustomAddButton from '@/common/components/custom/buttons/CustomAddButton';
import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { useMutation } from 'react-query';
import Loading from '@/common/components/Loading';
import DataTable from '@/common/components/custom/DataTable';
import moment from 'moment';
import { getWebPushClients } from '@/api/query/web-push';
import { getFormatedFullDate } from '@/common/functions/getFormatedFullDate';

const TopContent = styled.div`
  display: flex;
  justify-content: right;
`;
const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
`;

const ActionWrapper = styled.div`
  margin-bottom: 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

const AdminWebPushClients = () => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const HEADERS = [
    {
      header: 'no.',
      accessorKey: 'no',
      size: 20,
    },
    {
      header: 'Created at',
      accessorKey: 'created_at',
      size: 20,
    },
    {
      header: 'User',
      accessorKey: 'user',
      size: 20,
    },
    {
      header: 'Active',
      accessorKey: 'active',
      size: 20,
    },
  ];

  const findWebClientsMutation = useMutation({
    mutationFn: () => getWebPushClients(),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data.map((d, i) => ({
          no: i + 1,
          created_at: getFormatedFullDate(d.created_at),
          user: d?.user?.email || '-----',
          active: d.canSend?.toString(),
        })),
      );
      setData(data);
    },
  });

  useEffect(() => {
    findWebClientsMutation.mutate();
  }, []);

  return (
    <Wrapper>
      {findWebClientsMutation.isLoading && <Loading />}
      <TableWrapper>
        <DataTable columns={HEADERS} data={tableData} rows={[10, 15, 20]} />
      </TableWrapper>
    </Wrapper>
  );
};

export default AdminWebPushClients;
