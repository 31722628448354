import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ArrowLeft from './images/arrow-left.svg';
import { useMutation } from 'react-query';
import { loadUserLearningData } from '@/api/query/user';
import Loading from '@/common/components/Loading';
import { MessageContext } from '@/common/contexts/MessageContext';
import { useNavigate } from 'react-router-dom';
import SearchIcon from './images/search-icon.svg';
import {
  changeUserNativeLanguage,
  findNativeLanguages,
} from '@/api/query/nativeLanguages';

const IconTitleWrapper = styled.div`
  width: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const TopBar = styled.div`
  margin-top: 24px;
  width: 90vw;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 600px) {
    margin-left: 20px;
    max-width: 1200px;
  }
`;

const Title = styled.div`
  color: #05275d;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
`;

const Card = styled.div`
  margin-top: 20px;
  border-radius: 16px;
  background: #fff;
  padding: 16px 0;

  @media (min-width: 600px) {
    width: 400px;
    border: 1px solid #d9d9d9;
    margin: 0 auto;
    max-height: 50vh;
    overflow: auto;
  }
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px;
  border-radius: 5px;
`;

export const LevelOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const InputWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;

  label {
    cursor: pointer;
    font-weight: ${(props) => (props.isActive ? '600' : 'normal')};
    color: #05275d;
    text-align: center;
    font-family: 'Work Sans';
    font-size: 12px;
    font-style: normal;
    line-height: 140%;
  }

  input {
    margin: 0;
  }
`;

const SearchInputWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

const SearchIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 8px;
  right: 10px;
`;

const SearchInput = styled.input`
  display: inline-flex;
  padding: 10px 0px 10px 20px;
  justify-content: center;
  align-items: flex-start;
  width: 250px;

  border-radius: 50px;
  background: #fff;
  border: none;

  &:focus {
    border: none;
    outline: none;
  }

  @media (min-width: 600px) {
    width: 400px;
    border: 1px solid #d9d9d9;
  }
`;

const LanguageName = styled.p`
  color: #05275d;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 171%; /* 23.94px */
  letter-spacing: 0.28px;
  margin: 0;
`;

const ProfileLanguage = () => {
  const [languages, setLanguages] = useState([]);
  const [regex, setRegex] = useState();
  const [userData, setUserData] = useState();

  const navigate = useNavigate();

  const searchRef = useRef();
  const { addMessage } = useContext(MessageContext);

  const loadDataMutation = useMutation({
    mutationFn: () => findNativeLanguages(),
    onSuccess: ({ data }) => {
      setLanguages(data);
    },
  });

  const loadUserMutation = useMutation({
    mutationFn: () => loadUserLearningData(),
    onSuccess: ({ data }) => {
      setUserData(data);
    },
  });

  const changeUserNativeLanguageMutation = useMutation({
    mutationFn: (data) => changeUserNativeLanguage(data),
    onSuccess: ({ data }) => {
      loadDataMutation.mutate();
      loadUserMutation.mutate();
      addMessage('Language changed', 'success');
    },
    onError: () => {
      addMessage('Error', 'error');
    },
  });

  const handleChangeLanguage = (lang) => {
    const payload = {
      lang,
    };

    changeUserNativeLanguageMutation.mutate(payload);
  };

  const handleSearch = (e) => {
    e?.preventDefault();

    const search = searchRef.current.value;

    if (search.length === 0) {
      return setRegex(null);
    }

    setRegex(search);
  };

  useEffect(() => {
    loadDataMutation.mutate();
    loadUserMutation.mutate();
  }, []);

  return (
    <>
      {(loadDataMutation.isLoading ||
        changeUserNativeLanguageMutation.isLoading ||
        loadUserMutation.isLoading) && <Loading />}
      <TopBar>
        <IconTitleWrapper>
          <img src={ArrowLeft} onClick={() => navigate('/profile/learning')} />
        </IconTitleWrapper>
        <Title>Your native language</Title>
        <IconTitleWrapper />
      </TopBar>
      <SearchInputWrapper>
        <div style={{ position: 'relative' }}>
          <SearchInput
            ref={searchRef}
            type="text"
            placeholder="search..."
            onChange={handleSearch}
          />
          <SearchIconWrapper>
            <img src={SearchIcon} />
          </SearchIconWrapper>
        </div>
      </SearchInputWrapper>
      <Card>
        {languages
          .filter((lang) => {
            if (!regex) {
              return true;
            }
            return lang.name.includes(regex);
          })
          .map((l) => (
            <Section
              id={`language-${l._id}`}
              onClick={(e) => handleChangeLanguage(l._id)}
              style={{
                background:
                  l._id === userData?.nativeLanguage?._id ? '#650675' : 'white',
              }}
            >
              <div
                style={{ width: '20px', overflow: 'hidden', height: '20px' }}
              >
                <img src={l.icon.base64} style={{ width: '100%' }} />
              </div>
              <LanguageName
                style={{
                  color:
                    l._id === userData?.nativeLanguage?._id
                      ? 'white'
                      : '#650675 ',
                }}
              >
                {l.name}
              </LanguageName>
            </Section>
          ))}
      </Card>
    </>
  );
};

export default ProfileLanguage;
