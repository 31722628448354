import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import ArrowLeft from './images/arrow-left.svg';
import ArrowRight from '../images/arrow-right.svg';
import { useMutation } from 'react-query';
import { loadUserLearningData } from '@/api/query/user';
import Loading from '@/common/components/Loading';
import { useNavigate } from 'react-router-dom';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import { LanguageContext } from '@/common/contexts/LanguageContext';

const IconTitleWrapper = styled.div`
  width: 12px;
  display: flex;
  align-items: center;

  div {
    display: none;
  }

  @media (min-width: 600px) {
    div {
      display: flex;
    }
    gap: 20px;
    cursor: pointer;
  }
`;

const TopBar = styled.div`
  margin-top: 24px;
  width: 90vw;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 600px) {
    margin-left: 20px;
    max-width: 1200px;
  }
`;

const Title = styled.div`
  color: #05275d;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
`;

const Card = styled.div`
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 16px;
  background: #fff;
  padding: 16px;

  width: 85vw;
  max-width: 600px;

  img {
    cursor: pointer;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 85vw;
  cursor: pointer;
  max-width: 600px;
`;

const SectionTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 85vw;
  max-width: 600px;
`;

const SectionTittle = styled.p`
  color: #05275d;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 123.5%; /* 17.29px */
  letter-spacing: 0.28px;
  margin: 0;
`;

const Border = styled.div`
  margin: 16px 0;
  border-bottom: 1px solid #ece5f2;
`;

const ImageWrapperMenu = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }
`;

const SelectedElementWrapper = styled.p`
  color: #05275d;
  text-align: right;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 123.5%; /* 17.29px */
  letter-spacing: 0.28px;
  min-width: 100px;
  margin: 0;
  margin-right: 20px;
`;

export const LevelOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin: 20px 0px 10px 30px;

  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    margin: 20px 0px 10px 0px;
    width: 700px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;

  label {
    cursor: pointer;
    font-weight: ${(props) => (props.isActive ? '600' : 'normal')};
    color: #05275d;
    text-align: center;
    font-family: 'Work Sans';
    font-size: 12px;
    font-style: normal;
    line-height: 140%;
  }

  input {
    margin: 0;
  }
`;

const ProfileLearning = () => {
  const [userData, setUserData] = useState({});
  const bottom = useLanguageData('profile.bottom');
  const { lang } = useContext(LanguageContext);

  const navigate = useNavigate();

  const loadDataMutation = useMutation({
    mutationFn: () => loadUserLearningData(),
    onSuccess: ({ data }) => {
      setUserData(data);
    },
  });

  const getNameByType = (type) => {
    if (type === 'ten_minutes') {
      return '10 min';
    }

    if (type === 'five_minutes') {
      return '5 min';
    }

    if (type === 'fifteen_minutes') {
      return '15 min';
    }
  };

  useEffect(() => {
    loadDataMutation.mutate();
  }, []);

  return (
    <>
      {loadDataMutation.isLoading && <Loading />}
      <TopBar>
        <IconTitleWrapper onClick={() => navigate('/profile')}>
          <img src={ArrowLeft} />
        </IconTitleWrapper>
        <Title>{bottom.learningSetting}</Title>
        <IconTitleWrapper />
      </TopBar>
      <Card>
        <Section onClick={() => navigate('/profile/learning/daily-minutes')}>
          <SectionTop>
            <SectionTittle>{bottom.dayTarget}</SectionTittle>
            <ImageWrapperMenu>
              <SelectedElementWrapper>
                {getNameByType(userData?.dailyMinutes)}
              </SelectedElementWrapper>
              <img src={ArrowRight} />
            </ImageWrapperMenu>
          </SectionTop>
        </Section>
        <Border />
        {lang === 'pl' && (
          <>
            <Section
              onClick={() => navigate('/profile/learning/learning-language')}
            >
              <SectionTop>
                <SectionTittle>Język nauki</SectionTittle>
                <ImageWrapperMenu>
                  <SelectedElementWrapper>
                    {console.log(userData)}
                    {userData?.learningLanguage?.name}
                  </SelectedElementWrapper>
                  <img src={ArrowRight} />
                </ImageWrapperMenu>
              </SectionTop>
            </Section>
            <Border />
          </>
        )}
        <Section onClick={() => navigate('/profile/learning/level')}>
          <SectionTop>
            <SectionTittle>{bottom.languageLevel}</SectionTittle>
            <ImageWrapperMenu>
              <SelectedElementWrapper>
                {userData?.level &&
                  `${
                    userData?.level[0]?.toUpperCase() +
                    userData?.level?.slice(1)
                  }`}
              </SelectedElementWrapper>
              <img src={ArrowRight} />
            </ImageWrapperMenu>
          </SectionTop>
        </Section>
      </Card>
    </>
  );
};

export default ProfileLearning;
