import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';
import { useMutation } from 'react-query';
import { loadDashboardData } from '@/api/query/report';
import { formatDateShort } from '../mail/AdminMail';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faHandPointUp,
  faMailForward,
  faMessage,
  faMoneyBill,
  faPeopleGroup,
} from '@fortawesome/free-solid-svg-icons';
import DailyChart from './components/DailyChart';
import DashbaordTable from './components/DashbaordTable';
import MonthtargetChart from './components/MonthtargetChart';
import Loading from '@/common/components/Loading';

const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
  gap: 20px;

  @media (max-width: 600px) {
    padding: 10px 10px 0 10px;
    gap: 10px;
  }
`;

const BoxDisplay = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    gap: 10px;
  }
`;

const BoxElement = styled(Link)`
  background-color: white;
  height: 80px;
  padding: 10px 12px;
  width: 200px;
  border-radius: 15px;
  text-decoration: none;
  color: #05275d;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 600px) {
    min-width: 140px;
    max-width: fit-content;
  }
`;

const TableWrapper = styled.div`
  background-color: white;
  padding: 0px 10px;
  border-radius: 15px;
  height: fit-content;
  height: 430px;
  width: calc(55vw - 100px);
  overflow: auto;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    height: 260px;
  }
`;

const TableElementsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const Title = styled.h3`
  color: #05275d;

  /* H3 Text Bold */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 10px 0;
`;

const Box = styled.h3`
  position: relative;
  width: 50px;
  height: 50px;
  background-color: green;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  color: white;
`;

const Today = styled.div`
  top: 30px;
  right: -20px;
  position: absolute;
  margin-bottom: 20px;
  width: 30px;
  height: 30px;
  background-color: red;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  color: white;
`;

const LeftTitle = styled.p`
  color: #05275d;

  /* H3 Text Bold */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
`;

const MiddleTitle = styled.p`
  color: #05275d;

  /* Text Smal 10 */
  font-family: Lato;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
`;

const RightTitle = styled.p`
  font-size: 16px;
  margin: 0;
`;

const LeftBox = styled.p`
  color: #05275d;

  /* Text normal */
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-width: 40px;
  margin: 0;
`;

const RightBox = styled.p`
  color: #05275d;

  /* Text normal Bold */
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin: 0;
`;

const BoxValueChange = styled.p`
  color: ${(prev) => (prev.target === 'down' ? '#A80404' : '#377e36')};

  /* Text normal Bold */
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  display: inline-flex;
  padding: 2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: ${(prev) => (prev.target === 'down' ? '#FFC0C0' : '#e0f0e4')};
  margin: 0;
  margin-top: -2px;
`;

const ThirtyDaysWrapper = styled.div`
  color: #05275d;
  margin-left: 40px;

  /* Text Smal 10 */
  font-family: Lato;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: inline-flex;
  padding: 3px;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
const SevenDaysWrapper = styled.div`
  color: #05275d;
  margin-left: 20px;

  /* Text Smal 10 */
  font-family: Lato;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: inline-flex;
  padding: 3px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #f1f3f7;
`;

const FlexElement = styled.div`
  display: flex;
  gap: 5px;
`;

const LeftBox2 = styled(LeftBox)`
  min-width: 0px;
`;

const AdminHome = () => {
  const [data, setData] = useState();

  const getAllInformationForDashboardMutation = useMutation({
    mutationFn: () => loadDashboardData(),
    onSuccess: ({ data }) => {
      setData({
        ...data,
      });
    },
  });

  useEffect(() => {
    getAllInformationForDashboardMutation.mutate();
  }, []);

  const calcMonthlyTarget = () => {
    const pln =
      Math.round(
        data?.monthTargetChart?.reduce((prev, next) => prev + next.sum, 0) *
          100,
      ) / 100;

    return <>{pln} zł</>;
  };

  const calcToDayByCountriesDaily = (data) => {
    const filtred = data.filter((d) => {
      const today = moment().format('DD/MM');

      if (d?.date === today) {
        return true;
      }
    });

    return (
      Math.round(
        filtred.reduce((prev, next) => prev + next.sum || 0, 0) * 100,
      ) / 100
    );
  };

  const calcToDayByCountries = (data) => {
    const filtred = data.filter((d) => {
      const today = moment().format('DD/MM');

      if (d?.date.split('_')[1] === today) {
        return true;
      }
    });

    return (
      Math.round(
        filtred.reduce((prev, next) => prev + next.sum || 0, 0) * 100,
      ) / 100
    );
  };

  return (
    <Wrapper>
      {getAllInformationForDashboardMutation.isLoading && <Loading />}
      <BoxDisplay>
        <BoxElement to={'/admin/order'}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <LeftTitle>Orders</LeftTitle>
            <MiddleTitle>30 days</MiddleTitle>
            <RightTitle>
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ color: '#05275d' }}
              />
            </RightTitle>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <div style={{ display: 'flex', gap: '10px' }}>
              <LeftBox>All:</LeftBox>
              <RightBox>{data?.payments?.all}</RightBox>
            </div>
            <div style={{ display: 'flex', gap: '10px' }}>
              <LeftBox>Today:</LeftBox>
              <RightBox>{data?.payments?.today}</RightBox>
            </div>
          </div>
        </BoxElement>
        <BoxElement to={'/admin/user'} style={{ width: '250px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <LeftTitle>
              Users{' '}
              <FontAwesomeIcon
                icon={faPeopleGroup}
                style={{ marginLeft: '10px', color: '#05275d' }}
              />
            </LeftTitle>
            <RightTitle>
              <FontAwesomeIcon icon={faArrowRight} />
            </RightTitle>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', gap: '10px' }}>
                <LeftBox>All:</LeftBox>
                <RightBox>{data?.customers?.totalUsers}</RightBox>
              </div>
              <div style={{ display: 'flex', gap: '10px' }}>
                <LeftBox>Bought:</LeftBox>
                <RightBox>{data?.customers?.usersWithPayments}</RightBox>
              </div>
              <div style={{ display: 'flex', gap: '10px' }}>
                <LeftBox>Renewal:</LeftBox>
                <RightBox>
                  {data?.customers?.usersWithAtLeastTwoPayments}
                </RightBox>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', gap: '10px' }}>
                <LeftBox>Active:</LeftBox>
                <RightBox>{data?.customers?.activeUsers}</RightBox>
              </div>
              <div style={{ display: 'flex', gap: '10px' }}>
                <LeftBox>Expire:</LeftBox>
                <RightBox>{data?.customers?.usersWithExpiredOrders}</RightBox>
              </div>
            </div>
          </div>
        </BoxElement>
        <BoxElement style={{ width: '150px' }} to={'/admin/hands'}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <LeftTitle>
              Hands{' '}
              <FontAwesomeIcon
                icon={faHandPointUp}
                style={{ marginLeft: '10px', color: '#05275d' }}
              />
            </LeftTitle>
            <RightTitle>
              <FontAwesomeIcon icon={faArrowRight} />
            </RightTitle>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <div style={{ display: 'flex', gap: '20px' }}>
              <LeftBox>Up:</LeftBox>
              <RightBox>{data?.hands?.up}</RightBox>
              <BoxValueChange target={'up'}>
                {' + '}
                {data?.hands?.upSevenDays}
              </BoxValueChange>
            </div>
            <div style={{ display: 'flex', gap: '20px' }}>
              <LeftBox>down:</LeftBox>
              <RightBox>{data?.hands?.down}</RightBox>
              <BoxValueChange target={'down'}>
                {' + '}
                {data?.hands?.downSevenDays}
              </BoxValueChange>
            </div>
          </div>
        </BoxElement>
        <BoxElement style={{ width: '150px' }} to={'/admin/panel'}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <LeftTitle>GPT </LeftTitle>
            <RightTitle>
              <FontAwesomeIcon icon={faArrowRight} />
            </RightTitle>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <div style={{ display: 'flex', gap: '20px' }}>
              <LeftBox>30 days:</LeftBox>
              <RightBox>{data?.costs?.all}$</RightBox>
            </div>
            <div style={{ display: 'flex', gap: '20px' }}>
              <LeftBox>Today:</LeftBox>
              <RightBox>
                <RightBox>{data?.costs?.today}$</RightBox>
              </RightBox>
            </div>
          </div>
        </BoxElement>
        <BoxElement style={{ width: '370px' }} to={'/admin/panel'}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <LeftTitle>Features </LeftTitle>
            <MiddleTitle>30 days actions</MiddleTitle>
            <RightTitle>
              <FontAwesomeIcon icon={faArrowRight} />
            </RightTitle>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: '10px',
              }}
            >
              <div style={{ display: 'flex', gap: '5px' }}>
                <LeftBox>Daily:</LeftBox>
                <RightBox>{data?.featureUsage?.daily}</RightBox>
              </div>
              <div style={{ display: 'flex', gap: '5px' }}>
                <LeftBox>Roleplays:</LeftBox>
                <RightBox>{data?.featureUsage?.scene}</RightBox>
              </div>
              <div style={{ display: 'flex', gap: '5px' }}>
                <LeftBox>Flashcards:</LeftBox>
                <RightBox>{data?.featureUsage?.flashcard}</RightBox>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                gap: '10px',
              }}
            >
              <div style={{ display: 'flex', gap: '5px' }}>
                <LeftBox>Words:</LeftBox>
                <RightBox>{data?.featureUsage?.word}</RightBox>
              </div>
              <div style={{ display: 'flex', gap: '5px' }}>
                <LeftBox>PhotoMode:</LeftBox>
                <RightBox>{data?.featureUsage?.image_mode}</RightBox>
              </div>
              <div style={{ display: 'flex', gap: '5px' }}>
                <LeftBox>CallMode:</LeftBox>
                <RightBox>{data?.featureUsage?.call_mode}</RightBox>
              </div>
              <div style={{ display: 'flex', gap: '5px' }}>
                <LeftBox>News:</LeftBox>
                <RightBox>{data?.featureUsage?.news}</RightBox>
              </div>
            </div>
          </div>
        </BoxElement>
      </BoxDisplay>
      <TableElementsWrapper>
        <TableWrapper>
          <Title>Daily Income</Title>
          <div
            style={{
              display: 'flex',
              gap: '50px',
              marginBottom: '10px',
            }}
          >
            <div style={{ display: 'flex', gap: '10px' }}>
              <LeftBox>30 days:</LeftBox>
              <RightBox>
                {Math.round(
                  data?.dailyChart?.reduce((prev, next) => prev + next.sum, 0) *
                    100,
                ) / 100}{' '}
                zł{' '}
              </RightBox>{' '}
            </div>
            <div style={{ display: 'flex', gap: '50px' }}>
              <LeftBox>Today:</LeftBox>
              <RightBox>
                {calcToDayByCountriesDaily(data?.dailyChart || [])} zł{' '}
              </RightBox>
            </div>
          </div>
          {data?.dailyChart && <DailyChart payments={data?.dailyChart} />}
        </TableWrapper>
        <TableWrapper style={{ width: '36vw' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '10px',
            }}
          >
            <LeftTitle>
              Mails <FontAwesomeIcon icon={faMailForward} />
            </LeftTitle>
            <RightTitle>
              <Link to={'/admin/mail'}>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{ color: '#05275d' }}
                />
              </Link>
            </RightTitle>
          </div>
          <DashbaordTable
            headers={[
              'Created at',
              'Email',
              'Type',
              'Delivered at',
              'Clicked at',
            ]}
            data={data?.mails?.map((m, i) => [
              formatDateShort(m.created_at),
              <Link
                style={{
                  color: '#05275d',

                  overflow: 'hidden',
                }}
                to={`/admin/customer/stats/${m?.user?._id}`}
              >
                <div
                  style={{
                    maxWidth: '20px',
                  }}
                >
                  {m?.user?.email}
                </div>
              </Link>,
              m.mailType,
              formatDateShort(m.delivered_at),
              formatDateShort(m.first_open_at),
            ])}
          />
        </TableWrapper>
      </TableElementsWrapper>
      <TableElementsWrapper>
        <TableWrapper>
          <Title>Monthly Target </Title>
          <div
            style={{
              display: 'flex',
              gap: '50px',
              marginBottom: '10px',
            }}
          >
            <div style={{ display: 'flex', gap: '20px' }}>
              <LeftBox>30 days:</LeftBox>
              <RightBox>{calcMonthlyTarget()}</RightBox>{' '}
            </div>
            <div style={{ display: 'flex', gap: '20px' }}>
              <LeftBox>Today:</LeftBox>
              <RightBox>
                {calcToDayByCountries(data?.monthTargetChart || [])} zł{' '}
              </RightBox>
            </div>
          </div>
          {data?.monthTargetChart && (
            <MonthtargetChart payments={data.monthTargetChart} />
          )}
        </TableWrapper>
        <TableWrapper style={{ width: '36vw' }}>
          <Title>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex' }}>
                IP stats
                <ThirtyDaysWrapper>30 days</ThirtyDaysWrapper>
              </div>
              <div>
                <Link to={'/admin/ip-stats'}>
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{ color: '#05275d' }}
                  />
                </Link>
              </div>
            </div>
          </Title>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              marginBottom: '10px',
            }}
          >
            <FlexElement>
              <LeftBox2>Landing: </LeftBox2>
              <RightBox>{data?.full30DaysIpStas?.landing}</RightBox>
            </FlexElement>
            <FlexElement>
              <LeftBox2>Registration: </LeftBox2>
              <RightBox>
                {data?.full30DaysIpStas?.registration}
                {' ('}
                {data?.full30DaysIpStas?.registrationPercent}%{') '}
              </RightBox>
            </FlexElement>
            <FlexElement>
              <LeftBox2>Accounts: </LeftBox2>
              <RightBox>
                {data?.full30DaysIpStas?.accounts}
                {' ('}
                {data?.full30DaysIpStas?.accountsPercent}%{') '}
              </RightBox>
            </FlexElement>
            <FlexElement>
              <LeftBox2>Plan: </LeftBox2>
              <RightBox>
                {data?.full30DaysIpStas?.plans}
                {' ('}
                {data?.full30DaysIpStas?.plansPercent}%{') '}
              </RightBox>
            </FlexElement>
            <FlexElement>
              <LeftBox2>Buy: </LeftBox2>
              <RightBox>
                {data?.full30DaysIpStas?.buy}
                {' ('}
                {data?.full30DaysIpStas?.buyPercent}%{') '}
              </RightBox>
            </FlexElement>
          </div>
          <DashbaordTable
            headers={['Created at', 'Email', 'Country', 'Type', 'IP', 'Device']}
            data={data?.ipStats?.map((m, i) => [
              formatDateShort(m.created_at),
              <Link
                style={{ color: '#05275d' }}
                to={`/admin/customer/stats/${m.user?._id}`}
              >
                {m.user?.email}
              </Link>,
              m?.user?.language,
              m.IpStatsType,
              m.ip_name,
              m.device_type,
            ])}
          />
        </TableWrapper>
      </TableElementsWrapper>
      <TableElementsWrapper>
        <TableWrapper
          style={{
            width: 'calc(45vw - 80px)',
          }}
        >
          <Title>
            Messages{' '}
            <FontAwesomeIcon
              icon={faMessage}
              style={{
                marginLeft: '10px',
              }}
            />
          </Title>
          {data?.messages && (
            <DashbaordTable
              headers={['Time', 'User', 'Feature', 'Country', 'Message']}
              data={data?.messages?.map((m, i) => [
                formatDateShort(m.created_at),
                <Link
                  style={{ color: '#05275d' }}
                  to={`/admin/customer/stats/${m?.user?._id}`}
                >
                  {m?.user?.email}
                </Link>,
                m.type,
                m?.user?.language,
                m.message,
              ])}
            />
          )}
        </TableWrapper>
        <TableWrapper
          style={{
            width: '45vw',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              margin: '10px',
            }}
          >
            <LeftTitle>
              Payments <FontAwesomeIcon icon={faMoneyBill} />
            </LeftTitle>
            <RightTitle>
              <Link to={'/admin/order'}>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{ color: '#05275d' }}
                />
              </Link>
            </RightTitle>
          </div>
          <DashbaordTable
            headers={[
              'Price (zł)',
              'Email',
              'Country',
              'Status',
              'Pending at',
              'Cancelled at',
              'Completed at',
            ]}
            data={data?.payments?.lastOrders?.map((m, i) => [
              m.price / 100,
              <Link
                style={{ color: '#05275d' }}
                to={`/admin/customer/stats/${m.order?.user?._id}`}
              >
                {m.order?.user?.email}
              </Link>,
              m?.order?.user?.language,
              m.status,
              formatDateShort(m.status_pending_date),
              formatDateShort(m.status_canceled_date),
              formatDateShort(m.status_complated_date),
            ])}
          />
        </TableWrapper>
      </TableElementsWrapper>
    </Wrapper>
  );
};

export default AdminHome;
