import React, { useContext, useMemo } from 'react';
import { LanguageContext } from '@/common/contexts/LanguageContext';
import OnePagePayuPayment from './components/payu/OnePagePayuPayment';
import OnePageStripeSubscribtionPayment from './components/stripe/OnePageStripeSubscribtionPayment';
import OnePageStripeOneTimePayment from './components/stripe/OnePageStripeOneTimePayment';

export const PAYU_OPERATOR_COUNTRIES = ['pl', 'ro', 'cs'];
export const STRIPE_SUBSCRIBTION = ['es', 'it'];
export const STRIPE_ONE_OFF = ['de', 'hu'];

const Checkout = () => {
  const { lang } = useContext(LanguageContext);

  const paymentPage = useMemo(() => {
    if (PAYU_OPERATOR_COUNTRIES.includes(lang)) {
      return <OnePagePayuPayment />;
    }

    if (STRIPE_SUBSCRIBTION.includes(lang)) {
      return <OnePageStripeSubscribtionPayment />;
    }

    if (STRIPE_ONE_OFF.includes(lang)) {
      return <OnePageStripeOneTimePayment />;
    }
  }, [lang]);

  return <div>{paymentPage}</div>;
};

export default Checkout;
