import React, { useContext, useRef, useState } from 'react';
import {
  AditionalInfo,
  Card,
  EyeIconWrapper,
  Input,
  InputWrapper,
  InputsWrapper,
  Label,
  RobotWrapper,
  SubmitButton,
  Title,
} from '../register.styles';
import { useMutation } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { RegisterContext } from '../context/register.context';
import Loading from '@/common/components/Loading';
import { handleRegisterCustomer } from '@/api/query/customer';
import styled from 'styled-components';
import ErrorImage from '../icons/exclamation-button.svg';
import useQueryMemo from '@/common/hooks/urlMemo';
import { IpStatsContext } from '@/common/contexts/IpStatsContext';
import { IP_STATS_ENUM } from '@/common/enums/ipStatsEnum';
import RegisterImage from '../images/rejestracja.png';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import GoogleAuth from '@/common/components/GoogleAuth';
import { MessageContext } from '@/common/contexts/MessageContext';
import {
  registerAccountWithFacebook,
  registerAccountWithGoogle,
} from '@/api/query/user';
import { LanguageContext } from '@/common/contexts/LanguageContext';
import FacebookAuth from '@/common/components/FacebookAuth';

const ErrorWrapper = styled.div`
  color: #fc0341;

  font-family: Work Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  margin-top: -10px;
  min-width: 320px;
  text-align: left;

  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
`;

const ImageWrapper = styled.div`
  width: 340px;

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const Or = styled.div`
  color: #868686;
  font-family: 'Work Sans';
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 14.3px */
  text-align: center;
`;

const UserDataView = () => {
  const data = useLanguageData('register.stepOne');

  const common = useLanguageData('common');
  const { lang } = useContext(LanguageContext);

  const queryMemo = useQueryMemo();
  const [labelType, setLabelType] = useState('password');
  const [labelTypeCheck, setLabelTypeCheck] = useState('password');

  const { setView, setUser } = useContext(RegisterContext);
  const [error, setError] = useState();
  const [errorPassword, setErrorPasswordr] = useState();

  const emailRef = useRef();
  const passwordRef = useRef();
  const { handleSendIPstats, handleFbStats } = useContext(IpStatsContext);

  const handleRegisterCustomerMutation = useMutation({
    mutationFn: (value) => handleRegisterCustomer(value),
    onSuccess: ({ data }) => {
      window?.uetq?.push('event', 'subscribe', {});

      localStorage.setItem('user_register', JSON.stringify(data));
      setUser(data);
      handleSendIPstats(IP_STATS_ENUM.ACCOUNT_CREATED, data?._id);
      setView(1);
    },
    onError: () => {
      setError(data.errorRegister);
    },
  });

  const checkPassword = (pass) => {
    if (pass.length <= 8)
      return {
        valid: false,
        message: data.passwordError1,
      };

    if (!/[A-Z].*/.test(pass))
      return {
        valid: false,
        message: data.passwordError2,
      };

    if (!/[a-z].*/.test(pass))
      return {
        valid: false,
        message: data.passwordError3,
      };

    if (!/\d/.test(pass))
      return {
        valid: false,
        message: data.passwordError4,
      };

    return { valid: true, message: 'Valid Password' };
  };

  const handleLogin = (e) => {
    e && e.preventDefault();

    const password = passwordRef.current.value;
    const checkedPassword = checkPassword(password);

    if (!checkedPassword.valid) {
      setErrorPasswordr(checkedPassword.message);

      setTimeout(() => {
        setErrorPasswordr(null);
      }, 3000);
      return;
    }

    const utm_campaign =
      queryMemo.get('utm_campaign') || localStorage.getItem('utm_campaign');
    const utm_medium =
      queryMemo.get('utm_medium') || localStorage.getItem('utm_medium');
    const utm_source =
      queryMemo.get('utm_source') || localStorage.getItem('utm_source');
    const utm_content =
      queryMemo.get('utm_content') || localStorage.getItem('utm_content');
    const utm_fbclid =
      queryMemo.get('utm_fbclid') || localStorage.getItem('utm_fbclid');
    const utm_term =
      queryMemo.get('utm_term') || localStorage.getItem('utm_term');

    const utm = {
      utm_campaign: utm_campaign,
      utm_medium: utm_medium,
      utm_source: utm_source,
      utm_content: utm_content,
      utm_fbclid: utm_fbclid,
      utm_term: utm_term,
      payment_option: localStorage.getItem('payment_option'),
    };

    const payload = {};
    payload.email = emailRef.current.value;
    payload.password = passwordRef.current.value;
    payload.utm = utm;
    payload.lang = lang;

    handleFbStats('CompleteRegistration', {});

    window?.gtag('complete_registration', {});
    window?.fbq('track', 'CompleteRegistration');
    window?.uetq?.push('event', 'subscribe', {});

    handleRegisterCustomerMutation.mutate(payload);
  };

  const handleChangeLabel = () => {
    if (labelType === 'password') {
      return setLabelType(() => 'text');
    }
    setLabelType(() => 'password');
  };

  const { addMessage } = useContext(MessageContext);

  const googleLoginMutation = useMutation({
    mutationFn: (payload) => registerAccountWithGoogle(payload),
    onSuccess: ({ data }) => {
      localStorage.setItem('user_register', JSON.stringify(data));
      setUser(data);
      handleSendIPstats(IP_STATS_ENUM.ACCOUNT_CREATED, data?._id);
      setView(1);
    },
    onError: () => addMessage(data.errorGoogle, 'error'),
  });

  const fbLoginMutation = useMutation({
    mutationFn: (payload) => registerAccountWithFacebook(payload),
    onSuccess: ({ data }) => {
      localStorage.setItem('user_register', JSON.stringify(data));
      setUser(data);
      handleSendIPstats(IP_STATS_ENUM.ACCOUNT_CREATED, data?._id);
      setView(1);
    },
    onError: () => addMessage(data.errorGoogle, 'error'),
  });

  const handleFBRegister = (credentials) => {
    const utm_campaign =
      queryMemo.get('utm_campaign') || localStorage.getItem('utm_campaign');
    const utm_medium =
      queryMemo.get('utm_medium') || localStorage.getItem('utm_medium');
    const utm_source =
      queryMemo.get('utm_source') || localStorage.getItem('utm_source');
    const utm_content =
      queryMemo.get('utm_content') || localStorage.getItem('utm_content');
    const utm_fbclid =
      queryMemo.get('utm_fbclid') || localStorage.getItem('utm_fbclid');
    const utm_term =
      queryMemo.get('utm_term') || localStorage.getItem('utm_term');

    const utm = {
      utm_campaign: utm_campaign,
      utm_medium: utm_medium,
      utm_source: utm_source,
      utm_content: utm_content,
      utm_fbclid: utm_fbclid,
      utm_term: utm_term,
      payment_option: localStorage.getItem('payment_option'),
    };
    const payload = credentials;
    payload.utm = utm;
    payload.lang = lang;

    window?.fbq('track', 'CompleteRegistration');
    window?.uetq?.push('event', 'subscribe', {});

    fbLoginMutation.mutate(payload);
  };

  const handleGoogleRegister = (credentials) => {
    const utm_campaign =
      queryMemo.get('utm_campaign') || localStorage.getItem('utm_campaign');
    const utm_medium =
      queryMemo.get('utm_medium') || localStorage.getItem('utm_medium');
    const utm_source =
      queryMemo.get('utm_source') || localStorage.getItem('utm_source');
    const utm_content =
      queryMemo.get('utm_content') || localStorage.getItem('utm_content');
    const utm_fbclid =
      queryMemo.get('utm_fbclid') || localStorage.getItem('utm_fbclid');
    const utm_term =
      queryMemo.get('utm_term') || localStorage.getItem('utm_term');

    const utm = {
      utm_campaign: utm_campaign,
      utm_medium: utm_medium,
      utm_source: utm_source,
      utm_content: utm_content,
      utm_fbclid: utm_fbclid,
      payment_option: localStorage.getItem('payment_option'),
      utm_term: utm_term,
    };
    const payload = credentials;
    payload.utm = utm;
    payload.lang = lang;

    window?.fbq('track', 'CompleteRegistration');
    window?.uetq?.push('event', 'subscribe', {});

    googleLoginMutation.mutate(payload);
  };

  return (
    <>
      {(handleRegisterCustomerMutation.isLoading ||
        googleLoginMutation.isLoading) && <Loading />}
      <Card onSubmit={handleLogin}>
        <ImageWrapper>
          <img src={RegisterImage} alt="SynthiAi register" />
        </ImageWrapper>
        <InputsWrapper>
          <Title>{data.title}</Title>
          <InputWrapper>
            <Label>{data.email}</Label>
            <Input
              ref={emailRef}
              id="email-SynthiAi"
              type="email"
              required
              placeholder={data.placeholderEmail}
            ></Input>
          </InputWrapper>
          <InputWrapper>
            <Label>{data.password}</Label>
            <Input
              ref={passwordRef}
              id="pawssword-SynthiAi"
              type={labelType}
              required
            ></Input>
            <EyeIconWrapper onClick={handleChangeLabel}>
              <FontAwesomeIcon
                icon={faEye}
                tabIndex="none"
                style={{
                  color: `${labelType === 'text' ? '#868686' : '#29185E'}`,
                }}
              />
            </EyeIconWrapper>
          </InputWrapper>
          <AditionalInfo style={{ color: errorPassword ? 'red' : '#868686' }}>
            {!errorPassword ? <>{data.subtitle}</> : errorPassword}
          </AditionalInfo>
          {error && (
            <ErrorWrapper>
              <img src={ErrorImage} /> {error}
            </ErrorWrapper>
          )}
          <SubmitButton type="submit">{data.start}</SubmitButton>
          <Or>{common.or}</Or>
          <GoogleAuth handleMutation={handleGoogleRegister} />
          <FacebookAuth handleCallback={handleFBRegister} />
        </InputsWrapper>
        <RobotWrapper />
      </Card>
    </>
  );
};

export default UserDataView;
