import CustomAddButton from '@/common/components/custom/buttons/CustomAddButton';
import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { useMutation } from 'react-query';
import CustomEditButton from '@/common/components/custom/buttons/CustomEditButton';
import Loading from '@/common/components/Loading';
import DataTable from '@/common/components/custom/DataTable';
import AdminPromotionEdit from './components/AdminPromotionEdit';
import { getCountries } from '@/api/query/country';
import { getPromotions } from '@/api/query/promotion';

const TopContent = styled.div`
  display: flex;
  justify-content: right;
`;
const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

const AdminPromotion = () => {
  const [tableData, setTableData] = useState([]);

  const HEADERS = [
    {
      header: 'no.',
      accessorKey: 'no',
      size: 20,
    },
    {
      header: 'Created at',
      accessorKey: 'created_at',
      size: 20,
    },
    {
      header: 'From Day',
      accessorKey: 'from_day',
      size: 20,
    },
    {
      header: 'To Day',
      accessorKey: 'to_day',
      size: 20,
    },
    {
      header: 'Promotion Type',
      accessorKey: 'promotion_type',
      size: 20,
    },
    {
      header: 'From X Days',
      accessorKey: 'from_x_days',
      size: 20,
    },
    {
      header: 'To X Days',
      accessorKey: 'to_x_days',
      size: 20,
    },
    {
      header: 'Actions',
      accessorKey: 'actions',
      size: 20,
      cell: (info) => (
        <div style={{ display: 'flex', gap: '10px' }}>
          <CustomEditButton onClick={(e) => setEdit(info.getValue())} />
        </div>
      ),
    },
  ];

  const [edit, setEdit] = useState();

  const getNotiFicationsMutation = useMutation({
    mutationFn: () => getPromotions(),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data.map((d, i) => ({
          no: i + 1,
          created_at: d.created_at,
          from_day: d.from_day,
          to_day: d.to_day,
          promotion_type: d.promotion_type,
          from_x_days: d.from_x_days,
          to_x_days: d.to_x_days,
          actions: d,
        })),
      );
    },
  });

  useEffect(() => {
    if (!edit) {
      getNotiFicationsMutation.mutate();
    }
  }, [edit]);

  return (
    <Wrapper>
      {getNotiFicationsMutation.isLoading && <Loading />}
      {edit && <AdminPromotionEdit edit={edit} setEdit={setEdit} />}
      <TopContent>
        <CustomAddButton onClick={() => setEdit({})} />
      </TopContent>
      <TableWrapper>
        <DataTable columns={HEADERS} data={tableData} rows={[10, 15, 20]} />
      </TableWrapper>
    </Wrapper>
  );
};

export default AdminPromotion;
