import { createPaymentAdmin, updatePaymentAdmin } from '@/api/query/payment';
import PopUp from '@/common/components/PopUp';
import Input from '@/common/components/custom/Input';
import SelectInput from '@/common/components/custom/SelectInput';
import CustomSaveButton from '@/common/components/custom/buttons/CustomSaveButton';
import { MessageContext } from '@/common/contexts/MessageContext';
import { getFormatedFullDateTime } from '@/common/functions/getFormatedFullDate';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { REFUNDATION_OPTIONS } from './RefundPopUp';
import TextArea from '@/common/components/TextArea';

const Wrapper = styled.form`
  padding: 10px;
`;

const PAYMENT_STATUS_OPTIONS = [
  { label: 'PENDING', value: 'PENDING' },
  { label: 'COMPLETED', value: 'COMPLETED' },
  { label: 'WAITING_FOR_CONFIRMATION', value: 'WAITING_FOR_CONFIRMATION' },
  { label: 'CANCELED', value: 'CANCELED' },
  { label: 'REFUNDED', value: 'REFUNDED' },
];

const AdminEditPayment = ({ edit, setEdit, user }) => {
  const { addMessage } = useContext(MessageContext);

  const [paymentStatus, setPaymentStatus] = useState(
    (edit?.status &&
      PAYMENT_STATUS_OPTIONS.find((p) => p.value === edit.status)) ||
      PAYMENT_STATUS_OPTIONS[1],
  );
  const statusPendingDateRef = useRef();
  const statusWaitingForConfirmationDateRef = useRef();
  const statusCompletedDateRef = useRef();
  const statusCanceledDateRef = useRef();
  const statusRefundDateRef = useRef();
  const [selectedReason, setSelectedReason] = useState(
    REFUNDATION_OPTIONS.find((f) => f.value === edit?.refundation?.reason),
  );
  const otherReasonDescription = useRef();
  const commentRefundationRef = useRef();

  const typesRef = useRef();
  const priceRef = useRef();

  const createPaymentMutation = useMutation({
    mutationFn: (payload) => createPaymentAdmin(payload),
    onSuccess: () => {
      setEdit(() => null);
      addMessage('Created', 'success');
    },
    onError: () => {
      addMessage('Sth went wrong', 'error');
    },
  });

  const updatePaymentMutation = useMutation({
    mutationFn: (payload) => updatePaymentAdmin(payload),
    onSuccess: () => {
      setEdit(() => null);
      addMessage('Updated', 'success');
    },
    onError: () => {
      addMessage('Sth went wrong', 'error');
    },
  });

  const handleSave = (e) => {
    e?.preventDefault();

    const payload = {
      ...edit,
      plan_type: typesRef.current?.value || '',
      price: priceRef.current?.value || '',
      status: paymentStatus?.value || '',
      status_pending_date: statusPendingDateRef.current?.value || '',
      status_waiting_for_confirmation_date:
        statusWaitingForConfirmationDateRef.current?.value || '',
      status_complated_date: statusCompletedDateRef.current?.value || '',
      status_canceled_date: statusCanceledDateRef.current?.value || '',
      status_refund_date: statusRefundDateRef.current?.value || '',
      reason: selectedReason?.value,
      otherReasonDescription: otherReasonDescription?.current?.value || '',
      comment: commentRefundationRef.current?.value || '',
      order: user?.order?._id,
    };

    if (!payload._id) {
      return createPaymentMutation.mutate(payload);
    }

    updatePaymentMutation.mutate(payload);
  };

  return (
    <PopUp setClose={setEdit}>
      <h3>{(edit?._id && 'Edit') || 'Create'} Payment</h3>
      <Wrapper onSubmit={handleSave}>
        <SelectInput
          name={'Payment Status'}
          selectWidth={250}
          width={400}
          options={PAYMENT_STATUS_OPTIONS}
          selected={paymentStatus}
          setSelected={setPaymentStatus}
          required
        />
        <Input
          inputRef={priceRef}
          name="Price "
          width={400}
          inputWidth={220}
          required={true}
          value={edit.price || ''}
          requiredSign
        />
        <Input
          inputRef={typesRef}
          name="Plan Type "
          width={400}
          inputWidth={220}
          required={true}
          value={edit.plan_type || ''}
          requiredSign
        />
        <Input
          inputRef={statusPendingDateRef}
          name="Status Pending Date"
          width={400}
          inputWidth={220}
          type="datetime-local"
          value={getFormatedFullDateTime(edit.status_pending_date)}
        />
        <Input
          inputRef={statusWaitingForConfirmationDateRef}
          name="Status Waiting For Confirmation Date"
          width={400}
          type="datetime-local"
          value={getFormatedFullDateTime(
            edit.status_waiting_for_confirmation_date,
          )}
          inputWidth={220}
        />
        <Input
          inputRef={statusCompletedDateRef}
          name="Status Completed Date"
          width={400}
          type="datetime-local"
          value={getFormatedFullDateTime(edit.status_complated_date)}
          inputWidth={220}
        />
        <Input
          inputRef={statusCanceledDateRef}
          name="Status Canceled Date"
          width={400}
          type="datetime-local"
          value={getFormatedFullDateTime(edit.status_canceled_date)}
          inputWidth={220}
        />
        <Input
          inputRef={statusRefundDateRef}
          name="Status Refund Date"
          width={400}
          type="datetime-local"
          value={getFormatedFullDateTime(edit.status_refund_date)}
          inputWidth={220}
        />
        <SelectInput
          name={'Refundation option'}
          options={REFUNDATION_OPTIONS}
          selected={selectedReason}
          setSelected={setSelectedReason}
          width={250}
          selectWidth={500}
        />
        {selectedReason?.value === 'OTHER' && (
          <Input
            name="Other Reason description"
            inputRef={otherReasonDescription}
            required
            requiredSign
            width={250}
            inputWidth={475}
            value={edit?.refundation?.otherReasonDescription || ' '}
          />
        )}
        <TextArea
          textAreaRef={commentRefundationRef}
          placeholder="Type description if needed"
          label={'Type description if needed'}
          defaultValue={edit?.refundation?.comment || ' '}
        />
        <div style={{ display: 'flex', justifyContent: 'right' }}>
          <CustomSaveButton />
        </div>
      </Wrapper>
    </PopUp>
  );
};

export default AdminEditPayment;
