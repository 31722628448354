import { crateMailConfig, updateMailConfig } from '@/api/query/mailConfig';
import PopUp from '@/common/components/PopUp';
import ToggleSwitch from '@/common/components/ToggleSwitch';
import CustomTable from '@/common/components/custom/CustomTable';
import Input from '@/common/components/custom/Input';
import CustomAddButton from '@/common/components/custom/buttons/CustomAddButton';
import CustomEditButton from '@/common/components/custom/buttons/CustomEditButton';
import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import MailConfigEditTemplate from './MailConfigEditTemplate';
import CategoryFilter from '../filters/CategoryFilter';
import CustomSaveButton from '@/common/components/custom/buttons/CustomSaveButton';
import { Color } from '@/common/colors/colors';
import TextArea from '@/common/components/TextArea';
import CustomDeleteButton from '@/common/components/custom/buttons/CustomDeleteButton';
import {
  getAllFieldsByQuery,
  getAllPosibleFiledsToFilter,
} from '@/api/query/report';
import QueryResultPopUp from './QueryResultPopUp';
import { MessageContext } from '@/common/contexts/MessageContext';
import SelectInput from '@/common/components/custom/SelectInput';
import { findNativeLanguages } from '@/api/query/nativeLanguages';
import { testMail } from '@/api/query/sendgrid';

const POSIBLE_MAIL_FILEDS_OPTIONS = [
  {
    label: 'Type',
    value: 'type',
  },
  {
    label: 'Price',
    value: 'price',
  },
  {
    label: 'From',
    value: 'from',
  },
  {
    label: 'To',
    value: 'to',
  },
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Password',
    value: 'password',
  },
  {
    label: 'Quiz email',
    value: 'quiz_email',
  },
  {
    label: 'Continue Registration Link',
    value: 'continueRegistrationLink',
  },
  {
    label: 'Language',
    value: 'language',
  },
];

const TopContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubTitle = styled.h3`
  text-decoration: underline;
  margin-bottom: 25px;
`;

const TestingWrapper = styled.div`
  display: flex;
  justify-content: left;
  gap: 50px;
  width: 100%;
  align-items: center;

  div {
    button {
      float: right;
      margin-right: 10px;
    }
  }
`;

const MAIL_PAYLOAD = {
  type: 'BASIC',
  price: '100 zł',
  from: '10-20-2020',
  to: '10-20-2020',
  name: 'Karolina',
  password: '123321',
  quiz_email: 'quiz@synthiaiapp.com',
  passwordRestartLink: 'https://pl.synthiaiapp.com/restart',
  continueRegistrationLink: 'https://pl.synthiaiapp.com/register/:id',
};

function reducer(state, action) {
  if (action.type === 'add')
    return {
      ...state,
      [action.key]: {
        ...(action.value && {
          value:
            action.type_field === 'Number'
              ? parseInt(action.value)
              : action.value,
        }),
        ...(action.arithmetic && { arithmetic: action.arithmetic }),
        ...(action.from && { from: action.from }),
        ...(action.to && { to: action.to }),
        ...(action.arithmetic_from && {
          arithmetic_from: action.arithmetic_from,
        }),
        ...(action.arithmetic_to && { arithmetic_to: action.arithmetic_to }),
        ...(action.isTrue && { isTrue: action.isTrue }),
        ...(action.type_field && { type_field: action.type_field }),
        ...(action.from_days && { from_days: parseInt(action.from_days) }),
        ...(action.from_hours && { from_hours: parseInt(action.from_hours) }),
        ...(action.to_days && { to_days: parseInt(action.to_days) }),
        ...(action.to_hours && { to_hours: parseInt(action.to_hours) }),
        ...(action.enumValues && { enumValues: action.enumValues }),
      },
    };

  if (action.type === 'validate') {
    const result = {};

    action.values.map((value) => {
      if (state[value.label]) {
        result[value.label] = state[value.label];
      }
    });

    return {
      ...result,
    };
  }

  if (action.type === 'remove') {
    delete state[action.key];
    return {
      ...state,
    };
  }

  if (action.type === 'init') {
    return {
      ...action.data,
    };
  }
}

const MailConfigEdit = ({ edit, setEdit }) => {
  const [filters, dispatchFilters] = useReducer(reducer, {});
  const [selectedData, setSelectedData] = useState([]);
  const [queryResult, setQueryResult] = useState();
  const [posbileMailFields, setPosibleMailFields] = useState(
    POSIBLE_MAIL_FILEDS_OPTIONS.filter((p) =>
      edit?.fields?.includes(p.value),
    ) || [],
  );

  const [data, setData] = useState();

  const getAllPosibleFiledsToFilterMutation = useMutation({
    mutationFn: () => getAllPosibleFiledsToFilter(),
    onSuccess: ({ data }) => {
      const toSetUp = [
        ...Object.entries(data.customerFields).map(([key, value]) => {
          return { label: 'customer.' + key, value: value };
        }),
        ...Object.entries(data.orderFields).map(([key, value]) => {
          return { label: 'order.' + key, value: value };
        }),
        ...Object.entries(data.paymentFields).map(([key, value]) => {
          return { label: 'payment.' + key, value: value };
        }),
      ];

      setData(() => toSetUp);

      const dataToSetUp = JSON.parse(edit.filters);

      dispatchFilters({
        type: 'init',
        data: dataToSetUp,
      });

      setSelectedData(() =>
        toSetUp.filter((ts) => Object.keys(dataToSetUp)?.includes(ts.label)),
      );
    },
  });

  useEffect(() => {
    getAllPosibleFiledsToFilterMutation.mutate();
  }, []);

  useEffect(() => {}, []);

  const textAreaRef = useRef();
  const emailTestRef = useRef();
  const nameRef = useRef();
  const cronRef = useRef();
  const activeRef = useRef();
  const [templateConfig, setTemplateConfig] = useState(
    edit?.templateConfig || [],
  );
  const [nativeLanguages, setNativeLanguages] = useState([]);
  const [editTemplate, setEditTemplate] = useState();

  const handleUpdateMailConfigMutation = useMutation({
    mutationFn: (payload) => updateMailConfig(payload),
    onSuccess: () => {
      setEdit(() => null);
    },
  });

  const handleLoadNativeLanugagesMutation = useMutation({
    mutationFn: (payload) => findNativeLanguages(payload),
    onSuccess: ({ data }) => {
      setNativeLanguages(() =>
        data.map((d) => ({ label: d.name, value: d._id })),
      );
    },
  });

  const handleCreateMailConfigMutation = useMutation({
    mutationFn: (payload) => crateMailConfig(payload),
    onSuccess: () => {
      setEdit(() => null);
    },
  });

  const deleteTemplate = (t) => {
    const fitred = templateConfig
      .filter((tc) => tc.id !== t.id)
      .map((tc, i) => ({ ...tc, id: i }));

    setTemplateConfig(() => fitred);
  };

  const handleTestMutation = useMutation({
    mutationFn: (payload) => testMail(payload),
    onSuccess: () => {
      addMessage('SENT', 'success');
    },
    onError: () => {
      addMessage('Error', 'error');
    },
  });

  const handleTest = (e) => {
    const result = {
      email: emailTestRef.current.value,
      mailPayload: textAreaRef.current?.value || MAIL_PAYLOAD,
      mailConfig: edit._id,
    };

    handleTestMutation.mutate(result);
  };

  const queryMutation = useMutation({
    mutationFn: (payload) => getAllFieldsByQuery(payload),
    onSuccess: ({ data }) => {
      setQueryResult(() => data);
    },
  });

  const handleCheckQuery = () => {
    const payload = {
      query: filters,
      data: {
        mailConfigId: edit?._id,
      },
    };
    queryMutation.mutate(payload);
  };

  const { addMessage } = useContext(MessageContext);

  const handleSave = async (e) => {
    e && e.preventDefault();

    if (cronRef?.current?.value?.split(' ').length != 6) {
      addMessage('CRON TIME SHOULD HAVE 6 spaces check info', 'error');
      return;
    }

    if (cronRef?.current?.value) {
      const hasError = cronRef?.current?.value
        ?.split(' ')
        .filter((element) => element.includes('/*'));
      if (hasError.length > 0) {
        addMessage('Not valid Cron, check info');
        return;
      }
    }

    if (templateConfig.length == 0 || selectedData.length == 0) {
      addMessage('Please add mail template and product', 'error');
      return;
    }

    const payload = {
      ...edit,
    };

    payload.name = nameRef.current?.value;
    payload.cron = cronRef.current?.value;
    payload.active = activeRef.current?.checked || false;
    payload.filters = JSON.stringify(filters);
    payload.templateConfig = templateConfig;
    payload.fields = posbileMailFields.map((p) => p.value);

    if (edit._id) {
      return handleUpdateMailConfigMutation.mutate(payload);
    }

    handleCreateMailConfigMutation.mutate(payload);
  };

  useEffect(() => {
    handleLoadNativeLanugagesMutation.mutate();
  }, []);

  return (
    <>
      <PopUp setClose={setEdit}>
        <form
          onSubmit={handleSave}
          style={{
            width: '1000px',
            minHeight: '800px',
            margin: '20px',
          }}
        >
          <h3>MailConfig {edit._id ? 'Edit' : 'Create'}</h3>
          <TopContent>
            <div>
              <Input
                name="Name"
                value={edit.name}
                inputWidth={200}
                width={80}
                inputRef={nameRef}
              />
              <Input
                inputRef={cronRef}
                name="Cron"
                value={`${edit.cron || '* * * * * *'}`}
                inputWidth={200}
                width={80}
              />
            </div>
            <div>
              <ToggleSwitch
                width={100}
                text="Active"
                toggleRef={activeRef}
                checked={edit.active}
              />
            </div>
          </TopContent>
          <h3>
            Templates <CustomAddButton onClick={() => setEditTemplate({})} />{' '}
          </h3>
          <CustomTable
            headers={[
              'No.',
              'Name',
              'Sender Name',
              'Sender Email',
              'Weight',
              'Action',
            ]}
            data={
              templateConfig
                ?.sort((a, b) => a.id - b.id)
                .map((t) => [
                  t.id,
                  t.name,
                  t.senderName,
                  t.senderEmail,
                  t.weight,
                  <div>
                    {' '}
                    <CustomEditButton onClick={() => setEditTemplate(t)} />
                    <CustomDeleteButton onClick={() => deleteTemplate(t)} />
                  </div>,
                ]) || []
            }
          />
          <CategoryFilter
            filters={filters}
            dispatchFilters={dispatchFilters}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            nativeLanguages={nativeLanguages}
            data={data}
          />
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <CustomEditButton
              text="Check query"
              onClick={() => handleCheckQuery()}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'left' }}>
            <SelectInput
              options={POSIBLE_MAIL_FILEDS_OPTIONS}
              selectWidth={750}
              multiple={true}
              selected={posbileMailFields}
              setSelected={setPosibleMailFields}
              name={'Mail Fields'}
              width={120}
            />
          </div>
          <div>
            <SubTitle>Testing:</SubTitle>
            <TestingWrapper>
              <TextArea
                textAreaRef={textAreaRef}
                label="Sending object"
                placeholder="object"
                defaultValue={JSON.stringify(MAIL_PAYLOAD, null, '\t')}
                fontSize={'12px'}
                width="600px"
                minHeight="210px"
              />
              <div>
                <Input
                  showLabel={false}
                  inputRef={emailTestRef}
                  inputWidth={200}
                  name="Mail"
                  type="email"
                  placeholder="Type email..."
                  color={Color.Blue}
                />
                <CustomEditButton text={'Test'} onClick={() => handleTest()} />
              </div>
            </TestingWrapper>
          </div>
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <CustomSaveButton />
          </div>
        </form>
      </PopUp>
      {editTemplate && (
        <MailConfigEditTemplate
          edit={editTemplate}
          setEdit={setEditTemplate}
          setTemplateConfig={setTemplateConfig}
        />
      )}
      {queryResult && (
        <QueryResultPopUp data={queryResult} setClose={setQueryResult} />
      )}
    </>
  );
};

export default MailConfigEdit;
