import React from 'react';
import { Card, Title, InfoWrapper, P } from '../styles';

const UserCost = ({ userCost }) => {
  const formatCost = (value, rate = 1) => {
    return `${Math.round(value * 100000) / 100000 || '-----'} $`;
  };

  return (
    <Card>
      <Title>User Cost:</Title>
      <InfoWrapper>
        <P>
          <p>Total Price:</p>
          <b>{formatCost(userCost.totalCost)} $</b>
        </P>
        <P>
          <p>Token in:</p>
          <b>
            {userCost.chatGptCost?.input || '-----'} (
            {formatCost((userCost.chatGptCost?.input * 0.5) / 1_000_000)} $)
          </b>
        </P>
        <P>
          <p>Token out:</p>
          <b>
            {userCost.chatGptCost?.output || '-----'} (
            {formatCost((userCost.chatGptCost?.output * 1.5) / 1_000_000)} $)
          </b>
        </P>
        <P>
          <p>Speech to text:</p>
          <b>
            {userCost.chatGptCost?.seconds || '-----'} s (
            {formatCost((userCost.chatGptCost?.seconds * 0.006) / 60)} $)
          </b>
        </P>
        <P>
          <p>Text to speech:</p>
          <b>
            {userCost.chatGptCost?.text_to_speach || '-----'} chars (
            {formatCost(
              (userCost.chatGptCost?.text_to_speach * 15) / 1_000_000,
            )}{' '}
            $)
          </b>
        </P>
      </InfoWrapper>
    </Card>
  );
};

export default UserCost;
