// src/components/CustomerStats/styles.js
import styled from 'styled-components';

export const Title = styled.h3`
  color: #05275d;
  text-align: left;
  font-family: 'Work Sans', sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 44.8px */
  margin: 0 0 20px 0;
  display: flex;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  margin: 0 20px;
`;

export const Card = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  width: fit-content;
  height: fit-content;
`;

export const P = styled.p`
  display: flex;
  gap: 10px;
  text-align: right;
  margin: 0;

  p {
    width: 120px;
    text-align: right;
    margin: 0;
  }

  b {
    font-weight: 600;
    color: #05275d;
    max-width: 250px;
    white-space: normal;
    word-break: break-word;
  }
`;

export const TableWrapper = styled.div`
  background-color: white;
  padding: 5px 20px;
  border-radius: 10px;
  max-width: calc(100vw - 150px);
  overflow-x: auto;
`;

export const InfoWrapper = styled.div`
  min-width: 300px;
  height: 340px;
`;
