import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCustomerContext } from './contexts/CustomerContext';
import { useCustomerData } from './hooks/useCustomerData';
import { useRefund } from './hooks/useRefund';
import { useRefundStripe } from './hooks/useRefundStripe';
import { useSendAnyMail } from './hooks/useSendAnyMail';
import { useSendUpsale } from './hooks/useSendUpsale';
import { useSendBeforeExpired } from './hooks/useSendBeforeExpired';
import { useCancelStripeSub } from './hooks/useCancelStripeSub';
import { Wrapper } from './styles';
import ActivityTable from './components/ActivityTable';
import IpStatsTable from './components/IpStatsTable';
import MailsTable from './components/MailsTable';
import PaymentDetails from './components/PaymentDetails';
import OrderDetails from './components/OrderDetails';
import Stats from './components/Stats';
import UserCost from './components/UserCost';
import GuessUtmDetails from './components/GuessUtmDetails';
import UtmDetails from './components/UtmDetails';
import UserInfo from './components/UserInfo';
import Actions from './components/Actions';
import Loading from '@/common/components/Loading';
import AdminEditPayment from '../AdminEditPayment';
import AdminEditOrder from '../AdminEditOrder';
import RefundPopUp from '../RefundPopUp';
import CancelSubscriptionPopUp from './components/CancelSubscriptionPopUp';
import AnyMailPopUp from '../AnyMailPopUp';
import { MessageContext } from '@/common/contexts/MessageContext';
import UserEdit from './components/UserEdit';

const CustomerStats = () => {
  const { id } = useParams();
  const {
    data,
    setData,
    userEdit,
    setUserEdit,
    editOrder,
    setEditOrder,
    editPayment,
    setEditPayment,
    showDecisionConfirmation,
    setShowDecisionConfirmation,
    showCancelSubscription,
    setShowCancelSubscription,
    anyMail,
    setAnyMail,
  } = useCustomerContext();

  const { addMessage } = React.useContext(MessageContext);

  const { mutate: fetchCustomer, isLoading: isFetching } = useCustomerData(
    id,
    setData,
  );

  const { mutate: refundCustomer, isLoading: isRefunding } = useRefund(
    id,
    fetchCustomer,
    addMessage,
  );

  const { mutate: refundStripe, isLoading: isRefundingStripe } =
    useRefundStripe(id, fetchCustomer, addMessage);

  const { mutate: sendAnyMail, isLoading: isSendingMail } =
    useSendAnyMail(addMessage);

  const { mutate: sendUpsale, isLoading: isSendingUpsale } = useSendUpsale(
    id,
    fetchCustomer,
  );

  const { mutate: sendBeforeExpired, isLoading: isSendingBeforeExpired } =
    useSendBeforeExpired(id, fetchCustomer);

  const { mutate: cancelSubscription, isLoading: isCancelingSubscription } =
    useCancelStripeSub(addMessage);

  useEffect(() => {
    if (id && !editOrder && !editPayment) {
      fetchCustomer();
    }
  }, [id, editOrder, editPayment, fetchCustomer]);

  const handleDecision = (payload) => {
    const completedPayment = data.payment
      .filter((p) => p.status === 'COMPLETED')
      .sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
      )[0];

    payload.paymentId = completedPayment?._id;
    payload.country = data.user.language;

    if (data.order.payment_type === 'stripe') {
      refundStripe(payload);
    } else {
      refundCustomer(payload);
    }
  };

  const handleSend = (payload) => {
    sendAnyMail(payload);
  };

  const handleDecisionCancelSub = () => {
    const payload = {
      sub_id: data.order.sub_id,
    };
    cancelSubscription(payload);
  };

  if (
    isFetching ||
    isRefunding ||
    isRefundingStripe ||
    isSendingMail ||
    isSendingUpsale ||
    isSendingBeforeExpired ||
    isCancelingSubscription
  ) {
    return <Loading />;
  }

  return (
    <>
      {userEdit && (
        <UserEdit
          edit={userEdit}
          setEdit={setUserEdit}
          fetchCustomer={fetchCustomer}
        />
      )}
      {showDecisionConfirmation && (
        <RefundPopUp
          handleDecision={handleDecision}
          setClose={setShowDecisionConfirmation}
          customerEmail={data?.user?.email}
        />
      )}
      {showCancelSubscription && (
        <CancelSubscriptionPopUp
          handleDecision={handleDecisionCancelSub}
          setShow={setShowCancelSubscription}
        />
      )}
      {anyMail && (
        <AnyMailPopUp setClose={setAnyMail} handleSend={handleSend} />
      )}
      {data && (
        <Wrapper>
          <Actions
            setShowDecisionConfirmation={setShowDecisionConfirmation}
            setAnyMail={setAnyMail}
            setShowCancelSubscription={setShowCancelSubscription}
          />
          <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
            <UserInfo user={data?.user || {}} setUserEdit={setUserEdit} />
            <UtmDetails utm={data?.user?.utm || {}} />
            <GuessUtmDetails guessUtm={data?.user?.guess_utm || {}} />
            <UserCost userCost={data.user || {}} />
            <Stats stats={data.stats || []} />
            <OrderDetails
              order={data.order || {}}
              setEditOrder={setEditOrder}
            />
            <PaymentDetails
              payments={data.payment || []}
              setEditPayment={setEditPayment}
            />
            <MailsTable mails={data.mails || []} />
            <IpStatsTable ipStats={data.ipStats || []} />
            <ActivityTable stats={data.stats || {}} />
          </div>
        </Wrapper>
      )}
      {editOrder && <AdminEditOrder edit={editOrder} setEdit={setEditOrder} />}
      {editPayment && (
        <AdminEditPayment
          edit={editPayment}
          setEdit={setEditPayment}
          user={data}
        />
      )}
    </>
  );
};

export default CustomerStats;
