import React, { useContext, useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { LanguageContext } from '@/common/contexts/LanguageContext';
import { useMutation } from 'react-query';
import Loading from '@/common/components/Loading';
import { createStrpiePayment } from '@/api/query/stripe';
import { RegisterContext } from '../../context/register.context';
import StripePaymentView from './StrpiePaymentView';

const stripePromise = loadStripe(
  'pk_live_51PIo36IyDClzFDYgRR9ded7bjivzG8lczHc96PoWlzBNlpcWl405Mw1HpQ7i4j9rlShrZdH6D3D79MvBHxFXAHTF00UARA2lEa',
);

const StrpieWrapper = ({}) => {
  const { lang } = useContext(LanguageContext);
  const { plan, user } = useContext(RegisterContext);

  const [clientSecret, setClientSecret] = useState();

  const createPaymentMutation = useMutation({
    mutationFn: (payload) => createStrpiePayment(payload),
    onSuccess: ({ data }) => {
      setClientSecret(() => data.clientSecret);
    },
  });

  const options = {
    clientSecret: clientSecret,
    locale: lang,
    appearance: {
      theme: 'flat',
      variables: {
        borderRadius: '10px',
        fontSizeBase: '12px',
      },
      rules: {
        '.Input': {
          borderRadius: '10px',
          padding: '8px',
          fontSize: '14px',
        },
        '.Tab': {
          padding: '8px',
        },
        '.Label': {
          fontSize: '12px',
        },
      },
    },
  };

  useEffect(() => {
    if (plan.stripe_product_id) {
      const payload = {
        userId: user._id,
        priceId: plan.stripe_product_id,
      };

      createPaymentMutation.mutate(payload);
    }
  }, []);

  if (!clientSecret) {
    return <Loading />;
  }

  return (
    <>
      <Elements stripe={stripePromise} options={options}>
        <StripePaymentView />
      </Elements>
    </>
  );
};

export default StrpieWrapper;
