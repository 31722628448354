import React from 'react';
import Select from 'react-select';

const square = (color) => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 4,
    content: '" "',
    display: color ? 'block' : 'none',
    marginRight: 8,
    height: 18,
    width: 18,
  },
});

const customStyles = ({
  width,
  rotation,
  reversed,
  showSquare,
  placeholderColor,
  bgColor,
  fontSize,
  textColor,
}) => {
  return {
    container: (provided) => ({
      ...provided,
      width: `${width}px`,
      fontSize: `${fontSize}px`,
    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: '10px',
      width: `${width}px`,
      transform: rotation,
      backgroundColor: bgColor || provided.backgroundColor,
      color: textColor || provided.color,
    }),
    valueContainer: (provided) => ({
      ...provided,
      transform: rotation,
    }),
    menu: (provided) => ({
      ...provided,
      width: `${width}px`,
      maxHeight: '140px',
      transform: reversed && 'translateY(-140%)',
    }),
    menuList: (provided) => ({
      ...provided,
      width: `${width}px`,
      maxHeight: '140px',
      backgroundColor: 'white',
    }),
    option: (provided, { data, isSelected }) => ({
      ...provided,
      ...square(showSquare && data.color),
      color: isSelected ? '#fff' : '#000',
    }),
    placeholder: (styles) => ({
      ...styles,
      color: placeholderColor,
    }),
  };
};

const SelectInput = ({
  name,
  options,
  setSelected,
  selected,
  multiple = false,
  width = 300,
  selectWidth = 260,
  setAccesses,
  labelUp = false,
  showLabel = true,
  onChange = () => {},
  color = 'black',
  required = false,
  placeholder = 'Select...',
  id,
  disabled = false,
  fontSize = 18,
  margin = '10px',
  hideStar = false,
  customThemeColors,
  bgColor,
  rotation,
  reversed,
  showSquare,
  placeholderColor = '#000',
  selectFontSize = 16,
  strong = true,
  textColor = '#000',
}) => {
  const handleChange = (e) => {
    setSelected(e);
  };

  return (
    <div
      style={{
        display: labelUp ? 'grid' : 'flex',
        alignItems: 'center',
        gap: labelUp ? '10px' : '30px',
        margin: margin,
        borderRadius: '20px !important',
      }}
    >
      {showLabel && (
        <label
          style={{
            width: `${width}px`,
            textAlign: 'right',
            fontSize: `${fontSize}px`,
          }}
        >
          <b
            style={{
              color,
              display: 'flex',
              gap: '5px',
              justifyContent: 'right',
            }}
          >
            {name}:{' '}
            {required && !hideStar && <span style={{ color: 'red' }}>*</span>}
          </b>
        </label>
      )}
      <div style={{ fontWeight: 'normal' }}>
        <Select
          required={required}
          id={id}
          theme={(theme) => {
            return customThemeColors
              ? { ...theme, colors: customThemeColors }
              : theme;
          }}
          value={selected}
          onChange={(e) => handleChange(e)}
          options={options}
          isMulti={multiple}
          reversed
          styles={customStyles({
            width: selectWidth,
            rotation,
            reversed,
            showSquare,
            placeholderColor,
            bgColor,
            fontSize: selectFontSize,
            textColor,
          })}
          placeholder={placeholder}
          isDisabled={disabled}
        />
      </div>
    </div>
  );
};

export default SelectInput;
