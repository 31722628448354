import { useContext, useEffect, useState } from 'react';
import UserDataView from './components/UserDataView';
import { Wrapper } from './register.styles';
import PaymentView from './components/PaymentView';
import { RegisterContext } from './context/register.context';
import PlanView from './components/PlanView';
import { useNavigate, useParams } from 'react-router-dom';
import Navigation from './components/Navigation';
import { useMutation } from 'react-query';
import { findUserById } from '@/api/query/user';
import styled from 'styled-components';
import { IP_STATS_ENUM } from '@/common/enums/ipStatsEnum';
import { IpStatsContext } from '@/common/contexts/IpStatsContext';
import { EventNamesEnum } from '@/common/enums/eventNamesEnum';
import { EventService } from '@/common/services/eventService';
import SynthiAiLogo from '@/common/images/SynthiAi.png';
import OnePagePayment from './tests/OnePagePayment';
import { isOrderPaid } from '@/api/query/order';

const CONFIG = [
  {
    name: 'Form',
    compontent: () => <UserDataView />,
  },
  {
    name: 'Plan',
    compontent: () => <PlanView />,
  },
  {
    name: 'Payment',
    compontent: () => <PaymentView />,
  },
];

const WrapperItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media (min-width: 600px) {
    width: 90vw;
    padding: 20px;
    border-radius: 15px;
    max-width: 1200px;
  }
`;

const SynthiAiLogoImg = styled.img`
  margin-top: 10px;

  @media (max-width: 600px) {
    height: 24px;
    margin-top: 0px;
    margin-bottom: 10px;
  }
`;

const Register = () => {
  const params = useParams();
  const [user, setUser] = useState();
  const [view, setView] = useState(0);
  const [plan, setPlan] = useState();
  const [showSuccess, setShowSuccess] = useState();
  const [showWarning, setShowWarning] = useState();
  const navigate = useNavigate();

  document.title = `Synthi Ai -  ${CONFIG[view]?.name}`;

  const { handleSendIPstats } = useContext(IpStatsContext);

  const findUserMutation = useMutation({
    mutationFn: (values) => findUserById(values),
    onSuccess: ({ data }) => {
      if (data.active === false) {
        setUser(data);
        setView(1);
      }
    },
  });

  useEffect(() => {
    window?.uetq?.push('event', 'add_to_cart');

    if (params.id) {
      findUserMutation.mutate(params.id);
      return EventService.subscribeOnce(
        EventNamesEnum.TRACING_ID_PROCESSED,
        () => handleSendIPstats(IP_STATS_ENUM.REGISTER_BY_EMAIL),
      );
    } else {
      EventService.subscribeOnce(EventNamesEnum.TRACING_ID_PROCESSED, () =>
        handleSendIPstats(IP_STATS_ENUM.REEGISTER_PAGE_VIEW, null),
      );
    }
  }, [params]);

  const loadPaymentStatusMutation = useMutation({
    mutationFn: (payload) => isOrderPaid(payload),
    onSuccess: ({ data }) => {
      if (data) {
        let naviaged = localStorage.getItem('register_navigated');

        if (naviaged) {
          return;
        }

        localStorage.setItem('register_navigated', { navigated: true });
        const user = localStorage.getItem('user_register');
        navigate(`/success-payment/${JSON.parse(user)._id}`);
      }
    },
  });

  useEffect(() => {
    const order = localStorage.getItem('register_order');
    if (order) {
      loadPaymentStatusMutation.mutate({ order: JSON.parse(order)._id });
    }
  }, []);

  return (
    <>
      <Wrapper>
        <SynthiAiLogoImg src={SynthiAiLogo} />
        <WrapperItems>
          <RegisterContext.Provider
            value={{ setView, user, setUser, plan, setPlan }}
          >
            <Navigation level={view} />
            {CONFIG[view]?.compontent()}
          </RegisterContext.Provider>
        </WrapperItems>
      </Wrapper>
    </>
  );
};

export default Register;
