import PopUp from '@/common/components/PopUp';
import Input from '@/common/components/custom/Input';
import React, { useContext, useRef } from 'react';
import { useMutation } from 'react-query';
import { styled } from 'styled-components';
import Loading from '@/common/components/Loading';
import CustomSaveButton from '@/common/components/custom/buttons/CustomSaveButton';
import TextArea from '@/common/components/TextArea';
import {
  createNotifications,
  updateNotifications,
} from '@/api/query/notification';
import { MessageContext } from '@/common/contexts/MessageContext';

export const ImageInput = styled.input`
  display: none;
`;
export const ImageWrapper = styled.div`
  width: 300px;
  height: 300px;
  overflow: hidden;
`;

const Title = styled.h2`
  text-align: center;
`;

const Form = styled.form`
  margin: 0px 30px;
  display: flex;
  flex-direction: column;
`;

const AdminNotificationEdit = ({ setEdit, edit }) => {
  const bodyRef = useRef();
  const iconRef = useRef();
  const titleRef = useRef();
  const urlRef = useRef();

  const { addMessage } = useContext(MessageContext);

  const createCategoryMutation = useMutation({
    mutationFn: (value) => createNotifications(value),
    onSuccess: ({ data }) => {
      addMessage('CREATED', 'success');
      setEdit(null);
    },
    onError: () => {
      addMessage('Sth went wrong', 'error');
    },
  });

  const updateCategoryMutation = useMutation({
    mutationFn: (value) => updateNotifications(value),
    onSuccess: ({ data }) => {
      addMessage('UPDATED', 'success');
      setEdit(null);
    },
    onError: () => {
      addMessage('Sth went wrong', 'error');
    },
  });

  const handleSave = (e) => {
    e && e.preventDefault();

    const payload = edit;

    payload.body = bodyRef.current.value;
    payload.icon = iconRef.current.value;
    payload.title = titleRef.current.value;
    payload.url = urlRef.current.value;

    if (edit._id) {
      return updateCategoryMutation.mutate(payload);
    }

    createCategoryMutation.mutate(payload);
  };

  return (
    <>
      {(updateCategoryMutation.isLoading ||
        createCategoryMutation.isLoading) && <Loading />}
      <PopUp setClose={setEdit}>
        <Title>Category {edit._id ? 'Edit' : 'Create'}</Title>
        <Form onSubmit={(e) => handleSave(e)}>
          <Input
            inputRef={bodyRef}
            name="Body"
            inputWidth={400}
            width={100}
            required
            requiredSign
            value={edit.body}
          />
          <Input
            inputRef={iconRef}
            name="Icon (url)"
            inputWidth={400}
            width={100}
            value={edit.icon}
          />
          <Input
            inputRef={titleRef}
            name="Title"
            inputWidth={400}
            width={100}
            required
            requiredSign
            value={edit.title}
          />
          <Input
            inputRef={urlRef}
            name="URL AFTER CLICK"
            inputWidth={400}
            width={100}
            value={edit.url}
          />
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <CustomSaveButton text="Save" />
          </div>
        </Form>
      </PopUp>
    </>
  );
};

export default AdminNotificationEdit;
