import { updateCustomer } from '@/api/query/customer';
import { findNativeLanguages } from '@/api/query/nativeLanguages';
import Loading from '@/common/components/Loading';
import PopUp from '@/common/components/PopUp';
import { LANGUAGES } from '@/common/components/Translations';
import Input from '@/common/components/custom/Input';
import SelectInput from '@/common/components/custom/SelectInput';
import CustomSaveButton from '@/common/components/custom/buttons/CustomSaveButton';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { styled } from 'styled-components';

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  min-width: 800px;
`;

const Title = styled.h3`
  text-align: left;
`;

const ButtonWrapper = styled.div`
  margin-top: 250px;
  display: flex;
  justify-content: right;
`;

const UserEdit = ({ edit, setEdit, fetchCustomer }) => {
  const [languages, setLanguages] = useState([]);
  const [nativeLanguage, setNativeLanguage] = useState(
    LANGUAGES.find((l) => l.value === edit.language),
  );
  const [learningLanguage, setLeanringLanguage] = useState(
    LANGUAGES.find((l) => l.value === edit.learningLanguage),
  );
  const nameRef = useRef();
  const passowrdRef = useRef();
  const emailRef = useRef();
  const activeRef = useRef();
  const firstLoginRef = useRef();
  const isCallModePopUpShowRef = useRef();
  const isDailyTutoroalPopUpShowRef = useRef();
  const showDailyVersionV2Ref = useRef();

  const languagesMutation = useMutation({
    mutationFn: (value) => findNativeLanguages(value),
    onSuccess: ({ data }) => {
      const options = data.map((d) => ({ label: d.name, value: d._id }));
      setLanguages(() => options);
      setNativeLanguage(() =>
        options.find((o) => edit?.nativeLanguage?._id === o.value),
      );
      setLeanringLanguage(() =>
        options.find((o) => edit?.learningLanguage?._id === o.value),
      );
    },
  });

  const updateCustomerMutation = useMutation({
    mutationFn: (value) => updateCustomer(value),
    onSuccess: ({ data }) => {
      setEdit(null);
      fetchCustomer();
    },
  });

  const handleSave = (e) => {
    e && e.preventDefault();

    const payload = edit;
    payload.name = nameRef.current.value;
    payload.password = passowrdRef.current.value;
    payload.email = emailRef.current.value;
    payload.active = activeRef.current.checked;
    payload.isFirstLogin = firstLoginRef.current.checked;
    payload.isCallModePopUpShow = isCallModePopUpShowRef.current.checked;
    payload.nativeLanguage = nativeLanguage?.value;
    payload.learningLanguage = learningLanguage?.value;
    payload.isDailyTutoroalPopUpShow =
      isDailyTutoroalPopUpShowRef.current.checked;
    payload.showDailyVersionV2 = showDailyVersionV2Ref.current.checked;

    updateCustomerMutation.mutate(payload);
  };

  useEffect(() => {
    languagesMutation.mutate();
  }, []);

  return (
    <>
      {updateCustomerMutation.isLoading && <Loading />}
      <PopUp setClose={setEdit}>
        <Title>Edit Customer</Title>
        <Wrapper onSubmit={(e) => handleSave(e)}>
          <Input
            inputRef={nameRef}
            name="Name"
            width={250}
            required
            value={edit.name}
          />
          <Input
            inputRef={emailRef}
            name="Email"
            width={250}
            type="email"
            required
            value={edit.email}
          />
          <Input
            inputRef={passowrdRef}
            name="Password"
            width={250}
            type="password"
            value={null}
            required={!edit._id}
          />
          <SelectInput
            name={'Native language'}
            selectWidth={280}
            width={250}
            options={languages}
            selected={nativeLanguage}
            setSelected={setNativeLanguage}
          />
          <SelectInput
            name={'Learning language'}
            selectWidth={280}
            width={250}
            options={languages}
            selected={learningLanguage}
            setSelected={setLeanringLanguage}
          />
          <Input
            inputRef={activeRef}
            name="Active"
            width={250}
            type="checkbox"
            checked={edit.active}
          />
          <Input
            inputRef={firstLoginRef}
            name="First login"
            width={250}
            type="checkbox"
            checked={edit.isFirstLogin}
          />
          <Input
            inputRef={isCallModePopUpShowRef}
            name="Call Mode Popup"
            width={250}
            type="checkbox"
            checked={edit.isCallModePopUpShow}
          />
          <Input
            inputRef={isDailyTutoroalPopUpShowRef}
            name="Daily tutorial Popup"
            width={250}
            type="checkbox"
            checked={edit.isDailyTutoroalPopUpShow}
          />
          <Input
            inputRef={showDailyVersionV2Ref}
            name="Show Daily V2"
            width={250}
            type="checkbox"
            checked={edit.showDailyVersionV2}
          />
          <ButtonWrapper>
            <CustomSaveButton />
          </ButtonWrapper>
        </Wrapper>
      </PopUp>
    </>
  );
};

export default UserEdit;
