import React, { useContext, useMemo } from 'react';
import './paymentview.styles.css';
import OneTimePaymentView from './paymentViews/OneTimePaymentView';
import { LanguageContext } from '@/common/contexts/LanguageContext';
import StripePaymentView from './paymentViews/StrpiePaymentView';
import {
  PAYU_OPERATOR_COUNTRIES,
  STRIPE_ONE_OFF,
  STRIPE_SUBSCRIBTION,
} from '@/pages/checkout/Checkout';
import StripeSubscriptionView from './paymentViews/StripeSubscriptionView';
import StripeSubscriptionWrapper from './paymentViews/StripeSubscriptionWrapper';

const PaymentView = () => {
  const { lang } = useContext(LanguageContext);

  const paymentPage = useMemo(() => {
    if (PAYU_OPERATOR_COUNTRIES.includes(lang)) {
      return <OneTimePaymentView />;
    }

    if (STRIPE_SUBSCRIBTION.includes(lang)) {
      return <StripeSubscriptionWrapper />;
    }

    if (STRIPE_ONE_OFF.includes(lang)) {
      return <StripePaymentView />;
    }
  }, [lang]);

  return <div>{paymentPage}</div>;
};

export default PaymentView;
