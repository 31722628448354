import CustomTable from '@/common/components/custom/CustomTable';
import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { useMutation } from 'react-query';
import SearchBar from './components/SearchBar';
import Loading from '@/common/components/Loading';
import { findAdvertReport, findRefundationReport } from '@/api/query/report';
import moment from 'moment';
import { findNativeLanguages } from '@/api/query/nativeLanguages';
import { formatDate, formatDateShort } from '../mail/AdminMail';

const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

const HEADERS = [
  'No.',
  'From',
  'To',
  'Advert Source',
  'Spend Netto',
  'Spend Brutto',
  'Payment',
  'Payment Value',
  'Payment Cost Brutto',
  'ROAS Brutto - Brutto',
  'Profit',
  'Renew',
];

const REFUND_HEADERS = [
  'No.',
  'From',
  'To',
  'Advert Source',
  'Refunded',
  'Refunded Value',
  'Profit',
];

const AdvertReport = () => {
  const [tableData, setTableData] = useState([]);
  const [tableDataReduns, setTableDataRefund] = useState([]);
  const [tableDataProfit, setTableDataProfit] = useState([]);

  const [languagesOptions, setLanguagesOption] = useState([]);

  const round = (num) => num.toFixed(2);

  const calculateByFiled = (arr, field) =>
    arr.reduce((prev, next) => prev + next[field], 0);

  const findStatsMutation = useMutation({
    mutationFn: (values) => findAdvertReport(values),
    onSuccess: ({ data }) => {
      setTableData(() => [
        ...data.allIncluded.map((d, i) => [
          i + 1,
          formatDateShort(d.value.from),
          formatDateShort(d.value.to),
          d.key,
          round(d.value.spend),
          round(d.value.spendVat),
          d.value.purchase,
          round(d.value.purchaseValue),
          round(d.value.spendVat / d.value.purchase),
          round(d.value.purchaseValue / d.value.spendVat),
          round(d.value.purchaseValue - d.value.spendVat),
          d.value.renew,
        ]),
        [
          '----',
          '----',
          '----',
          '----',
          round(
            calculateByFiled(
              data.allIncluded.map((d) => d.value),
              'spend',
            ),
          ),
          round(
            calculateByFiled(
              data.allIncluded.map((d) => d.value),
              'spendVat',
            ),
          ),
          calculateByFiled(
            data.allIncluded.map((d) => d.value),
            'purchase',
          ),
          round(
            calculateByFiled(
              data.allIncluded.map((d) => d.value),
              'purchaseValue',
            ),
          ),
          round(
            calculateByFiled(
              data.allIncluded.map((d) => d.value),
              'spendVat',
            ) /
              calculateByFiled(
                data.allIncluded.map((d) => d.value),
                'purchase',
              ),
          ),
          round(
            calculateByFiled(
              data.allIncluded.map((d) => d.value),
              'purchaseValue',
            ) /
              calculateByFiled(
                data.allIncluded.map((d) => d.value),
                'spendVat',
              ),
          ),
          round(
            calculateByFiled(
              data.allIncluded.map((d) => d.value),
              'purchaseValue',
            ) -
              calculateByFiled(
                data.allIncluded.map((d) => d.value),
                'spendVat',
              ),
          ),
          calculateByFiled(
            data.allIncluded.map((d) => d.value),
            'renew',
          ),
        ],
      ]);

      setTableDataRefund(() => [
        ...data.refundsReport.map((d, i) => [
          i + 1,
          formatDateShort(d.value.from),
          formatDateShort(d.value.to),
          d.key,
          d.value.purchase,
          round(d.value.purchaseValue),
          round(d.value.purchaseValue * -1),
        ]),
        [
          '----',
          '----',
          '----',
          '----',
          calculateByFiled(
            data.refundsReport.map((d) => d.value),
            'purchase',
          ),
          round(
            calculateByFiled(
              data.refundsReport.map((d) => d.value),
              'purchaseValue',
            ),
          ),
          round(
            calculateByFiled(
              data.refundsReport.map((d) => d.value),
              'purchaseValue',
            ) * -1,
          ),
        ],
      ]);

      setTableDataProfit(() => [
        ...data.withoutRefunds.map((d, i) => [
          i + 1,
          formatDateShort(d.value.from),
          formatDateShort(d.value.to),
          d.key,
          round(d.value.spend),
          round(d.value.spendVat),
          d.value.purchase,
          round(d.value.purchaseValue),
          round(d.value.spendVat / d.value.purchase),
          round(d.value.purchaseValue / d.value.spendVat),
          round(d.value.purchaseValue - d.value.spendVat),
          d.value.renew,
        ]),
        [
          '----',
          '----',
          '----',
          '----',
          round(
            calculateByFiled(
              data.withoutRefunds.map((d) => d.value),
              'spend',
            ),
          ),
          round(
            calculateByFiled(
              data.withoutRefunds.map((d) => d.value),
              'spendVat',
            ),
          ),
          calculateByFiled(
            data.withoutRefunds.map((d) => d.value),
            'purchase',
          ),
          round(
            calculateByFiled(
              data.withoutRefunds.map((d) => d.value),
              'purchaseValue',
            ),
          ),
          round(
            calculateByFiled(
              data.withoutRefunds.map((d) => d.value),
              'spendVat',
            ) /
              calculateByFiled(
                data.withoutRefunds.map((d) => d.value),
                'purchase',
              ),
          ),
          round(
            calculateByFiled(
              data.withoutRefunds.map((d) => d.value),
              'purchaseValue',
            ) /
              calculateByFiled(
                data.withoutRefunds.map((d) => d.value),
                'spendVat',
              ),
          ),
          round(
            calculateByFiled(
              data.withoutRefunds.map((d) => d.value),
              'purchaseValue',
            ) -
              calculateByFiled(
                data.withoutRefunds.map((d) => d.value),
                'spendVat',
              ),
          ),
          calculateByFiled(
            data.withoutRefunds.map((d) => d.value),
            'renew',
          ),
        ],
      ]);
    },
  });

  const findNativeLanguagesMutation = useMutation({
    mutationFn: () => findNativeLanguages(),
    onSuccess: ({ data }) => {
      const langs = data.map((d) => ({ label: d.name, value: d.short }));
      setLanguagesOption(() => langs);
    },
  });

  const handleSearch = (payload) => {
    findStatsMutation.mutate(payload);
  };

  useEffect(() => {
    findNativeLanguagesMutation.mutate();
  }, []);

  return (
    <>
      {findStatsMutation.isLoading && <Loading />}
      <Wrapper>
        <TableWrapper>
          <SearchBar
            handleSearch={handleSearch}
            languagesOptions={languagesOptions}
          />
        </TableWrapper>
        <h3>Advert Report (Karolina)</h3>
        <TableWrapper>
          <CustomTable headers={HEADERS} data={tableData} />
        </TableWrapper>
        <h3>Profit Report</h3>
        <TableWrapper>
          <CustomTable headers={HEADERS} data={tableDataProfit} />
        </TableWrapper>
        <h3>Refundation Report</h3>
        <TableWrapper>
          <CustomTable headers={REFUND_HEADERS} data={tableDataReduns} />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default AdvertReport;
