import { createLesson, updateLesson } from '@/api/query/lesson';
import { getWordLessonTopType } from '@/api/query/word-lesson-top-type';
import ImageContainer from '@/common/components/ImageContainer';
import Loading from '@/common/components/Loading';
import PopUp from '@/common/components/PopUp';
import TextArea from '@/common/components/TextArea';
import Translations from '@/common/components/Translations';
import CustomTable from '@/common/components/custom/CustomTable';
import Input from '@/common/components/custom/Input';
import SelectInput from '@/common/components/custom/SelectInput';
import CustomAddButton from '@/common/components/custom/buttons/CustomAddButton';
import CustomDeleteButton from '@/common/components/custom/buttons/CustomDeleteButton';
import CustomEditButton from '@/common/components/custom/buttons/CustomEditButton';
import CustomSaveButton from '@/common/components/custom/buttons/CustomSaveButton';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { styled } from 'styled-components';
import LessonPromptOption from './LessonPromptOption';

const Wrapper = styled.div`
  padding: 10px;
`;

const Title = styled.h2`
  text-align: center;
`;

const SaveWrapper = styled.div`
  margin: 20px 0 10px 0;
  display: flex;
  justify-content: right;
`;

const TableWrapper = styled.div`
  max-width: 800px;
  max-height: 300px;
  overflow: auto;
`;

const Form = styled.form`
  margin: 0px 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Headers = ['No', 'Language', 'Action'];

const AdminLessonEdit = ({ setEdit, edit, languages }) => {
  const [image, setImage] = useState(edit?.image?.base64);
  const [promptEdit, setPromptEdit] = useState();
  const [categoryOptions, setCategoryOptions] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const starOneRef = useRef();
  const starThreeRef = useRef();
  const starTwoRef = useRef();
  const numberRef = useRef();

  const handleDeletePromt = (prompt) => {
    setPrompts((prev) => prev.filter((p) => p.id !== prompt.id));
  };

  const [prompts, setPrompts] = useState(edit.promptsOptions || []);

  const tableRows = useMemo(() => {
    if (!prompts) return [];
    return prompts
      .sort((a, b) => a.number - b.number)
      .map((prompt) => [
        prompt.id + 1,
        languages.find((l) => l.value === prompt.language)?.label,
        <div style={{ display: 'flex', gap: ' 10px' }}>
          <CustomDeleteButton onClick={() => handleDeletePromt(prompt)} />
          <CustomEditButton onClick={() => setPromptEdit(prompt)} />
        </div>,
      ]);
  }, [prompts]);

  const answerPromptRef = useRef();

  const findCategoriesMutation = useMutation({
    mutationFn: () => getWordLessonTopType(),
    onSuccess: ({ data }) => {
      const options = data.map((d) => ({ label: d.name, value: d._id }));
      setCategoryOptions(options);
      setSelectedCategory(
        options.find((o) => edit.wordLessonTopType._id === o.value) || {},
      );
    },
  });

  const createLessonMutation = useMutation({
    mutationFn: (value) => createLesson(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const updateLessonMutation = useMutation({
    mutationFn: (value) => updateLesson(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const handleSave = (e) => {
    e && e.preventDefault();

    const payload = edit;
    payload.category = selectedCategory.value;
    payload.starOne = starOneRef.current.value;
    payload.starTwo = starThreeRef.current.value;
    payload.starThree = starTwoRef.current.value;
    payload.image = image;
    payload.number = numberRef?.current?.value;
    payload.promptsOptions = prompts;

    if (edit._id) {
      return updateLessonMutation.mutate(payload);
    }

    createLessonMutation.mutate(payload);
  };

  useEffect(() => {
    findCategoriesMutation.mutate();
  }, []);

  return (
    <>
      {(findCategoriesMutation.isLoading ||
        createLessonMutation.isLoading ||
        updateLessonMutation.isLoading) && <Loading />}
      <PopUp setClose={setEdit}>
        <Title>Lesson {edit._id ? 'Edit' : 'Create'}</Title>
        <Form onSubmit={(e) => handleSave(e)}>
          <div>
            <div style={{ display: 'flex', marginBottom: '10px' }}>
              <Input
                inputRef={numberRef}
                name="Number"
                type="number"
                inputWidth={200}
                width={100}
                required
                requiredSign
                value={edit.number}
              />
              <ImageContainer image={image} setImage={setImage} />
            </div>

            <div style={{ display: 'flex', marginBottom: '10px' }}>
              <Input
                inputRef={starOneRef}
                name="Star 1"
                type="number"
                inputWidth={40}
                width={80}
                required
                requiredSign
                value={edit.starOne}
              />
              <Input
                inputRef={starThreeRef}
                name="Star 2"
                type="number"
                inputWidth={40}
                width={80}
                required
                requiredSign
                value={edit.starTwo}
              />
              <Input
                inputRef={starTwoRef}
                name="Star 3"
                type="number"
                inputWidth={40}
                width={80}
                required
                requiredSign
                value={edit.starThree}
              />
            </div>
            <SelectInput
              width={80}
              name="category"
              options={categoryOptions}
              setSelected={setSelectedCategory}
              selected={selectedCategory}
            />
          </div>
          <TableWrapper>
            <CustomTable data={tableRows} headers={Headers} />
          </TableWrapper>
          <SaveWrapper>
            <CustomAddButton
              text="Add PromptOption"
              onClick={() => setPromptEdit({})}
            />
            <CustomSaveButton />
          </SaveWrapper>
        </Form>
      </PopUp>
      {promptEdit && (
        <LessonPromptOption
          prompts={prompts}
          setEdit={setPromptEdit}
          setAllPrompts={setPrompts}
          edit={promptEdit}
          languages={languages}
        />
      )}
    </>
  );
};

export default AdminLessonEdit;
