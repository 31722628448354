import React, { useContext, useMemo, useRef, useState } from "react";
import CustomTable from "./custom/CustomTable";
import CustomEditButton from "./custom/buttons/CustomEditButton";
import CustomDeleteButton from "./custom/buttons/CustomDeleteButton";
import { MessageContext } from "../contexts/MessageContext";
import PopUp from "./PopUp";
import CustomAddButton from "./custom/buttons/CustomAddButton";
import Input from "./custom/Input";
import SelectInput from "./custom/SelectInput";
import CustomSaveButton from "./custom/buttons/CustomSaveButton";
import { Color } from "../colors/colors";
import TextArea from "./TextArea";

export const LANGUAGES = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "Polish",
    value: "pl",
  },
  {
    label: "German",
    value: "de",
  },
  {
    label: "Spanish",
    value: "es",
  },
];

const HEADERS = ["No.", "Translation", "Lang", "Actions"];

const Translation = ({ edit, handleEdit, setClose }) => {
  const [selectedLang, setSelectedLang] = useState(
    LANGUAGES.find((e) => e.value === edit.languageShort) || LANGUAGES[0]
  );
  const translationRef = useRef();

  const handleSave = () => {
    const payload = { ...edit };
    payload.translation = translationRef.current.value;
    payload.languageShort = selectedLang.value;

    if (!payload.translation) {
      return;
    }

    handleEdit(payload);
  };

  return (
    <PopUp setClose={setClose}>
      <form onSubmit={handleSave} style={{ margin: "20px" }}>
        <TextArea
          defaultValue={edit.translation}
          label={"Translation"}
          required={true}
          textAreaRef={translationRef}
          requiredSign={true}
        />
        <SelectInput
          name={"Language"}
          color={Color.DarkBlue}
          selectWidth={180}
          width={220}
          options={LANGUAGES}
          selected={selectedLang}
          setSelected={setSelectedLang}
          required={true}
          requiredSign={true}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            marginTop: "120px",
          }}
        >
          <CustomEditButton onClick={() => handleSave()} text={"Save"} />
        </div>
      </form>
    </PopUp>
  );
};

const Translations = ({ translations = [], setTranslations, name }) => {
  const [editTranslation, setEditTranslaiton] = useState();
  const { addMessage } = useContext(MessageContext);

  const mapedData = useMemo(
    () =>
      translations.map((t) => [
        t.id,
        t.translation,
        t.languageShort,
        <div key={t.id}>
          <CustomEditButton onClick={() => setEditTranslaiton(t)} />
        </div>,
      ]),
    [translations]
  );

  const handleEdit = (editData) => {
    const hasTranslation = translations
      .filter((f) => f.id !== editData.id)
      .find((t) => t.languageShort === editData.languageShort);

    if (hasTranslation) {
      addMessage("There is same language translation", "error");
      return false;
    }

    if (editData.id) {
      setTranslations((t) => [
        ...t.filter((tf) => tf.id !== editData.id),
        editData,
      ]);
      setEditTranslaiton(() => null);
      return;
    }

    editData.id = translations.length + 1;

    setTranslations((t) => [...t, editData]);
    setEditTranslaiton(() => null);
  };

  return (
    <>
      {editTranslation && (
        <Translation
          edit={editTranslation}
          handleEdit={handleEdit}
          setClose={setEditTranslaiton}
        />
      )}
      <div style={{ margin: "20px 0 " }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>{name}</div>
          <CustomAddButton onClick={() => setEditTranslaiton({})} />
        </div>
        <CustomTable data={mapedData} headers={HEADERS} />
      </div>
    </>
  );
};

export default Translations;
