import { createLesson, updateLesson } from '@/api/query/lesson';
import { getWordLessonTopType } from '@/api/query/word-lesson-top-type';
import ImageContainer from '@/common/components/ImageContainer';
import Loading from '@/common/components/Loading';
import PopUp from '@/common/components/PopUp';
import TextArea from '@/common/components/TextArea';
import Translations from '@/common/components/Translations';
import CustomTable from '@/common/components/custom/CustomTable';
import Input from '@/common/components/custom/Input';
import SelectInput from '@/common/components/custom/SelectInput';
import CustomAddButton from '@/common/components/custom/buttons/CustomAddButton';
import CustomDeleteButton from '@/common/components/custom/buttons/CustomDeleteButton';
import CustomEditButton from '@/common/components/custom/buttons/CustomEditButton';
import CustomSaveButton from '@/common/components/custom/buttons/CustomSaveButton';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { styled } from 'styled-components';

const Wrapper = styled.div`
  padding: 10px;
`;

const Title = styled.h2`
  text-align: center;
`;

const SaveWrapper = styled.div`
  margin: 20px 0 10px 0;
  display: flex;
  justify-content: right;
`;

const TableWrapper = styled.div`
  max-width: 800px;
  max-height: 300px;
  overflow: auto;
`;

const Form = styled.form`
  margin: 0px 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Headers = ['Number', 'Name', 'Action'];

const PromptEdit = ({ promptEdit, prompts, setPrompts, setPromptEdit }) => {
  const nameRef = useRef();
  const numberRef = useRef();

  const handleSave = (e) => {
    e && e.preventDefault();

    const payload = {};
    payload.name = nameRef.current.value;
    payload.number = parseInt(numberRef.current.value);
    payload.id = promptEdit.name ? promptEdit.id : prompts.length;

    setPrompts((prev) => [
      ...prev.filter((pre) => pre.id !== payload.id),
      payload,
    ]);
    setPromptEdit(null);
  };

  return (
    <PopUp setClose={setPromptEdit}>
      <Wrapper>
        <Title>Prompt {promptEdit.number ? 'Edit' : 'Add'}</Title>
        <Form onSubmit={(e) => handleSave(e)}>
          <Input
            inputRef={numberRef}
            name="Number"
            width={100}
            type="number"
            inputWidth={30}
            placeholder=""
            required
            requiredSign
            value={promptEdit.number}
          />
          <TextArea
            textAreaRef={nameRef}
            label={'Promt'}
            required
            defaultValue={promptEdit.name}
            width="800px"
          />
          <SaveWrapper>
            <CustomSaveButton />
          </SaveWrapper>
        </Form>
      </Wrapper>
    </PopUp>
  );
};

const LessonPromptOption = ({ setEdit, edit, setAllPrompts, languages }) => {
  const [promptEdit, setPromptEdit] = useState();
  const [selectedLang, setSelectedLang] = useState(
    languages.find((l) => l.value === edit.language),
  );

  const endLessonRulePromptRef = useRef();
  const answerPromptRef = useRef();

  const handleDeletePromt = (prompt) => {
    setPrompts((prev) => prev.filter((p) => p._id !== prompt._id));
  };

  const [prompts, setPrompts] = useState(edit.prompts || []);

  const tableRows = useMemo(() => {
    if (!prompts) return [];
    return prompts
      .sort((a, b) => a.number - b.number)
      .map((prompt) => [
        prompt.number,
        prompt.name,
        <div style={{ display: 'flex', gap: ' 10px' }}>
          <CustomDeleteButton onClick={() => handleDeletePromt(prompt)} />
          <CustomEditButton onClick={() => setPromptEdit(prompt)} />
        </div>,
      ]);
  }, [prompts]);

  const handleSave = (e) => {
    e && e.preventDefault();

    const payload = edit;
    payload.answer_promt = answerPromptRef.current.value;
    payload.prompts = prompts;
    payload.endLessonRulePrompt = endLessonRulePromptRef.current.value;
    payload.language = selectedLang.value;

    if (!edit?.id) {
      setAllPrompts((prev) => [...prev, { ...payload, id: prev.length }]);

      return setEdit(() => null);
    }

    setAllPrompts((prev) => [
      ...prev?.filter((p) => p.id !== payload.id),
      payload,
    ]);

    setEdit(() => null);
  };

  return (
    <>
      <PopUp setClose={setEdit}>
        <Title>Lesson Lang {edit._id ? 'Edit' : 'Create'}</Title>
        <Form onSubmit={(e) => handleSave(e)}>
          <div>
            <SelectInput
              selected={selectedLang}
              setSelected={setSelectedLang}
              options={languages}
              name={'Language'}
              width={80}
            />
            <TextArea
              textAreaRef={endLessonRulePromptRef}
              label="End lesson rule Prompt"
              width="800px"
              required
              requiredSign
              defaultValue={edit.endLessonRulePrompt}
            />
            <TextArea
              textAreaRef={answerPromptRef}
              label="Answer Prompt"
              required
              defaultValue={edit.answer_promt}
              width="800px"
            />
          </div>
          <TableWrapper>
            <CustomTable data={tableRows} headers={Headers} />
          </TableWrapper>
          <SaveWrapper>
            <CustomAddButton
              text="Add prompt"
              onClick={() => setPromptEdit({})}
            />
            <CustomSaveButton />
          </SaveWrapper>
        </Form>
      </PopUp>
      {promptEdit && (
        <PromptEdit
          prompts={prompts}
          setPromptEdit={setPromptEdit}
          setPrompts={setPrompts}
          promptEdit={promptEdit}
        />
      )}
    </>
  );
};

export default LessonPromptOption;
