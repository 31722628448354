import React, { useEffect, useMemo, useState } from 'react';
import Search, { TABLE_FILEDS_OPTIONS } from './components/Search';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { getFilters, searchFilterInformation } from '@/api/query/filter';
import { getAdmins } from '@/api/query/admin';
import Loading from '@/common/components/Loading';
import { findNativeLanguages } from '@/api/query/nativeLanguages';
import DataTable from '@/common/components/custom/DataTable';
import { getWordLessonTopType } from '@/api/query/word-lesson-top-type';
import { getAiPersons } from '@/api/query/ai-person';
import { getChatConfigs } from '@/api/query/chat-config';

const SearchWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const DataTableWrapper = styled.div`
  max-width: 92vw;
  overflow: auto;
  background-color: white;
  margin: 20px;
`;

const AdminSearch = () => {
  const [headers, setHeaders] = useState(TABLE_FILEDS_OPTIONS);
  const [filters, setFilters] = useState([]);
  const [results, setResults] = useState();
  const [admins, setAdmins] = useState([]);
  const [nativeLanguages, setNativeLanguages] = useState([]);
  const [hobbies, setHobbies] = useState([]);
  const [speakers, setSpeakers] = useState([]);
  const [chatConfigs, setChatConfigs] = useState([]);

  const HEADERS = useMemo(() => {
    return [
      {
        header: 'no.',
        accessorKey: 'no',
        size: 40,
      },
      ...[...headers].map((h) => ({
        header: h.label,
        accessorKey: h.value.split('.').join('_'),
        size: h.label * 3,
      })),
    ];
  }, [headers]);

  const handleLoadFilters = useMutation({
    mutationFn: () => getFilters(),
    onSuccess: ({ data }) => {
      setFilters(() => data.map((d) => ({ label: d.name, value: d })));
    },
    onError: () => {},
  });

  const handleLoadHobby = useMutation({
    mutationFn: () => getWordLessonTopType(),
    onSuccess: ({ data }) => {
      const mapedHobbies = data.map((h) => ({ label: h.name, value: h }));
      setHobbies(() => mapedHobbies);
    },
    onError: () => {},
  });

  const handleLoadSpeakers = useMutation({
    mutationFn: () => getAiPersons(),
    onSuccess: ({ data }) => {
      setSpeakers(() => data.map((d) => ({ label: d.name, value: d })));
    },
    onError: () => {},
  });

  const handleLoadChatConfigs = useMutation({
    mutationFn: () => getChatConfigs(),
    onSuccess: ({ data }) => {
      const options = [];

      data.forEach((d) => {
        d.promptGroup.forEach((pg) => {
          options.push({
            label: pg.name,
            value: pg,
          });
        });
      });

      setChatConfigs(() => options);
    },
    onError: () => {},
  });

  const handleLoadNativeLanguages = useMutation({
    mutationFn: () => findNativeLanguages(),
    onSuccess: ({ data }) => {
      setNativeLanguages(() =>
        data.map((d) => ({ label: d.name, value: d._id })),
      );
    },
    onError: () => {},
  });

  const handeLoadAdmins = useMutation({
    mutationFn: () => getAdmins(),
    onSuccess: ({ data }) => {
      setAdmins(() => data.map((d) => ({ label: d.email, value: d._id })));
    },
    onError: () => {},
  });

  const getFiled = (data, fields) => {
    let path = fields.split('.');

    if (path[0] === 'order' && Array.isArray(data.order)) {
      data.order = data.order[0];
    }

    if (!data[path[0]]) {
      return '-----';
    }
    let result = data[path[0]];
    for (let i = 1; i < path.length; i++) {
      if (result[path[i]] === undefined) {
        return '------';
      }

      if (path[i] === 'price') {
        return result[path[i]] / 100;
      }

      result = result[path[i]];
    }

    return result;
  };

  const handleSearchMutation = useMutation({
    mutationFn: (data) => searchFilterInformation(data),
    onSuccess: ({ data }) => {
      const mapedData = data.map((d, i) => {
        let ob = {
          no: `${i + 1}.`,
        };

        headers.forEach((h) => {
          let res = getFiled(d, h.value);

          if (h.value.split('.').join('_') === 'hobby') {
            res = hobbies
              .filter((f) => res.includes(f.value._id))
              .map((f) => f.label)
              .join(', ');
          }

          if (h.value.split('.').join('_') === 'speaker') {
            res = speakers
              .filter((f) => res.includes(f.value._id))
              .map((f) => f.label)
              .join(', ');
          }

          if (h.value.split('.').join('_') === 'chatConfigs') {
            res = Object.values(res)
              .map((ch) => {
                const lookUped = chatConfigs.find(
                  (_ch) => _ch.value._id === ch,
                );
                return lookUped?.label || '-----';
              })
              .join(', ');
          }

          if (h.value.split('.').join('_') === 'nativeLanguage') {
            res = nativeLanguages.find((f) => res === f.value)?.label;
          }

          ob[h.value.split('.').join('_')] = res;
        });
        return ob;
      });

      setResults(() => mapedData);
    },
    onError: () => {},
  });

  const handleSearch = (p) => {
    handleSearchMutation.mutate(p);
  };

  const reload = () => {
    handleLoadFilters.mutate();
  };

  useEffect(() => {
    handleLoadFilters.mutate();
    handeLoadAdmins.mutate();
    handleLoadNativeLanguages.mutate();
    handleLoadHobby.mutate();
    handleLoadSpeakers.mutate();
    handleLoadChatConfigs.mutate();
  }, []);

  return (
    <>
      {(handeLoadAdmins.isLoading ||
        handleLoadFilters.isLoading ||
        handleSearchMutation.isLoading ||
        handleLoadHobby.isLoading ||
        handleLoadSpeakers.isLoading ||
        handleLoadChatConfigs.isLoading) && <Loading />}
      <SearchWrapper>
        <Search
          savedFilters={filters}
          admins={admins}
          hobbies={hobbies}
          speakers={speakers}
          chatConfigs={chatConfigs}
          nativeLanguages={nativeLanguages}
          handleSearch={handleSearch}
          headers={headers}
          setHeaders={setHeaders}
          reload={reload}
        />
      </SearchWrapper>
      <DataTableWrapper>
        {HEADERS && results && <DataTable data={results} columns={HEADERS} />}
      </DataTableWrapper>
    </>
  );
};

export default AdminSearch;
