import Api from '../api';
const { axios: api } = Api;
const queryString = require('query-string');

const PREFIX = 'translation';

export const updateTranslation = async (payload) => {
  const data = await api.patch(`/${PREFIX}/${payload._id}`, payload);
  return { data: data.data };
};

export const findTranslationByTypeAndLang = async (payload) => {
  const data = await api.get(`/${PREFIX}/${payload.type}/${payload.lang}`);
  return { data: data.data };
};
