import Loading from '@/common/components/Loading';
import PopUp from '@/common/components/PopUp';
import CustomTable from '@/common/components/custom/CustomTable';
import Input from '@/common/components/custom/Input';
import CustomAddButton from '@/common/components/custom/buttons/CustomAddButton';
import CustomEditButton from '@/common/components/custom/buttons/CustomEditButton';
import CustomSaveButton from '@/common/components/custom/buttons/CustomSaveButton';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { styled } from 'styled-components';
import EditPrompt from './EditPrompt';
import CustomDeleteButton from '@/common/components/custom/buttons/CustomDeleteButton';
import {
  createDailyConversationConfig,
  updateDailyConversaionConfig,
} from '@/api/query/dail-config';
import SelectInput from '@/common/components/custom/SelectInput';
import EditPromptGroup from './EditPromptGroup';
import {
  createChatConfig,
  handleRestartCustomersPicks,
  updateChatConfig,
} from '@/api/query/chat-config';
import {
  LEVELS,
  LEVELS_OPTIONS,
  LEVEL_OPTIONS,
} from '@/pages/landing/components/DemoScript/components/LevelMessage/levelMessage.constants';
import { MessageContext } from '@/common/contexts/MessageContext';

const Wrapper = styled.div`
  padding: 10px;
`;

const Title = styled.h2`
  text-align: center;
`;

const SaveWrapper = styled.div`
  margin: 20px 0 10px 0;
  display: flex;
  justify-content: right;
`;

const TableWrapper = styled.div`
  max-width: 800px;
  max-height: 300px;
  overflow: auto;
`;

const Form = styled.form`
  margin: 0px 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const CHAT_CONFIG_OPTIONS = [
  {
    label: 'Daily',
    value: 'daily',
  },
  {
    label: 'News',
    value: 'news',
  },

  {
    label: 'Daily V2',
    value: 'daily_v2',
  },
  {
    label: 'News V2',
    value: 'news_v2',
  },
  {
    label: 'Call Mode',
    value: 'call_mode',
  },
];

const HEADERS = ['no.', 'Name', 'Weight', 'Actions'];

const AdminChatConfigsEdit = ({ setEdit, edit, language }) => {
  const [showPrompt, setShowPrompt] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState(
    language.find((l) => l.value === edit?.language?._id),
  );
  const [selectedChatConfigOptions, setSelectedChatConfigOptions] = useState(
    CHAT_CONFIG_OPTIONS.find((d) => d.value === edit.type),
  );
  const [selectedLevel, setSelectedLevel] = useState(
    LEVELS_OPTIONS.filter((l) => edit?.levels?.includes(l.value)),
  );
  const [prompts, setPrompts] = useState(edit.promptGroup || []);

  const tableData = useMemo(() => {
    if (!prompts) {
      return [];
    } else {
      return prompts
        .sort((a, b) => a.id - b.id)
        .map((g) => [
          g.id,
          g.name,
          g.weight,
          <div style={{ display: 'flex', gap: '10px' }}>
            <CustomEditButton onClick={() => setShowPrompt(g)} />
          </div>,
        ]);
    }
  }, [prompts]);

  const nameRef = useRef();
  const defaultRef = useRef();

  const { addMessage } = useContext(MessageContext);

  const createWordLessonConfigMutation = useMutation({
    mutationFn: (value) => createChatConfig(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const updateWordLessonConfigMutation = useMutation({
    mutationFn: (value) => updateChatConfig(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const restartCustomersPicksMutation = useMutation({
    mutationFn: (value) => handleRestartCustomersPicks(value),
    onSuccess: () => {
      addMessage('Restarted', 'success');
    },
    onError: () => {
      addMessage('Error', 'error');
    },
  });

  const restartCustomersPicks = () => {
    const payload = {
      type: selectedChatConfigOptions.value,
    };

    restartCustomersPicksMutation.mutate(payload);
  };

  const handleSave = (e) => {
    e && e.preventDefault();

    const payload = edit;
    payload.name = nameRef.current.value;
    payload.active = defaultRef.current.checked;
    payload.promptGroup = prompts;
    payload.type = selectedChatConfigOptions.value;
    payload.language = selectedLanguage.value;

    payload.levels = selectedLevel.map((l) => l.value);

    if (edit._id) {
      return updateWordLessonConfigMutation.mutate(payload);
    }

    createWordLessonConfigMutation.mutate(payload);
  };

  return (
    <>
      {(updateWordLessonConfigMutation.isLoading ||
        createWordLessonConfigMutation.isLoading ||
        restartCustomersPicksMutation.isLoading) && <Loading />}
      <PopUp setClose={setEdit}>
        <Title>Chat config {edit._id ? 'Edit' : 'Create'}</Title>
        <Form onSubmit={(e) => handleSave(e)}>
          <SelectInput
            options={LEVELS_OPTIONS}
            selected={selectedLevel}
            setSelected={setSelectedLevel}
            multiple={true}
            name={'Levels'}
            width={80}
            selectWidth={620}
            required
            requiredSign
          />
          <Input
            inputRef={nameRef}
            name="Name"
            inputWidth={600}
            width={80}
            required
            requiredSign
            value={edit.name}
          />
          <SelectInput
            name={'Type'}
            width={80}
            options={CHAT_CONFIG_OPTIONS}
            selected={selectedChatConfigOptions}
            setSelected={setSelectedChatConfigOptions}
            required
            requiredSign
          />
          <SelectInput
            name={'Language'}
            width={80}
            options={language}
            selected={selectedLanguage}
            setSelected={setSelectedLanguage}
            required
            requiredSign
          />
          <Input
            inputRef={defaultRef}
            name="Active"
            type="checkbox"
            inputWidth={200}
            width={80}
            checked={edit.active}
          />
          <h3 style={{ margin: 0 }}>Prompt Group</h3>
          <TableWrapper>
            <CustomTable headers={HEADERS} data={tableData} />
          </TableWrapper>
          <SaveWrapper>
            {/* <CustomAddButton
              onClick={() => restartCustomersPicks()}
              text="Restart customer PICKS"
            /> */}
            <CustomAddButton onClick={() => setShowPrompt({})} />
            <CustomSaveButton />
          </SaveWrapper>
        </Form>
      </PopUp>
      {showPrompt && (
        <EditPromptGroup
          setClose={setShowPrompt}
          edit={showPrompt}
          handleAddPrompt={setPrompts}
        />
      )}
    </>
  );
};

export default AdminChatConfigsEdit;
