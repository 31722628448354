import Api from '../api';
const { axios: api } = Api;
const queryString = require('query-string');

const PREFIX = 'sendgrid';

export const testMail = async (payload) => {
  const data = await api.post(`/${PREFIX}/test-mail`, payload);
  return { data: data.data };
};
