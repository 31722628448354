import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { Color } from '@/common/colors/colors';
import SelectInput from '@/common/components/custom/SelectInput';
import { useMutation } from 'react-query';
import { findNativeLanguages } from '@/api/query/nativeLanguages';
import Loading from '@/common/components/Loading';

const SelectBarContainer = styled.form`
  border-radius: 10px;
  background-color: ${Color.White};
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border: 1px dashed grey;
  margin: 20px auto;
`;

const SearchButton = styled.button`
  padding: 10px 20px;
  background-color: ${Color.DarkBlue};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  margin-left: 20px;
  font-size: 12px;

  &:hover {
    background-color: ${Color.Grey};
  }
`;

export const LANG_OPTIONS = [
  {
    label: 'Polski',
    value: 'pl',
  },
  {
    label: 'Niemiecki',
    value: 'de',
  },
  {
    label: 'Hiszpański',
    value: 'es',
  },
  {
    label: 'Angielski',
    value: 'en',
  },
  {
    label: 'Czeski',
    value: 'cs',
  },
  {
    label: 'Chorwacki',
    value: 'hr',
  },
  {
    label: 'Słowacki',
    value: 'sk',
  },
  {
    label: 'Włoski',
    value: 'it',
  },
  {
    label: 'Rumuński',
    value: 'ro',
  },
  {
    label: 'Francuski',
    value: 'fr',
  },
];

const TYPE_OPTIONS = [
  { label: 'Flashcard Category', value: 'flashcard_category' },
  { label: 'Lesson', value: 'lesson' },
  { label: 'Hobby', value: 'hobby' },
  { label: 'Words', value: 'words' },
  { label: 'News', value: 'news' },
];

const SearchBar = ({
  handleSearch,
  selectedType,
  selectedLang,
  setSelectedType,
  setSelectedLang,
}) => {
  const [languagesOptions, setLanugagesOptions] = useState();

  const findLanguagesMutation = useMutation({
    mutationFn: () => findNativeLanguages(),
    onSuccess: ({ data }) => {
      const languages = data.map((l) => ({ label: l.name, value: l.short }));

      setLanugagesOptions(() => languages);
    },
  });

  const handleSearchCheck = (e) => {
    e?.preventDefault();
    const payload = {};

    payload.lang = selectedLang.value;
    payload.type = selectedType.value;

    handleSearch(payload);
  };

  useEffect(() => {
    findLanguagesMutation.mutate();
  }, []);

  return (
    <>
      {findLanguagesMutation.isLoading && <Loading />}
      <SelectBarContainer onSubmit={handleSearchCheck}>
        <SelectInput
          name="Lang"
          width={50}
          selectWidth={150}
          options={languagesOptions}
          selected={selectedLang}
          setSelected={setSelectedLang}
        />
        <SelectInput
          name="Type"
          width={50}
          selectWidth={150}
          options={TYPE_OPTIONS}
          selected={selectedType}
          setSelected={setSelectedType}
        />
        <SearchButton type="submit">Search</SearchButton>
      </SelectBarContainer>
    </>
  );
};

export default SearchBar;
