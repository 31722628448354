import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { RegisterContext } from '../context/register.context';
import { createCustomerOrder } from '@/api/query/order';
import { PLAN_TYPE } from '../register.constants';
import PayuIcon from '../svg/payou.logo.svg';
import Visa from '../icons/Visa blue.svg';
import MasterCard from '../icons/mastercard.svg';
import Blik from '../icons/blik.svg';
import Transfer from '../icons/przelewy.svg';
import { IpStatsContext } from '@/common/contexts/IpStatsContext';
import { IP_STATS_ENUM } from '@/common/enums/ipStatsEnum';
import Lock from '../icons/secured-lock.svg';
import CheckWhite from '../icons/check-white.svg';
import { getCurrentPriceConfig } from '@/api/query/price-config';
import Loading from '@/common/components/Loading';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import { LanguageContext } from '@/common/contexts/LanguageContext';

const Title = styled.h3`
  text-align: center;
  color: #05275d;
  font-family: Work Sans;
  font-size: 28px;
  margin: 20px 0;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;

  @media (max-width: 600px) {
    font-size: 22px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 600px) {
    width: 320px;
  }
`;

const Info = styled.div`
  color: #29185e;
  width: 100%;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;

  @media (max-width: 600px) {
    width: 300px;
    text-align: center;
    color: #05275d;
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

const ActionWrapper = styled.form`
  display: flex;
  justify-content: center;
  gap: 30px;
  margin: 0px 0;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`;

const LetMailButton = styled.button`
  display: flex;
  width: 200px;
  padding: 15px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px;
  background: #730bdb;
  outline: none;
  border: none;
  cursor: pointer;
  color: #fff;
  /* Button */
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;

  &:hover {
    transform: scale(1.1);
  }
`;

const PricesWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;

  @media (max-width: 600px) {
    display: none;
  }
`;

const FlexElement = styled.div`
  width: 150px;
  border-radius: 16px;
  border: 1px solid #730bdb;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  color: #05275d;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  border: ${(props) =>
    props.selected ? '3px solid #730BDB' : '1px solid #29185E'};
  background: ${(props) => (props.selected ? '#ECE5F2' : '#F4F6F8')};

  @media (min-width: 600px) {
    width: 200px;
    height: 220px;
    border-radius: 16px;
    align-items: center;
    gap: 10px;
  }
`;

const FlexElementMobile = styled.div`
  width: 85vw;
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #05275d;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  background: white;
  padding: 10px;
  height: 115px;
  border: ${(props) =>
    props.selected ? '3px solid #730BDB' : '1px solid #29185E'};
  background: ${(props) => (props.selected ? '#ECE5F2' : '#F4F6F8')};
`;

const TextTop = styled.div`
  color: #05275d;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.4px;
`;

const TextPrice = styled.p`
  color: #05275d;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.4px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0;

  h3 {
    color: #05d698;
    font-family: Work Sans;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.96px;
    margin: 0;
  }
`;

const TextBottom = styled.div`
  color: #05275d;
  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0px;
`;

const Card = styled.div`
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @media (min-width: 600px) {
    margin: 0 auto;
    overflow-y: auto;
  }
`;

const PaymentTypeWrapper = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
  min-width: 350px;

  @media (max-width: 600px) {
    background: transparent;
  }
`;

const PaymentTitle = styled.p`
  color: #05275d;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const PaymentText = styled.p`
  color: #05275d;
  text-align: left;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  min-width: 180px;
  margin: 0;

  @media (max-width: 600px) {
    background: white;
  }
`;

const Border = styled.div`
  height: 1px;
  width: 180px;
  background: #d9d9d9;
  margin-top: -15px;

  @media (max-width: 600px) {
    margin: 0;
    width: 100px;
  }
`;

const PaymentElement = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  width: 190px;
  border-radius: 100px;
  background: #730bdb;
  padding: 10px 0;

  color: #fff;

  text-align: center;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  background: linear-gradient(91deg, #4f17aa 1.48%, #ac02f1 99.24%);

  &:hover {
    transition: all 100ms ease-in-out;
    transform: scale(1.04);
  }
`;

const PaymentIcon = styled.img`
  width: 60px;
`;

const MonthWrapper = styled.h3`
  color: #05275d;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin: 20px 0;

  @media (max-width: 600px) {
    margin: 0px;
    font-size: 20px;
    margin-top: 25px;
  }
`;

const FullPriceWrapper = styled.p`
  color: #05275d;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0;

  @media (max-width: 600px) {
    text-align: center;
  }
`;

const NowUwillPay = styled.p`
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
  margin: 0;
`;

const Picker = styled.div`
  display: inline-flex;
  width: 150px;
  height: 40px;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  border: 1px solid #29185e;
  background: #fff;

  color: #29185e;

  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;

  @media (max-width: 600px) {
    width: 120px;
  }

  @media (max-width: 600px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const PickerPicked = styled(Picker)`
  border-radius: 8px;
  border: 1px solid #730bdb;
  background: #730bdb;

  @media (max-width: 600px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const ElementTop = styled.div`
  border-radius: 22px;
  background: #730bdb;
  padding: 10px;
  margin-top: -20px;
  margin-bottom: -25px;

  color: #fff;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.24px;
  text-transform: uppercase;

  @media (max-width: 600px) {
    padding: 6px 26px;
  }
`;

const FullPriceWrapperPercent = styled.p`
  color: #05275d;

  text-align: center;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  text-decoration: line-through red;
  -webkit-text-decoration: line-through red;
  margin-top: -5px;
  margin-bottom: -15px;

  @media (max-width: 600px) {
    margin: 40px 0 0 0;
  }
`;

const PricesWrapperMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: 600px) {
    display: none;
  }
`;

export const CONFIG_DATA = {
  _1: {
    price: '59,99',
    months: '1 miesiąc',
    full_price: '59,99',
    label: 'Basic',
    type: PLAN_TYPE.BASIC,
  },
  _2: {
    price: '39,99',
    months: '3 miesiące',
    full_price: '119,97',
    label: 'Pro',
    type: PLAN_TYPE.PRO,
  },
  _3: {
    price: '29,99',
    months: '6 miesięcy',
    full_price: '179,94',
    label: 'Platinum',
    type: PLAN_TYPE.PREMIUM,
  },
};

export const CONFIG_DATA_ARRAY = [
  {
    price: '59,99',
    months: '1 miesiąc',
    full_price: '59,99',
    label: 'Basic',
    type: PLAN_TYPE.BASIC,
  },
  {
    price: '39,99',
    months: '3 miesiące',
    full_price: '119,97',
    label: 'Pro',
    type: PLAN_TYPE.PRO,
  },
  {
    price: '29,99',
    months: '6 miesięcy',
    full_price: '179,94',
    label: 'Platinum',
    type: PLAN_TYPE.PREMIUM,
  },
];

export const PAYMENT_TYPES = {
  PAYU: 'payu',
  APPLE: 'apple',
  GOOGLE: 'google',
};

export const PAYMENT_FORMS = [
  {
    type: PAYMENT_TYPES.PAYU,
    text: 'PayU',
    subText: 'Karta płatnicza VISA lub MasterCard',
    icon: PayuIcon,
  },
];

const PlanView = () => {
  const data = useLanguageData('register.stepTwo');
  const { lang } = useContext(LanguageContext);

  const [pricesData, setPricesData] = useState([]);

  const [selectedOption, setSelectedOption] = useState();
  const { setView, user, setPlan } = useContext(RegisterContext);
  const { handleSendIPstats, handleFbStats } = useContext(IpStatsContext);

  const handlePickPlanMutation = useMutation({
    mutationFn: (value) => createCustomerOrder(value),
    onSuccess: ({ data }) => {
      handleSendIPstats(IP_STATS_ENUM.PLAN);
      localStorage.setItem('register_order', JSON.stringify(data));
      setPlan(() => ({ ...data, ...selectedOption }));
      setView(2);
    },
  });

  const handleLoadPricesMutation = useMutation({
    mutationFn: (value) => getCurrentPriceConfig(value),
    onSuccess: ({ data }) => {
      setPricesData(() =>
        data.prices.sort((a, b) => a.full_price - b.full_price),
      );
      setSelectedOption(
        () => data.prices.sort((a, b) => a.full_price - b.full_price)[1],
      );
    },
  });
  const handleCreateOrder = (e, type) => {
    e.preventDefault();

    handleFbStats('InitiateCheckout', {});
    window?.gtag('initiate_checkout', {});
    window?.fbq('track', 'InitiateCheckout');

    const payload = {};
    payload.user = user?._id;
    payload.plan_type = selectedOption.type.value;
    payload.price = parseFloat(selectedOption.full_price) * 100;
    payload.payment_type = type;

    handlePickPlanMutation.mutate(payload);
  };

  useEffect(() => {
    const payload = {
      country: lang,
    };

    handleLoadPricesMutation.mutate(payload);
  }, []);

  if (handleLoadPricesMutation.isLoading && selectedOption) {
    return <Loading />;
  }

  return (
    <Wrapper>
      <Card>
        <Title>{data.title}</Title>
        <PricesWrapper>
          {[...(pricesData || [])]
            .sort((a, b) => a.full_price - b.full_price)
            .map((p) => (
              <FlexElement onClick={() => setSelectedOption(p)}>
                {p.inAppPrice.saving && (
                  <ElementTop>{p.inAppPrice.saving}</ElementTop>
                )}
                <MonthWrapper>{p.inAppPrice.label} </MonthWrapper>
                <Border style={{ marginTop: '-15px' }} />
                <FullPriceWrapperPercent
                  style={{
                    marginTop: !p.inAppPrice.original_price_info
                      ? '15px'
                      : '0px',
                  }}
                >
                  {p.inAppPrice.original_price_info}
                </FullPriceWrapperPercent>
                <FullPriceWrapper>
                  <b>
                    {p.inAppPrice.promotion_monthly_price_info.split(' ')[0]}
                  </b>{' '}
                  {p.inAppPrice.promotion_monthly_price_info.split(' ')[1]}
                </FullPriceWrapper>
                {selectedOption._id === p._id ? (
                  <PickerPicked>
                    <img src={CheckWhite} />
                  </PickerPicked>
                ) : (
                  <Picker> {p.inAppPrice.chose}</Picker>
                )}
                <div style={{ marginTop: '-5px' }}></div>
                <NowUwillPay> {p.inAppPrice.nowYouWillPay}</NowUwillPay>
                <NowUwillPay>
                  {p.inAppPrice.promotion_full_price_info}
                </NowUwillPay>
              </FlexElement>
            ))}
        </PricesWrapper>
        {/* Mobile */}
        <PricesWrapperMobile>
          {[...(pricesData || [])]
            .sort((a, b) => a.per_month - b.per_month)
            .map((p) => (
              <FlexElementMobile onClick={() => setSelectedOption(p)}>
                {p.inAppPrice.saving ? (
                  <ElementTop style={{ width: '120px' }}>
                    {p.inAppPrice.saving}
                  </ElementTop>
                ) : (
                  <div style={{ marginTop: '-25px' }}></div>
                )}
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      width: '150px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <MonthWrapper>{p.inAppPrice.label}</MonthWrapper>
                    <Border />
                    <NowUwillPay style={{ margin: '15px 0 0 0' }}>
                      {p.inAppPrice.nowYouWillPay}
                    </NowUwillPay>
                    <NowUwillPay>
                      {' '}
                      {p.inAppPrice.promotion_full_price_info}
                    </NowUwillPay>
                  </div>
                  <div
                    style={{
                      width: '150px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <FullPriceWrapperPercent>
                      {p.inAppPrice.original_price_info}
                    </FullPriceWrapperPercent>
                    <FullPriceWrapper>
                      <b>
                        {
                          p.inAppPrice.promotion_monthly_price_info.split(
                            ' ',
                          )[0]
                        }
                      </b>{' '}
                      {p.inAppPrice.promotion_monthly_price_info.split(' ')[1]}
                    </FullPriceWrapper>
                    {selectedOption._id === p._id ? (
                      <PickerPicked>
                        <img src={CheckWhite} />
                      </PickerPicked>
                    ) : (
                      <Picker>{p.inAppPrice.chose}</Picker>
                    )}
                  </div>
                </div>
              </FlexElementMobile>
            ))}
        </PricesWrapperMobile>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <PaymentElement
            id="initiate_checkout"
            style={{ margin: '10px 0' }}
            onClick={(e) => handleCreateOrder(e, PAYMENT_FORMS[0].type)}
          >
            {data.buy}
          </PaymentElement>
          <PaymentTypeWrapper>
            <PaymentTitle>
              <img src={Lock} /> {data.savePayment}{' '}
              {lang === 'pl' && <PaymentIcon src={PayuIcon} />}
              {lang === 'de' && 'Stripe'}
            </PaymentTitle>
            <PaymentText
              style={{
                borderRadius: '4px',
                border: '1px solid rgba(79, 167, 180, 0.20)',
                display: 'flex',
                padding: '5px',
                gap: '20px',
              }}
            >
              {lang === 'pl' && (
                <PaymentIcon src={Blik} style={{ width: '30px' }} />
              )}
              <PaymentIcon
                src={Visa}
                style={{
                  width: '30px',
                }}
              />
              <PaymentIcon
                src={MasterCard}
                style={{
                  width: '20px',
                }}
              />
              <PaymentIcon
                src={Transfer}
                style={{
                  width: '45px',
                }}
              />
            </PaymentText>
          </PaymentTypeWrapper>
        </div>
      </Card>
    </Wrapper>
  );
};

export default PlanView;
