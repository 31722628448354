import {
  findCustomer,
  updateUserFlashcardSettings,
} from "@/api/query/customer";
import Loading from "@/common/components/Loading";
import PopUp from "@/common/components/PopUp";
import { useLanguageData } from "@/common/hooks/useLanguageData";
import { LEVELS } from "@/pages/landing/components/DemoScript/components/LevelMessage/levelMessage.constants";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";

const PopUpTitle = styled.h3`
  color: #05275d;
  text-align: center;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 22.4px */
`;

const Label = styled.label`
  cursor: pointer;
  color: #05275d;
  font-family: "Work Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */

  width: 150px;
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const InputLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SaveButton = styled.button`
  display: inline-flex;
  padding: 11px 32px 12px 31px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #650675;

  color: #fff;
  text-align: center;
  font-family: "Work Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const Form = styled.form`
  padding: 25px 160px 20px 160px;

  @media (max-width: 600px) {
    padding: 10px 60px 10px 60px;
  }
`;

const LevelSetings = ({ setClose, settings }) => {
  const [currentSettings, setCurrentSettings] = useState({
    level: LEVELS.INTERMEDIATE,
  });

  const level = useLanguageData("features.flashcards.level");

  const findUserByIdMutation = useMutation({
    mutationFn: () => findCustomer(),
    onSuccess: ({ data }) => {
      setCurrentSettings(
        data?.flashcardSettings || { level: LEVELS.INTERMEDIATE }
      );
    },
  });

  const updateUserFlashcardSettingsMutation = useMutation({
    mutationFn: (value) => updateUserFlashcardSettings(value),
    onSuccess: ({ data }) => {
      setClose(false);
    },
  });

  const handleChangeLevel = (e) => {
    e.preventDefault();

    const payload = {};
    payload.level = currentSettings.level;
    updateUserFlashcardSettingsMutation.mutate(payload);
  };

  useEffect(() => {
    findUserByIdMutation.mutate();
  }, []);

  return (
    <>
      {(findUserByIdMutation.isLoading ||
        updateUserFlashcardSettingsMutation.isLoading) && <Loading />}
      <PopUp setClose={setClose}>
        <Form onSubmit={handleChangeLevel}>
          <PopUpTitle>{level.title}</PopUpTitle>
          <InputLabelWrapper>
            <InputWrapper>
              <input
                id="onePopupSettings"
                type="radio"
                name="check"
                value={"one"}
                checked={LEVELS.BEGINNER === currentSettings.level}
                onChange={() =>
                  setCurrentSettings(() => ({
                    level: LEVELS.BEGINNER,
                  }))
                }
              />
              <Label
                htmlFor="onePopupSettings"
                style={{
                  color:
                    LEVELS.BEGINNER === currentSettings.level ? "#650675" : "",
                }}
              >
                {level.beginner}
              </Label>
            </InputWrapper>
            <InputWrapper>
              <input
                id="twoPopupSettings"
                type="radio"
                name="check"
                value={"two"}
                checked={LEVELS.INTERMEDIATE === currentSettings.level}
                onChange={() =>
                  setCurrentSettings(() => ({
                    level: LEVELS.INTERMEDIATE,
                  }))
                }
              />
              <Label
                htmlFor="twoPopupSettings"
                style={{
                  color:
                    LEVELS.INTERMEDIATE === currentSettings.level
                      ? "#650675"
                      : "",
                }}
              >
                {level.intermediate}
              </Label>
            </InputWrapper>
            <InputWrapper>
              <input
                id="threePopupSettings"
                type="radio"
                name="check"
                value={"three"}
                checked={LEVELS.ADVANCED === currentSettings.level}
                onChange={() =>
                  setCurrentSettings(() => ({
                    level: LEVELS.ADVANCED,
                  }))
                }
              />
              <Label
                htmlFor="threePopupSettings"
                style={{
                  color:
                    LEVELS.ADVANCED === currentSettings.level ? "#650675" : "",
                }}
              >
                {level.advance}
              </Label>
            </InputWrapper>
          </InputLabelWrapper>
          <ButtonWrapper>
            <SaveButton>{level.change}</SaveButton>
          </ButtonWrapper>
        </Form>
      </PopUp>
    </>
  );
};

export default LevelSetings;
