import CustomTable from '@/common/components/custom/CustomTable';
import CustomAddButton from '@/common/components/custom/buttons/CustomAddButton';
import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { useMutation } from 'react-query';
import CustomEditButton from '@/common/components/custom/buttons/CustomEditButton';
import AdminWordLessonTypeEdit from './components/AdminChatConfigsEdit';
import { getWordLessonTypeConfig } from '@/api/query/word-lesson-type-config';
import Loading from '@/common/components/Loading';
import { getDailyConversationConfigs } from '@/api/query/dail-config';
import DataTable from '@/common/components/custom/DataTable';
import { getChatConfigs } from '@/api/query/chat-config';
import { findNativeLanguages } from '@/api/query/nativeLanguages';

const TopContent = styled.div`
  display: flex;
  justify-content: right;
`;
const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
`;

const ActionWrapper = styled.div`
  margin-bottom: 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

const AdminChatConfigs = () => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [edit, setEdit] = useState();
  const [languages, setLanguages] = useState();

  const HEADERS = [
    {
      header: 'no.',
      accessorKey: 'no',
      size: 20,
    },
    {
      header: 'Name',
      accessorKey: 'name',
      size: 20,
    },
    {
      header: 'Active',
      accessorKey: 'active',
      size: 20,
    },
    {
      header: 'Type',
      accessorKey: 'type',
      size: 20,
    },
    {
      header: 'Levels',
      accessorKey: 'level',
      size: 20,
    },
    {
      header: 'Language',
      accessorKey: 'language',
      size: 20,
    },
    {
      header: 'Actions',
      accessorKey: 'actions',
      size: 20,
      cell: (info) => (
        <div style={{ display: 'flex', gap: '10px' }}>
          <CustomEditButton onClick={(e) => setEdit(info.getValue())} />
        </div>
      ),
    },
  ];

  const findWordLessonsConfigMutation = useMutation({
    mutationFn: () => getChatConfigs(),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data.map((d, i) => ({
          no: i + 1,
          name: d.name,
          active: d?.active ? 'tak' : 'nie',
          type: d.type,
          level: d.levels?.join(', ') || '-----',
          language: d?.language?.name,
          actions: d,
        })),
      );
      setData(data);
    },
  });

  const languagesMutation = useMutation({
    mutationFn: () => findNativeLanguages(),
    onSuccess: ({ data }) => {
      setLanguages(() => data.map((d) => ({ label: d.name, value: d._id })));
    },
  });

  useEffect(() => {
    if (!edit) {
      findWordLessonsConfigMutation.mutate();
      languagesMutation.mutate();
    }
  }, [edit]);

  return (
    <>
      {findWordLessonsConfigMutation.isLoading && <Loading />}
      <Wrapper>
        {edit && (
          <AdminWordLessonTypeEdit
            edit={edit}
            setEdit={setEdit}
            language={languages}
          />
        )}
        <TopContent>
          <ActionWrapper>
            <CustomAddButton onClick={() => setEdit({})} />
          </ActionWrapper>
        </TopContent>
        <TableWrapper>
          <DataTable columns={HEADERS} data={tableData} />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default AdminChatConfigs;
