import React, { useEffect, useState } from 'react';
import RobotTalkIcon from '@/common/images/robot-talk.svg';
import { DemoConversationContext } from '@/common/contexts/DemoConversation';
import {
  ContentWrapper,
  DemoSectionWrapper,
  Height,
  Logo,
  MessageBox,
} from './talking.styles';

import { TalkiMessage } from './components';
import { useMutation } from 'react-query';
import { Wrapper } from '../home/Home.styles';
import { getDailyImage } from '@/api/query/image-generator';
import FlashcardView from './components/flashcardView/FlashcardView';
import Loading from '@/common/components/Loading';

const FlashCardTalking = ({ setPageNumber }) => {
  const [flascardData, setFlascardData] = useState();
  const [dailyConversation, setDailyConversation] = useState();
  const [data, setData] = useState({});
  const [showButtoReccord, setShowButtoReccord] = useState(false);
  const [showLoading, setShowLoading] = useState();
  const [showText2, setShowText2] = useState(true);

  const handleDailyConversationMutation = useMutation({
    mutationFn: () => getDailyImage(),
    onSuccess: ({ data }) => {
      setFlascardData(data);
      setDailyConversation(() => data.conversation);
    },
  });

  useEffect(() => {
    handleDailyConversationMutation.mutate();
  }, []);

  useEffect(() => {
    window.document.title = 'Synthi Ai - flashcards';
  }, []);

  return (
    <Wrapper>
      {handleDailyConversationMutation.isLoading && <Loading />}
      {flascardData && <FlashcardView data={flascardData} />}
      <DemoSectionWrapper>
        <ContentWrapper>
          <Logo src={RobotTalkIcon} />
          <DemoConversationContext.Provider
            value={{
              data,
              setData,
              showButtoReccord,
              setShowButtoReccord,
              showLoading,
              setShowLoading,
              setPageNumber,
              showText2,
              setShowText2,
              dailyConversation,
            }}
          >
            <MessageBox>
              <TalkiMessage />
              <Height />
            </MessageBox>
          </DemoConversationContext.Provider>
        </ContentWrapper>
      </DemoSectionWrapper>
    </Wrapper>
  );
};

export default FlashCardTalking;
