import Api from '../api';

const { axios: api } = Api;
const queryString = require('query-string');

const PREFIX = 'order';

export const createCustomerOrder = async (payload) => {
  const data = await api.post(`/${PREFIX}`, payload);

  return { data: data.data };
};

export const updateCustomerOrder = async (payload) => {
  const data = await api.patch(`/${PREFIX}/customer-order-by-admin`, payload);
  return { data: data.data };
};
export const registerOnePageView = async (payload) => {
  const data = await api.post(`/${PREFIX}/one-page`, payload);
  return { data: data.data };
};

export const findOrders = async () => {
  const data = await api.get(`/${PREFIX}`);
  return { data: data.data };
};

export const findOrdersLast = async (payload) => {
  const data = await api.get(`/${PREFIX}/last?days=${payload.last_days}`);

  return { data: data.data };
};

export const loadUserPlanData = async () => {
  const data = await api.get(`/${PREFIX}/user-plan-data`);

  return { data: data.data };
};

export const handleRefund = async (payload) => {
  const data = await api.post(
    `/${PREFIX}/refund/payment/${payload.paymentId}`,
    payload,
  );

  return { data: data.data };
};

export const isOrderPaid = async (payload) => {
  const data = await api.get(`/${PREFIX}/is-order-paid/${payload.order}`);
  return { data: data.data };
};
