export const IP_STATS_ENUM = {
  LANDING: 'landing',
  REGISTER_BY_TOP: 'register_by_top',
  REGISTER_BY_MIDDLE: 'register_by_middle',
  REGISTER_BY_LOGIN: 'register_by_login',
  REGISTER_BY_BASIC: 'register_by_basic',
  REGISTER_BY_PRO: 'register_by_pro',
  REGISTER_BY_PLATINUM: 'register_by_platinum',
  REGISTER_BY_PREMIUM: 'register_by_premium',
  REGISTER_BY_BUY: 'register_by_buy',
  REGISTER_BY_EMAIL: 'register_by_email',
  PLAN: 'plan',
  PAY: 'pay',
  LOGIN: 'login',
  CHECK: 'check',
  PASS_DEMO: 'pass_demo',
  CLICK_DEMO: 'click_demo',
  CLICK_DEMO_MOBILE: 'click_demo_mobile',
  ACCOUNT_CREATED: 'account_created',
  TALKING_IN_FOTO_MODE: 'talking_in_foto_mode',
  MAIL_UP_SALE_BACK: 'mail_up_sale_back',
  ACCOUNT_EXPIRED_WINDOW_PAY: 'account_expired_window_pay',
  PAY_IN_APPLICATION: 'pay_in_application',
  LOGIN_TO_SYSTEM: 'login_to_system',
  MAIL_BEFORE_EXPIRED: 'mail_before_expired',
  LOGIN_PAGE_VIEW: 'login_page_view',
  REEGISTER_PAGE_VIEW: 'register_page_view',
  NOTIFICATION_REQUEST_DENIED: 'notification_request_denied',
  SKIPED_PLAN: 'skiped_plan',

  APP_MAIN_SCREEN_PROLONG_PLAN: 'app_main_screen_prolong_plan',
  APP_MAIN_SCREEN_BUY_PLAN: 'app_main_screen_buy_plan',
  APP_MAIN_SCREEN_BUY_PLAN_SUMMARY: 'app_main_screen_buy_plan_summary',
  APP_MAIN_SCREEN_SKIP_PLAN: 'app_main_screen_skip_plan',
  APP_PROFILE_YOURPLAN_PLAN: 'app_profile_yourplan_plan',
  APP_PROFILE_YOURPLAN_BUY_PLAN: 'app_profile_yourplan_buy_plan',
  APP_PROFILE_YOURPLAN_BUY_PLAN_SUMMARY:
    'app_profile_yourplan_buy_plan_summary',
  APP_PROFILE_YOURPLAN2_PLAN: 'app_profile_yourplan2_plan',
  APP_MAIN_SCREEN_EXPIRED_BUY_PLAN: 'app_main_screen_expired_buy_plan',
  APP_MAIN_SCREEN_EXPIRED_BUY_PLAN_SUMMARY:
    'app_main_screen_expired_buy_plan_summary',
  APP_MAIN_POPUP_NEW_CALLMODE: 'app_main_popup_new_callmode',
  APP_MAIN_POPUP_NEW_CALLMODE_CALL: 'app_main_popup_new_callmode_call',
  APP_MAIN_POPUP_NEW_CALLMODE_SKIP: 'app_main_popup_new_callmode_skip',
  APP_CALL_MODE_POPUP_HANDS: 'app_callmode_popup_hands',
  APP_CALL_MODE_POPUP_HANDS_SKIP: 'app_callmode_popup_hands_skip',
  APP_CALL_MODE_POPUP_HANDS_BACK: 'app_callmode_popup_hands_back',
};
