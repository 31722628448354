import CustomLine from '@/common/charts/Line';
import moment from 'moment';
import React, { useMemo } from 'react';

const DailyChart = ({ payments }) => {
  const chartData = useMemo(() => {
    const labels = [];
    const resultMap = new Map();

    for (let i = 30; i >= 0; i--) {
      labels.push(moment().subtract(i, 'days').format('DD/MM'));
    }

    if (payments) {
      const uniqueCountries = Array.from(
        new Set(payments.map((payment) => payment.country)),
      ).sort();

      labels.forEach((label) => {
        uniqueCountries.forEach((country) => {
          let currentPayment = payments.find(
            (payment) => payment.date === label && payment.country === country,
          );

          resultMap.set(country, [
            ...(resultMap.get(country) || []),
            currentPayment?.sum || 0,
          ]);
        });
      });
    }

    return {
      labels,
      datasets: Array.from(resultMap.entries()).map(
        ([country, data], index) => ({
          label: country,
          data: data,
          borderColor: `hsl(${index * 50}, 70%, 50%)`,
          backgroundColor: `hsl(${index * 50}, 70%, 50%)`,
          yAxisID: 'y',
        }),
      ),
    };
  }, [payments]);

  return (
    <div>
      <CustomLine
        data={chartData}
        options={
          {
            // plugins: {
            //   legend: {
            //     display: false,
            //   },
            // },
          }
        }
      />
    </div>
  );
};

export default DailyChart;
