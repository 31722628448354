import Api from '../api';
const { axios: api } = Api;
const queryString = require('query-string');

const PREFIX = 'news';

export const createNews = async (payload) => {
  const data = await api.post(`/${PREFIX}`, payload);
  return { data: data.data };
};

export const updateNews = async (payload) => {
  const data = await api.patch(`/${PREFIX}/${payload._id}`, payload);
  return { data: data.data };
};

export const findAllNews = async () => {
  const data = await api.get(`/${PREFIX}/`);
  return { data: data.data };
};

export const findLatestNews = async () => {
  const data = await api.get(`/${PREFIX}/the-new-one`);
  return { data: data.data };
};

export const deleteNews = async (payload) => {
  const data = await api.delete(`/${PREFIX}/${payload._id}`);
  return { data: data.data };
};
