import CustomTable from '@/common/components/custom/CustomTable';
import CustomAddButton from '@/common/components/custom/buttons/CustomAddButton';
import React, { useMemo, useRef, useState } from 'react';
import { styled } from 'styled-components';
import { useMutation } from 'react-query';
import { findCategories } from '@/api/query/category';
import SearchBar from './components/SearchBar';
import Loading from '@/common/components/Loading';
import { IP_STATS_ENUM } from '@/common/enums/ipStatsEnum';
import { getIpStatsByDates } from '@/api/query/ipStats';

const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

const HEADERS = ['Event Name', 'How much'];
const HEADERS_TWO = ['Event Name', 'Amount'];

const AdminIpStats = () => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableDataTwo, setTableDataTwo] = useState([]);
  const [includerValue, setIncluderValue] = useState();

  const uniqueIp = useRef();
  const uniqueUid = useRef();

  const calcDataForPassingPercent = (status, data) => {
    const uniqIP = uniqueIp?.current?.checked || false;
    const uniqUID = uniqueUid?.current?.checked || false;

    let filtred = data.filter((d) => d.IpStatsType.includes(status));

    if (!uniqIP && !uniqUID) {
      return filtred.length;
    }

    if (uniqUID) {
      let toCheck = filtred.filter((d) => d.utm?.uid).map((d) => d.utm?.uid);

      if (toCheck.length === 1) {
        filtred = toCheck;
      } else {
        filtred = [...new Set(toCheck)];
      }
    }

    if (uniqIP) {
      let toCheckIP = filtred.map((d) => d.ip_name);

      if (toCheckIP.length === 1) {
        filtred = toCheckIP;
      } else {
        filtred = [...new Set(toCheckIP)];
      }
    }
    return filtred.length;
  };

  const calcDataByStatus = (status, data) => {
    const uniqIP = uniqueIp?.current?.checked || false;
    const uniqUID = uniqueUid?.current?.checked || false;

    let filtred = data.filter((d) => d?.IpStatsType?.toLowerCase() === status);

    if (!uniqIP && !uniqUID) {
      return filtred.length;
    }

    if (uniqUID) {
      let toCheck = filtred.filter((d) => d.utm?.uid).map((d) => d.utm?.uid);

      if (toCheck.length === 1) {
        filtred = toCheck;
      } else {
        filtred = [...new Set(toCheck)];
      }
    }

    if (uniqIP) {
      let toCheckIP = filtred.map((d) => d.ip_name);

      if (toCheckIP.length === 1) {
        filtred = toCheckIP;
      } else {
        filtred = [...new Set(toCheckIP)];
      }
    }

    return filtred.length;
  };

  const findStatsMutation = useMutation({
    mutationFn: (values) => getIpStatsByDates(values),
    onSuccess: ({ data }) => {
      const names = [...new Set([...data].map((d) => d.IpStatsType))].filter(
        (f) => f,
      );

      data = data.filter((f) => f.IpStatsType);
      setTableData(() =>
        names.map((key) => [
          key?.toUpperCase(),
          calcDataByStatus(key?.toLowerCase(), data),
        ]),
      );

      setTableDataTwo(() => [
        ['Landing', calcDataForPassingPercent(IP_STATS_ENUM.LANDING, data)],
        [
          'Register',
          calcDataForPassingPercent(IP_STATS_ENUM.REGISTER_BY_BASIC, data) +
            calcDataForPassingPercent(IP_STATS_ENUM.REGISTER_BY_BUY, data) +
            calcDataForPassingPercent(IP_STATS_ENUM.REGISTER_BY_PRO, data) +
            calcDataForPassingPercent(
              IP_STATS_ENUM.REGISTER_BY_PLATINUM,
              data,
            ) +
            calcDataForPassingPercent(IP_STATS_ENUM.REGISTER_BY_PREMIUM, data),
        ],
        [
          'Account Created',
          calcDataForPassingPercent(IP_STATS_ENUM.ACCOUNT_CREATED, data),
        ],
        ['Plan', calcDataForPassingPercent(IP_STATS_ENUM.PLAN, data)],
        ['Pay', calcDataForPassingPercent(IP_STATS_ENUM.PAY, data)],
      ]);

      setData(data);
    },
  });

  const handleSearch = (payload) => {
    findStatsMutation.mutate(payload);
  };

  const handleOnInlucderChange = (e) => {
    const toCheck = e.target.value;

    if (toCheck.length > 0) {
      return setIncluderValue(toCheck);
    }

    setIncluderValue(null);
  };

  const filtredData = useMemo(() => {
    return [...tableData].filter((a) => {
      if (includerValue && !a[0]?.includes(includerValue)) {
        return false;
      }

      return true;
    });
  }, [tableData, includerValue]);

  return (
    <>
      {findStatsMutation.isLoading && <Loading />}
      <Wrapper>
        <SearchBar
          handleSearch={handleSearch}
          uniqueIp={uniqueIp}
          uniqueUid={uniqueUid}
          onInlucderChange={handleOnInlucderChange}
        />
        <TableWrapper>
          <CustomTable
            headers={HEADERS_TWO}
            data={tableDataTwo}
            csvData={tableDataTwo}
          />
        </TableWrapper>
        <TableWrapper>
          <CustomTable
            headers={HEADERS}
            data={filtredData}
            csvData={filtredData}
          />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default AdminIpStats;
