import Api from '../api';
const { axios: api } = Api;
const queryString = require('query-string');

const PREFIX = 'promotion';

export const getPromotions = async () => {
  const data = await api.get(`/${PREFIX}`);
  return { data: data.data };
};

export const createPromotion = async (payload) => {
  const data = await api.post(`/${PREFIX}`, payload);
  return { data: data.data };
};

export const updatePromotion = async (payload) => {
  const data = await api.patch(`/${PREFIX}/${payload._id}`, payload);
  return { data: data.data };
};
