import PopUp from '@/common/components/PopUp';
import CustomTable from '@/common/components/custom/CustomTable';
import CustomEditButton from '@/common/components/custom/buttons/CustomEditButton';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  max-height: 600px;
  width: 800px;
`;

const QueryResultPopUp = ({ setClose, data }) => {
  const navigate = (d) => {
    window.open(`/admin/customer/stats/${d._id}`);
  };

  return (
    <PopUp setClose={setClose}>
      <Wrapper>
        <h3>Query Result</h3>
        <CustomTable
          headers={['No.', 'Email', 'Name', 'Action']}
          data={data.map((d, i) => [
            i + 1,
            d.email,
            d.name,
            <div>
              <FontAwesomeIcon icon={faEye} onClick={() => navigate(d)} />
            </div>,
          ])}
        />
      </Wrapper>
    </PopUp>
  );
};

export default QueryResultPopUp;
