import React, { useEffect, useState } from 'react';
import { DemoConversationContext } from '@/common/contexts/DemoConversation';
import { ContentWrapper, DemoSectionWrapper, MessageBox } from './daily.styles';
import { TalkiMessage } from './components';
import { useMutation } from 'react-query';
import { createDailyConversation } from '@/api/query/lead-history';
import { Wrapper } from '../home/Home.styles';
import {
  findCustomer,
  markShowDailyPopupConversatoin,
} from '@/api/query/customer';
import NewTopTileHeader from '@/common/components/chat/NewTopTileHeader';
import { useParams } from 'react-router-dom';
import { SOURCES_TYPES } from '@/common/components/customer/Hands';
import DailyTutorial from './components/DailyTutorial';

export const SPEAKER_OPTIONS = {
  LEO: 'Leo',
  HENRY: 'Henry',
  SARAH: 'Sarah',
  RUBY: 'Ruby',
};

const Daily = ({ setPageNumber }) => {
  const [showPopUpTutorial, setShowPopUpTutorial] = useState('waiting');
  const [dailyConversation, setDailyConversation] = useState();
  const [data, setData] = useState({});
  const [showButtoReccord, setShowButtoReccord] = useState(false);
  const [showLoading, setShowLoading] = useState();
  const [showText2, setShowText2] = useState(true);
  const [user, setUser] = useState({});
  const [muteVoice, setMuteVoice] = useState(
    localStorage.getItem('daily_mute') === '1' ? true : false,
  );
  const { id } = useParams();

  const markDailyConversationMutation = useMutation({
    mutationFn: () => markShowDailyPopupConversatoin(),
  });

  const handleDailyConversationMutation = useMutation({
    mutationFn: (payload) => createDailyConversation(payload),
    onSuccess: (data) => {
      setDailyConversation(data);
    },
  });

  const getCustomerMutation = useMutation({
    mutationFn: () => findCustomer(),
    onSuccess: ({ data }) => {
      setUser(data);

      if (!data?.isDailyTutoroalPopUpShow) {
        setShowPopUpTutorial(() => true);
      } else {
        setShowPopUpTutorial(() => false);
      }
    },
  });

  const handleCloseTutorial = () => {
    setShowPopUpTutorial(() => false);
    markDailyConversationMutation.mutate();
  };

  useEffect(() => {
    const payload = {
      news: id,
    };

    handleDailyConversationMutation.mutate(payload);
    getCustomerMutation.mutate();
  }, []);

  useEffect(() => {
    window.document.title = 'Synthi Ai - chat';
  }, []);

  return (
    <>
      {showPopUpTutorial === true && (
        <DailyTutorial handleClose={handleCloseTutorial} />
      )}
      <Wrapper>
        <NewTopTileHeader
          source={SOURCES_TYPES.DAILY_V2}
          image={user?.speaker?.icon?.base64}
          name={user?.speaker?.name}
          backTo={'/learn'}
          muteVoice={muteVoice}
          setMuteVoice={setMuteVoice}
          showVoiceMute={true}
          centerDesktop={true}
          setShowPopUpTutorial={setShowPopUpTutorial}
        />
        <DemoSectionWrapper>
          <ContentWrapper>
            <DemoConversationContext.Provider
              value={{
                data,
                setData,
                showButtoReccord,
                setShowButtoReccord,
                showLoading,
                setShowLoading,
                setPageNumber,
                showText2,
                setShowText2,
                dailyConversation,
                muteVoice,
                showPopUpTutorial,
                loadingCustomer: getCustomerMutation.isLoading,
              }}
            >
              <MessageBox id="chat-box">
                <TalkiMessage />
                <div id="bottom-info" />
              </MessageBox>
            </DemoConversationContext.Provider>
          </ContentWrapper>
        </DemoSectionWrapper>
      </Wrapper>
    </>
  );
};

export default Daily;
