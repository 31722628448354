import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import Visa from './images/Visa blue.svg';
import Master from './images/mastercard.svg';
import Blik from './images/blik.svg';
import Transfer from './images/przelewy.svg';
import Payu from './images/payou.logo.svg';
import { useMutation } from 'react-query';
import { createCustomerOneTimeOrderFromApp } from '@/api/query/payu';
import { IP_STATS_ENUM } from '@/common/enums/ipStatsEnum';
import { IpStatsContext } from '@/common/contexts/IpStatsContext';
import { english } from '@/common/data/english';
import SynthiAiSumImgage from './images/synthi_summary.png';
import Lock from './images/secured-lock.svg';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import userManager from '@/api/userManager';

const PaymentIcon = styled.img`
  width: 60px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75vw;
  background: #fff;
  padding: 10px 30px;

  border-radius: 10px;

  @media (min-width: 600px) {
    display: none;
  }
`;
const Title = styled.h3`
  color: #05275d;

  text-align: center;
  font-family: Work Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.56px;
  margin: 0;

  @media (max-width: 600px) {
    display: none;
  }
`;

const SubTitle = styled.p`
  color: #05275d;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;

  @media (min-width: 600px) {
    text-align: left;
    color: #05275d;
    font-family: 'Work Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

const Price = styled.p`
  color: #05275d;
  font-family: 'Work Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.4px;
`;

const Pacakge = styled.div`
  display: flex;
  padding: 10px;
  gap: 20px;

  @media (min-width: 600px) {
    padding: 10px 0px 20px 0;
  }
`;

const SubPacakge = styled.p`
  color: #05275d;

  text-align: center;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  width: 100%;
  text-align: left;
`;

const ImgeWrapper = styled.img`
  max-width: 40px;
`;

const DataText = styled.p`
  color: #05275d;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  width: 100%;
  margin: 0;
`;

const Border = styled.div`
  height: 1px;
  width: 100%;
  margin-top: 10px;
  background: #d9d9d9;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  border-radius: 5px;
  border: 1px solid rgba(79, 167, 180, 0.2);
  background: #fff;
  padding: 5px;
`;

const ActionWrapper = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 0px 0;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`;

const LetMailButton = styled.button`
  display: inline-flex;
  padding: 9px 82px 10px 82px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  background: linear-gradient(91deg, #4f17aa 1.48%, #ac02f1 99.24%);
`;

const PayuLink = styled.a`
  color: #05275d;
`;

const TitleMobile = styled.div`
  color: #05275d;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.56px;
  margin: 20px 0;

  @media (min-width: 600px) {
    @media (min-width: 600px) {
      color: #29185e;
      text-align: center;
      font-family: 'Work Sans';
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
`;
const TopPlanData = styled.div`
  color: #05275d;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 5px;
`;

const BottomPlanData = styled.div`
  color: #05275d;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const LinkWrapper = styled.div`
  color: #05275d;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  /* Hide the default checkbox */
  input[type='checkbox'] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 15px;
    height: 15px;
    border: 2px solid #29185e;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }

  /* Style the checkbox when checked */
  input[type='checkbox']:checked {
    background-color: #29185e;
  }

  /* Create the checkmark using a pseudo-element */
  input[type='checkbox']:checked::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  input[type='checkbox']:focus {
    box-shadow: 0 0 3px #29185e;
  }
`;

const MainWrapperContent = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    height: calc(var(--vh, 1vh) * 100 - 100px);
  }
`;

const PaymentTitle = styled.p`
  color: #05275d;
  font-family: 'Work Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const WrapperDesktop = styled.div`
  display: flex;
  gap: 40px;
  margin-bottom: 50px;
  margin: 20px auto;

  @media (max-width: 600px) {
    display: none;
  }
`;

const Left = styled.div``;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ProfilePayment = ({ userData }) => {
  const data = useLanguageData('register.stepThree');
  const common = useLanguageData('common');

  const { handleSendIPstats } = useContext(IpStatsContext);

  const handlePickPlanFromAppMutation = useMutation({
    mutationFn: (value) => createCustomerOneTimeOrderFromApp(value),
    onSuccess: (response) => {
      handleSendIPstats(IP_STATS_ENUM.APP_PROFILE_YOURPLAN_BUY_PLAN_SUMMARY);
      window.location.replace(response.data);
    },
  });

  const handleCreateOrder = (e) => {
    e.preventDefault();

    const payload = {
      full_price: userData.full_price * 100,
      type: userData.type.value,
    };

    handlePickPlanFromAppMutation.mutate(payload);
  };

  useEffect(() => {
    handleSendIPstats(IP_STATS_ENUM.APP_PROFILE_YOURPLAN_BUY_PLAN);
  }, []);

  return (
    <MainWrapperContent>
      <TitleMobile>{data.title}</TitleMobile>
      <Wrapper>
        <SubTitle>{data.data}</SubTitle>
        <DataText style={{ marginTop: '10px' }}>
          {userManager.getUser().name} {userManager.getUser().surname}
        </DataText>
        <DataText>{userManager.getUser().email}</DataText>
        <Border style={{ marginTop: '20px' }} />
        <Pacakge>
          <div style={{ width: '90px' }}>
            <img
              src={SynthiAiSumImgage}
              style={{ width: '100%', height: 'auto' }}
            />
          </div>
          <div>
            <TopPlanData>
              {data.plan} {userData?.type?.label}
            </TopPlanData>
            <BottomPlanData>
              {data.forLong} {userData?.inAppPrice?.label}
            </BottomPlanData>
          </div>
        </Pacakge>
        <Border style={{ marginTop: '-5px' }} />
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <SubTitle>{data.toPay}</SubTitle>
          <Price>{userData?.inAppPrice?.promotion_full_price_info}</Price>
        </div>
      </Wrapper>
      <WrapperDesktop>
        <Left>
          <div style={{ width: '200px' }}>
            <img
              src={SynthiAiSumImgage}
              style={{ width: '100%', height: 'auto' }}
            />
          </div>
        </Left>
        <Right>
          <SubTitle>{data.data}</SubTitle>
          <DataText style={{ marginTop: '0px' }}>
            {userManager.getUser().name} {userManager.getUser().surname}
          </DataText>
          <DataText style={{ marginTop: '-5px' }}>
            {userManager.getUser().email}
          </DataText>
          <Border style={{ marginTop: '20px' }} />
          <Pacakge>
            <div>
              <TopPlanData>
                {data.plan} {userData?.type?.label}
              </TopPlanData>
              <BottomPlanData>
                {data.forLong} {userData?.inAppPrice?.label}
              </BottomPlanData>
            </div>
          </Pacakge>
          <Border style={{ marginTop: '-5px' }} />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              marginBottom: '-10px',
            }}
          >
            <SubTitle>{data.toPay}</SubTitle>
            <Price>{userData?.inAppPrice?.promotion_full_price_info}</Price>
          </div>
        </Right>
      </WrapperDesktop>
      <ActionWrapper onSubmit={(e) => handleCreateOrder(e)}>
        <LinkWrapper style={{ display: 'flex', alignItems: 'center' }}>
          <input type="checkbox" id="rules_2" required defaultChecked />
          <label
            htmlFor="rules_2"
            style={{
              cursor: 'pointer',
              display: 'flex',
              gap: '3px',
              padding: '0 0 0 5px ',
            }}
          >
            {data.accept}{' '}
            <div
              onClick={() => window.open(common.termsLink)}
              style={{ textDecoration: 'underline' }}
            >
              {' '}
              {data.rules}
            </div>
            SynthiAi
          </label>
        </LinkWrapper>
        <LinkWrapper
          htmlFor="rules_1"
          style={{
            marginTop: '0px',
            minWidth: '180px',
            fontSize: '12px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {data.payingAccept}{' '}
          <PayuLink
            href="https://static.payu.com/sites/terms/files/payu_terms_of_service_single_transaction_pl_pl.pdf"
            target="_blank"
            style={{ paddingLeft: '5px' }}
          >
            {' '}
            {data.paymentRules}
          </PayuLink>
          .
        </LinkWrapper>
        <LetMailButton
          type="submit"
          style={{ fontSize: '18px', margin: '5px 0' }}
        >
          {data.buyAndPay}
        </LetMailButton>
        <PaymentTitle>
          <img src={Lock} /> {english.register.plan.savePayment}{' '}
          <PaymentIcon src={Payu} style={{ width: '45px' }} />
        </PaymentTitle>
        <ImageWrapper style={{ marginTop: '-15px' }}>
          <PaymentIcon src={Blik} style={{ width: '30px' }} />
          <PaymentIcon
            src={Visa}
            style={{
              width: '30px',
            }}
          />
          <PaymentIcon
            src={Master}
            style={{
              width: '20px',
            }}
          />
          <PaymentIcon
            src={Transfer}
            style={{
              width: '45px',
            }}
          />
        </ImageWrapper>
      </ActionWrapper>
    </MainWrapperContent>
  );
};

export default ProfilePayment;
