import Api from "../api";
const { axios: api } = Api;
const queryString = require("query-string");

const PREFIX = "call-mode-config";

export const getCallModeConfigs = async (payload) => {
  const data = await api.get(`/${PREFIX}`, payload);
  return { data: data.data };
};

export const createCallModeConfig = async (payload) => {
  const data = await api.post(`/${PREFIX}/`, payload);
  return { data: data.data };
};

export const updateCallModeConfig = async (payload) => {
  const data = await api.patch(`/${PREFIX}/${payload._id}`, payload);
  return { data: data.data };
};
