import React from 'react';
import { Card, Title, InfoWrapper, P } from '../styles';
import { formatDate } from '@/pages/admin/mail/AdminMail';
import CustomEditButton from '@/common/components/custom/buttons/CustomEditButton';

const OrderDetails = ({ order, setEditOrder }) => (
  <Card>
    <Title>
      Order: <CustomEditButton onClick={() => setEditOrder(order)} />
    </Title>
    <InfoWrapper>
      <P>
        <p>Plan:</p>
        <b>{order.plan_type || '------'}</b>
      </P>
      <P>
        <p>Price:</p>
        <b>{(order.price / 100).toFixed(2) || '------'} zł</b>
      </P>
      <P>
        <p>Created At:</p>
        <b>{formatDate(order.created_at) || '------'}</b>
      </P>
      <P>
        <p>Expired At:</p>
        <b>{formatDate(order.expired_date) || '------'}</b>
      </P>
    </InfoWrapper>
  </Card>
);

export default OrderDetails;
