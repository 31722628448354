import React from 'react';
import CustomDeleteButton from '@/common/components/custom/buttons/CustomDeleteButton';

const Actions = ({
  setShowDecisionConfirmation,
  setAnyMail,
  setShowCancelSubscription,
}) => (
  <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
    <CustomDeleteButton
      text="Refund"
      onClick={() => setShowDecisionConfirmation('REFUND')}
    />
    <CustomDeleteButton text="Send Any Mail" onClick={() => setAnyMail(true)} />
    <CustomDeleteButton
      text="Cancel Subscription"
      onClick={() => setShowCancelSubscription(true)}
    />
  </div>
);

export default Actions;
