import PopUp from '@/common/components/PopUp';
import TextArea from '@/common/components/TextArea';
import Input from '@/common/components/custom/Input';
import SelectInput from '@/common/components/custom/SelectInput';
import CustomSaveButton from '@/common/components/custom/buttons/CustomSaveButton';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';

const CustomFrom = styled.form`
  padding: '50px';
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 20px;
`;

export const REFUNDATION_OPTIONS = [
  { label: 'INADEQUATE_LEVEL', value: 'INADEQUATE_LEVEL' },
  {
    label: 'EXPECTED_SOMETHING_DIFFERENT',
    value: 'EXPECTED_SOMETHING_DIFFERENT',
  },
  { label: 'OTHER', value: 'OTHER' },
  { label: 'NO_REASON', value: 'NO_REASON' },
];

const RefundPopUp = ({ handleDecision, setClose, customerEmail }) => {
  const commentRef = useRef();
  const descriptionRef = useRef();
  const [selectedReason, setSelectedReason] = useState();

  const handleConfirm = (e) => {
    e?.preventDefault();

    const payload = {
      reason: selectedReason?.value,
      otherReasonDescription: descriptionRef?.current?.value,
      comment: commentRef?.current?.value,
    };

    handleDecision(payload);
  };

  return (
    <PopUp setClose={setClose}>
      <CustomFrom onSubmit={handleConfirm}>
        <h3 style={{ color: 'red' }}>
          YOU ARE RETURNING REAL MONEY FOR {customerEmail} ARE YOU SURE?
        </h3>
        <SelectInput
          name={'Refundation option'}
          options={REFUNDATION_OPTIONS}
          selected={selectedReason}
          setSelected={setSelectedReason}
          width={250}
          selectWidth={500}
        />
        {selectedReason?.value === 'OTHER' && (
          <Input
            name="Other Reason description"
            inputRef={descriptionRef}
            required
            requiredSign
            width={250}
            inputWidth={475}
          />
        )}
        <TextArea
          textAreaRef={commentRef}
          placeholder="Type description if needed"
          label={'Type description if needed'}
          defaultValue={' '}
        />
        <ButtonWrapper>
          <CustomSaveButton text="Refund" />
        </ButtonWrapper>
      </CustomFrom>
    </PopUp>
  );
};

export default RefundPopUp;
