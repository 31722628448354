import { useMutation } from 'react-query';
import { sendUPsaleMail } from '@/api/query/mail';

export const useSendUpsale = (id, refetchCustomer) => {
  const mutation = useMutation(sendUPsaleMail, {
    onSuccess: () => {
      refetchCustomer();
    },
    onError: () => {
      // Handle error if needed
    },
  });

  return mutation;
};
