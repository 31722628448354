import React, { useMemo } from 'react';
import { TableWrapper } from '../styles';
import { formatDate } from '@/pages/admin/mail/AdminMail';
import DataTable from '@/common/components/custom/DataTable';

const IPSTATS_HEADERS = [
  {
    header: 'No.',
    accessorKey: 'no',
    size: 50,
  },
  {
    header: 'Type',
    accessorKey: 'type',
    size: 100,
  },
  {
    header: 'Device',
    accessorKey: 'device',
    size: 150,
  },
  {
    header: 'IP',
    accessorKey: 'ip',
    size: 150,
  },
  {
    header: 'UTMS',
    accessorKey: 'utms',
    size: 300,
    cell: ({ cell }) => (
      <
        // style={{
        //   maxWidth: '400px',
        //   whiteSpace: 'normal',
        //   wordBreak: 'break-word',
        // }}
      >
        {Object.entries(cell.getValue())
          .filter(([_, value]) => value)
          .map(([key, value]) => `${key}: ${value}`)
          .join(', ')}
      </>
    ),
  },
  {
    header: 'Created At',
    accessorKey: 'created_at',
    size: 150,
  },
];

const IpStatsTable = ({ ipStats = [] }) => {
  const sortedIpStats = useMemo(() => {
    return [...ipStats].sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    );
  }, [ipStats]);

  const tableData = useMemo(() => {
    return sortedIpStats.map((ipStat, index) => ({
      no: index + 1,
      type: ipStat.IpStatsType,
      device: ipStat.device_type,
      ip: ipStat.ip_name,
      utms: ipStat.utm,
      created_at: formatDate(ipStat.created_at),
    }));
  }, [sortedIpStats]);

  return (
    <TableWrapper>
      <h3>IP Stats:</h3>
      <DataTable
        columns={IPSTATS_HEADERS}
        data={tableData}
        rows={[5, 10, 25, 30, 35, 40, 100, 200]}
      />
    </TableWrapper>
  );
};

export default IpStatsTable;
