import React from 'react';
import styled from 'styled-components';
import FilledHeart from '../images/filled-heart.svg';
import BlankHeart from '../images/blank-heart.svg';
import { Link } from 'react-router-dom';
import { useLanguageData } from '@/common/hooks/useLanguageData';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 18px;
`;

const Title = styled(Link)`
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  text-align: left;
  width: 360px;
  margin-bottom: -5px;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 5px;
  text-decoration: none;
`;

const BoxWrapper = styled.div`
  display: flex;
  overflow: auto;
  scrollbar-color: transparent transparent;
  gap: 14px;
  margin-top: 14px;
`;

const Box = styled(Link)`
  position: relative;
  width: 140px;
  height: 140px;
  border-radius: 8px;
  background-color: white !important;
  box-shadow: 0px 0px 11px 0px rgba(79, 167, 180, 0.2);
`;

const ImageWrapper = styled.div`
  width: 140px;
  height: 140px;
  position: relative;
  overflow: hidden;
  z-index: 10;

  img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
  }
`;

const TopTitle = styled.div`
  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 123.5%;
  letter-spacing: 0.4px;
  position: absolute;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  div {
    max-width: 130px;
  }
`;

const Subtitle = styled(Link)`
  color: #29185e;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  text-decoration: none;
`;

const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(41, 24, 94, 0.5);
  border-radius: 8px;
`;

const Heart = styled.div`
  position: absolute;
  right: 5px;
  bottom: 0px;
  width: 25px;
  z-index: 2;

  img {
    width: 100%;
    height: auto;
  }
`;

const NewOne = styled.div`
  display: inline-flex;
  padding: 1px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 4px;
  background: #03da9a;

  color: #29185e;
  font-family: 'Work Sans';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 14px */
`;

const SceneDesktop = ({ data = [], liked = [], handleAddToFavorite }) => {
  const trasnaltion = useLanguageData('learn');

  return (
    <Wrapper>
      <Title to={`/learn/scenes`}>
        {trasnaltion.scene.title}{' '}
        {trasnaltion?.scene?.new && <NewOne>{trasnaltion.scene.new}</NewOne>}
      </Title>
      <NavWrapper>
        <Subtitle to={`/learn/scenes`}>{trasnaltion.scene.subtitle} </Subtitle>
      </NavWrapper>
      <BoxWrapper>
        {data.slice(0, 4).map((d) => (
          <Box to={`/learn/scenes/${d._id}`}>
            <ImageWrapper>
              <TopTitle>
                <div>{d.name}</div>
              </TopTitle>
              <img src={d.image.base64} alt={d.name} />
              <ImageOverlay />
              <Heart
                onClick={(e) => {
                  e?.preventDefault();
                  e?.stopPropagation();
                  handleAddToFavorite(d._id, 'scenes');
                }}
              >
                <img src={liked.includes(d._id) ? FilledHeart : BlankHeart} />
              </Heart>
            </ImageWrapper>
          </Box>
        ))}
        <Box to={`/learn/scenes`}>
          <ImageWrapper>
            <TopTitle>
              <div>{trasnaltion.seeMore}</div>
            </TopTitle>
            <ImageOverlay
              style={{
                background: '#29185E',
              }}
            />
          </ImageWrapper>
        </Box>
      </BoxWrapper>
    </Wrapper>
  );
};

export default SceneDesktop;
