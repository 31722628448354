import React, { createContext, useContext, useState } from 'react';

const CustomerContext = createContext();

export const CustomerProvider = ({ children }) => {
  const [data, setData] = useState();
  const [editOrder, setEditOrder] = useState(null);
  const [editPayment, setEditPayment] = useState(null);
  const [showDecisionConfirmation, setShowDecisionConfirmation] =
    useState(null);
  const [showCancelSubscription, setShowCancelSubscription] = useState(false);
  const [anyMail, setAnyMail] = useState(false);
  const [userEdit, setUserEdit] = useState();

  return (
    <CustomerContext.Provider
      value={{
        data,
        setData,
        userEdit,
        setUserEdit,
        editOrder,
        setEditOrder,
        editPayment,
        setEditPayment,
        showDecisionConfirmation,
        setShowDecisionConfirmation,
        showCancelSubscription,
        setShowCancelSubscription,
        anyMail,
        setAnyMail,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export const useCustomerContext = () => useContext(CustomerContext);
