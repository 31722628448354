import { findOrdersLast } from '@/api/query/order';
import CustomBar from '@/common/charts/Bar';
import { Color } from '@/common/colors/colors';
import { PLAN_TYPE } from '@/pages/register/register.constants';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

const UsageStats = () => {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  const handleData = (data) => {
    const plan = {
      basic: data.reduce(
        (prev, next) => prev + (next.plan_type === PLAN_TYPE.BASIC ? 1 : 0),
        0,
      ),
      pro: data.reduce(
        (prev, next) => prev + (next.plan_type === PLAN_TYPE.PRO ? 1 : 0),
        0,
      ),
      platinum: data.reduce(
        (prev, next) => prev + (next.plan_type === PLAN_TYPE.PREMIUM ? 1 : 0),
        0,
      ),
    };

    return {
      labels: ['PLANS'],
      datasets: [
        {
          label: 'BASIC',
          data: [plan.basic],
          backgroundColor: Color.Dark,
        },
        {
          label: 'PRO',
          data: [plan.pro],
          backgroundColor: Color.Blue,
        },
        {
          label: 'PLATINUM',
          data: [plan.platinum],
          backgroundColor: 'darkgreen',
        },
      ],
    };
  };

  const findDataMutation = useMutation({
    mutationFn: (value) => findOrdersLast(value),
    onSuccess: ({ data }) => {
      setChartData(handleData(data));
    },
  });

  useEffect(() => {
    const payload = {};
    payload.last_days = 30;

    findDataMutation.mutate(payload);
  }, []);

  return (
    <div>
      Plans Stats -30 days
      <CustomBar data={chartData} />
    </div>
  );
};

export default UsageStats;
