import CustomAddButton from '@/common/components/custom/buttons/CustomAddButton';
import React, { useContext, useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { useMutation } from 'react-query';
import CustomEditButton from '@/common/components/custom/buttons/CustomEditButton';
import Loading from '@/common/components/Loading';
import DataTable from '@/common/components/custom/DataTable';
import { getNotiFications } from '@/api/query/notification';
import AdminPaymentTypeEdit from './components/AdminPaymentTypeEdit';
import { sendWebPush } from '@/api/query/web-push';
import { MessageContext } from '@/common/contexts/MessageContext';
import { getPaymentTypes } from '@/api/query/paymentType';
import { getFormatedFullDate } from '@/common/functions/getFormatedFullDate';

const TopContent = styled.div`
  display: flex;
  justify-content: right;
`;
const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

const AdminPaymentType = () => {
  const [tableData, setTableData] = useState([]);

  const HEADERS = [
    {
      header: 'no.',
      accessorKey: 'no',
      size: 20,
    },
    {
      header: 'Created at',
      accessorKey: 'created_at',
      size: 20,
    },
    {
      header: 'Label',
      accessorKey: 'label',
      size: 20,
    },
    {
      header: 'How many months',
      accessorKey: 'for_months',
      size: 20,
    },
    {
      header: 'Value',
      accessorKey: 'value',
      size: 20,
    },
    {
      header: 'Actions',
      accessorKey: 'actions',
      size: 20,
      cell: (info) => (
        <div style={{ display: 'flex', gap: '10px' }}>
          <CustomEditButton onClick={(e) => setEdit(info.getValue())} />
        </div>
      ),
    },
  ];

  const [edit, setEdit] = useState();

  const getNotiFicationsMutation = useMutation({
    mutationFn: () => getPaymentTypes(),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data.map((d, i) => ({
          no: i + 1,
          created_at: getFormatedFullDate(d.created_at),
          label: d.label,
          value: d.value,
          for_months: d.for_months,
          actions: d,
        })),
      );
    },
  });

  useEffect(() => {
    if (!edit) {
      getNotiFicationsMutation.mutate();
    }
  }, [edit]);

  return (
    <Wrapper>
      {getNotiFicationsMutation.isLoading && <Loading />}
      {edit && <AdminPaymentTypeEdit edit={edit} setEdit={setEdit} />}
      <TopContent>
        <CustomAddButton onClick={() => setEdit({})} />
      </TopContent>
      <TableWrapper>
        <DataTable columns={HEADERS} data={tableData} rows={[10, 15, 20]} />
      </TableWrapper>
    </Wrapper>
  );
};

export default AdminPaymentType;
