import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { useMutation } from 'react-query';
import Loading from '@/common/components/Loading';
import DataTable from '@/common/components/custom/DataTable';
import moment from 'moment';
import { getWebPushSent } from '@/api/query/web-push';
import { getFormatedDate } from '@/common/functions/getFormatedDate';
import { getFormatedFullDate } from '@/common/functions/getFormatedFullDate';

const TopContent = styled.div`
  display: flex;
  justify-content: right;
`;
const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

const AdminWebPushNotificationSent = () => {
  const [tableData, setTableData] = useState([]);

  const HEADERS = [
    {
      header: 'no.',
      accessorKey: 'no',
      size: 20,
    },
    {
      header: 'Sender',
      accessorKey: 'sender',
      size: 20,
    },
    {
      header: 'Sent At',
      accessorKey: 'sent_at',
      size: 20,
    },
    {
      header: 'Recived At',
      accessorKey: 'recived_at',
      size: 20,
    },
    {
      header: 'Failed At',
      accessorKey: 'failed_at',
      size: 20,
    },
    {
      header: 'Click At',
      accessorKey: 'click_at',
      size: 20,
    },

    {
      header: 'Title',
      accessorKey: 'title',
      size: 20,
    },
    {
      header: 'Reciver',
      accessorKey: 'reciver',
      size: 20,
    },
  ];

  const findWebPushSentMutation = useMutation({
    mutationFn: () => getWebPushSent(),
    onSuccess: ({ data }) => {
      console.log(data);
      setTableData(() =>
        data.map((d, i) => ({
          no: i + 1,
          sent_at: getFormatedFullDate(d.sent_at),
          recived_at: getFormatedFullDate(d.recived_at),
          failed_at: getFormatedFullDate(d.failed_at),
          click_at: getFormatedFullDate(d.click_at),
          sender: d.sendBy?.email || '----',
          title: d.webPushNotification?.title || '----',
          reciver: d.webPush?.user?.email || '----',
        })),
      );
    },
  });

  useEffect(() => {
    findWebPushSentMutation.mutate();
  }, []);

  return (
    <Wrapper>
      {findWebPushSentMutation.isLoading && <Loading />}
      <TableWrapper>
        <DataTable columns={HEADERS} data={tableData} rows={[10, 15, 20]} />
      </TableWrapper>
    </Wrapper>
  );
};

export default AdminWebPushNotificationSent;
