import React, { useRef } from "react";
import { styled } from "styled-components";
import { Color } from "@/common/colors/colors";
import moment from "moment";
import Input from "@/common/components/custom/Input";
import ToggleSwitch from "@/common/components/ToggleSwitch";

const SelectBarContainer = styled.form`
  border-radius: 10px;
  background-color: ${Color.White};
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border: 1px dashed grey;
`;

const SearchButton = styled.button`
  padding: 8px 16px;
  background-color: ${Color.DarkBlue};
  color: white;
  border: none;
  font-size: 16px;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  margin-left: 20px;

  &:hover {
    background-color: ${Color.Grey};
  }
`;

const SearchBar = ({ handleSearch, toogleRef }) => {
  const today = moment().format("YYYY-MM-DD");

  const fromRef = useRef();
  const toRef = useRef();
  const campaignRef = useRef();
  const mediumRef = useRef();
  const sourceRef = useRef();
  const contentRef = useRef();
  const pageNameRef = useRef();

  const handleSearchCheck = (e) => {
    e?.preventDefault();

    const payload = {};

    payload.campaign = campaignRef.current.value;
    payload.medium = mediumRef.current.value;
    payload.source = sourceRef.current.value;
    payload.content = contentRef.current.value;
    payload.pageName = pageNameRef.current.value;
    payload.from = moment(fromRef.current.value).format("YYYY-MM-DD");
    payload.to = moment(toRef.current.value).format("YYYY-MM-DD");

    handleSearch(payload);
  };

  return (
    <>
      <SelectBarContainer onSubmit={handleSearchCheck}>
        <div>
          <Input
            type="date"
            inputRef={fromRef}
            name="From date"
            inputWidth={120}
            width={100}
            required={true}
            value={today}
          />
          <Input
            type="date"
            inputRef={toRef}
            name="To date"
            inputWidth={120}
            width={100}
            required={true}
            value={today}
          />
        </div>
        <div>
          <Input
            inputRef={campaignRef}
            name="Campaign"
            inputWidth={120}
            width={100}
          />
          <Input
            inputRef={mediumRef}
            name="Medium"
            inputWidth={120}
            width={100}
          />
        </div>
        <div>
          <Input
            inputRef={sourceRef}
            name="Source"
            inputWidth={120}
            width={100}
          />
          <Input
            inputRef={contentRef}
            name="Content"
            inputWidth={120}
            width={100}
          />
        </div>
        <div>
          <Input
            inputRef={pageNameRef}
            name="Page name"
            inputWidth={120}
            width={100}
          />
          <SearchButton type="submit">Search </SearchButton>
        </div>
      </SelectBarContainer>
    </>
  );
};

export default SearchBar;
