import {
  createWordLessonTopType,
  loadHobbyData,
  updateWordLessonTopType,
} from "@/api/query/word-lesson-top-type";
import Loading from "@/common/components/Loading";
import PopUp from "@/common/components/PopUp";
import Input from "@/common/components/custom/Input";
import CustomSaveButton from "@/common/components/custom/buttons/CustomSaveButton";
import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { styled } from "styled-components";
import UserProile from "@/common/icons/user-profile.svg";
import { getBase64 } from "@/common/functions/getBase64";
import CustomTable from "@/common/components/custom/CustomTable";
import Translations from "@/common/components/Translations";

const Title = styled.h2`
  text-align: center;
`;

const SaveWrapper = styled.div`
  margin: 20px 0 10px 0;
  display: flex;
  justify-content: right;
`;

const Form = styled.form`
  margin: 0px 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const ImageWrapper = styled.div`
  width: 100px;
  height: 100px;
  overflow: hidden;
`;

export const ImageInput = styled.input`
  display: none;
`;

const AdminWordLessonTopTypeEdit = ({ setEdit, edit }) => {
  const nameRef = useRef();
  const fileRef = useRef();
  const bigFileRef = useRef();
  const numberRef = useRef();
  const [hobbyData, setHobbyData] = useState({});
  const [translations, setTranslations] = useState(edit.translations || []);

  const [currentBase64, setCurrentBase64] = useState(
    edit.file?.base64 || UserProile
  );
  const [currentBigBase64, setCurrentBigBase64] = useState(
    edit.bigFile?.base64 || UserProile
  );

  const createWordLessonTopTypeMutation = useMutation({
    mutationFn: (value) => createWordLessonTopType(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const updateWordLessonTopTypeMutation = useMutation({
    mutationFn: (value) => updateWordLessonTopType(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const handleSave = async (e) => {
    e && e.preventDefault();

    const payload = edit;
    payload.base64 = currentBase64;
    payload.bigBase64 = currentBigBase64;
    payload.number = numberRef.current.value;
    payload.translations = translations;

    if (edit._id) {
      return updateWordLessonTopTypeMutation.mutate(payload);
    }

    createWordLessonTopTypeMutation.mutate(payload);
  };

  const handleSelectFile = async () => {
    const file = fileRef.current.files[0];
    const base64 = await getBase64(file);

    setCurrentBase64(base64);
  };

  const handleSelectBigFile = async () => {
    const file = bigFileRef.current.files[0];
    const base64 = await getBase64(file);
    setCurrentBigBase64(base64);
  };

  const loadHobbyDataMutation = useMutation({
    mutationFn: (data) => loadHobbyData(data),
    onSuccess: ({ data }) => {
      setHobbyData(data);
    },
  });

  useEffect(() => {
    loadHobbyDataMutation.mutate(edit._id);
  }, []);

  return (
    <>
      {(createWordLessonTopTypeMutation.isLoading ||
        updateWordLessonTopTypeMutation.isLoading) && <Loading />}
      <PopUp setClose={setEdit}>
        <Title>Wrod lesson top type {edit._id ? "Edit" : "Create"}</Title>
        <Form onSubmit={(e) => handleSave(e)} novalidate>
          <Translations
            translations={translations}
            setTranslations={setTranslations}
            name={"Translations"}
          />
          <Input
            inputRef={numberRef}
            name="Number"
            type="number"
            inputWidth={200}
            width={80}
            required
            requiredSign
            value={edit.number}
          />
          <div style={{ display: "flex", gap: "40px" }}>
            <div>
              <div>Small Image</div>
              <ImageWrapper>
                <ImageInput
                  id="file-input"
                  type="file"
                  ref={fileRef}
                  onChange={handleSelectFile}
                />
                <label htmlFor="file-input">
                  <img
                    alt=""
                    src={currentBase64}
                    style={{ width: "100%", height: "auto" }}
                  />
                </label>
              </ImageWrapper>
            </div>
            <div>
              <div>Big Image</div>
              <ImageWrapper>
                <ImageInput
                  id="file-input-2"
                  type="file"
                  ref={bigFileRef}
                  onChange={handleSelectBigFile}
                />
                <label htmlFor="file-input-2">
                  <img
                    alt=""
                    src={currentBigBase64}
                    style={{ width: "100%", height: "auto" }}
                  />
                </label>
              </ImageWrapper>
            </div>
          </div>
          <div style={{ display: "flex", gap: "40px", flexWrap: "wrap" }}>
            <div>
              <div>FlashCards:</div>
              <CustomTable
                headers={["no", "name", "image"]}
                data={hobbyData?.flashcards?.map((f, i) => [
                  i + 1,
                  f.name,
                  <div style={{ width: "100px" }}>
                    <img
                      src={f?.file?.base64}
                      style={{ width: "100%", height: "auto" }}
                    />
                  </div>,
                ])}
              />
            </div>
            <div>
              <div>Scenes:</div>
              <CustomTable
                headers={["no", "name", "image"]}
                data={hobbyData?.scenes?.map((f, i) => [
                  i + 1,
                  f.name,
                  <div style={{ width: "100px" }}>
                    <img
                      src={f?.image?.base64}
                      style={{ width: "100%", height: "auto" }}
                    />
                  </div>,
                ])}
              />
            </div>
            <div>
              <div>Words:</div>
              <CustomTable
                headers={["no", "name", "image"]}
                data={hobbyData?.words?.map((f, i) => [
                  i + 1,
                  f.name,
                  <div style={{ width: "100px" }}>
                    <img
                      src={f?.file?.base64}
                      style={{ width: "100%", height: "auto" }}
                    />
                  </div>,
                ])}
              />
            </div>
          </div>
          <SaveWrapper>
            <CustomSaveButton />
          </SaveWrapper>
        </Form>
      </PopUp>
    </>
  );
};

export default AdminWordLessonTopTypeEdit;
