import CustomTable from '@/common/components/custom/CustomTable';
import CustomAddButton from '@/common/components/custom/buttons/CustomAddButton';
import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { useMutation } from 'react-query';
import CustomEditButton from '@/common/components/custom/buttons/CustomEditButton';
import { getWordLessonTypeForCustomer } from '@/api/query/word-lesson-type';
import AdminWordLessonTypeEdit from './components/AdminWordLessonTypeEdit';
import Loading from '@/common/components/Loading';
import DataTable from '@/common/components/custom/DataTable';
import SelectInput from '@/common/components/custom/SelectInput';
import { LANG_OPTIONS } from '../translation/components/SearchBar';

const TopContent = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 10px;
`;
const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
`;
const ActionWrapper = styled.div`
  margin-bottom: 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;
const Title = styled.h3`
  text-align: center;
  color: #05275d;
  font-size: 24px;
  margin: 10px 0 0;
`;

const AdminWordLessonType = () => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [edit, setEdit] = useState();
  const [selected, setSelected] = useState(LANG_OPTIONS[0]);

  const handleSelect = (value) => {
    const currentData = data.map((d, i) => ({
      no: i + 1,
      name: d.translations.find((t) => t.languageShort === value.value)
        ?.translation,
      number: d.number,
      top_category: d?.wordLessonTopType?.name || '----',
      image: d.file?.base64,
      actions: d,
    }));

    setTableData(() => currentData);
    setSelected(() => value);
  };

  const HEADERS = [
    {
      header: 'no.',
      accessorKey: 'no',
      size: 20,
    },
    {
      header: 'Number',
      accessorKey: 'number',
      size: 20,
    },
    {
      header: 'Name',
      accessorKey: 'name',
      size: 20,
    },
    {
      header: 'Top category',
      accessorKey: 'top_category',
      size: 20,
    },
    {
      header: 'Image',
      accessorKey: 'image',
      size: 20,
      cell: (info) => (
        <div style={{ width: '50px', overflow: 'hidden' }}>
          <img
            src={info.getValue()}
            style={{ width: '100%', height: 'auto' }}
          />
        </div>
      ),
    },

    {
      header: 'Actions',
      accessorKey: 'actions',
      size: 20,
      cell: (info) => (
        <div style={{ display: 'flex', gap: '10px' }}>
          <CustomEditButton onClick={(e) => setEdit(info.getValue())} />
        </div>
      ),
    },
  ];

  const findLessonsMutation = useMutation({
    mutationFn: () => getWordLessonTypeForCustomer(),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data.map((d, i) => ({
          no: i + 1,
          name: d.translations.find((t) => t.languageShort === selected.value)
            ?.translation,
          number: d.number,
          top_category: d?.wordLessonTopType?.name || '----',
          image: d.file?.base64,
          actions: d,
        })),
      );
      setData(data);
    },
  });

  useEffect(() => {
    if (!edit) {
      findLessonsMutation.mutate();
    }
  }, [edit]);

  return (
    <>
      {findLessonsMutation.isLoading && <Loading />}
      <Wrapper>
        {edit && <AdminWordLessonTypeEdit edit={edit} setEdit={setEdit} />}
        <TopContent>
          <CustomAddButton onClick={() => setEdit({})} />
          <SelectInput
            options={LANG_OPTIONS}
            width={100}
            name={'Translation'}
            selected={selected}
            setSelected={handleSelect}
          />
        </TopContent>
        <TableWrapper>
          <DataTable columns={HEADERS} data={tableData} rows={[5, 10]} />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default AdminWordLessonType;
