import { cleanObject } from '@/common/functions/cleanObject';
import Api from '../api';
const { axios: api } = Api;
const queryString = require('query-string');

const PREFIX = 'ip-stats';

export const addIpStats = async (values) => {
  const data = await api.post(`/${PREFIX}`, values);
  return { data: data.data };
};

export const addFBStats = async (values) => {
  const data = await api.post(`/${PREFIX}/fb`, values);
  return { data: data.data };
};

export const getIpStatsByDates = async (payload) => {
  const data = await api.get(
    `/${PREFIX}?${queryString.stringify(cleanObject(payload), {
      arrayFormat: 'bracket',
    })}`,
  );
  return { data: data.data };
};
