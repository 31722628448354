import CustomAddButton from '@/common/components/custom/buttons/CustomAddButton';
import React, { useContext, useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { useMutation } from 'react-query';
import CustomEditButton from '@/common/components/custom/buttons/CustomEditButton';
import Loading from '@/common/components/Loading';
import DataTable from '@/common/components/custom/DataTable';
import { getNotiFications } from '@/api/query/notification';
import AdminNotificationEdit from './components/AdminNotificationEdit';
import { sendWebPush } from '@/api/query/web-push';
import { MessageContext } from '@/common/contexts/MessageContext';
import PopUp from '@/common/components/PopUp';
import SelectInput from '@/common/components/custom/SelectInput';
import AdminNotyficationTest from './components/AdminNotyficationTest';

const TopContent = styled.div`
  display: flex;
  justify-content: right;
`;
const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

const AdminNotification = () => {
  const [tableData, setTableData] = useState([]);
  const [showTest, setShowTest] = useState();

  const { addMessage } = useContext(MessageContext);

  const handleSendToClientMutation = useMutation({
    mutationFn: (value) => sendWebPush(value),
    onSuccess: ({ data }) => {
      addMessage('SENT', 'success');
    },
  });

  const HEADERS = [
    {
      header: 'no.',
      accessorKey: 'no',
      size: 20,
    },
    {
      header: 'Created at',
      accessorKey: 'created_at',
      size: 20,
    },
    {
      header: 'Body',
      accessorKey: 'body',
      size: 20,
    },
    {
      header: 'Title',
      accessorKey: 'title',
      size: 20,
    },
    {
      header: 'Actions',
      accessorKey: 'actions',
      size: 20,
      cell: (info) => (
        <div style={{ display: 'flex', gap: '10px' }}>
          <CustomEditButton onClick={(e) => setEdit(info.getValue())} />
          <CustomAddButton
            text="SEND"
            onClick={(e) =>
              handleSendToClientMutation.mutate({
                notyficationId: info.getValue()._id,
              })
            }
          />
          <CustomAddButton
            text="TEST"
            onClick={(e) => setShowTest(() => info.getValue())}
          />
        </div>
      ),
    },
  ];

  const [edit, setEdit] = useState();

  const getNotiFicationsMutation = useMutation({
    mutationFn: () => getNotiFications(),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data.map((d, i) => ({
          no: i + 1,
          created_at: d.created_at,
          body: d.body,
          title: d.title,
          actions: d,
        })),
      );
    },
  });

  useEffect(() => {
    if (!edit) {
      getNotiFicationsMutation.mutate();
    }
  }, [edit]);

  return (
    <>
      <Wrapper>
        {getNotiFicationsMutation.isLoading && <Loading />}
        {edit && <AdminNotificationEdit edit={edit} setEdit={setEdit} />}
        <TopContent>
          <CustomAddButton onClick={() => setEdit({})} />
        </TopContent>
        <TableWrapper>
          <DataTable columns={HEADERS} data={tableData} rows={[10, 15, 20]} />
        </TableWrapper>
      </Wrapper>
      {showTest && (
        <AdminNotyficationTest setClose={setShowTest} data={showTest} />
      )}
    </>
  );
};

export default AdminNotification;
