import Android1 from './images/Android_1_150_DE.png';
import Android2 from './images/Android_Dodaj_150_DE.png';
import Ios1 from './images/IOS_1_150_DE.png';
import Ios2 from './images/IOS_Dodaj_150_DE.png';
import Last from './images/ios_android_3.png';

import VolumeUp from './images/dailyTutorial/volume-up.svg';
import HandsUp from './images/dailyTutorial/hand-up.svg';
import VolumeUpBlank from './images/dailyTutorial/volume-up-blank.svg';
import TranslationBlank from './images/dailyTutorial/icon-translate-blank.svg';
import HintBlank from './images/dailyTutorial/idea-blank.svg';
import InfoBlank from './images/dailyTutorial/information-blank.svg';
import GreenCircle from './images/dailyTutorial/green-circle.svg';
import YellowCircle from './images/dailyTutorial/yelow-circle.png';
import Microphone from './images/dailyTutorial/icon-microphone.svg';
import ScreenOne from './images/dailyTutorial/samouczek_czat_1.png';
import ScreenTwo from './images/dailyTutorial/samouczek_czat_2.png';
import ScreenThree from './images/dailyTutorial/samouczek_czat_3.png';
import ScreenFour from './images/dailyTutorial/samouczek_czat_4.png';
import Tutorial from './images/dailyTutorial/samouczek-fiolet.svg';

export default {
  passwordRestart: {
    title: 'Passwort zurücksetzen',
    password: 'Passwort',
    repeatPassword: 'Passwort wiederholen',
    restartPawssord: 'Passwort zurücksetzen',
  },

  paywall: {
    title: 'Wählen Sie einen Plan, um weiter zu lernen',
    buy: 'Kaufen',
    savePayment: 'Sichere Zahlungen werden verarbeitet von',
    skip: 'Überspringen',
    pickPlan: 'Plan auswählen',
    endPlanFirstPart: 'Ihr Zugang endet in',
    endPlanSecondPart: 'Tagen.',
    endPlanAditionalPart: 'Tag.',
  },
  paymentStatus: {
    veryfication: 'Zahlung wird noch verarbeitet...',
    title: 'Zahlung abgeschlossen',
    success: 'erfolgreich!',
    failure: 'fehlgeschlagen!',
    subtitle: 'In Kürze wirst du zur Anmeldeseite weitergeleitet.',
    textInfo: `Ihre Zahlung wird gerade verarbeitet. In wenigen Minuten erhalten Sie eine E-Mail mit Ihrem Registrierungspasswort. Bitte überprüfen Sie Ihren Posteingang sowie den Spam-Ordner. Um die Suche zu erleichtern, geben Sie am besten das Wort "synthiai" in das Suchfeld ein. Bei Problemen kontaktieren Sie uns bitte unter: contact@synthiaiapp.com.`,
    login: 'Zum Login',
  },
  register: {
    steps: {
      register: 'Registrierung',
      package: 'Paket',
      summarize: 'Zusammenfassung',
      step: 'Schritt ',
    },
    stepOne: {
      password: 'Passwort',
      errorGoogle: 'Melden Sie sich mit Ihrer E-Mail an',
      title: 'Registrierung',
      nameAndSurname: 'Vorname und Nachname',
      placeholderNameAndSurname: 'z.B. Adam Smith',
      email: 'E-Mail',
      placeholderEmail: 'z.B. beispiel@synthiaiapp.com',
      password: 'Passwort',
      start: 'KONTO ERSTELLEN',
      subtitle:
        'Das Passwort muss mindestens 8 Zeichen lang sein und Buchstaben, Großbuchstaben und Zahlen enthalten.',
      passwordError1: 'Das Passwort muss mindestens 8 Zeichen lang sein.',
      passwordError2:
        'Das Passwort muss mindestens 1 Großbuchstaben enthalten.',
      passwordError3:
        'Das Passwort muss mindestens 1 Kleinbuchstaben enthalten.',
      passwordError4: 'Das Passwort muss mindestens 1 Zahl enthalten.',
    },
    stepTwo: {
      title: 'Wählen Sie Ihren Plan aus',
      month: 'Monat',
      months: 'Monate',
      months2: 'Monate',
      value: '€/Monat',
      pick: 'wählen',
      nowPay: 'Jetzt bezahlen',
      saveOne: 'Sie sparen',
      buy: 'Kaufen',
      savePayment: 'Sichere Zahlungen werden unterstützt von',
    },
    stepThree: {
      title: 'Zusammenfassung',
      product: 'Produkt:',
      plan: 'Synthi Ai-Paket',
      forLong: 'Zugriff für',
      data: 'Daten:',
      nameAndSurname: 'Vorname und Nachname:',
      paymentForm: 'Zahlungsmethode:',
      toPay: 'Heute zu bezahlen:',
      accept: 'Ich akzeptiere die',
      rules: 'Regeln',
      payingAccept: 'Durch die Zahlung akzeptieren Sie die',
      paymentRules: '"PayU-Zahlungsrichtlinien"',
      buyAndPay: 'KAUFEN UND BEZAHLEN',
    },
  },
  login: {
    title: 'Anmeldung',
    email: 'E-Mail',
    password: 'Passwort',
    textSub: 'Passwort vergessen? Setzen Sie es zurück',
    restart: 'hier',
    restartPasswordText:
      'Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen. Das Passwort wird an Ihre E-Mail-Adresse gesendet.',
    placeholder: 'Geben Sie Ihre E-Mail-Adresse ein...',
    resetPassword: 'Passwort zurücksetzen',
    buttonText: 'Anmelden',
    errorLogin:
      'Überprüfen Sie, ob Ihr Passwort oder Ihre E-Mail-Adresse korrekt sind',
    passwordResterted:
      'Ihr Passwort wurde zurückgesetzt, überprüfen Sie Ihre E-Mails.',
    passwordError: 'Leider konnten wir Ihre E-Mail-Adresse nicht finden.',
  },
  navBar: {
    home: 'Start',
    learn: 'Lernen',
    profile: 'Profil',
  },
  home: {
    hello: 'Hallo',
    question: 'Worüber möchten Sie heute sprechen?',
    answer: 'Beginnen Sie ein Gespräch',
    forYou: 'Empfohlen für Sie',
    popularNow: 'Aktuell beliebt',
    doYouKnow: 'Wussten Sie, dass...?',
    funFact: 'Tagesfakt',
    new: 'Neu',
    newTitle: 'Szenen',
    newSubtitle:
      'Schlüpfen Sie in die Rolle und spielen Sie eine Szene mit einem KI-Tutor zu einem ausgewählten Thema nach.',
    yourFavorite: 'Ihre Favoriten',
  },
  learn: {
    chat: {
      title: 'Chat',
      subtitle: 'Sprechen Sie über jedes beliebige Thema.',
    },
    fotoMode: {
      title: 'Foto-Rätsel',
      subtitle: 'Beschreiben Sie das Bild so genau wie möglich.',
    },
    scene: {
      title: 'Szenen',
      subtitle: 'Spielen Sie eine Rolle mit einem KI-Tutor nach.',
      new: 'Neu',
    },
    word: {
      title: 'Wörter',
      subtitle: 'Erweitern Sie Ihren Wortschatz.',
    },
    flashcard: {
      title: 'Karteikarten',
      subtitle: 'Wiederholen und merken Sie sich Wörter.',
    },
    seeMore: 'Alle anzeigen',
  },
  common: {
    description:
      'Synthi AI - Ihr Intelligenter Assistent zum Englischlernen. Lernen Sie fließend Englisch zu sprechen, mit Hilfe künstlicher Intelligenz. Entdecken Sie einen neuen, effektiven Ansatz zum Sprachenlernen. Fangen Sie noch heute an!',
    deletedFromFavorite: 'Aus den Favoriten entfernt',
    addedToFavorite: 'Zu den Favoriten hinzugefügt',
    flashcards: 'Karteikarten',
    scenes: 'Szenen',
    words: 'Wörter',
    or: 'oder',
    continueWithFB: 'Nachfassen bei Facebook',
    privacyLink: '/Privacy_EN.pdf',
    termsLink: '/Terms_Conditions_EN.pdf',
  },
  profile: {
    days: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
    top: {
      profile: 'Profil',
      accessTo: 'Zugang zu:',
      profileImage: 'Profilbild',
      nameAndSurname: 'Vorname und Nachname',
      password: 'Passwort',
      change: 'Ändern',
      changeProfileImage: 'Profilbild ändern',
      changeNameAndSurname: 'Ändern Sie Ihren Vor- und Nachnamen',
      changeNameAndSurnamePlaceHolder: 'Geben Sie Ihren Vor- und Nachnamen ein',
      save: 'Speichern',
      changePassword: 'Ändern Sie Ihr Passwort',
      passwordInfo:
        'Das Passwort muss mindestens 8 Zeichen lang sein und Buchstaben, Großbuchstaben und Zahlen enthalten.',
      repeatPassword: 'Passwort wiederholen',
      passwordChanged: 'Passwort geändert',
      error: 'Hoppla, etwas ist schief gelaufen',
      nameChanged: 'Name geändert',
      profileChanged: 'Profil geändert',
    },
    plan: {
      subInfo: 'um Ihr Abonnement zu kündigen E-Mail contact@synthiaiapp.com',
      yourPlan: 'Dein Plan',
      extend: 'Verlängere deinen Plan',
      short_extend: 'Verlängern',
      currentPlan: 'Aktueller Plan:',
      activeToPlan: 'Aktiv bis:',
      currentPlanLong: 'Dein aktueller Plan',
    },
    middle: {
      yourMedals: 'Ihre Auszeichnungen',
      oneDay: 'Tag',
      twoDays: 'Tage',
      words: 'Wörter',
      learningTime: 'Lernzeit',
      streak: 'Streak',
      streakRecord: 'Streak-Rekord',
      yourRecord: 'Ihr Rekord:',
      currentRecord: 'Aktueller Stand:',
      words: 'Wörter',
      wordsInWeek: 'Anzahl der Wörter pro Woche:',
    },
    bottom: {
      yourTutor: 'Ihr KI-Tutor',
      change: 'Ändern',
      logout: 'Abmelden',
      FAQ: 'FAQ',
      shareOpinion: 'Meinung teilen',
      yourPlan: 'Ihr Plan',
      hobby: 'Interessen',
      learningSetting: 'Lerneinstellungen',
      back: 'Zurück',
      dayTarget: 'Tagesziel',
      languageLevel: 'Sprachniveau',
      changeYourLangLevel: 'Ändern Sie Ihr Sprachniveau.',
      pickNewTutor: 'Wählen Sie einen neuen Tutor',
      yourDecision:
        'Ihre Entscheidung beeinflusst den Stil und die Atmosphäre der Lektionen.',
      tutroChanged: 'Tutor geändert',
      error: 'Hoppla, etwas ist schief gelaufen',
      englishLevelChanged: 'Ihr Niveau wurde geändert',
      levelsOptions: [
        {
          name: 'Anfänger A1',
          description: 'Beginnen Sie Ihr Abenteuer mit der englischen Sprache',
          value: 'basic',
          borderRadius: '8px 8px 0 0',
        },
        {
          name: 'Anfänger A2',
          description:
            'Sie können grundlegende Ausdrücke in einfachen Gesprächen verstehen.',
          borderRadius: '0',
          value: 'beginner',
        },
        {
          name: 'Fortgeschritten B1',
          description:
            'Sie führen einfache, aber verständliche Gespräche zu bekannten Themen.',
          borderRadius: '0',
          value: 'intermediate',
        },
        {
          name: 'Fortgeschritten B2',
          description: 'Sie kommunizieren frei in den meisten Situationen',
          borderRadius: '0',
          value: 'advanced',
        },
        {
          name: 'Experte C1+',
          description:
            'Sie beherrschen die Sprache effektiv in komplexen Kontexten.',
          value: 'expert',
          borderRadius: '0 0 8px 8px ',
        },
      ],
      changeDailyTarget: 'Ändern Sie Ihr tägliches Lernziel.',
      deletedFromFavorite: 'Aus Favoriten entfernt',
      addedToFavorite: 'Zu Favoriten hinzugefügt',
      lessonTime: [
        {
          name: 'Schnelle Lektion ',
          dsecription: 'Perfekte Option für schnelles Lernen',
          time: '5 Minuten',
          value: 'five_minutes',
          borderRadius: '8px 8px 0 0',
        },
        {
          name: 'Standard-Sitzung',
          dsecription: 'Tolle Wahl für ausgewogenes Lernen',
          time: '10 Minuten',
          value: 'ten_minutes',
          borderRadius: '0',
        },
        {
          name: 'Intensives Training',
          dsecription: 'Beste Option für Enthusiasten',
          time: '15 Minuten',
          value: 'fifteen_minutes',
          borderRadius: '0 0 8px 8px ',
        },
      ],
      dailyTargetChanged: 'Tägliches Ziel geändert!',
      pickIntresets: 'Wählen Sie mindestens 3 Interessen aus.',
      activeTo: 'Aktiver Plan bis:',
      daysLeft: 'Verbleibende Tage im Plan:',
      opinionSubtitle:
        'Wir verbessern ständig unsere App. Teilen Sie uns Ihre Meinung mit, was wir in der App ändern können oder was Ihnen am besten gefällt.',
      startTyping: 'Schreiben Sie Ihre Meinung',
      faq: 'FAQ',
      send: 'Senden',
      withProblems: 'Bei Fragen/Problemen kontaktieren Sie uns unter:',
      contactMail: 'contact@synthiaiapp.com',
      faqConfig: [
        {
          id: 1,
          question: 'Mein Ton funktioniert nicht.',
          answer: `Wenn Sie Probleme mit dem Ton haben, beachten Sie bitte folgende Tipps: 
      
              Überprüfen Sie die Soundberechtigungen im Browser.
      
              Stellen Sie sicher, dass die Lautstärke Ihres Geräts angemessen ist.
      
              Überprüfen Sie die richtige Verbindung der Lautsprecher/Kopfhörer.
      
              Überprüfen Sie die Soundeinstellungen des Systems, um sicherzustellen, dass Apps nicht stummgeschaltet sind.
      
              Probieren Sie ein anderes Gerät aus oder wenden Sie sich an den technischen Support.
          `,
        },
        {
          id: 2,
          question: 'Ich kann meine Stimme nicht aufnehmen.',
          answer: `Wenn Sie Probleme mit der Aufnahme von Audio haben, beachten Sie bitte folgende Tipps: 
              
              Überprüfen Sie die Mikrofonberechtigungen im Browser.
      
              Stellen Sie sicher, dass der mobile Browser Zugriff auf das Mikrofon hat.
      
              Überprüfen Sie die korrekte Verbindung des Mikrofons.
      
              Verwenden Sie ein anderes Gerät oder wenden Sie sich an den technischen Support.
          `,
        },
        {
          id: 3,
          question: 'Kann ich die App auf mehr als einem Gerät verwenden?',
          answer: `Natürlich, melden Sie sich einfach auf jedem kompatiblen Gerät an, um ein nahtloses Lernerlebnis zu genießen.`,
        },
        {
          id: 4,
          question: 'Kann ich Synthi Ai offline verwenden?',
          answer: `Nein, Synthi Ai erfordert einen Webbrowser mit Internetzugang.`,
        },
        {
          id: 5,
          question: 'Wie ändere ich den KI-Tutor?',
          answer: `Gehen Sie zu "Tutor" in den Synthi Ai-Einstellungen. Entdecken und wählen Sie Ihren bevorzugten Tutor aus.`,
        },
        {
          id: 6,
          question: 'Wie kann ich den Zugang zur App nach Ablauf verlängern?',
          answer: `Sie können Ihren Plan nach dem Ablauf erneuern, indem Sie sich anmelden.`,
        },
        {
          id: 7,
          question: 'Kann ich eine Rechnung erhalten?',
          answer:
            'Kontaktieren Sie uns unter contact@synthiaiapp.com und geben Sie Ihre USt-IdNr. sowie Unternehmensdaten an.',
        },
        {
          id: 8,
          question: 'Wie kann ich den technischen Support kontaktieren?',
          answer:
            'Senden Sie eine E-Mail an contact@synthiaiapp.com und geben Sie Details zum Problem sowie Informationen zum Gerät und Browser an.',
        },
      ],
    },
    rules: 'Regeln',
    privacy: 'Datenschutzbestimmungen',
    span: ' und ',
  },
  welcome: {
    zeroScreen: {
      welcomeInApp: 'Willkommen bei der Synthi AI App!',
      start: "LOS GEHT'S",
      welcomeMobile: 'Willkommen',
      config: {
        chrome: {
          title: 'Installieren Sie die App auf Ihrem Android-Gerät.',
          text1: 'Klicken Sie im Chrome-Browser auf die',
          text1bold: ' Einstellungen',
          image1: Android1,
          text2: 'Klicken Sie dann auf',
          text2bold: ' Zur Startseite hinzufügen',
          image2: Android2,
          text3:
            'Fertig! Synthi AI befindet sich jetzt auf Ihrem Startbildschirm.',
          image3: Last,
        },
        safari: {
          title: 'Installieren Sie die App auf Ihrem iOS-Gerät.',
          text1: 'Klicken Sie im Safari-Browser auf die Schaltfläche',
          text1bold: ' Teilen',
          image1: Ios1,
          text2: 'Klicken Sie dann auf',
          text2bold: ' Zur Startseite hinzufügen',
          image2: Ios2,
          text3:
            'Fertig! Synthi AI befindet sich jetzt auf Ihrem Startbildschirm.',
          image3: Last,
        },
      },
    },
    firstScreen: {
      title: 'Wählen Sie Ihren Tutor!',
      subtitle:
        'Ihre Entscheidung beeinflusst den Stil und die Atmosphäre der Lektionen.',
      next: 'WEITER',
    },
    secondScreen: {
      title: 'Was ist Ihr aktuelles Englischniveau?',
      config: [
        {
          name: 'Anfänger A1',
          description: 'Beginnen Sie Ihr Abenteuer mit der englischen Sprache',
          value: 'basic',
        },
        {
          name: 'Anfänger A2',
          description:
            'Sie können grundlegende Informationen in einfachen, alltäglichen Gesprächen verstehen.',
          value: 'beginner',
        },
        {
          name: 'Fortgeschritten B1',
          description:
            'Sie führen einfache, aber verständliche Gespräche zu bekannten Themen.',
          value: 'intermediate',
        },
        {
          name: 'Fortgeschritten B2',
          description: 'Sie kommunizieren frei in den meisten Situationen',
          value: 'advanced',
        },
        {
          name: 'Experte C1+',
          description:
            'Sie beherrschen die Sprache effektiv in komplexen Kontexten.',
          value: 'expert',
        },
      ],
      next: 'WEITER',
    },
    thirdScreen: {
      title: 'Was sind Ihre Interessen?',
      subtitle: 'Wählen Sie mindestens 3 Interessen aus.',
      next: 'WEITER',
    },
    fourthScreen: {
      title: 'Wie viele Minuten pro Tag möchten Sie lernen?',
      subtitle: 'Wählen Sie Ihr tägliches Lernziel.',
      next: "LOS GEHT'S!",
      config: [
        {
          name: 'Schnelle Lektion ',
          dsecription: 'Perfekte Option für schnelles Lernen',
          time: '5 Minuten',
          value: 'five_minutes',
        },
        {
          name: 'Standard-Sitzung',
          dsecription: 'Tolle Wahl für ausgewogenes Lernen',
          time: '10 Minuten',
          value: 'ten_minutes',
        },
        {
          name: 'Intensives Training',
          dsecription: 'Beste Option für Enthusiasten',
          time: '15 Minuten',
          value: 'fifteen_minutes',
        },
      ],
    },
  },
  features: {
    topbar: {
      back: 'Zurück',
    },
    fotoMode: {
      welcome: {
        title: 'Foto-Rätsel',
        subtitle:
          'Beschreiben Sie, was Sie auf dem Bild sehen, mit möglichst vielen Details zu einem ausgewählten Thema.',
        start: 'Starten!',
      },
      info: {
        startTyping: 'Schreiben Sie...',
        newTask: 'Neue Aufgabe',
        send: 'Senden',
      },
    },
    flashcards: {
      title: 'Karteikarten',
      subtitleTop:
        'Lernen und behalten Sie neue Wörter dank interaktiver Flashcard-Lernmethoden.',
      addCategory: 'Fügen Sie Ihre Kategorie hinzu',
      subtitle: 'Geben Sie den Namen Ihrer Kategorie ein',
      type: 'geben Sie die Kategorie ein...',
      add: 'Hinzufügen',
      previous: 'Vorherige Karte',
      task: 'Aufgabe',
      changeLevel: 'Ändern Sie das Niveau',
      dontKnow: 'Ich weiß nicht',
      know: 'Ich weiß',
      pickPairs: 'Paare bilden',
      goodJob: 'Gute Arbeit!',
      titleEnd: 'Ihr Engagement ist beeindruckend',
      subtitleEnd: 'Sind Sie bereit für weitere Herausforderungen?',
      repeat: 'Kategorie wiederholen',
      other: 'Wählen Sie eine andere Kategorie',
      level: {
        title: 'Ändern Sie das Kartenstufen-Niveau',
        beginner: ' Anfänger A1-A2',
        intermediate: 'Fortgeschritten B1-B2',
        advance: 'Experte C1-C2',
        change: 'Ändern',
      },
    },
    scene: {
      title: 'Szenen',
      subtitle:
        'Schlüpfen Sie in die Rolle und spielen Sie eine Szene mit einem KI-Tutor zu einem ausgewählten Thema.',
      suggest: 'Neue Szene vorschlagen',
      talk: 'Sprechen',
      deletedFromFavorite: 'Aus Favoriten entfernt',
      addedToFavorite: 'Zu Favoriten hinzugefügt',
    },
    word: {
      title: 'Wörter',
      subtitle:
        'Erweitern Sie Ihren Wortschatz in Schlüsselbereichen des Gesprächs mit einem KI-Tutor.',
      suggest: 'Neues Wortthema vorschlagen',
      deletedFromFavorite: 'Aus Favoriten entfernt',
      addedToFavorite: 'Zu Favoriten hinzugefügt',
    },
  },
  cookie: {
    title: 'Cookie-Benachrichtigung',
    subtitle:
      'Diese Website verwendet Cookies, um Ihnen ein besseres Nutzungserlebnis zu bieten und personalisierte Inhalte bereitzustellen. Wenn Sie mehr darüber erfahren möchten, lesen Sie unsere ',
    link: 'Datenschutzrichtlinie',
    accept: 'AKZEPTIEREN',
    denied: 'ABLEHNEN',
  },
  talkingBar: {
    allowMicrofon: 'Bitte erlauben Sie den Zugriff auf das Mikrofon',
    typeMessage: 'Nachricht schreiben...',
    record: 'Aufnehmen',
    recording: 'Aufnahme',
  },
  hands: {
    giveUsInfo: 'Teilen Sie uns Ihre Meinung mit',
    thanksForGrading: 'Vielen Dank für Ihre Bewertung!',
    subtitle: 'Ihre Meinung ist uns wichtig!',
    subtitle2: 'Sagen Sie uns, was Sie von dieser Lektion halten:',
    skip: 'ÜBERSPRINGEN',
    send: 'SENDEN',
  },
  suggestConfig: {
    profile: {
      title: 'Teilen Sie Ihre Meinung mit!',
      subtitle:
        'Wir verbessern unsere App ständig. Teilen Sie uns mit, was wir in der App ändern können oder was Ihnen am besten gefällt.',
    },
    word: {
      title: 'Neues Wortthema vorschlagen',
      subtitle: '',
    },
    scene: {
      title: 'Neue Szene vorschlagen',
      subtitle: '',
    },
    startTyping: 'Anfangen zu tippen',
    send: 'SENDEN',
    thx: 'Meinung hinzugefügt! Danke!',
    error: 'Ups, etwas ist schief gelaufen',
  },
  checkout: {
    emailGuessText: 'Meinten Sie',
    leftTitle: 'Wählen Sie Ihren Plan',
    rightTitle: 'Vervollständigen Sie die Daten',
    subscribe: 'KAUFEN',
    total: 'Gesamt',
    currencySign: '€',
    accept: 'Ich akzeptiere die ',
    space: ' und ',
    email: 'E-Mail',
    placeHolderEmail: 'Geben Sie Ihre E-Mail-Adresse ein',
    rules: 'Nutzungsbedingungen',
    tos: 'Datenschutzrichtlinien',
  },
  dailyV2: {
    hint: {
      title: 'Hinweis',
      pick: 'Wählen',
    },
    feedback: {
      yourMessage: 'Ihre Nachricht',
      goodMessage: 'Korrekte Nachricht',
      explanation: 'Erklärung',
    },
    tutorial: {
      title: 'Wie benutzt man den Chat?',
      skip: 'Überspringen',
      next: 'WEITER',
      start: 'Starten',
      screenConf: [ScreenOne, ScreenTwo, ScreenThree, ScreenFour],
      config: [
        [
          { title: 'Klicken, um den Tutor stummzuschalten', image: VolumeUp },
          { title: 'Klicken, um Feedback zu geben', image: HandsUp },
          {
            title: 'Klicken Sie, um die Anleitung zu öffnen',
            image: Tutorial,
          },
        ],
        [
          { title: 'Klicken, um erneut abzuspielen', image: VolumeUpBlank },
          {
            title: 'Klicken, um die Übersetzung anzuzeigen',
            image: TranslationBlank,
          },
          { title: 'Klicken, um den Hinweis anzuzeigen', image: HintBlank },
          { title: 'Klicken, um ein Problem zu melden', image: InfoBlank },
        ],
        [
          { title: 'Ihre Antwort ist korrekt', image: GreenCircle },
          {
            title:
              'Klicken, um die korrigierte Antwort und Erklärung anzuzeigen',
            image: YellowCircle,
          },
        ],
        [
          {
            title:
              'Klicken Sie in das Textfeld, um eine Nachricht zu schreiben ',
          },
          {
            title: 'Klicken, um eine Nachricht aufzunehmen',
            image: Microphone,
          },
        ],
      ],
    },
  },
  problem: {
    title: 'Problem melden',
    subtitle: 'Beschreiben Sie das Problem bezüglich der KI-Nachricht',
    send: 'Senden',
    messageSend: 'Nachricht gesendet',
    error: 'Etwas ist schiefgelaufen, schreiben Sie uns :)',
  },
};
