import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { useMutation } from 'react-query';
import Loading from '@/common/components/Loading';
import moment from 'moment';
import { getHands } from '@/api/query/hands';
import { SOURCES_TYPES } from '@/common/components/customer/Hands';
import DataTable from '@/common/components/custom/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

const HEADERS = [
  {
    header: 'no.',
    accessorKey: 'no',
    size: 20,
  },
  {
    header: 'Created At',
    accessorKey: 'created_at',
    size: 20,
    cell: (info) => <div>{info.getValue()}</div>,
  },
  {
    header: 'Email',
    accessorKey: 'email',
    size: 20,
  },
  {
    header: 'Country',
    accessorKey: 'country',
    size: 20,
  },
  {
    header: 'Decision',
    accessorKey: 'decision',
    size: 20,
  },

  {
    header: 'Message',
    accessorKey: 'message',
    size: 20,
  },
  {
    header: 'Type',
    accessorKey: 'type',
    size: 20,
  },
  {
    header: 'Detail name',
    accessorKey: 'detail_name',
    size: 20,
  },
  {
    header: 'Actions',
    accessorKey: 'actions',
    size: 20,
    cell: (info) => (
      <div style={{ width: '50px', overflow: 'hidden', cursor: 'pointer' }}>
        <FontAwesomeIcon
          icon={faEye}
          onClick={() =>
            window.open(`/admin/customer/stats/${info.getValue()}`, '_blank')
          }
        />
      </div>
    ),
  },
];

export const formatDate = (d, rest) => {
  if (!d) {
    return '------';
  }

  return moment(d).format('DD/MM/YYYY HH:mm');
};

const AdminHands = () => {
  const [tableData, setTableData] = useState([]);

  const getDetailName = (detail) => {
    if (detail.type === SOURCES_TYPES.SCENE) {
      return detail.lesson.name;
    }
    if (detail.type === SOURCES_TYPES.WORD) {
      return detail.wordLessonType.name;
    }

    if (detail.chat_message) {
      return detail.chat_message;
    }
    return '------';
  };

  const findAllHandsMutation = useMutation({
    mutationFn: () => getHands(),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data
          ?.sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime(),
          )
          .map((d, i) => ({
            no: i + 1,
            created_at: formatDate(d.created_at, d),
            email: d.user.email,
            country: d.user.language,
            decision: d.hand_decision,
            message: d.message || '-----',
            type: d.type,
            detail_name: getDetailName(d),
            actions: d.user._id,
          })),
      );
    },
  });

  useEffect(() => {
    findAllHandsMutation.mutate();
  }, []);

  return (
    <>
      {findAllHandsMutation.isLoading && <Loading />}
      <Wrapper>
        <TableWrapper>
          <DataTable data={tableData} columns={HEADERS} />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default AdminHands;
