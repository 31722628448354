import Android1 from './images/Android_1_150_RO.png';
import Android2 from './images/Android_Dodaj_150_RO.png';
import Ios1 from './images/IOS_1_150_RO.png';
import Ios2 from './images/IOS_Dodaj_150_RO.png';
import Last from './images/ios_android_3.png';

import VolumeUp from './images/dailyTutorial/volume-up.svg';
import HandsUp from './images/dailyTutorial/hand-up.svg';
import VolumeUpBlank from './images/dailyTutorial/volume-up-blank.svg';
import TranslationBlank from './images/dailyTutorial/icon-translate-blank.svg';
import HintBlank from './images/dailyTutorial/idea-blank.svg';
import InfoBlank from './images/dailyTutorial/information-blank.svg';
import GreenCircle from './images/dailyTutorial/green-circle.svg';
import YellowCircle from './images/dailyTutorial/yelow-circle.png';
import Microphone from './images/dailyTutorial/icon-microphone.svg';
import ScreenOne from './images/dailyTutorial/samouczek_czat_1.png';
import ScreenTwo from './images/dailyTutorial/samouczek_czat_2.png';
import ScreenThree from './images/dailyTutorial/samouczek_czat_3.png';
import ScreenFour from './images/dailyTutorial/samouczek_czat_4.png';
import Tutorial from './images/dailyTutorial/samouczek-fiolet.svg';

export default {
  passwordRestart: {
    title: 'Resetează parola',
    password: 'Parolă',
    repeatPassword: 'Repetă parola',
    restartPawssord: 'Resetează parola',
  },

  paywall: {
    title: 'Alegeți un plan pentru a continua să învățați',
    buy: 'Cumpără',
    savePayment: 'Plăți sigure gestionate de',
    skip: 'Omite',
    pickPlan: 'Alegeți un plan',
    endPlanFirstPart: 'Accesul dvs. se va încheia în',
    endPlanSecondPart: 'zile.',
    endPlanAditionalPart: 'zi.',
  },
  paymentStatus: {
    veryfication: 'Plata este încă în curs de procesare...',
    title: 'Plata finalizată',
    success: 'cu succes!',
    failure: 'eșuat!',
    subtitle: 'În curând veți fi redirecționat către pagina de conectare.',
    textInfo: `Plata dumneavoastră este în curs de procesare. În câteva minute veți primi un e-mail cu parola de înregistrare. Vă rugăm să verificați atât inbox-ul, cât și folderul de spam. Pentru a facilita căutarea, vă recomandăm să introduceți cuvântul "synthiai" în câmpul de căutare. Dacă aveți probleme, vă rugăm să ne contactați la: contact@synthiaiapp.com.`,
    login: 'Mergi la autentificare',
  },
  register: {
    steps: {
      register: 'Înregistrare',
      package: 'Pachet',
      summarize: 'Rezumat',
      step: 'Pasul ',
    },
    stepOne: {
      password: 'Parolă',
      errorGoogle: 'Conectați-vă cu e-mailul dvs.',
      title: 'Înregistrare',
      nameAndSurname: 'Prenume și Nume',
      placeholderNameAndSurname: 'ex. Adam Smith',
      email: 'E-mail',
      placeholderEmail: 'ex. exemplu@synthiaiapp.com',
      password: 'Parolă',
      start: 'CREAȚI CONTUL',
      subtitle:
        'Parola trebuie să aibă cel puțin 8 caractere și să conțină litere, majuscule și cifre.',
      passwordError1: 'Parola trebuie să aibă cel puțin 8 caractere.',
      passwordError2: 'Parola trebuie să conțină cel puțin o literă majusculă.',
      passwordError3: 'Parola trebuie să conțină cel puțin o literă mică.',
      passwordError4: 'Parola trebuie să conțină cel puțin o cifră.',
    },
    stepTwo: {
      title: 'Selectați planul dvs.',
      month: 'Lună',
      months: 'Luni',
      months2: 'Luni',
      value: '€/Lună',
      pick: 'alege',
      nowPay: 'Plătiți acum',
      saveOne: 'Economisiți',
      buy: 'Cumpără',
      savePayment: 'Plăți sigure gestionate de',
    },
    stepThree: {
      title: 'Rezumat',
      product: 'Produs:',
      plan: 'Pachetul Synthi Ai',
      forLong: 'Acces pentru',
      data: 'Date:',
      nameAndSurname: 'Prenume și Nume:',
      paymentForm: 'Metoda de plată:',
      toPay: 'De plătit astăzi:',
      accept: 'Accept',
      rules: 'Regulile',
      payingAccept: 'Prin plata acceptați',
      paymentRules: '"Politicile de plată PayU"',
      buyAndPay: 'CUMPĂRĂ ȘI PLĂTEȘTE',
    },
  },
  login: {
    title: 'Autentificare',
    email: 'E-mail',
    password: 'Parolă',
    textSub: 'Ați uitat parola? Resetați-o',
    restart: 'aici',
    restartPasswordText:
      'Introduceți adresa dvs. de e-mail pentru a vă reseta parola. Parola va fi trimisă la adresa dvs. de e-mail.',
    placeholder: 'Introduceți adresa dvs. de e-mail...',
    resetPassword: 'Resetați parola',
    buttonText: 'Autentificare',
    errorLogin: 'Verificați dacă parola sau e-mailul dvs. sunt corecte',
    passwordResterted:
      'Parola dvs. a fost resetată, verificați-vă e-mailurile.',
    passwordError: 'Din păcate, nu am putut găsi adresa dvs. de e-mail.',
  },
  navBar: {
    home: 'Start',
    learn: 'Învățare',
    profile: 'Profil',
  },
  home: {
    hello: 'Bună',
    question: 'Despre ce doriți să vorbiți astăzi?',
    answer: 'Începeți o conversație',
    forYou: 'Recomandat pentru dvs.',
    popularNow: 'Popular acum',
    doYouKnow: 'Știați că...?',
    funFact: 'Faptul zilei',
    new: 'Nou',
    newTitle: 'Scene',
    newSubtitle:
      'Intrați în rol și jucați o scenă cu un tutor AI pe un subiect ales.',
    yourFavorite: 'Favoritele dvs.',
  },
  learn: {
    chat: {
      title: 'Chat',
      subtitle: 'Discutați despre orice subiect.',
    },
    fotoMode: {
      title: 'Puzzle foto',
      subtitle: 'Descrieți imaginea cât mai detaliat posibil.',
    },
    scene: {
      title: 'Scene',
      subtitle: 'Jucați un rol cu un tutor AI.',
      new: 'Nou',
    },
    word: {
      title: 'Cuvinte',
      subtitle: 'Extindeți-vă vocabularul.',
    },
    flashcard: {
      title: 'Fișe',
      subtitle: 'Repetați și memorați cuvinte.',
    },
    seeMore: 'Vezi tot',
  },
  common: {
    description:
      'Synthi AI - Asistentul dvs. inteligent pentru învățarea limbii engleze. Învățați să vorbiți fluent în engleză, cu ajutorul inteligenței artificiale. Descoperiți o nouă abordare eficientă a învățării limbilor străine. Începeți chiar astăzi!',
    deletedFromFavorite: 'Eliminat din favorite',
    addedToFavorite: 'Adăugat la favorite',
    flashcards: 'Fișe',
    scenes: 'Scene',
    words: 'Cuvinte',
    or: 'sau',
    continueWithFB: 'Continuă cu Facebook',
    privacyLink: '/Privacy_EN_2.pdf',
    termsLink: '/Terms_Conditions_EN_2.pdf',
  },
  profile: {
    days: ['Lu', 'Ma', 'Mi', 'Jo', 'Vi', 'Sâ', 'Du'],
    top: {
      profile: 'Profil',
      accessTo: 'Acces la:',
      profileImage: 'Imagine profil',
      nameAndSurname: 'Prenume și Nume',
      password: 'Parolă',
      change: 'Schimbă',
      changeProfileImage: 'Schimbă imaginea de profil',
      changeNameAndSurname: 'Schimbați prenumele și numele dvs.',
      changeNameAndSurnamePlaceHolder: 'Introduceți prenumele și numele dvs.',
      save: 'Salvează',
      changePassword: 'Schimbați parola',
      passwordInfo:
        'Parola trebuie să aibă cel puțin 8 caractere și să conțină litere, majuscule și cifre.',
      repeatPassword: 'Repetați parola',
      passwordChanged: 'Parola a fost schimbată',
      error: 'Ups, ceva a mers greșit',
      nameChanged: 'Numele a fost schimbat',
      profileChanged: 'Profilul a fost schimbat',
    },
    plan: {
      subInfo:
        'pentru a anula abonamentul trimiteți un e-mail la contact@synthiaiapp.com',
      yourPlan: 'Planul dvs.',
      extend: 'Prelungiți planul dvs.',
      short_extend: 'Prelungiți',
      currentPlan: 'Planul actual:',
      activeToPlan: 'Activ până la:',
      currentPlanLong: 'Planul dvs. actual',
    },
    middle: {
      yourMedals: 'Medaliile dvs.',
      oneDay: 'Zi',
      twoDays: 'Zile',
      words: 'Cuvinte',
      learningTime: 'Timp de învățare',
      streak: 'Serie',
      streakRecord: 'Record de serie',
      yourRecord: 'Recordul dvs.:',
      currentRecord: 'Stadiul actual:',
      words: 'Cuvinte',
      wordsInWeek: 'Număr de cuvinte pe săptămână:',
    },
    bottom: {
      yourTutor: 'Tutorul dvs. AI',
      change: 'Schimbă',
      logout: 'Deconectare',
      FAQ: 'Întrebări frecvente',
      shareOpinion: 'Împărtășiți opinia dvs.',
      yourPlan: 'Planul dvs.',
      hobby: 'Interese',
      learningSetting: 'Setări de învățare',
      back: 'Înapoi',
      dayTarget: 'Obiectiv zilnic',
      languageLevel: 'Nivel de limbă',
      changeYourLangLevel: 'Schimbați nivelul dvs. de limbă.',
      pickNewTutor: 'Alegeți un nou tutor',
      yourDecision: 'Decizia dvs. va influența stilul și atmosfera lecțiilor.',
      tutroChanged: 'Tutorul a fost schimbat',
      error: 'Ups, ceva a mers greșit',
      englishLevelChanged: 'Nivelul dvs. a fost schimbat',
      levelsOptions: [
        {
          name: 'Începător A1',
          description: 'Începeți aventura dvs. cu limba engleză',
          value: 'basic',
          borderRadius: '8px 8px 0 0',
        },
        {
          name: 'Începător A2',
          description:
            'Puteți înțelege expresii de bază în conversații simple.',
          borderRadius: '0',
          value: 'beginner',
        },
        {
          name: 'Intermediar B1',
          description:
            'Purtați conversații simple, dar inteligibile pe teme familiare.',
          borderRadius: '0',
          value: 'intermediate',
        },
        {
          name: 'Avansat B2',
          description: 'Comunicați liber în majoritatea situațiilor',
          borderRadius: '0',
          value: 'advanced',
        },
        {
          name: 'Expert C1+',
          description: 'Stăpâniți limba eficient în contexte complexe.',
          value: 'expert',
          borderRadius: '0 0 8px 8px ',
        },
      ],
      changeDailyTarget: 'Schimbați obiectivul dvs. zilnic de învățare.',
      deletedFromFavorite: 'Eliminat din favorite',
      addedToFavorite: 'Adăugat la favorite',
      lessonTime: [
        {
          name: 'Lecție rapidă',
          dsecription: 'Opțiune perfectă pentru învățare rapidă',
          time: '5 minute',
          value: 'five_minutes',
          borderRadius: '8px 8px 0 0',
        },
        {
          name: 'Sesiune standard',
          dsecription: 'Alegere excelentă pentru învățare echilibrată',
          time: '10 minute',
          value: 'ten_minutes',
          borderRadius: '0',
        },
        {
          name: 'Antrenament intens',
          dsecription: 'Cea mai bună opțiune pentru entuziaști',
          time: '15 minute',
          value: 'fifteen_minutes',
          borderRadius: '0 0 8px 8px ',
        },
      ],
      dailyTargetChanged: 'Obiectiv zilnic schimbat!',
      pickIntresets: 'Alegeți cel puțin 3 interese.',
      activeTo: 'Plan activ până la:',
      daysLeft: 'Zile rămase în plan:',
      opinionSubtitle:
        'Îmbunătățim constant aplicația noastră. Spuneți-ne ce putem schimba în aplicație sau ce v-a plăcut cel mai mult.',
      startTyping: 'Scrieți opinia dvs.',
      faq: 'Întrebări frecvente',
      send: 'Trimiteți',
      withProblems: 'Pentru întrebări/probleme contactați-ne la:',
      contactMail: 'contact@synthiaiapp.com',
      faqConfig: [
        {
          id: 1,
          question: 'Sunetul meu nu funcționează.',
          answer: `Dacă aveți probleme cu sunetul, vă rugăm să urmați aceste sfaturi: 
      
              Verificați permisiunile de sunet în browser.
      
              Asigurați-vă că volumul dispozitivului dvs. este corespunzător.
      
              Verificați conexiunea corectă a difuzoarelor/căștilor.
      
              Verificați setările de sunet ale sistemului pentru a vă asigura că aplicațiile nu sunt mute.
      
              Încercați un alt dispozitiv sau contactați suportul tehnic.
          `,
        },
        {
          id: 2,
          question: 'Nu îmi pot înregistra vocea.',
          answer: `Dacă aveți probleme cu înregistrarea audio, vă rugăm să urmați aceste sfaturi: 
              
              Verificați permisiunile pentru microfon în browser.
      
              Asigurați-vă că browser-ul mobil are acces la microfon.
      
              Verificați conexiunea corectă a microfonului.
      
              Folosiți un alt dispozitiv sau contactați suportul tehnic.
          `,
        },
        {
          id: 3,
          question: 'Pot folosi aplicația pe mai multe dispozitive?',
          answer: `Desigur, autentificați-vă pe fiecare dispozitiv compatibil pentru a vă bucura de o experiență de învățare fără întreruperi.`,
        },
        {
          id: 4,
          question: 'Pot folosi Synthi Ai offline?',
          answer: `Nu, Synthi Ai necesită un browser web cu acces la internet.`,
        },
        {
          id: 5,
          question: 'Cum îmi schimb tutorul AI?',
          answer: `Mergeți la "Tutor" în setările Synthi Ai. Descoperiți și alegeți tutorul preferat.`,
        },
        {
          id: 6,
          question: 'Cum îmi pot prelungi accesul după expirare?',
          answer: `Vă puteți reînnoi planul după expirare, autentificându-vă.`,
        },
        {
          id: 7,
          question: 'Pot primi o factură?',
          answer:
            'Contactați-ne la contact@synthiaiapp.com și furnizați codul dvs. fiscal și datele companiei.',
        },
        {
          id: 8,
          question: 'Cum pot contacta suportul tehnic?',
          answer:
            'Trimiteți un e-mail la contact@synthiaiapp.com și furnizați detalii despre problemă, precum și informații despre dispozitiv și browser.',
        },
      ],
    },
    rules: 'Reguli',
    privacy: 'Politica de confidențialitate',
    span: ' și ',
  },
  welcome: {
    zeroScreen: {
      welcomeInApp: 'Bun venit în aplicația Synthi AI!',
      start: 'SĂ ÎNCEPEM',
      welcomeMobile: 'Bun venit',
      config: {
        chrome: {
          title: 'Instalați aplicația pe dispozitivul dvs. Android.',
          text1: 'Apăsați pe butonul',
          text1bold: ' Setări',
          image1: Android1,
          text2: 'Apoi apăsați pe',
          text2bold: ' Adăugați pe ecranul de start',
          image2: Android2,
          text3: 'Gata! Synthi AI este acum pe ecranul dvs. de start.',
          image3: Last,
        },
        safari: {
          title: 'Instalați aplicația pe dispozitivul dvs. iOS.',
          text1: 'Apăsați pe butonul',
          text1bold: ' Distribuie',
          image1: Ios1,
          text2: 'Apoi apăsați pe',
          text2bold: ' Adăugați pe ecranul de start',
          image2: Ios2,
          text3: 'Gata! Synthi AI este acum pe ecranul dvs. de start.',
          image3: Last,
        },
      },
    },
    firstScreen: {
      title: 'Alegeți tutorul dvs.!',
      subtitle: 'Decizia dvs. va influența stilul și atmosfera lecțiilor.',
      next: 'CONTINUAȚI',
    },
    secondScreen: {
      title: 'Care este nivelul dvs. actual de engleză?',
      config: [
        {
          name: 'Începător A1',
          description: 'Începeți aventura dvs. cu limba engleză',
          value: 'basic',
        },
        {
          name: 'Începător A2',
          description:
            'Puteți înțelege informații de bază în conversații simple, de zi cu zi.',
          value: 'beginner',
        },
        {
          name: 'Intermediar B1',
          description:
            'Purtați conversații simple, dar inteligibile pe teme familiare.',
          value: 'intermediate',
        },
        {
          name: 'Avansat B2',
          description: 'Comunicați liber în majoritatea situațiilor',
          value: 'advanced',
        },
        {
          name: 'Expert C1+',
          description: 'Stăpâniți limba eficient în contexte complexe.',
          value: 'expert',
        },
      ],
      next: 'CONTINUAȚI',
    },
    thirdScreen: {
      title: 'Care sunt interesele dvs.?',
      subtitle: 'Alegeți cel puțin 3 interese.',
      next: 'CONTINUAȚI',
    },
    fourthScreen: {
      title: 'Câte minute pe zi doriți să învățați?',
      subtitle: 'Alegeți obiectivul dvs. zilnic de învățare.',
      next: 'SĂ ÎNCEPEM!',
      config: [
        {
          name: 'Lecție rapidă',
          dsecription: 'Opțiune perfectă pentru învățare rapidă',
          time: '5 minute',
          value: 'five_minutes',
        },
        {
          name: 'Sesiune standard',
          dsecription: 'Alegere excelentă pentru învățare echilibrată',
          time: '10 minute',
          value: 'ten_minutes',
        },
        {
          name: 'Antrenament intens',
          dsecription: 'Cea mai bună opțiune pentru entuziaști',
          time: '15 minute',
          value: 'fifteen_minutes',
        },
      ],
    },
  },
  features: {
    topbar: {
      back: 'Înapoi',
    },
    fotoMode: {
      welcome: {
        title: 'Puzzle foto',
        subtitle:
          'Descrieți ce vedeți în imagine, cu cât mai multe detalii pe un subiect ales.',
        start: 'Începeți!',
      },
      info: {
        startTyping: 'Scrieți...',
        newTask: 'Sarcină nouă',
        send: 'Trimiteți',
      },
    },
    flashcards: {
      title: 'Fișe',
      subtitleTop:
        'Învățați și memorați cuvinte noi prin metode interactive de învățare cu fișe.',
      addCategory: 'Adăugați categoria dvs.',
      subtitle: 'Introduceți numele categoriei dvs.',
      type: 'introduceți categoria...',
      add: 'Adăugați',
      previous: 'Fișa anterioară',
      task: 'Sarcină',
      changeLevel: 'Schimbați nivelul',
      dontKnow: 'Nu știu',
      know: 'Știu',
      pickPairs: 'Formează perechi',
      goodJob: 'Bună treabă!',
      titleEnd: 'Angajamentul dvs. este impresionant',
      subtitleEnd: 'Sunteți pregătit pentru alte provocări?',
      repeat: 'Repetați categoria',
      other: 'Alegeți o altă categorie',
      level: {
        title: 'Schimbați nivelul fișelor',
        beginner: 'Începător A1-A2',
        intermediate: 'Intermediar B1-B2',
        advance: 'Expert C1-C2',
        change: 'Schimbați',
      },
    },
    scene: {
      title: 'Scene',
      subtitle:
        'Intrați în rol și jucați o scenă cu un tutor AI pe un subiect ales.',
      suggest: 'Sugerați o scenă nouă',
      talk: 'Vorbiți',
      deletedFromFavorite: 'Eliminat din favorite',
      addedToFavorite: 'Adăugat la favorite',
    },
    word: {
      title: 'Cuvinte',
      subtitle:
        'Extindeți-vă vocabularul în domenii cheie ale conversației cu un tutor AI.',
      suggest: 'Sugerați un nou subiect de cuvinte',
      deletedFromFavorite: 'Eliminat din favorite',
      addedToFavorite: 'Adăugat la favorite',
    },
  },
  cookie: {
    title: 'Notificare privind cookie-urile',
    subtitle:
      'Acest site folosește cookie-uri pentru a vă oferi o experiență mai bună și pentru a oferi conținut personalizat. Dacă doriți să aflați mai multe, citiți ',
    link: 'Politica noastră de confidențialitate',
    accept: 'ACCEPT',
    denied: 'REFUZ',
  },
  talkingBar: {
    allowMicrofon: 'Vă rugăm să permiteți accesul la microfon',
    typeMessage: 'Scrieți un mesaj...',
    record: 'Înregistrare',
    recording: 'Înregistrare în curs',
  },
  hands: {
    giveUsInfo: 'Împărtășiți-ne opinia dvs.',
    thanksForGrading: 'Vă mulțumim pentru evaluare!',
    subtitle: 'Opinia dvs. este importantă pentru noi!',
    subtitle2: 'Spuneți-ne ce părere aveți despre această lecție:',
    skip: 'OMITEȚI',
    send: 'TRIMITEȚI',
  },
  suggestConfig: {
    profile: {
      title: 'Împărtășiți opinia dvs.!',
      subtitle:
        'Îmbunătățim constant aplicația noastră. Spuneți-ne ce putem schimba în aplicație sau ce v-a plăcut cel mai mult.',
    },
    word: {
      title: 'Sugerați un nou subiect de cuvinte',
      subtitle: '',
    },
    scene: {
      title: 'Sugerați o scenă nouă',
      subtitle: '',
    },
    startTyping: 'Începeți să scrieți',
    send: 'TRIMITEȚI',
    thx: 'Opinia a fost adăugată! Mulțumim!',
    error: 'Ups, ceva a mers greșit',
  },
  checkout: {
    emailGuessText: 'Ați vrut să spuneți',
    leftTitle: 'Alegeți un plan pentru dvs.',
    rightTitle: 'Completați datele',
    email: 'Email',
    placeHolderEmail: 'Introduceți emailul',
    pay: 'CUMPĂR ȘI PLĂTESC',
    accept: 'Accept',
    rules: 'regulamentul',
    space: 'și',
    tos: 'politica de confidențialitate',
    payingAccept: 'Prin plată, acceptați',
    paymentRules: '"Regulile de plată PayU"',
    savePayments: 'Plăți sigure asigurate de',
    total: 'Total',
    currency: 'Lei',
  },
  dailyV2: {
    hint: {
      title: 'Sfat',
      pick: 'Alege',
    },
    feedback: {
      yourMessage: 'Mesajul tău',
      goodMessage: 'Mesaj corect',
      explanation: 'Explicație',
    },
    tutorial: {
      title: 'Cum să folosești chat-ul?',
      skip: 'Sari peste',
      next: 'URMĂTOR',
      start: 'Începe',
      screenConf: [ScreenOne, ScreenTwo, ScreenThree, ScreenFour],
      config: [
        [
          {
            title: 'Apasă pentru a dezactiva sunetul Tutorului',
            image: VolumeUp,
          },
          { title: 'Apasă pentru a oferi feedback', image: HandsUp },
          {
            title: 'Faceți clic pentru a deschide instrucțiunile',
            image: Tutorial,
          },
        ],
        [
          { title: 'Apasă pentru a reda din nou', image: VolumeUpBlank },
          {
            title: 'Apasă pentru a vedea traducerea',
            image: TranslationBlank,
          },
          { title: 'Apasă pentru a vedea sfatul', image: HintBlank },
          { title: 'Apasă pentru a raporta o problemă', image: InfoBlank },
        ],
        [
          { title: 'Răspunsul tău este corect', image: GreenCircle },
          {
            title: 'Apasă pentru a vedea răspunsul corectat și explicația',
            image: YellowCircle,
          },
        ],
        [
          { title: 'Apasă în câmpul de text pentru a scrie un mesaj' },
          { title: 'Apasă pentru a înregistra un mesaj', image: Microphone },
        ],
      ],
    },
  },
  problem: {
    title: 'Raportați o problemă',
    subtitle: 'Descrieți problema referitoare la mesajul AI',
    send: 'Trimite',
    messageSend: 'Mesaj trimis',
    error: 'Ceva nu a mers bine, scrieți-ne :)',
  },
};
