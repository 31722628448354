import React from 'react';
import { Card, Title, InfoWrapper, P } from '../styles';
import { FROM_SOURCE_TYPES } from '@/common/constants/types';

const Stats = ({ stats }) => {
  const countBySource = (sourceType) => {
    return stats.reduce(
      (prev, next) => prev + (next.source === sourceType ? 1 : 0),
      0,
    );
  };

  return (
    <Card>
      <Title>Stats:</Title>
      <InfoWrapper>
        <P>
          <p>Speak:</p>
          <b>{countBySource(FROM_SOURCE_TYPES.SPEAK)}</b>
        </P>
        <P>
          <p>Write:</p>
          <b>{countBySource(FROM_SOURCE_TYPES.WRITE)}</b>
        </P>
        <P>
          <p>Hint:</p>
          <b>{countBySource(FROM_SOURCE_TYPES.HINT)}</b>
        </P>
      </InfoWrapper>
    </Card>
  );
};

export default Stats;
