import {
  createWordLessonTypeConfig,
  updateWordLessonTypeConfig,
} from "@/api/query/word-lesson-type-config";
import Loading from "@/common/components/Loading";
import PopUp from "@/common/components/PopUp";
import TextArea from "@/common/components/TextArea";
import CustomTable from "@/common/components/custom/CustomTable";
import Input from "@/common/components/custom/Input";
import CustomAddButton from "@/common/components/custom/buttons/CustomAddButton";
import CustomEditButton from "@/common/components/custom/buttons/CustomEditButton";
import CustomSaveButton from "@/common/components/custom/buttons/CustomSaveButton";
import { Border } from "@/pages/customer/profile/profile.styles";
import React, { useMemo, useRef, useState } from "react";
import { useMutation } from "react-query";
import { styled } from "styled-components";
import EditPrompt from "./EditPrompt";
import CustomDeleteButton from "@/common/components/custom/buttons/CustomDeleteButton";
import {
  createDailyConversationConfig,
  updateDailyConversaionConfig,
} from "@/api/query/dail-config";

const Wrapper = styled.div`
  padding: 10px;
`;

const Title = styled.h2`
  text-align: center;
`;

const SaveWrapper = styled.div`
  margin: 20px 0 10px 0;
  display: flex;
  justify-content: right;
`;

const TableWrapper = styled.div`
  max-width: 800px;
  max-height: 300px;
  overflow: auto;
`;

const Form = styled.form`
  margin: 0px 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const HEADERS = ["no.", "prompt", "Actions"];

const AdminDailyConversationEdit = ({ setEdit, edit }) => {
  const [showPrompt, setShowPrompt] = useState();
  const [prompts, setPrompts] = useState(edit.prompts || []);

  const deletePrompt = (promptElement) => {
    const filtred = prompts
      .filter((p) => p.id !== promptElement.id)
      .map((p, i) => ({ ...p, id: i + 1 }));

    setPrompts(() => filtred);
  };

  const tableData = useMemo(() => {
    if (!prompts) {
      return [];
    } else {
      return prompts
        .sort((a, b) => a.id - b.id)
        .map((g) => [
          g.id,
          g.prompt,
          <div style={{ display: "flex", gap: "10px" }}>
            <CustomEditButton onClick={() => setShowPrompt(g)} />
            <CustomDeleteButton onClick={() => deletePrompt(g)} />
          </div>,
        ]);
    }
  }, [prompts]);

  const nameRef = useRef();
  const defaultRef = useRef();
  const openingFunFactRef = useRef();

  const createWordLessonConfigMutation = useMutation({
    mutationFn: (value) => createDailyConversationConfig(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const updateWordLessonConfigMutation = useMutation({
    mutationFn: (value) => updateDailyConversaionConfig(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const handleSave = (e) => {
    e && e.preventDefault();

    const payload = edit;
    payload.name = nameRef.current.value;
    payload.isDefault = defaultRef.current.checked;
    payload.prompts = prompts;
    payload.openingPromptFunFuct = openingFunFactRef.current.value;

    if (edit._id) {
      return updateWordLessonConfigMutation.mutate(payload);
    }

    createWordLessonConfigMutation.mutate(payload);
  };

  return (
    <>
      {(updateWordLessonConfigMutation.isLoading ||
        createWordLessonConfigMutation.isLoading) && <Loading />}
      <PopUp setClose={setEdit}>
        <Title>Daily config {edit._id ? "Edit" : "Create"}</Title>
        <Form onSubmit={(e) => handleSave(e)}>
          <Input
            inputRef={nameRef}
            name="Name"
            inputWidth={600}
            width={80}
            required
            requiredSign
            value={edit.name}
          />
          <TextArea
            textAreaRef={openingFunFactRef}
            label="Opening Prompt Fun Fuct"
            width={750}
            required
            requiredSign
            defaultValue={edit.openingPromptFunFuct}
          />
          <Input
            inputRef={defaultRef}
            name="Active"
            type="checkbox"
            inputWidth={200}
            width={80}
            checked={edit.isDefault}
          />
          <TableWrapper>
            <CustomTable headers={HEADERS} data={tableData} />
          </TableWrapper>
          <SaveWrapper>
            <CustomAddButton onClick={() => setShowPrompt({})} />
            <CustomSaveButton />
          </SaveWrapper>
        </Form>
      </PopUp>
      {showPrompt && (
        <EditPrompt
          setClose={setShowPrompt}
          edit={showPrompt}
          handleAddPrompt={setPrompts}
        />
      )}
    </>
  );
};

export default AdminDailyConversationEdit;
