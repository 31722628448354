import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import SuccessImage from '@/common/icons/successImage.svg';
import userManager from '@/api/userManager';
import { IpStatsContext } from '@/common/contexts/IpStatsContext';
import { useMutation } from 'react-query';
import { checkActive } from '@/api/query/customer';
import { useLanguageData } from '@/common/hooks/useLanguageData';
import Loading from '@/common/components/Loading';

const TextInfo = styled.p`
  color: gray;
  text-decoration: underline;
  font-style: italic;
  max-width: 800px;
  text-align: center;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 0 auto;
`;
const Title = styled.div`
  color: ${(prev) =>
    prev.isUserActive === PAYMENT_STATUS.COMPLITED
      ? '#05275d'
      : prev.isUserActive === PAYMENT_STATUS.CANCELED
      ? 'red'
      : 'blue'};
  text-align: center;
  font-family: Work Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.56px;
`;

const SubTitle = styled.div`
  color: ${(prev) => (prev.isUserActive ? '#05275d' : 'red')};

  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */

  max-width: 300px;
`;

const ImageFailedWrapper = styled.div`
  display: flex;
  width: 84px;
  height: 84px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 103px;
  background: ${(props) =>
    props.paymentStatus === PAYMENT_STATUS.PROCESS ? 'blue' : '#f96565'};
  margin-top: 60px;

  img {
    width: 60%;
  }
`;

const ButtonMove = styled.button`
  display: flex;
  gap: 10px;
  padding: 9px 82px 10px 82px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  background: linear-gradient(91deg, #4f17aa 1.48%, #ac02f1 99.24%);
  margin: 5px 0;

  @media (min-width: 600px) {
    margin-top: 20px;
  }
`;

const PAYMENT_STATUS = {
  PROCESS: 'porcess',
  COMPLITED: 'complited',
  CANCELED: 'canceled',
};

const Success = () => {
  const [paymentStatus, setPaymentStatus] = useState(PAYMENT_STATUS.PROCESS);
  const langData = useLanguageData('paymentStatus');

  const { handleFbStats, handleSendIPstats } = useContext(IpStatsContext);
  const { id } = useParams();

  const navigate = useNavigate();

  const handleGetUserInfo = useMutation({
    mutationFn: (userId) => checkActive(userId),
    onSuccess: ({ data }) => {
      const { user, order, payment } = data;

      if (payment.length === 0) {
        return setTimeout(() => {
          const user = userManager?.getUserRegister();
          const userId = userManager?.getUser();

          if (user || id || userId) {
            handleGetUserInfo.mutate(id || user?._id || userId?.id);
          }
        }, 1000);
      }
      if (payment[0]) {
        if (payment[0].status_complated_date) {
          setPaymentStatus(() => PAYMENT_STATUS.COMPLITED);
        }

        if (payment[0].status_canceled_date) {
          setPaymentStatus(() => PAYMENT_STATUS.CANCELED);
        }
      }

      if (data.active) {
        if (data.has_promo) {
          handleSendIPstats('PURCHASE_AD_LP2_PROMO');
        } else {
          handleSendIPstats('PURCHASE');
        }

        handleFbStats('Purchase', {});
        window?.gtag('purchase', {});
        window?.fbq('track', 'Purchase');
        window?.uetq?.push('event', 'purchase', {
          revenue_value: 60,
          currency: 'PLN',
        });

        localStorage.removeItem('user_register');
        localStorage.removeItem('register_order');
      }
    },
  });

  useEffect(() => {
    const user = userManager?.getUserRegister();
    const userId = userManager?.getUser();

    if (user || id || userId) {
      handleGetUserInfo.mutate(id || user?._id || userId?.id);
    } else {
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    }
  }, []);

  return (
    <>
      {handleGetUserInfo.isLoading && <Loading />}
      <Wrapper>
        {paymentStatus === PAYMENT_STATUS.COMPLITED ? (
          <img
            alt=""
            src={SuccessImage}
            style={{ width: '80px', height: '80px', marginTop: '60px' }}
          />
        ) : (
          <ImageFailedWrapper paymentStatus={paymentStatus} />
        )}
        <Title isUserActive={paymentStatus}>
          {paymentStatus === PAYMENT_STATUS.COMPLITED
            ? langData.success
            : PAYMENT_STATUS.CANCELED === paymentStatus
            ? langData.failure
            : langData.veryfication}
        </Title>
        {[PAYMENT_STATUS.CANCELED, PAYMENT_STATUS.COMPLITED].includes(
          paymentStatus,
        ) && (
          <SubTitle isUserActive={PAYMENT_STATUS.COMPLITED === paymentStatus}>
            {langData.subtitle}
          </SubTitle>
        )}
        {[PAYMENT_STATUS.COMPLITED, PAYMENT_STATUS.PROCESS].includes(
          paymentStatus,
        ) && (
          <>
            <ButtonMove onClick={() => navigate('/login')}>
              {langData.login}
            </ButtonMove>
            <TextInfo>{langData.textInfo}</TextInfo>
          </>
        )}
      </Wrapper>
    </>
  );
};

export default Success;
