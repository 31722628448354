import userManager from '@/api/userManager';
import PopUp from '@/common/components/PopUp';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import Loading from '@/common/components/Loading';
import { updateUserFirstView } from '@/api/query/customer';
import Mobile from './mobile/Mobile';
import Desktop from './desktop/Desktop';

export const LevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) {
    align-items: center;
    padding-top: 24px;
    gap: 24px;
  }
`;

export const LevelOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  @media (min-width: 800px) {
    flex-direction: row;
    gap: 18px;
  }
`;

const MobileWrapper = styled.div`
  @media (min-width: 600px) {
    display: none;
  }
`;

const DekstopWrapper = styled.div`
  width: 90vw;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 80vw;
  max-height: 600px;

  @media (max-width: 600px) {
    display: none;
  }
`;

const FirstLogin = ({ setClose, loader }) => {
  const handleSaveUserPreferencesMutation = useMutation({
    mutationFn: (values) => updateUserFirstView(values),
    onSuccess: ({ data }) => {
      const user = userManager.getUser();
      user.isFirstLogin = false;
      userManager.setUser(user);
      loader.mutate();
    },
  });

  const handleSend = (payload) => {
    payload.speaker = payload.tutor._id;
    payload.tutor = null;
    handleSaveUserPreferencesMutation.mutate(payload);
  };

  return (
    <>
      {handleSaveUserPreferencesMutation.isLoading && <Loading />}
      <PopUp setClose={() => {}} closeIcon={false}>
        <MobileWrapper>
          <Mobile handleSend={handleSend} />
        </MobileWrapper>
        <DekstopWrapper>
          <Desktop handleSend={handleSend} />
        </DekstopWrapper>
      </PopUp>
    </>
  );
};

export default FirstLogin;
