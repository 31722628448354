import Api from '../api';
const { axios: api } = Api;
const queryString = require('query-string');

const PREFIX = 'mail-config';

export const loadMailConfigs = async () => {
  const data = await api.get(`/${PREFIX}`);
  return { data: data.data };
};

export const crateMailConfig = async (payload) => {
  const data = await api.post(`/${PREFIX}`, payload);
  return { data: data.data };
};

export const updateMailConfig = async (payload) => {
  const data = await api.patch(`/${PREFIX}/${payload._id}`, payload);
  return { data: data.data };
};

export const sendAnyMailFromCustomerDashboard = async (payload) => {
  const data = await api.post(`/${PREFIX}/send-any-mail`, payload);
  return { data: data.data };
};
