import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import ArrowLeft from './images/arrow-left.svg';
import { useMutation } from 'react-query';
import { loadUserLearningData } from '@/api/query/user';
import { MessageContext } from '@/common/contexts/MessageContext';
import { useNavigate } from 'react-router-dom';
import { markeLanguageAsLearningLang } from '@/api/query/customer';
import { config } from '@/common/components/layout/productLayout/mobile/PickLanguage';
import Loading from '@/common/components/Loading';

const IconTitleWrapper = styled.div`
  width: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const TopBar = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 600px) {
    padding: 0 20px;
    max-width: 1200px;
  }
`;

const Title = styled.div`
  color: var(--Text-color, #29185e);
  text-align: center;
  font-family: 'Work Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
`;

const Card = styled.div`
  margin-top: 20px;
  border-radius: 8px;
  background: #fff;
  width: 90vw;
  margin: 20px auto;
  overflow: hidden;

  @media (min-width: 600px) {
    width: 400px;
    border: 1px solid #d9d9d9;
    max-height: 50vh;
    overflow: hidden;
  }
`;

export const LevelOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const InputWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;

  label {
    cursor: pointer;
    font-weight: ${(props) => (props.isActive ? '600' : 'normal')};
    color: #05275d;
    text-align: center;
    font-family: 'Work Sans';
    font-size: 12px;
    font-style: normal;
    line-height: 140%;
  }

  input {
    margin: 0;
  }
`;

const Element = styled.div`
  width: 100%;
  display: inline-flex;
  cursor: pointer;
  padding: 14px 14px;
  align-items: center;
  gap: 20px;
  border-radius: 8px;
  border-radius: ${(props) => props.borderRadius};
  background: ${(props) => (props.isActive ? '#730bdb' : 'white')};
`;

const TitleBox = styled.div`
  color: ${(props) => (props.isActive ? '#F4F6F8' : '#29185E')} !important;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 123.5%; /* 17.29px */
  letter-spacing: 0.28px;
`;

const Left = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
`;

const SaveButton = styled.button`
  display: flex;
  width: 193px;
  padding: 14px 66px 13px 66px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(
    --Purple,
    linear-gradient(91deg, #4f17aa 1.48%, #ac02f1 99.24%)
  );

  color: #fff;
  text-align: center;
  font-family: 'Work Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  text-transform: uppercase;
`;

const ProfileLearningLanguage = () => {
  const [userData, setUserData] = useState({});
  const [selected, setSelected] = useState();

  const navigate = useNavigate();

  const { addMessage } = useContext(MessageContext);

  const loadUserMutation = useMutation({
    mutationFn: () => loadUserLearningData(),
    onSuccess: ({ data }) => {
      setUserData(data);
      setSelected(
        () =>
          config.find((c) => c.value === data?.learningLanguage?.short)?.value,
      );
    },
  });

  const markLanguageAsLearningLangMutation = useMutation({
    mutationFn: (values) => markeLanguageAsLearningLang(values),
    onSuccess: ({ data }) => {
      addMessage('Język nauki został zmieniony', 'success');
    },
    onError: () => {
      addMessage('Error', 'error');
      loadUserMutation.mutate();
    },
  });

  const handleSendRequest = (lang) => {
    const payload = {
      lang,
    };

    markLanguageAsLearningLangMutation.mutate(payload);
  };
  useEffect(() => {
    loadUserMutation.mutate();
  }, []);

  return (
    <>
      {loadUserMutation.isLoading && <Loading />}
      <TopBar style={{ marginTop: '24px' }}>
        <IconTitleWrapper>
          <img src={ArrowLeft} onClick={() => navigate('/profile/learning')} />
        </IconTitleWrapper>
        <Title>Język nauki</Title>
        <IconTitleWrapper />
      </TopBar>
      <Card>
        {config.map((c, i) => (
          <Element
            isActive={selected === c.value}
            onClick={() => {
              handleSendRequest(c.value);
              setSelected(c.value);
            }}
            borderRadius={i === 0 ? '8px 8px 0 0' : ''}
          >
            <Left>
              <img src={c.image} />
            </Left>
            <TitleBox isActive={selected === c.value}>{c.label2}</TitleBox>
          </Element>
        ))}
      </Card>
    </>
  );
};

export default ProfileLearningLanguage;
