export const getPopularDomainsByLanguage = (lang) => {
  const domainsByLanguage = {
    pl: [
      'onet.pl',
      'wp.pl',
      'o2.pl',
      'interia.pl',
      'gazeta.pl',
      'tlen.pl',
      'neostrada.pl',
      'poczta.fm',
      'go2.pl',
      '2com.pl',
      'buziaczek.pl',
      'autograf.pl',
      'gmail.com',
      'yahoo.pl',
      'interklasa.pl',
      'polbox.com',
      'nazwa.pl',
      'onet.eu',
      'prokonto.pl',
      'int.pl',
      'serwus.pl',
      'epf.pl',
      'xmail.net',
      'vp.pl',
    ],
    cs: [
      'seznam.cz',
      'centrum.cz',
      'volny.cz',
      'post.cz',
      'email.cz',
      'quick.cz',
      'atlas.cz',
      'chello.cz',
      'klikni.cz',
      'tiscali.cz',
      'gmail.com',
      'gmail.cz',
      'atlas.cz',
      'post.sk',
      'centrum.cz',
      'wo.cz',
      'wmail.cz',
      'czbox.cz',
      'volnybox.cz',
      'cznet.cz',
      'host.cz',
      'supermail.cz',
    ],
    sk: [
      'zoznam.sk',
      'centrum.sk',
      'atlas.sk',
      'post.sk',
      'szm.sk',
      'chello.sk',
      'mail.t-com.sk',
      'stonline.sk',
      'pobox.sk',
      'orangemail.sk',
      'gmail.com',
      'email.sk',
      'mail.sk',
      'netmail.sk',
      'skmail.sk',
      'tmail.sk',
      'telekom.sk',
      'dsl.sk',
      'onet.sk',
      'web.sk',
    ],
    de: [
      'gmx.de',
      'web.de',
      't-online.de',
      'freenet.de',
      'arcor.de',
      'yahoo.de',
      'hotmail.de',
      'mail.de',
      'online.de',
      'outlook.de',
      'posteo.de',
      'mailbox.org',
      'gmail.com',
      'emailn.de',
      'gmx.net',
      'webmail.de',
      'kabelmail.de',
      'vodafone.de',
      'tiscali.de',
      'alice.de',
      'netcologne.de',
      'citymail.de',
      'mailbox.de',
      'berlin.de',
    ],
    ro: [
      'yahoo.ro',
      'mail.ru',
      'hotmail.ro',
      'freemail.ro',
      'rdsnet.ro',
      'onmail.ro',
      'clicknet.ro',
      'email.ro',
      'rambler.ru',
      'gmail.com',
      'caramail.ro',
      'yandex.ro',
      'outlook.ro',
      'zoho.ro',
      'lycos.ro',
      'zoho.eu',
      'gmail.eu',
      'hotmail.eu',
      'netmail.ro',
      'mailro.ro',
    ],
    it: [
      'libero.it',
      'virgilio.it',
      'tiscali.it',
      'alice.it',
      'tin.it',
      'hotmail.it',
      'fastwebnet.it',
      'poste.it',
      'telecomitalia.it',
      'vodafone.it',
      'inwind.it',
      'iol.it',
      'gmail.com',
      'yahoo.it',
      'hotmail.eu',
      'email.it',
      'katamail.com',
      'libero.eu',
      'tin.eu',
      'tiscali.eu',
      'postafree.it',
      'posteitaliane.it',
      'email.eu',
    ],
    es: [
      'terra.es',
      'telefonica.net',
      'ya.com',
      'hotmail.es',
      'yahoo.es',
      'wanadoo.es',
      'orange.es',
      'teleline.es',
      'mixmail.com',
      'eresmas.com',
      'jet.es',
      'gmail.com',
      'terra.com',
      'outlook.es',
      'mail.com',
      'hispavista.com',
      'email.es',
      'vodafone.es',
      'correo.es',
      'ya.es',
      'rediffmail.com',
      'telefonica.es',
    ],
    hu: [
      'freemail.hu',
      'live.com',
      'gmail.com',
      'gmail.hu',
      'citromail.hu',
      'outlook.hu',
      'mailbox.hu',
      'intermail.hu',
      'freemail.c3.hu',
      'vipmail.hu',
      't-online.hu',
      'drotposta.hu',
      'egon.hu',
      'upcmail.hu',
      'chello.hu',
      'externet.hu',
      'primposta.hu',
      'invitel.hu',
      'myvip.hu',
      'mailbox.hu',
    ],
  };

  return domainsByLanguage[lang] || [];
};
