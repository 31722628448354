export const messageDataInitObject = {
  conversationWithChatGPT: [],
  current: null,
  showMessagesTranslations: [],
  translationMessages: [],
  loadingTranslations: [],
  loadingVoice: null,
  recordings: [],
  voiceTalking: null,
  isHintLoading: null,
  hintData: null,
  messageScore: [],
  handleShowBadMessage: null,
  showProblem: null,
};
