import React, { useRef, useState } from "react";
import { styled } from "styled-components";
import { useMutation } from "react-query";
import SearchBar from "./components/SearchBar";
import Loading from "@/common/components/Loading";
import { getUTMReport } from "@/api/query/report";
import NewCustomTable from "@/common/components/custom/NewCustomTable";

const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
  max-width: 90vw;
  overflow-x: auto;
`;

const HEADERS = [
  "Landing page views",
  "Wejście na rejestracje (add_to_cart)",
  "LP CR (LP->rejestracja)",
  "utworzenie konta (complete_registration)",
  "rejestr CR (rej->zakończone rej)",
  "Wybór planu (complete_registration)",
  "CR wybór planu",
  "Rozpoczęcie płatności (add_payment_info)",
  "CR podsumowanie",
  "Zakupy (purchase)",
  "CR płatność",
  "Wartość zakupów",
];

const AdminUTMReport = () => {
  const [tableData, setTableData] = useState([]);
  const uniqueUid = useRef();

  const findStatsMutation = useMutation({
    mutationFn: (values) => getUTMReport(values),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((d) => [
            d.landing,
            d.register,
            `${Math.round((d.register / d.landing) * 100) || 0} %`,
            d.account,
            `${Math.round((d.account / d.register) * 100) || 0} %`,
            d.plan,
            `${Math.round((d.plan / d.account) * 100) || 0} %`,
            d.pay,
            `${Math.round((d.pay / d.plan) * 100) || 0} %`,
            d.bought,
            `${Math.round((d.bought / d.pay) * 100) || 0} %`,
            Math.round(d.value * 100) / 100 || 0,
          ])
      );
    },
  });

  const handleSearch = (payload) => {
    findStatsMutation.mutate(payload);
  };

  return (
    <>
      {findStatsMutation.isLoading && <Loading />}
      <Wrapper>
        <TableWrapper>
          <SearchBar handleSearch={handleSearch} toogleRef={uniqueUid} />
        </TableWrapper>
        {tableData && (
          <TableWrapper>
            <NewCustomTable
              headers={HEADERS}
              data={tableData}
              csvData={tableData}
            />
          </TableWrapper>
        )}
      </Wrapper>
    </>
  );
};

export default AdminUTMReport;
