import CustomTable from '@/common/components/custom/CustomTable';
import CustomAddButton from '@/common/components/custom/buttons/CustomAddButton';
import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import AdminFlashcardCategoryEdit from './components/AdminNewsEdit';
import { useMutation } from 'react-query';
import CustomEditButton from '@/common/components/custom/buttons/CustomEditButton';
import { deleteCategory, findCategories } from '@/api/query/category';
import CustomDeleteButton from '@/common/components/custom/buttons/CustomDeleteButton';
import {
  deleteFlashcardCategory,
  findFlashcardCategories,
} from '@/api/query/flashcard-category';
import Loading from '@/common/components/Loading';
import DataTable from '@/common/components/custom/DataTable';
import { deleteNews, findAllNews } from '@/api/query/news';
import { formatDate } from '../mail/AdminMail';
import moment from 'moment';
import SelectInput from '@/common/components/custom/SelectInput';
import { TRANSLATION_STATUS_OPTIONS } from '../translation/Translation';
import { LANG_OPTIONS } from '../translation/components/SearchBar';

const TopContent = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`;
const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
`;

const ActionWrapper = styled.div`
  margin-bottom: 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

const AdminNews = () => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selected, setSelected] = useState(LANG_OPTIONS[0]);

  const handleSelect = (value) => {
    const currentData = data.map((d, i) => ({
      no: i + 1,
      date: moment(d.avaiable_from).format('YYYY/MM/DD'),
      description: d.translations.find((t) => t.languageShort === value.value)
        ?.translation,
      image: d.image?.base64,
      actions: d,
    }));

    setTableData(() => currentData);
    setSelected(() => value);
  };

  const HEADERS = [
    {
      header: 'no.',
      accessorKey: 'no',
      size: 20,
    },
    {
      header: 'Date',
      accessorKey: 'date',
      size: 20,
    },
    {
      header: 'Description',
      accessorKey: 'description',
      size: 20,
    },
    {
      header: 'Image',
      accessorKey: 'image',
      size: 20,
      cell: (info) => (
        <div style={{ width: '50px', overflow: 'hidden' }}>
          <img
            src={info.getValue()}
            style={{ width: '100%', height: 'auto' }}
          />
        </div>
      ),
    },
    {
      header: 'Actions',
      accessorKey: 'actions',
      size: 20,
      cell: (info) => (
        <div style={{ display: 'flex', gap: '10px' }}>
          <CustomEditButton onClick={(e) => setEdit(info.getValue())} />
          <CustomDeleteButton
            onClick={(e) => handleDeleteCategory(info.getValue())}
          />
        </div>
      ),
    },
  ];

  const deleteFlashcardCategoryMutation = useMutation({
    mutationFn: (payload) => deleteNews(payload),
    onSuccess: ({ data }) => {
      findFlashcardCategoriesMutation.mutate();
    },
  });

  const handleDeleteCategory = (d) => {
    const payload = {};
    payload._id = d._id;

    deleteFlashcardCategoryMutation.mutate(payload);
  };

  const [edit, setEdit] = useState();

  const findFlashcardCategoriesMutation = useMutation({
    mutationFn: () => findAllNews(),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data.map((d, i) => ({
          no: i + 1,
          date: moment(d.avaiable_from).format('YYYY/MM/DD'),
          description: d.translations.find(
            (t) => t.languageShort === selected.value,
          )?.translation,
          image: d.image?.base64,
          actions: d,
        })),
      );
      setData(data);
    },
  });

  useEffect(() => {
    if (!edit) {
      findFlashcardCategoriesMutation.mutate();
    }
  }, [edit]);

  return (
    <Wrapper>
      {(findFlashcardCategoriesMutation.isLoading ||
        deleteFlashcardCategoryMutation.isLoading) && <Loading />}
      {edit && <AdminFlashcardCategoryEdit edit={edit} setEdit={setEdit} />}
      <TopContent>
        <CustomAddButton onClick={() => setEdit({})} />
        <SelectInput
          options={LANG_OPTIONS}
          width={100}
          name={'Translation'}
          selected={selected}
          setSelected={handleSelect}
        />
      </TopContent>
      <TableWrapper>
        <DataTable columns={HEADERS} data={tableData} rows={[10, 15, 20]} />
      </TableWrapper>
    </Wrapper>
  );
};

export default AdminNews;
