import PopUp from '@/common/components/PopUp';
import TextArea from '@/common/components/TextArea';
import Input from '@/common/components/custom/Input';
import CustomSaveButton from '@/common/components/custom/buttons/CustomSaveButton';
import { Title } from '@/pages/customer/word-lesson-type/WordLessonTypeList';
import React, { useRef } from 'react';
import styled from 'styled-components';

const SaveWrapper = styled.div`
  margin: 20px 0 10px 0;
  display: flex;
  justify-content: right;
`;

const EditPrompt = ({ setClose, edit, handleAddPrompt }) => {
  const promptRef = useRef();
  const weightRef = useRef();

  const handleSave = (e) => {
    e && e.preventDefault();

    const paylaod = edit;
    paylaod.prompt = promptRef.current.value;
    paylaod.weight = weightRef.current.value;

    if (edit.id) {
      handleAddPrompt((prev) => [
        ...prev.filter((p) => p.id !== paylaod.id),
        paylaod,
      ]);
      setClose(null);
      return;
    }

    handleAddPrompt((prev) => [...prev, { ...paylaod, id: prev.length + 1 }]);
    setClose(null);
  };

  return (
    <>
      <PopUp setClose={setClose}>
        <form onSubmit={handleSave}>
          <Title> Prompt {edit?.id ? 'edit' : 'create'}</Title>
          <Input
            inputRef={weightRef}
            name="Weight"
            inputWidth={100}
            width={100}
            required
            requiredSign
            value={edit.weight}
          />

          <TextArea
            width={'1000px'}
            minHeight="600px"
            textAreaRef={promptRef}
            label="Prompt"
            defaultValue={edit.prompt}
            required={true}
          />
          <SaveWrapper>
            <CustomSaveButton />
          </SaveWrapper>
        </form>
      </PopUp>
    </>
  );
};

export default EditPrompt;
