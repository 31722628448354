import React from 'react';
import { CustomerProvider } from './contexts/CustomerContext';
import CustomerStats from './CustomerStats';

const CustomerStatsWrapper = () => {
  return (
    <CustomerProvider>
      <CustomerStats />
    </CustomerProvider>
  );
};

export default CustomerStatsWrapper;
