import {
  faBookOpen,
  faColumns,
  faGamepad,
  faHistory,
  faIcons,
  faImage,
  faLanguage,
  faMagic,
  faMailBulk,
  faMoneyBill,
  faPerson,
  faPlay,
  faServer,
  faSignLanguage,
  faTachometer,
  faTrain,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px 20px 0 20px;
  gap: 20px;
`;

const CustomLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-weight: bold;
  color: #05275d;
  font-family: Work Sans;
  gap: 8px;
  padding: 32px;
  border-radius: 16px;
  background: rgba(79, 167, 180, 0.2);
  transition: all 0.3s;

  &:hover {
    transform: scale(0.8);
  }

  @media (max-width: 600px) {
    padding: 10px;
    font-size: 16px;
    border-radius: 10px;
  }
`;

const IconWrapper = styled.div`
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const AdminElements = [
  {
    name: 'User',
    link: '/admin/user',
    icon: faUser,
  },
  {
    name: 'Hobby',
    link: '/admin/word-lesson-top-type',
    icon: faMagic,
  },
  {
    name: 'Ordery',
    link: '/admin/order',
    icon: faBookOpen,
  },
  {
    name: 'AI Persons',
    link: '/admin/ai-persons',
    icon: faPerson,
  },
  {
    name: 'Languages',
    link: '/admin/language',
    icon: faLanguage,
  },
  {
    name: 'Customer profiles',
    link: '/admin/customer-profile',
    icon: faIcons,
  },
  {
    name: 'Translation',
    link: '/admin/translation',
    icon: faTrain,
  },
  {
    name: 'Payment type',
    link: '/admin/payment-type',
    icon: faPlay,
  },
  {
    name: 'Currency',
    link: '/admin/currency',
    icon: faGamepad,
  },
  {
    name: 'Country',
    link: '/admin/country',
    icon: faColumns,
  },
  {
    name: 'Price',
    link: '/admin/price',
    icon: faMoneyBill,
  },
  {
    name: 'Price Config',
    link: '/admin/price-config',
    icon: faImage,
  },
  {
    name: 'Promotion',
    link: '/admin/promotion',
    icon: faImage,
  },
  {
    name: 'Spends',
    link: '/admin/spends',
    icon: faMoneyBill,
  },
  {
    name: 'Mail Templates',
    link: '/admin/mail-templates',
    icon: faMailBulk,
  },
  {
    name: 'Mail Config',
    link: '/admin/mail-config',
    icon: faMailBulk,
  },
];

const Common = () => {
  return (
    <Wrapper>
      {AdminElements.map((element) => (
        <CustomLink to={element.link}>
          <IconWrapper>
            <FontAwesomeIcon icon={element.icon} />
          </IconWrapper>
          {element.name}
        </CustomLink>
      ))}
    </Wrapper>
  );
};

export default Common;
