import React from 'react';
import { Card, Title, P } from '../styles';
import { formatDate } from '@/pages/admin/mail/AdminMail';
import styled from 'styled-components';
import CustomEditButton from '@/common/components/custom/buttons/CustomEditButton';

const P2 = styled(P)`
  p {
    width: 160px;
    text-align: right;
    margin: 0;
  }
`;

const UserInfo = ({ user, setUserEdit }) => (
  <Card>
    <Title>
      {user.email} ({formatDate(user.created_at)}):{' '}
      <CustomEditButton onClick={() => setUserEdit(user)} />
    </Title>
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <div>
        <P2>
          <p>Name: </p>
          <b>
            {user.name} {user.surname}
          </b>
        </P2>
        <P2>
          <p>Speaker:</p>
          <b>{user.speaker?.name || '-----'}</b>
        </P2>
        <P2>
          <p>Learning lang:</p>
          <b>{user.learningLanguage?.name || '-----'}</b>
        </P2>
        <P2>
          <p>Native Lang:</p>
          <b>{user.nativeLanguage?.name || '-----'}</b>
        </P2>
        <P2>
          <p>Page Url:</p>
          <b>{user.pageUrl || '-----'}</b>
        </P2>
        <P2>
          <p>Type:</p>
          <b>{user.accountType || '-----'}</b>
        </P2>
      </div>
      <div>
        <P2>
          <p>Active:</p>
          <b>{user.active ? 'Yes' : 'No'}</b>
        </P2>
        <P2>
          <p>First Login:</p>
          <b>{user.isFirstLogin ? 'No' : 'Yes'}</b>
        </P2>
        <P2>
          <p>Daily Popup:</p>
          <b>{user.isDailyTutoroalPopUpShow ? ' Yes' : 'No'}</b>
        </P2>
        <P2>
          <p>Daily V2:</p>
          <b>{user.showDailyVersionV2 ? 'Yes ' : 'No'}</b>
        </P2>
        <P2>
          <p>Call Mode Popup:</p>
          <b>{user.isCallModePopUpShow ? 'Yes ' : 'No'}</b>
        </P2>
      </div>
      <div>
        <P2>
          <p>Current Streak:</p>
          <b>{user.currentStreak} d</b>
        </P2>
        <P2>
          <p>Max Streak:</p>
          <b>{user.maxStreak} d</b>
        </P2>
        <P2>
          <p>Seconds in App:</p>
          <b>{user.secondsInApp} s</b>
        </P2>
      </div>
      <div>
        <P2>
          <p>Hobby:</p>
          <b style={{ maxWidth: '300px', textAlign: 'left' }}>
            {user.hobby?.map((h) => h.name).join(', ') || '-----'}
          </b>
        </P2>
        <P2>
          <p>Daily Minutes:</p>
          <b>{user.dailyMinutes || '-----'}</b>
        </P2>
        <P2>
          <p>Level:</p>
          <b>{user.level}</b>
        </P2>
      </div>
    </div>
  </Card>
);

export default UserInfo;
