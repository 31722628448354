import Api from "../api";
const { axios: api } = Api;
const queryString = require("query-string");

const PREFIX = "auth";

export const login = async (payload) => {
  const data = await api.post(`/${PREFIX}/login`, payload);
  return { data: data.data };
};

export const googleLogin = async (payload) => {
  const data = await api.post(`/${PREFIX}/login-google`, payload);
  return { data: data.data };
};

export const facebookLogin = async (payload) => {
  const data = await api.post(`/${PREFIX}/login-facebook`, payload);
  return { data: data.data };
};
