import Book from './images/spersonalizowana.png';
import Podium from './images/podium.svg';
import Message from './images/speech-bubble.svg';
import Listen from './images/listen.svg';
import Hello from './images/skuteczna.png';
import Idea from './images/podpowiedzi.png';
import Translation from './images/translate.svg';

import Frame1 from '@/common/images/language.svg';
import Calendar from '@/common/images/calendar-landing.png';
import Now from './images/natychmiastowa_2.png';

import Sylwia from '@/common/images/sylwia.svg';
import Pawel from '@/common/images/pawel.svg';
import Malgorzata from '@/common/images/malgorzata.svg';

import Chat from './images/chat.png';
import Fiszki from './images/fiszki.png';
import FotoZagadka from './images/foto-zagadka.png';
import Rozmowki from './images/rozmowki.png';
import Slowka from './images/slowka.png';

export const english = {
  header: {
    buy: 'Kup',
    login: 'Zaloguj',
  },
  main: {
    hero: {
      title: 'Mów po angielsku z SynthiAi',
      subittle: 'z Inteligentnym Asystentem',
      subtitle2:
        'Poznaj innowacyjne podejście Sztucznej Inteligencji (AI) do nauki angielskiego',
      buyNow: 'Kup teraz',
    },
    innovation: {
      title: `Poznaj innowacyjne podejście Sztucznej Inteligencji (AI) do nauki
        angielskiego`,
      icons: [
        {
          top: `Spersonalizowana`,
          bottom: 'tematyka lekcji',
          image: Book,
        },
        {
          top: `Poziom dostosowany 
              `,
          bottom: 'do Ciebie',
          image: Podium,
        },
        {
          top: `Rozmowy na`,
          bottom: ' dowolny temat',
          image: Message,
        },
        {
          top: `Trening rozumienia 
              `,
          bottom: 'ze słuchu',
          image: Listen,
        },
        {
          top: `Skuteczna 
              `,
          bottom: 'nauka słówek',
          image: Hello,
        },
        {
          top: `Podpowiedzi 
             `,
          bottom: ' Sztucznej Inteligencji',
          image: Idea,
        },
        {
          top: `Natychmiastowe `,
          bottom: `tłumaczenie treści`,
          image: Translation,
        },
      ],
    },
    head: {
      title: 'Mów po angielsku bez stresu',
      subtitle: 'z Inteligentnym Asystentem SynthiAi',
    },
    meet: {
      title: `Poznaj innowacyjne podejście Sztucznej Inteligencji (AI) do nauki
        angielskiego`,
      buyButton: 'Kup teraz',
    },
    compare: {
      title: `10 razy lepszy niż tradycyjny nauczyciel`,
      leftTitle: '🧑‍💻 Tutor AI',
      rightTitle: '👩‍🏫 Tradycyjny nauczyciel',
      leftDescription: `Mówi i brzmi jak prawdziwy native speaker\nNie trzeba planować z wyprzedzeniem \nDostępny przez cały dzień, każdego dnia \nNieskończone możliwości uczenia się\nnowych tematów \nNatychmiastowa informacja zwrotna na\ntemat umiejętności konwersacyjnych\nBrak stresu i poczucia niepokoju - SynthiAi nie\nocenia.`,
      leftDescriptionBottom: `Mówi i brzmi jak prawdziwy native speaker\nNie trzeba planować z wyprzedzeniem \nDostępny przez cały dzień, każdego dnia \nNieskończone możliwości uczenia się\nnowych tematów \nNatychmiastowa informacja zwrotna na\ntemat umiejętności konwersacyjnych\nBrak stresu i poczucia niepokoju - SynthiAi nie\nocenia.`,
      rightDescription: `Mówi ze swoim indywidualnym akcentem\nMa napięty harmonogram, musisz znaleźć czas\nktóry będzie odpowiedni dla was obojga\nPojedyncza lekcja kosztuje 50+ zł.\nProwadzi tradycyjne lekcje trwające 45-60 minut,\nnie jestelastyczny\nNa początku może być niezręcznie`,
      rightDescriptionBottom: `Indywidualny akcent\nNapięty harmonogram\n50+ zł za lekcję \nNa początku może być niezręcznie`,
    },
    planSection: {
      title: 'Wybierz plan dla siebie',
      subTitle: 'Wybierz najlepszy plan i zarejestruj się, żeby dokonać zakupu',
      basicText: 'Ćwicz i ucz się bez ograniczeń przez 1 miesiąc',
      basicPriceText: '59,99 zł miesięcznie',
      proTopText: 'Najczęściej wybierane!',
      proMidText: 'Ćwicz i ucz się bez ograniczeń przez 3 miesiące',
      proPriceText: '39,99 zł miesięcznie',
      proFinalPriceText: '119,97 zł płatne co 3 miesiące',
      platinumTopText: 'Najlepsza oferta!',
      platinumSaveText: 'Oszczędzasz 50%',
      platinumMiddleText: 'Ćwicz i ucz się bez ograniczeń przez 6 miesięcy',
      platinumPriceText: '29,99 zł miesięcznie',
      platinumFullPriceText: ' 179,94 zł płatne co 6 miesięcy',
      buttonText: 'Zarejestruj się, Aby Kupić',
    },
    mockup: {
      title: 'Mów po angielsku bez stresu',
      subtitleSub: 'Wykonaj pierwszy krok, aby pokonać barierę językową!',
      subtitle: 'Poznaj możliwości SynthiAi\nw darmowej lekcji próbnej.',
      buttonText: 'Sprawdź',
    },
    what: {
      title: 'Co otrzymujesz?',
      items: [
        {
          image: Calendar,
          title: 'Indywidualny plan konwersacji',
          text: 'Rozmawiaj codziennie na dowolny temat i śledź swoje postępy! ',
        },
        {
          image: Frame1,
          title: 'Wsparcie dwujęzycznego Inteligentnego Asystenta',
          text: 'Korzystaj z tłumaczeń i podpowiedzi po polsku lub angielsku.',
        },
        {
          image: Now,
          title: 'Natychmiastowa informacja zwrotna',
          text: 'Rozmawiaj i wdrażaj wskazówki SynthiAi, jak natradycyjnej lekcji z nauczycielem.',
        },
      ],
    },
    opinion: {
      title: 'Recenzje naszych użytkowników 💬',
      items: [
        {
          text: 'SynthiAi to świetna aplikacja do poszerzania słownictwa. Rozmowy są naprawdę naturalne i przyjemne, można w wolnej chwili wejść do aplikacji i pogadać po angielsku.',
          image: Sylwia,
          name: 'Sylwia',
        },
        {
          text: 'Dla introwertyka, który stresuje się nawet telefonem do nauczyciela, żeby umówić się na lekcję… SynthiAi to strzał w 10 :) Zaczynałem od dukania po angielsku, a dzięki systematycznym powtórkom szybko zyskuję pewność siebie w mówieniu po angielsku.',
          image: Pawel,
          name: 'Paweł',
        },
        {
          text: 'Świetna alternatywa dla konwersacji z native speakerami. Za cenę jednej lekcji mogę rozmawiać do woli przez miesiąc.',
          image: Malgorzata,
          name: 'Małgorzata',
        },
      ],
    },
    faq: {
      title: 'FAQ',
      items: [
        {
          title: 'Pytania ogólne:',
          elements: [
            {
              top: 'Czym jest SynthiAi?',
              bottom: `SynthiAi to innowacyjna aplikacja mobilna, która uczy angielskiego za pomocą sztucznej inteligencji. Oferuje interaktywne rozmowy z chatbotem AI, pomagając uczącym się poprawić swoje umiejętności języka angielskiego.     `,
            },
            {
              top: 'Co sprawia, że nauka z SynthiAi jest skuteczna?',
              bottom: `SynthiAi wykorzystuje zaawansowane algorytmy przetwarzania języka naturalnego i uczenia maszynowego, aby angażować użytkowników w realistyczne rozmowy po angielsku. Inteligentny Asystent SynthiAi odpowiada na wiadomości użytkowników, udzielając opinii i sugestii poprawy.
                        `,
            },
            {
              top: 'Czy SynthiAi jest odpowiedni dla początkujących użytkowników?',
              bottom: `SynthiAi został zaprojektowany tak, aby odpowiadać uczniom wszystkich poziomów, od początkujących do zaawansowanych użytkowników. Dostosowuje trudność rozmowy w zależności od poziomu biegłości użytkownika, zapewniając optymalne doświadczenie nauki.
                        `,
            },
            {
              top: 'O czym mam rozmawiać z SynthiAi?',
              bottom: `SynthiAi sam poprowadzi cię przez tematy, które uznasz za interesujące i zajmie się prowadzeniem rozmowy. Inteligentny Asystent oferuje podpowiedzi jak rozpocząć wypowiedź, by wspomóc cię w płynnej rozmowie. 
                        `,
            },
            {
              top: 'Czy SynthiAi mówi po polsku?',
              bottom: `SynthiAi skupia się na nauczaniu języka angielskiego i prowadzi konwersacje po angielsku, ale rozumie też język polski. Dzięki temu zawsze możesz sprawdzić tłumaczenie wypowiedzi Inteligentnego Asystenta, a nawet odpowiedzieć po polsku.
                        `,
            },
          ],
        },
        {
          title: 'Korzystanie z SynthiAi:',
          elements: [
            {
              top: 'Gdzie mogę korzystać z SynthiAi?',
              bottom: `Z SynthiAi możesz korzystać na dowolnym urządzeniu (smartfon, tablet, laptop) podłączonym do internetu, w dowolnej przeglądarce internetowej. 
                        `,
            },
            {
              top: 'Czy mogę używać SynthiAi w trybie offline?',
              bottom: `Nie, SynthiAi działa online, ponieważ opiera się na algorytmach AI i połączeniu z serwerem. 
                        `,
            },
            {
              top: 'Czy mogę ćwiczyć mówienie z SynthiAi w czasie rzeczywistym?',
              bottom: `Tak, SynthiAi umożliwia praktykę mówienia w czasie rzeczywistym za pośrednictwem chatbota AI. Możesz prowadzić mówione rozmowy i otrzymywać natychmiastową opinię w celu poprawy umiejętności mówienia.
                        `,
            },
            {
              top: 'Jakie są dostępne metody płatności?',
              bottom: `Dla Twojej wygody akceptujemy szeroki zakres metod płatności, w tym szybkie przelewy bankowe, BLIK oraz wszystkie główne karty płatnicze. Wybierz metodę, która jest dla Ciebie najwygodniejsza, i zacznij swoją przygodę z językiem angielskim z SynthiAi! 
                        `,
            },
            {
              top: 'Jak mogę zapłacić za SynthiAi i jakie są dostępne plany?',
              bottom: `W SynthiAi oferujemy proste i wygodne plany jednorazowej płatności, które pozwalają na pełny dostęp do aplikacji na okres 1, 3 lub 6 miesięcy. Po dokonaniu płatności, cieszysz się nieprzerwanym dostępem do wszystkich funkcji aplikacji przez wybrany czas, bez obaw o miesięczne opłaty. Oto nasze plany:
                        `,
              plans: [
                '- Plan Basic: Idealny dla początkujących, oferujący kompletny dostęp na 1 miesiąc.',
                '- Plan Pro: Nasz najpopularniejszy plan, obejmujący 3 miesiące nauki z dodatkowymi funkcjami.',
                '- Plan Platinum: Dla najbardziej zaangażowanych uczniów, zapewniający 6 miesięcy intensywnego rozwoju językowego.',
                'Po upływie tego okresu, możesz łatwo odnowić swój plan, aby kontynuować naukę bez przerwy.',
              ],
            },
          ],
        },
      ],
    },
  },
  login: {
    title: 'Logowanie',
    email: 'Email',
    password: 'Hasło',
    textSub: 'Nie pamiętasz hasła? Zresetuj',
    restart: 'tutuj',
    restartPasswordText:
      'Wpisz swojego maila aby zresetowac haslo. Email z hasłem przyjdzie na twoja skrzyke pocztowa',
    placeholder: 'wpisz swojego maila...',
    resetPassword: 'zresetuj hasło',
    buttonText: 'Zaloguj',
    errorLogin: 'Sprawdź czy twoje hasło lub email są prawidłowe',
    passwordResterted: 'Twoje hasło zostało zresetowane sprawdź maila.',
    passwordError: 'Niestety nie mogliśmy znaleźć twojego maila.',
  },
  register: {
    errorRegister: 'Na takim mailu istenije juz aktywny uzytkownik',
    passwordError1: 'Hasło musi zawierać co najmniej 8 znaków.',
    passwordError2: 'Hasło musi zawierać co najmniej 1 wielka litere.',
    passwordError3: 'Hasło musi zawierać co najmniej 1 mała litere.',
    passwordError4: 'Hasło musi zawierać co najmniej 1 liczbę.',
    register: 'Rejestracja',
    nameAndSurname: 'Imię i Nazwisko',
    email: 'Email',
    passwordInfo:
      'Hasło musi zawierać co najmniej 8 znaków, w tym małe i duże litery oraz cyfrę.',
    createAccount: 'Utwórz konto',
    titles: {
      register: 'Rejestracja',
      plan: 'Pakiet',
      sum: 'Podsumowanie',
    },
    plan: {
      title: 'Wybierz plan dla siebie',
      month: 'miesiąc',
      months: 'miesiące',
      months2: 'miesięcy',
      value: 'zł/mies',
      pick: 'wybierz',
      nowPay: 'Teraz zapłacisz',
      saveOne: 'Oszczędzasz',
      buy: 'Kup',
      savePayment: 'Bezpieczne płatności obsługuje',
    },
    sum: {
      title: 'Podsumowanie',
      product: 'Produkt:',
      plan: 'SynthiAi Pakiet',
      forLong: 'Dostęp na',
      data: 'dane:',
      nameAndSurname: 'Imie i nazwisko:',
      paymentForm: 'Forma płatności:',
      toPay: 'Do zapłaty dzisiaj:',
      accept: 'Akceptuję',
      rules: 'regulamin',
      payingAccept: 'Płacąc akceptujesz',
      paymentRules: '"Zasady płatności PayU"',
      buyAndPay: 'KUPUJĘ I PŁACĘ',
    },
  },
  footer: {
    privacy: 'Polityka prywatności',
    rules: 'Regulamin',
  },
  app: {
    home: {
      hello: 'Hello',
      saySth: 'Say something',
      numberOfWords: 'Number of words spoken today',
      forYou: 'Recommended exercise for you',
      lastActivity: 'Your last activity:',
      saidWords: 'Words spoken',
      savedStars: 'Stars earned',
      scene: 'Dialogues',
      photoMode: 'Photo Puzzle',
      words: 'Words',
      flashcards: 'Flashcards',
      imageMode: 'Photo Puzzle',
    },
    learn: {
      title: 'Ucz się',
      items: [
        {
          title: 'Words',
          description: 'Expand your vocabulary',
          link: '/word-lesson-type/',
          image: Slowka,
        },
        {
          title: 'Dialogues',
          description: 'Practice speaking and listening',
          link: '/learn/categories/',
          image: Rozmowki,
        },
        {
          title: 'Chat',
          description: 'Talk about any topic',
          link: '/learn/chat',
          image: Chat,
          popular: true,
        },
        {
          title: 'Flashcards',
          description: 'Discover new words every day',
          link: '/learn/new-flashcard-list',
          image: Fiszki,
        },
        {
          title: 'Photo Puzzle',
          description: 'Describe everyday situations',
          link: '/learn/foto-mode/',
          image: FotoZagadka,
        },
      ],
    },
  },
};
