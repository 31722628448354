import PopUp from '@/common/components/PopUp';
import Input from '@/common/components/custom/Input';
import React, { useContext, useRef } from 'react';
import { useMutation } from 'react-query';
import { styled } from 'styled-components';
import Loading from '@/common/components/Loading';
import CustomSaveButton from '@/common/components/custom/buttons/CustomSaveButton';
import { MessageContext } from '@/common/contexts/MessageContext';
import { createPaymentType, updatePaymentType } from '@/api/query/paymentType';

export const ImageInput = styled.input`
  display: none;
`;
export const ImageWrapper = styled.div`
  width: 300px;
  height: 300px;
  overflow: hidden;
`;

const Title = styled.h2`
  text-align: center;
`;

const Form = styled.form`
  margin: 0px 30px;
  display: flex;
  flex-direction: column;
`;

const AdminPaymentTypeEdit = ({ setEdit, edit }) => {
  const labelRef = useRef();
  const valueRef = useRef();
  const for_monthsRef = useRef();

  const { addMessage } = useContext(MessageContext);

  const createCategoryMutation = useMutation({
    mutationFn: (value) => createPaymentType(value),
    onSuccess: ({ data }) => {
      addMessage('CREATED', 'success');
      setEdit(null);
    },
    onError: () => {
      addMessage('Sth went wrong', 'error');
    },
  });

  const updateCategoryMutation = useMutation({
    mutationFn: (value) => updatePaymentType(value),
    onSuccess: ({ data }) => {
      addMessage('UPDATED', 'success');
      setEdit(null);
    },
    onError: () => {
      addMessage('Sth went wrong', 'error');
    },
  });

  const handleSave = (e) => {
    e && e.preventDefault();

    const payload = edit;

    payload.label = labelRef.current.value;
    payload.value = valueRef.current.value;
    payload.for_months = parseInt(for_monthsRef.current.value);

    if (edit._id) {
      return updateCategoryMutation.mutate(payload);
    }

    createCategoryMutation.mutate(payload);
  };

  return (
    <>
      {(updateCategoryMutation.isLoading ||
        createCategoryMutation.isLoading) && <Loading />}
      <PopUp setClose={setEdit}>
        <Title>Category {edit._id ? 'Edit' : 'Create'}</Title>
        <Form onSubmit={(e) => handleSave(e)}>
          <Input
            inputRef={labelRef}
            name="Label"
            inputWidth={400}
            width={100}
            required
            requiredSign
            value={edit.label}
          />
          <Input
            inputRef={valueRef}
            name="Value"
            inputWidth={400}
            width={100}
            value={edit.value}
          />
          <Input
            inputRef={for_monthsRef}
            name="For months"
            inputWidth={400}
            width={100}
            required
            requiredSign
            value={edit.for_months}
          />
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <CustomSaveButton text="Save" />
          </div>
        </Form>
      </PopUp>
    </>
  );
};

export default AdminPaymentTypeEdit;
