import Api from '../api';
const { axios: api } = Api;
const queryString = require('query-string');

const PREFIX = 'price-config';

export const getPriceConfigs = async () => {
  const data = await api.get(`/${PREFIX}`);
  return { data: data.data };
};

export const createPriceConfig = async (payload) => {
  const data = await api.post(`/${PREFIX}`, payload);
  return { data: data.data };
};

export const updatePriceConfig = async (payload) => {
  const data = await api.patch(`/${PREFIX}/${payload._id}`, payload);
  return { data: data.data };
};

export const getCurrentPriceConfig = async (payload) => {
  const data = await api.get(`/${PREFIX}/current/${payload.country}`);
  return { data: data.data };
};
