import moment from 'moment';

export const getFormatedFullDate = (data) => {
  if (!data) {
    return '-----';
  }

  return moment(data).format('DD/MM/YYYY HH:mm:ss');
};

export const getFormatedFullDateTime = (data) => {
  if (!data) {
    return '-----';
  }

  return moment(data).format('YYYY-MM-DDTHH:mm');
};
