import PopUp from '@/common/components/PopUp';
import Input from '@/common/components/custom/Input';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { styled } from 'styled-components';
import Loading from '@/common/components/Loading';
import CustomSaveButton from '@/common/components/custom/buttons/CustomSaveButton';
import { MessageContext } from '@/common/contexts/MessageContext';
import {
  createPriceManagment,
  updatePriceManagment,
} from '@/api/query/currency';
import { createPriceConfig, updatePriceConfig } from '@/api/query/price-config';
import SelectInput from '@/common/components/custom/SelectInput';
import moment from 'moment';
import { getCountries } from '@/api/query/country';
import { getPrices } from '@/api/query/price';

export const ImageInput = styled.input`
  display: none;
`;
export const ImageWrapper = styled.div`
  width: 300px;
  height: 300px;
  overflow: hidden;
`;

const Title = styled.h2`
  text-align: center;
`;

const Form = styled.form`
  margin: 0px 30px;
  display: flex;
  flex-direction: column;
  height: 350px;
`;

const AdminPriceConfigManagmentEdit = ({ setEdit, edit }) => {
  const avaiableFromRef = useRef();
  const avaiableToRef = useRef();

  const [priceOptions, setPriceOptions] = useState([]);
  const [selectedPrices, setSelectedPrices] = useState([]);

  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();

  const { addMessage } = useContext(MessageContext);

  const getCountriesMutation = useMutation({
    mutationFn: (value) => getCountries(value),
    onSuccess: ({ data }) => {
      const countries = data.map((c) => ({ label: c.name, value: c._id }));
      setCountryOptions(() => countries);

      if (edit._id) {
        setSelectedCountry(() =>
          countries.find((c) => c.value === edit.country._id),
        );
      }
    },
    onError: () => {
      addMessage('Sth went wrong', 'error');
    },
  });

  const getPricesMutation = useMutation({
    mutationFn: (value) => getPrices(value),
    onSuccess: ({ data }) => {
      const prices = data.map((c) => ({ label: c.name, value: c._id }));
      setPriceOptions(() => prices);

      if (edit._id) {
        setSelectedPrices(() =>
          prices.filter((c) => edit.prices.map((p) => p._id).includes(c.value)),
        );
      }
    },
    onError: () => {
      addMessage('Sth went wrong', 'error');
    },
  });

  const createCategoryMutation = useMutation({
    mutationFn: (value) => createPriceConfig(value),
    onSuccess: ({ data }) => {
      addMessage('CREATED', 'success');
      setEdit(null);
    },
    onError: () => {
      addMessage('Sth went wrong', 'error');
    },
  });

  const updateCategoryMutation = useMutation({
    mutationFn: (value) => updatePriceConfig(value),
    onSuccess: ({ data }) => {
      addMessage('UPDATED', 'success');
      setEdit(null);
    },
    onError: () => {
      addMessage('Sth went wrong', 'error');
    },
  });

  const handleSave = (e) => {
    e && e.preventDefault();

    const payload = edit;

    payload.avaiableFrom = new Date(
      avaiableFromRef.current.value,
    ).toISOString();
    payload.avaiableTo = new Date(avaiableToRef.current.value).toISOString();
    payload.prices = selectedPrices.map((p) => p.value);
    payload.country = selectedCountry.value;

    if (edit._id) {
      return updateCategoryMutation.mutate(payload);
    }

    createCategoryMutation.mutate(payload);
  };

  useEffect(() => {
    getCountriesMutation.mutate();
    getPricesMutation.mutate();
  }, []);

  return (
    <>
      {(updateCategoryMutation.isLoading ||
        createCategoryMutation.isLoading) && <Loading />}
      <PopUp setClose={setEdit}>
        <Title>Category {edit._id ? 'Edit' : 'Create'}</Title>
        <Form onSubmit={(e) => handleSave(e)}>
          <Input
            inputRef={avaiableFromRef}
            name="From"
            type="datetime-local"
            inputWidth={370}
            width={140}
            required
            requiredSign
            value={moment(edit.avaiableFrom).format('YYYY-MM-DDTHH:mm')}
          />
          <Input
            inputRef={avaiableToRef}
            name="To"
            type="datetime-local"
            inputWidth={370}
            width={140}
            required
            requiredSign
            value={moment(edit.avaiableTo).format('YYYY-MM-DDTHH:mm')}
          />
          <SelectInput
            name={'Prices'}
            width={140}
            selectWidth={400}
            options={priceOptions}
            setSelected={setSelectedPrices}
            selected={selectedPrices}
            multiple
            required
          />
          <SelectInput
            name={'Country'}
            width={140}
            selectWidth={400}
            options={countryOptions}
            setSelected={setSelectedCountry}
            selected={selectedCountry}
            required
          />
          <div style={{ flex: '1' }} />
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <CustomSaveButton text="Save" />
          </div>
        </Form>
      </PopUp>
    </>
  );
};

export default AdminPriceConfigManagmentEdit;
