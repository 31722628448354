import Api from '../api';
const { axios: api } = Api;
const queryString = require('query-string');

const PREFIX = 'advert';

export const createAdvert = async (payload) => {
  const data = await api.post(`/${PREFIX}`, payload);
  return { data: data.data };
};

export const updateAdvert = async (payload) => {
  const data = await api.patch(`/${PREFIX}/${payload._id}`, payload);
  return { data: data.data };
};

export const getAdverts = async () => {
  const data = await api.get(`/${PREFIX}/`);
  return { data: data.data };
};
