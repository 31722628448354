import {
  createWordLessonTypeConfig,
  updateWordLessonTypeConfig,
} from '@/api/query/word-lesson-type-config';
import Loading from '@/common/components/Loading';
import PopUp from '@/common/components/PopUp';
import TextArea from '@/common/components/TextArea';
import CustomTable from '@/common/components/custom/CustomTable';
import Input from '@/common/components/custom/Input';
import CustomAddButton from '@/common/components/custom/buttons/CustomAddButton';
import CustomEditButton from '@/common/components/custom/buttons/CustomEditButton';
import CustomSaveButton from '@/common/components/custom/buttons/CustomSaveButton';
import { Border } from '@/pages/customer/profile/profile.styles';
import React, { useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { styled } from 'styled-components';
import EditGame from './EditGame';
import SelectInput from '@/common/components/custom/SelectInput';

const Wrapper = styled.div`
  padding: 10px;
`;

const Title = styled.h2`
  text-align: center;
`;

const SaveWrapper = styled.div`
  margin: 20px 0 10px 0;
  display: flex;
  justify-content: right;
`;

const TableWrapper = styled.div`
  max-width: 800px;
  max-height: 300px;
  overflow: auto;
`;

const Form = styled.form`
  margin: 0px 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const HEADERS = ['no.', 'Game name', 'Actions'];

const AdminWordLessonConfigEdit = ({ setEdit, edit, languages }) => {
  const [showGame, setShowGame] = useState();
  const [selectedLanguages, setSelectedLanguages] = useState(
    languages.find((l) => edit?.language?._id === l.value),
  );

  const [games, setGames] = useState(edit.restPrompts || []);
  const tableData = useMemo(() => {
    if (!games) {
      return [];
    } else {
      return games.map((g) => [
        g.id,
        g.name,
        <CustomEditButton onClick={() => setShowGame(g)} />,
      ]);
    }
  }, [games]);

  const nameRef = useRef();
  const activeRef = useRef();
  const weightRef = useRef();

  const welcomePromptRef = useRef();
  const welcomeFollowUpPromptRef = useRef();
  const welcomeQuestionPromptRef = useRef();
  const answerWelcomePromptRef = useRef();

  const wordPromptRef = useRef();
  const wordAnswerPrompRef = useRef();
  const wordQuestionPromptRef = useRef();
  const wordFollowUpPromptRef = useRef();

  const createWordLessonConfigMutation = useMutation({
    mutationFn: (value) => createWordLessonTypeConfig(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const updateWordLessonConfigMutation = useMutation({
    mutationFn: (value) => updateWordLessonTypeConfig(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const handleSave = (e) => {
    e && e.preventDefault();

    const payload = edit;
    payload.name = nameRef.current.value;
    payload.active = activeRef.current.checked;
    payload.wordPrompt = wordPromptRef.current.value;
    payload.wordAnswerPrompt = wordAnswerPrompRef.current.value;
    payload.wordQuestionPrompt = wordQuestionPromptRef.current.value;
    payload.wordFollowUpPrompt = wordFollowUpPromptRef.current.value;
    payload.restPrompts = games;
    payload.language = selectedLanguages.value;
    payload.weight = weightRef.current.value;

    if (edit._id) {
      return updateWordLessonConfigMutation.mutate(payload);
    }

    createWordLessonConfigMutation.mutate(payload);
  };

  return (
    <>
      {(updateWordLessonConfigMutation.isLoading ||
        createWordLessonConfigMutation.isLoading) && <Loading />}
      <PopUp setClose={setEdit}>
        <Title>Wrod lesson type config {edit._id ? 'Edit' : 'Create'}</Title>
        <Form onSubmit={(e) => handleSave(e)}>
          <div style={{ display: 'flex' }}>
            <Input
              inputRef={nameRef}
              name="Name"
              inputWidth={200}
              width={80}
              required
              requiredSign
              value={edit.name}
            />
            <Input
              inputRef={weightRef}
              name="Weight"
              inputWidth={200}
              width={100}
              required
              requiredSign
              value={edit.weight}
            />
            <Input
              inputRef={activeRef}
              name="Active"
              type="checkbox"
              inputWidth={20}
              width={80}
              checked={edit.active}
            />
          </div>
          <SelectInput
            width={100}
            name={'Language'}
            options={languages}
            selected={selectedLanguages}
            setSelected={setSelectedLanguages}
          />
          <Border style={{ background: ' red' }} />
          <Title>Word</Title>
          <TextArea
            textAreaRef={wordPromptRef}
            label="Opening prompt"
            defaultValue={edit.wordPrompt}
          />
          <TextArea
            textAreaRef={wordFollowUpPromptRef}
            label="Follow up prompt"
            defaultValue={edit.wordFollowUpPrompt}
          />
          <TextArea
            textAreaRef={wordQuestionPromptRef}
            label="Question / End rule prompt"
            defaultValue={edit.wordQuestionPrompt}
          />
          <TextArea
            textAreaRef={wordAnswerPrompRef}
            defaultValue={edit.wordAnswerPrompt}
            label="Answer prompt"
          />
          <Border style={{ background: 'red' }} />
          <TableWrapper>
            <CustomTable headers={HEADERS} data={tableData} />
          </TableWrapper>
          <SaveWrapper>
            <CustomAddButton onClick={() => setShowGame({})} />
            <CustomSaveButton />
          </SaveWrapper>
        </Form>
      </PopUp>
      {showGame && (
        <EditGame
          setClose={setShowGame}
          edit={showGame}
          handleAddGame={setGames}
        />
      )}
    </>
  );
};

export default AdminWordLessonConfigEdit;
