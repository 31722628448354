import CustomAddButton from '@/common/components/custom/buttons/CustomAddButton';
import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { useMutation } from 'react-query';
import CustomEditButton from '@/common/components/custom/buttons/CustomEditButton';
import Loading from '@/common/components/Loading';
import DataTable from '@/common/components/custom/DataTable';
import { getAdverts } from '@/api/query/advert';
import { getFormatedDate } from '@/common/functions/getFormatedDate';
import AdminSpendsEdit from './components/AdminSpendsEdit';

const TopContent = styled.div`
  display: flex;
  justify-content: right;
`;
const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

const AdminSpends = () => {
  const [tableData, setTableData] = useState([]);

  const HEADERS = [
    {
      header: 'no.',
      accessorKey: 'no',
      size: 20,
    },
    {
      header: 'Created at',
      accessorKey: 'created_at',
      size: 20,
    },
    {
      header: 'Source',
      accessorKey: 'source',
      size: 20,
    },
    {
      header: 'Day',
      accessorKey: 'for_day',
      size: 20,
    },
    {
      header: 'Spent ( netto )',
      accessorKey: 'spend',
      size: 20,
    },
    {
      header: 'Language',
      accessorKey: 'language',
      size: 20,
    },
    {
      header: 'Actions',
      accessorKey: 'actions',
      size: 20,
      cell: (info) => (
        <div style={{ display: 'flex', gap: '10px' }}>
          <CustomEditButton onClick={(e) => setEdit(info.getValue())} />
        </div>
      ),
    },
  ];

  const [edit, setEdit] = useState();

  const getAdvertMutation = useMutation({
    mutationFn: () => getAdverts(),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data
          .sort(
            (a, b) =>
              new Date(b.for_day).getTime() - new Date(a.for_day).getTime(),
          )
          .map((d, i) => ({
            no: i + 1,
            created_at: getFormatedDate(d.created_at),
            source: d.source,
            for_day: d.for_day,
            spend: Math.round(d.spend * 100) / 100,
            language: d.language,
            actions: d,
          })),
      );
    },
  });

  useEffect(() => {
    if (!edit) {
      getAdvertMutation.mutate();
    }
  }, [edit]);

  return (
    <Wrapper>
      {getAdvertMutation.isLoading && <Loading />}
      {edit && <AdminSpendsEdit edit={edit} setEdit={setEdit} />}
      <TopContent>
        <CustomAddButton onClick={() => setEdit({})} />
      </TopContent>
      <TableWrapper>
        <DataTable
          columns={HEADERS}
          data={tableData}
          rows={[10, 15, 20, 50, 100]}
        />
      </TableWrapper>
    </Wrapper>
  );
};

export default AdminSpends;
