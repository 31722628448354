import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import ArrowLeft from "./images/arrow-left.svg";
import { useMutation } from "react-query";
import { loadUserLearningData } from "@/api/query/user";
import Loading from "@/common/components/Loading";
import { MessageContext } from "@/common/contexts/MessageContext";
import { useNavigate } from "react-router-dom";
import { updateCustomerLevel } from "@/api/query/customer";
import { useLanguageData } from "@/common/hooks/useLanguageData";

const IconTitleWrapper = styled.div`
  width: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const TopBar = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 600px) {
    padding: 0 20px;
    max-width: 1200px;
  }
`;

const Title = styled.div`
  text-align: left;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 123.5%; /* 17.29px */
  letter-spacing: 0.28px;
  color: ${(props) => (props?.isActive ? "white" : "#29185e")} !important;
  max-width: 500px;
`;

const Description = styled.div`
  text-align: left;
  font-family: "Work Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
  color: ${(props) => (props?.isActive ? "white" : "#29185e")};
  max-width: 400px;
`;

const Card = styled.div`
  margin-top: 20px;
  border-radius: 16px;
  background: #fff;
  width: 90vw;
  box-sizing: border-box;
  margin: 20px auto;
  max-width: 500px !important;

  @media (min-width: 600px) {
    border: 1px solid #d9d9d9;
    overflow: auto;
  }
`;

export const LevelOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const Element = styled.div`
  width: 100%;
  display: inline-flex;
  box-sizing: border-box;
  cursor: pointer;
  padding: 16px 14px 16px 14px;
  flex-direction: column;
  gap: 6px;
  border-radius: ${(props) => props.borderRadius};
  background: ${(props) => (props.isActive ? "#730bdb" : "white")};
  border-bottom: 1px solid #ece5f2;
  max-width: 500px;
`;

const TitleTop = styled.div`
  color: var(--Text-color, #29185e);
  text-align: center;
  font-family: "Work Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
`;

const SubTilte = styled.div`
  color: #29185e;
  text-align: center;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 123.5%; /* 17.29px */
  letter-spacing: 0.28px;
`;

const ProfileLevel = () => {
  const [userData, setUserData] = useState({});
  const bottom = useLanguageData("profile.bottom");
  const navigate = useNavigate();

  const { addMessage } = useContext(MessageContext);

  const loadUserMutation = useMutation({
    mutationFn: () => loadUserLearningData(),
    onSuccess: ({ data }) => {
      setUserData(data);
    },
  });

  const changeUserLevelMutation = useMutation({
    mutationFn: (data) => updateCustomerLevel(data),
    onSuccess: ({ data }) => {
      loadUserMutation.mutate();
      addMessage(bottom.englishLevelChanged, "success");
    },
    onError: () => {
      addMessage(bottom.error, "error");
    },
  });

  const handleLevelMinutes = (type) => {
    const payload = {
      level: type,
    };

    changeUserLevelMutation.mutate(payload);
  };

  useEffect(() => {
    loadUserMutation.mutate();
  }, []);

  return (
    <>
      {(changeUserLevelMutation.isLoading || loadUserMutation.isLoading) && (
        <Loading />
      )}
      <TopBar style={{ marginTop: "24px" }}>
        <IconTitleWrapper>
          <img src={ArrowLeft} onClick={() => navigate("/profile/learning")} />
        </IconTitleWrapper>
        <TitleTop>{bottom.languageLevel}</TitleTop>
        <IconTitleWrapper />
      </TopBar>
      <SubTilte>{bottom.changeYourLangLevel}</SubTilte>
      <Card>
        {bottom.levelsOptions.map((c) => (
          <Element
            isActive={userData?.level === c.value}
            borderRadius={c.borderRadius}
            onClick={() => handleLevelMinutes(c.value)}
          >
            <Title isActive={userData?.level === c.value}>{c.name}</Title>
            <Description isActive={userData?.level === c.value}>
              {c.description}
            </Description>
          </Element>
        ))}
      </Card>
    </>
  );
};

export default ProfileLevel;
