import Api from "../api";
const { axios: api } = Api;
const queryString = require("query-string");

const PREFIX = "web-push-notification";

export const getNotiFications = async () => {
  const data = await api.get(`/${PREFIX}`);
  return { data: data.data };
};

export const createNotifications = async (payload) => {
  const data = await api.post(`/${PREFIX}`, payload);
  return { data: data.data };
};

export const updateNotifications = async (payload) => {
  const data = await api.patch(`/${PREFIX}/${payload._id}`, payload);
  return { data: data.data };
};
