import PopUp from '@/common/components/PopUp';
import Input from '@/common/components/custom/Input';
import { getBase64 } from '@/common/functions/getBase64';
import React, { useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { styled } from 'styled-components';
import Image from '@/common/icons/image-icon.svg';
import Loading from '@/common/components/Loading';
import CustomSaveButton from '@/common/components/custom/buttons/CustomSaveButton';
import { createNews, updateNews } from '@/api/query/news';
import moment from 'moment';
import Translations from '@/common/components/Translations';
import TextArea from '@/common/components/TextArea';
import SelectInput from '@/common/components/custom/SelectInput';
import { updateTranslation } from '@/api/query/translation';

export const ImageInput = styled.input`
  display: none;
`;
export const ImageWrapper = styled.div`
  width: 300px;
  height: 300px;
  overflow: hidden;
`;

const Title = styled.h2`
  text-align: center;
`;

const Form = styled.form`
  margin: 0px 30px;
  display: flex;
  flex-direction: column;
  height: 400px;
`;

const SaveButton = styled.button`
  margin: 10px 0 5px 0;
  align-self: center;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #650675;
  outline: none;
  border: none;
  color: #fff;

  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
`;

const TRANSLATION_STATUS_OPTIONS = [
  { label: 'Verified', value: 'verified' },
  { label: 'Wrong', value: 'wrong' },
  { label: 'To Verify', value: 'to_verify' },
];

const AdminTranslationEdit = ({ setEdit, edit }) => {
  const translationRef = useRef();

  const handleGetBaseGrade = () => {
    if (edit.translation_status.length) {
      edit.translation_status.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      );

      return TRANSLATION_STATUS_OPTIONS.find(
        (to) => to.value === edit.translation_status[0].status,
      );
    }

    return null;
  };

  const [selectedGrade, setSelectedGrade] = useState(handleGetBaseGrade());

  const updateCategoryMutation = useMutation({
    mutationFn: (value) => updateTranslation(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const handleSave = (e) => {
    e && e.preventDefault();

    const payload = edit;
    payload.translation = translationRef.current.value;
    payload.status = selectedGrade.value;

    return updateCategoryMutation.mutate(payload);
  };

  return (
    <>
      {updateCategoryMutation.isLoading && <Loading />}
      <PopUp setClose={setEdit}>
        <Title>Translation Edit</Title>
        <Form onSubmit={(e) => handleSave(e)}>
          <div>Orginal: {edit.orginal}</div>
          <TextArea
            textAreaRef={translationRef}
            defaultValue={edit.translation}
          />
          <SelectInput
            options={TRANSLATION_STATUS_OPTIONS}
            selected={selectedGrade}
            setSelected={setSelectedGrade}
            name={'Grade'}
          />
          <div style={{ flex: 1 }} />
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <CustomSaveButton text="Save" />
          </div>
        </Form>
      </PopUp>
    </>
  );
};

export default AdminTranslationEdit;
