import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import CookieContextProvider from "./common/contexts/CookieContext";
import LanguageContextProvider from "./common/contexts/LanguageContext";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <LanguageContextProvider>
        <CookieContextProvider>
          <App />
        </CookieContextProvider>
      </LanguageContextProvider>
    </QueryClientProvider>
  </BrowserRouter>
);
