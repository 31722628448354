import PopUp from '@/common/components/PopUp';
import Input from '@/common/components/custom/Input';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { styled } from 'styled-components';
import Loading from '@/common/components/Loading';
import CustomSaveButton from '@/common/components/custom/buttons/CustomSaveButton';
import { MessageContext } from '@/common/contexts/MessageContext';
import SelectInput from '@/common/components/custom/SelectInput';
import NewCustomTable from '@/common/components/custom/NewCustomTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { createAdvert, updateAdvert } from '@/api/query/advert';
import { findNativeLanguages } from '@/api/query/nativeLanguages';

export const ImageInput = styled.input`
  display: none;
`;
export const ImageWrapper = styled.div`
  width: 300px;
  height: 300px;
  overflow: hidden;
`;

const Title = styled.h2`
  text-align: left;
`;

const Form = styled.form`
  margin: 0px 30px;
  display: flex;
  flex-direction: column;
  height: 400px;
`;

const SOURCES_OPTIONS = [
  { label: 'Meta', value: 'meta' },
  { label: 'MS', value: 'microsoft' },
  { label: 'Google', value: 'google' },
];

const AdminSpendsEdit = ({ setEdit, edit }) => {
  const [selectedSources, setSelectedSources] = useState(
    SOURCES_OPTIONS.find((s) => s.value === edit.source) || SOURCES_OPTIONS[0],
  );
  const [showDeatails, setShowDetails] = useState();
  const [languagesOptions, setLanguagesOptions] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState();

  const forDayRef = useRef();
  const spendRef = useRef();

  const { addMessage } = useContext(MessageContext);

  const createAdvertMutation = useMutation({
    mutationFn: (value) => createAdvert(value),
    onSuccess: ({ data }) => {
      addMessage('CREATED', 'success');
      setEdit(null);
    },
    onError: () => {
      addMessage('Sth went wrong', 'error');
    },
  });

  const updateAdvertMutation = useMutation({
    mutationFn: (value) => updateAdvert(value),
    onSuccess: ({ data }) => {
      addMessage('UPDATED', 'success');
      setEdit(null);
    },
    onError: () => {
      addMessage('Sth went wrong', 'error');
    },
  });

  const findLanugagesMutation = useMutation({
    mutationFn: (value) => findNativeLanguages(value),
    onSuccess: ({ data }) => {
      const languages = data.map((l) => ({ label: l.name, value: l.short }));
      setLanguagesOptions(() => languages);
      setSelectedLanguage(() =>
        languagesOptions.find((l) => l.value === edit.language),
      );
    },
    onError: () => {
      addMessage('Sth went wrong', 'error');
    },
  });

  const handleSave = (e) => {
    e && e.preventDefault();

    const payload = { ...edit };
    payload.source = selectedSources.value;
    payload.for_day = forDayRef.current.value;
    payload.spend = spendRef.current.value;
    payload.language = selectedLanguage.value;

    if (edit._id) {
      return updateAdvertMutation.mutate(payload);
    }

    createAdvertMutation.mutate(payload);
  };

  useEffect(() => {
    findLanugagesMutation.mutate();
  }, []);

  return (
    <>
      {(updateAdvertMutation.isLoading ||
        createAdvertMutation.isLoading ||
        findLanugagesMutation.isLoading) && <Loading />}
      <PopUp setClose={setEdit}>
        <Title>Spent {edit._id ? 'Edit' : 'Create'}</Title>
        <Form onSubmit={handleSave}>
          <SelectInput
            name="Source"
            width={200}
            selectWidth={200}
            options={SOURCES_OPTIONS}
            setSelected={setSelectedSources}
            selected={selectedSources}
          />
          <SelectInput
            name="Language"
            width={200}
            selectWidth={200}
            options={languagesOptions}
            setSelected={setSelectedLanguage}
            selected={selectedLanguage}
          />
          <Input
            inputRef={forDayRef}
            name={'For day'}
            type="date"
            width={200}
            inputWidth={180}
            value={edit.for_day}
          />
          <Input
            inputRef={spendRef}
            name={'Spent ( netto zł )'}
            type="number"
            width={200}
            inputWidth={180}
            value={edit.spend}
          />
          <NewCustomTable
            headers={['no.', 'adset_name', 'spent ( netto zł )', 'actions']}
            data={edit.AdSet?.map((d, i) => [
              i + 1,
              d.adset_name,
              d.spend,
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <FontAwesomeIcon
                  icon={faEye}
                  onClick={() => setShowDetails(d)}
                />
              </div>,
            ])}
          />
          <div
            style={{
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'right',
            }}
          >
            <CustomSaveButton />
          </div>
        </Form>
      </PopUp>
      {showDeatails && (
        <PopUp setClose={setShowDetails}>
          <NewCustomTable
            headers={['no.', 'type', 'cost ( netto zł )']}
            data={showDeatails.cost_per_action_type?.map((d, i) => [
              i + 1,
              d.action_type,
              Math.round(d.value * 100) / 100,
            ])}
          />
        </PopUp>
      )}
    </>
  );
};

export default AdminSpendsEdit;
