import PopUp from '@/common/components/PopUp';
import Input from '@/common/components/custom/Input';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { styled } from 'styled-components';
import Loading from '@/common/components/Loading';
import CustomSaveButton from '@/common/components/custom/buttons/CustomSaveButton';
import { MessageContext } from '@/common/contexts/MessageContext';
import SelectInput from '@/common/components/custom/SelectInput';
import { createPromotion, updatePromotion } from '@/api/query/promotion';
import moment from 'moment';

export const ImageInput = styled.input`
  display: none;
`;
export const ImageWrapper = styled.div`
  width: 300px;
  height: 300px;
  overflow: hidden;
`;

const Title = styled.h2`
  text-align: center;
`;

const Form = styled.form`
  margin: 0px 30px;
  display: flex;
  flex-direction: column;
  height: 400px;
`;

const PROMOTION_TYPE = {
  FOR_DAY_PROMO: 'for_day',
  X_DAY_PROMO: 'x_day_promo',
};

const AdminPromotionEdit = ({ setEdit, edit }) => {
  const [promotionTypeOptions] = useState([
    { label: 'For Day', value: PROMOTION_TYPE.FOR_DAY_PROMO },
    { label: 'X Day Promo', value: PROMOTION_TYPE.X_DAY_PROMO },
  ]);
  const [selectedPromotionType, setSelectedPromotionType] = useState(null);

  const fromDayRef = useRef();
  const toDayRef = useRef();
  const fromXDaysRef = useRef();
  const toXDaysRef = useRef();
  const percentRef = useRef();

  const { addMessage } = useContext(MessageContext);

  const createPromotionMutation = useMutation({
    mutationFn: (value) => createPromotion(value),
    onSuccess: ({ data }) => {
      addMessage('CREATED', 'success');
      setEdit(null);
    },
    onError: () => {
      addMessage('Sth went wrong', 'error');
    },
  });

  const updatePromotionMutation = useMutation({
    mutationFn: (value) => updatePromotion(value),
    onSuccess: ({ data }) => {
      addMessage('UPDATED', 'success');
      setEdit(null);
    },
    onError: () => {
      addMessage('Sth went wrong', 'error');
    },
  });

  const handleSave = (e) => {
    e && e.preventDefault();

    const payload = { ...edit };

    payload.promotion_type = selectedPromotionType.value;
    payload.percent = percentRef.current.value;

    if (selectedPromotionType.value === PROMOTION_TYPE.FOR_DAY_PROMO) {
      payload.from_day = moment(fromDayRef.current.value).endOf('day');
      payload.to_day = moment(toDayRef.current.value).startOf('day');
    } else if (selectedPromotionType.value === PROMOTION_TYPE.X_DAY_PROMO) {
      payload.from_x_days = parseInt(fromXDaysRef.current.value);
      payload.to_x_days = parseInt(toXDaysRef.current.value);
    }

    if (edit._id) {
      return updatePromotionMutation.mutate(payload);
    }

    createPromotionMutation.mutate(payload);
  };

  useEffect(() => {
    if (edit._id) {
      setSelectedPromotionType(
        promotionTypeOptions.find(
          (option) => option.value === edit.promotion_type,
        ),
      );
    }
  }, [edit, promotionTypeOptions]);

  return (
    <>
      {(updatePromotionMutation.isLoading ||
        createPromotionMutation.isLoading) && <Loading />}
      <PopUp setClose={setEdit}>
        <Title>Promotion {edit._id ? 'Edit' : 'Create'}</Title>
        <Form onSubmit={handleSave}>
          <SelectInput
            name="Promotion Type"
            width={100}
            selectWidth={400}
            options={promotionTypeOptions}
            setSelected={setSelectedPromotionType}
            selected={selectedPromotionType}
          />
          {selectedPromotionType &&
            selectedPromotionType.value === PROMOTION_TYPE.FOR_DAY_PROMO && (
              <>
                <Input
                  inputRef={fromDayRef}
                  name="From Day"
                  inputWidth={400}
                  width={100}
                  type="date"
                  defaultValue={edit.from_day}
                />
                <Input
                  inputRef={toDayRef}
                  name="To Day"
                  inputWidth={400}
                  width={100}
                  type="date"
                  defaultValue={edit.to_day}
                />
              </>
            )}
          {selectedPromotionType &&
            selectedPromotionType.value === PROMOTION_TYPE.X_DAY_PROMO && (
              <>
                <Input
                  inputRef={fromXDaysRef}
                  name="From X Days"
                  inputWidth={400}
                  width={100}
                  type="number"
                  defaultValue={edit.from_x_days}
                />
                <Input
                  inputRef={toXDaysRef}
                  name="To X Days"
                  inputWidth={400}
                  width={100}
                  type="number"
                  defaultValue={edit.to_x_days}
                />
              </>
            )}
          <Input
            inputRef={percentRef}
            name="Percent"
            inputWidth={400}
            width={100}
            type="number"
            defaultValue={edit.percent}
          />
          <div style={{ flex: 1 }} />
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <CustomSaveButton text="Save" />
          </div>
        </Form>
      </PopUp>
    </>
  );
};

export default AdminPromotionEdit;
