import React, { useEffect, useState } from 'react';
import SearchBar from './components/SearchBar';
import { useMutation } from 'react-query';
import { loadMailConfigs } from '@/api/query/mailConfig';
import styled from 'styled-components';
import { getMailSearchReport } from '@/api/query/report';
import NewCustomTable from '@/common/components/custom/NewCustomTable';
import CustomTable from '@/common/components/custom/CustomTable';
import Loading from '@/common/components/Loading';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const HEADERS = [
  'no',
  'name',
  'Requested',
  'To send',
  'Sent',
  'Delivered',
  'Open',
  'Click',
  'Bounced',
  'Buy',
  'Buy After 24 Hours ',
];

const MailReport = () => {
  const [options, setOptions] = useState();
  const [data, setData] = useState([]);

  const round = (n) => {
    return Math.round(n * 10000) / 100;
  };

  const mailSearchMutation = useMutation({
    mutationFn: (payload) => getMailSearchReport(payload),
    onSuccess: ({ data }) => {
      setData(() =>
        data.map((d) => [
          d.no,
          d.name,
          d.all,
          `${d.to_send} (${round(d.to_send / d.all)}%)`,
          `${d.sent} (${round(d.sent / d.all)}%)`,
          `${d.delivered} (${round(d.delivered / d.sent)}%)`,
          `${d.open} (${round(d.open / d.sent)}%)`,
          `${d.click} (${round(d.click / d.sent)}%)`,
          `${d.bounced} (${round(d.bounced / d.sent)}%)`,
          `${d.buy} (${round(d.buy / d.delivered)}%)`,
          `${d.buyAfter24HoursAfterMail} (${round(
            d.buyAfter24HoursAfterMail / d.delivered,
          )}%)`,
        ]),
      );
    },
  });

  const mailConfigsMutation = useMutation({
    mutationFn: () => loadMailConfigs(),
    onSuccess: ({ data }) =>
      setOptions(() => data.map((d) => ({ label: d.name, value: d._id }))),
  });

  const handleSearch = (payload) => {
    setData(() => []);
    mailSearchMutation.mutate(payload);
  };

  useEffect(() => {
    mailConfigsMutation.mutate();
  }, []);

  return (
    <>
      {mailConfigsMutation.isLoading && <Loading />}
      <Wrapper>
        <SearchBar options={options} handleSearch={handleSearch} />
        <div
          style={{
            backgroundColor: 'white',
            padding: '10px',
            borderRadius: '14px',
            marginTop: '20px',
          }}
        >
          <NewCustomTable headers={HEADERS} data={data} />
        </div>
      </Wrapper>
    </>
  );
};

export default MailReport;
