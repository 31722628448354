import Api from '../api';
const { axios: api } = Api;
const queryString = require('query-string');

const PREFIX = 'populare';

export const createPopulare = async (payload) => {
  const data = await api.post(`/${PREFIX}`, payload);
  return { data: data.data };
};

export const updatePopulare = async (payload) => {
  const data = await api.patch(`/${PREFIX}`, payload);
  return { data: data.data };
};

export const findAllPopulare = async (payload) => {
  const data = await api.get(`/${PREFIX}/`, payload);
  return { data: data.data };
};
