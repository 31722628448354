import PopUp from '@/common/components/PopUp';
import Input from '@/common/components/custom/Input';
import { getBase64 } from '@/common/functions/getBase64';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { styled } from 'styled-components';
import Image from '@/common/icons/image-icon.svg';
import SelectInput from '@/common/components/custom/SelectInput';
import Loading from '@/common/components/Loading';
import { getWordLessonTopType } from '@/api/query/word-lesson-top-type';
import CustomSaveButton from '@/common/components/custom/buttons/CustomSaveButton';
import { createNews, updateNews } from '@/api/query/news';
import TextArea from '@/common/components/TextArea';
import moment from 'moment';
import Translations from '@/common/components/Translations';

export const ImageInput = styled.input`
  display: none;
`;
export const ImageWrapper = styled.div`
  width: 300px;
  height: 300px;
  overflow: hidden;
`;

const Title = styled.h2`
  text-align: center;
`;

const Form = styled.form`
  margin: 0px 30px;
  display: flex;
  flex-direction: column;
`;

const SaveButton = styled.button`
  margin: 10px 0 5px 0;
  align-self: center;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #650675;
  outline: none;
  border: none;
  color: #fff;

  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
`;

const AdminNewsEdit = ({ setEdit, edit }) => {
  const nameRef = useRef();
  const imageRef = useRef();
  const dateRef = useRef();
  const [image, setImage] = useState(edit?.image?.base64);
  const [translations, setTranslations] = useState(edit.translations || []);

  const createCategoryMutation = useMutation({
    mutationFn: (value) => createNews(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const updateCategoryMutation = useMutation({
    mutationFn: (value) => updateNews(value),
    onSuccess: ({ data }) => {
      setEdit(null);
    },
  });

  const handleSave = (e) => {
    e && e.preventDefault();

    const payload = edit;
    payload.translations = translations;
    payload.for_date = moment(dateRef.current.value).startOf('date').toDate();
    payload.image = image;

    if (edit._id) {
      return updateCategoryMutation.mutate(payload);
    }

    createCategoryMutation.mutate(payload);
  };

  const handleSelectFile = async () => {
    const file = imageRef.current.files[0];
    const base64 = await getBase64(file);

    setImage(base64);
  };

  return (
    <>
      {(updateCategoryMutation.isLoading ||
        createCategoryMutation.isLoading) && <Loading />}
      <PopUp setClose={setEdit}>
        <Title>Category {edit._id ? 'Edit' : 'Create'}</Title>
        <Form onSubmit={(e) => handleSave(e)}>
          <Input
            inputRef={dateRef}
            name="Date"
            type="Date"
            inputWidth={200}
            width={100}
            required
            requiredSign
            value={moment(edit.avaiable_from).format('YYYY-MM-DD')}
          />
          <ImageContainer>
            <ImageWrapper>
              <ImageInput
                id="file-input"
                type="file"
                name="cv"
                ref={imageRef}
                onChange={handleSelectFile}
              />
              <label htmlFor="file-input">
                <img
                  alt=""
                  src={image || Image}
                  style={{ width: '100%', height: 'auto' }}
                />
              </label>
            </ImageWrapper>
          </ImageContainer>
          <div style={{ display: 'flex', justifyContent: 'right' }}>
            <CustomSaveButton text="Save" />
          </div>
        </Form>
      </PopUp>
    </>
  );
};

export default AdminNewsEdit;
