import React from 'react';
import DataTable from '@/common/components/custom/DataTable';
import { TableWrapper } from '../styles';
import { formatDate } from '@/pages/admin/mail/AdminMail';

const ACTIVITY_HEADERS = [
  {
    header: 'No.',
    accessorKey: 'no',
    size: 50,
  },
  {
    header: 'Created At',
    accessorKey: 'created_at',
    size: 150,
  },
  {
    header: 'Type',
    accessorKey: 'type',
    size: 100,
  },
  {
    header: 'Source',
    accessorKey: 'source',
    size: 100,
  },
  {
    header: 'Detail',
    accessorKey: 'detail',
    size: 200,
  },
  {
    header: 'Person',
    accessorKey: 'person',
    size: 250,
  },
  {
    header: 'Language',
    accessorKey: 'language',
    size: 250,
  },
  {
    header: 'Message',
    accessorKey: 'message',
    size: 250,
  },
];

const ActivityTable = ({ stats }) => {
  const sortedStats = [...stats].sort(
    (a, b) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
  );

  const tableData = sortedStats.map((stat, index) => ({
    no: index + 1,
    created_at: formatDate(stat.created_at),
    type: stat.type,
    source: stat.source,
    person: stat?.aiPerson?.name || '----',
    language: stat?.aiPerson?.language?.name || '----',
    detail:
      stat.lesson?.name ||
      stat.wordLessonType?.name ||
      stat.flashcardCategories?.name ||
      '------',
    message: stat.message,
  }));

  return (
    <TableWrapper>
      <h3>Activity:</h3>
      <div>
        <DataTable columns={ACTIVITY_HEADERS} data={tableData} />
      </div>
    </TableWrapper>
  );
};

export default ActivityTable;
