import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import AdminFlashcardCategoryEdit from './components/AdminTranslationEdit';
import { useMutation } from 'react-query';
import CustomEditButton from '@/common/components/custom/buttons/CustomEditButton';
import Loading from '@/common/components/Loading';
import DataTable from '@/common/components/custom/DataTable';
import moment from 'moment';
import { findTranslationByTypeAndLang } from '@/api/query/translation';
import SearchBar from './components/SearchBar';

const TopContent = styled.div`
  display: flex;
  justify-content: right;
`;
const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
`;

const ActionWrapper = styled.div`
  margin-bottom: 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

export const TRANSLATION_STATUS_OPTIONS = [
  { label: 'Verified', value: 'verified' },
  { label: 'Wrong', value: 'wrong' },
  { label: 'To Verify', value: 'to_verify' },
];

const AdminTranslation = () => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedLang, setSelectedLang] = useState([]);
  const [selectedType, setSelectedType] = useState([]);

  const HEADERS = [
    {
      header: 'no.',
      accessorKey: 'no',
      size: 20,
    },
    {
      header: 'Created at',
      accessorKey: 'created_at',
      size: 20,
    },
    {
      header: 'Orginal',
      accessorKey: 'orginal',
      size: 20,
    },
    {
      header: 'Translation',
      accessorKey: 'translation',
      size: 20,
    },
    {
      header: 'Translation Status',
      accessorKey: 'translation_status',
      size: 20,
    },
    {
      header: 'Actions',
      accessorKey: 'actions',
      size: 20,
      cell: (info) => (
        <div style={{ display: 'flex', gap: '10px' }}>
          <CustomEditButton onClick={(e) => setEdit(info.getValue())} />
        </div>
      ),
    },
  ];

  const [edit, setEdit] = useState();

  const getTranslationStatus = (translationStatus) => {
    if (translationStatus.length > 0) {
      translationStatus.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      );

      return (
        translationStatus[0].status +
        ' by ' +
        translationStatus[0]?.marked_by?.email
      );
    }

    return '------';
  };

  const findFlashcardCategoriesMutation = useMutation({
    mutationFn: (payload) => findTranslationByTypeAndLang(payload),
    onSuccess: ({ data }) => {
      setTableData(() =>
        data.map((d, i) => ({
          no: i + 1,
          created_at: moment(d.created_at).format('YYYY/MM/DD'),
          orginal: d.orginal,
          translation: d.translation,
          translation_status: getTranslationStatus(d.translation_status),
          actions: d,
        })),
      );
      setData(data);
    },
  });

  const handleSearch = (payload) => {
    findFlashcardCategoriesMutation.mutate(payload);
  };

  useEffect(() => {
    if (!edit && selectedLang && selectedType) {
      const payload = {};
      payload.lang = selectedLang.value;
      payload.type = selectedType.value;

      handleSearch(payload);
    }
  }, [edit]);

  return (
    <>
      <Wrapper>
        {findFlashcardCategoriesMutation.isLoading && <Loading />}
        {edit && <AdminFlashcardCategoryEdit edit={edit} setEdit={setEdit} />}
        <TopContent>
          <SearchBar
            handleSearch={handleSearch}
            selectedLang={selectedLang}
            setSelectedLang={setSelectedLang}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
          />
        </TopContent>
        <TableWrapper>
          <DataTable columns={HEADERS} data={tableData} rows={[10, 15, 20]} />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default AdminTranslation;
