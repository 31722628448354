import React from 'react';
import { Card, Title, InfoWrapper, P } from '../styles';

const UtmDetails = ({ utm }) => (
  <Card>
    <Title>Utms:</Title>
    <InfoWrapper>
      <P>
        <p>Campaign:</p>
        <b>{utm?.utm_campaign?.toString() || '------'}</b>
      </P>
      <P>
        <p>Medium:</p>
        <b>{utm?.utm_medium?.toString() || '------'}</b>
      </P>
      <P>
        <p>Source:</p>
        <b>{utm?.utm_source?.toString() || '------'}</b>
      </P>
      <P>
        <p>Content:</p>
        <b>{utm?.utm_content?.toString() || '------'}</b>
      </P>
      <P>
        <p>Term:</p>
        <b>{utm?.utm_term?.toString() || '------'}</b>
      </P>
      <P>
        <p>Fbclid:</p>
        <b>{utm?.utm_fbclid?.toString() || '------'}</b>
      </P>
      <P>
        <p>Msclkid:</p>
        <b>{utm?.msclkid?.toString() || '------'}</b>
      </P>
    </InfoWrapper>
  </Card>
);

export default UtmDetails;
